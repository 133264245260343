import React, { useEffect, useState, useContext } from 'react'
import { CircularProgress } from '@mui/material'
import '../../css/bulkupload.css'
import FileUploader from './FileUploader'
import Preview from './Preview'
import UploadIcon from '@mui/icons-material/Upload'
import DownloadIcon from '@mui/icons-material/Download'
import { toast, ToastContainer } from 'react-toastify'
import { DataSubmission } from '../Other/DataSubmission'
import { CSVLink } from "react-csv";
import AuthContext from "../../AuthContext/AuthContext"
import NegativeDataUploadPanel from './NegativeDataUploadPanel'
import { ValuesContext } from '../Admin/HomeAdmin'

//Main bulk form for all the bulk uploads 
const BulkForm = ({ data }) => {
    const { userData } = useContext(ValuesContext)
    const [arraydata, setData] = useState([])
    const [csvdata] = useState({ data: '' })
    const [state, setState] = useState('1')
    const [creditorName, setCreditorName] = useState('')
    const [dataType, setDataType] = useState('')
    const [dataSource, setDataSource] = useState('')
    const [debtorType, setDebtorType] = useState('')
    const [creditType, setCreditType] = useState('')
    const { authTokens, logoutUser } = useContext(AuthContext);
    let negativetable = ''

    //clear the data and trigger reset on the file  if changes are made to the types
    useEffect(() => {
        if (data === 'Negative Data' || data === 'Active Accounts' || data === 'Hire Purchase') {
            setData([])
        }
    }, [dataType, debtorType])

    //allocating endpoints depending on the bulk file
    var endpoint
    if (data === 'Individuals BIU') {
        endpoint = '/bulk-individual-log-biu/'
    }
    if (data === 'Individual Credit Reports') {
        endpoint = '/individual-cr-bulk/'
    }
    if (data === 'Company Credit Reports') {
        endpoint = '/company-cr-bulk/'
    }
    if (data === 'Individuals') {
        endpoint = '/bulk-individual/'
    }
    if (data === 'Companies') {
        endpoint = '/bulk-company/'
    }

    if (data === 'Negative Data') {
        if (debtorType === 'Company') {
            endpoint = '/bulk-company-claim/'
            negativetable = 'Company Claims'
        }

        if (debtorType === 'Individual') {
            endpoint = '/bulk-individual-claim/'
            negativetable = 'Individual Claims'
        }
    }

    if (data === 'Active Accounts') {
        if (debtorType === 'Company') {
            endpoint = '/bulk-company-active/'
            negativetable = 'Company Active Accounts'
        }
        if (debtorType === 'Individual') {
            endpoint = '/bulk-individual-active/'
            negativetable = 'Individual Active Accounts'
        }
    }

    if (data === 'Hire Purchase') {
        if (debtorType === 'Company') {
            endpoint = '/bulk-hirepurchase-company/'
            negativetable = 'Company Hire Purchase'
        }
        if (debtorType === 'Individual') {
            endpoint = '/bulk-hirepurchase-individual/'
            negativetable = 'Individual Hire Purchase'
        }
    }

    //submitting the bulk data
    const submitData = async () => {
        var creditor;
        if (creditorName !== '') {
            if (userData.data.groups[0] === 3 || userData.data.groups[0] === 4) {
                creditor = creditorName
            } else {
                creditor = creditorName.value
            }
        }
        if (data === 'Hire Purchase') {
            if (debtorType === '' || creditType === '' || dataSource === '' || creditorName === '') {
                toast.warning('Fill in all information above to upload!')
            } else {
                var subData = {
                    financier_name: creditor,
                    data_source: dataSource,
                    data_date: creditType,
                    data_type: debtorType,
                    data: arraydata
                }

                if (arraydata.length <= 0) {
                    toast.warning('No file available for upload!')
                } else {
                    setState('2')
                    const responsed = await DataSubmission(String(authTokens.access), 'post', endpoint, subData)
                    if (responsed[0].result === 'success') {
                        csvdata.data = responsed[1].request_response.data
                        setState('0')
                    } else {
                        setState('3')
                    }
                }
            }
        } else if (data === 'Active Accounts') {
            if (debtorType === '' || creditType === '') {
                toast.warning('Select the debtor and credit types to proceed!')
            } else {
                var subData = {
                    creditor_name: creditor,
                    data_source: dataSource,
                    credit_type: creditType,
                    debtor_type: debtorType,
                    data: arraydata
                }

                if (arraydata.length <= 0) {
                    toast.warning('No file available for upload!')
                } else {
                    setState('2')
                    const responsed = await DataSubmission(String(authTokens.access), 'post', endpoint, subData)
                    if (responsed[0].result === 'success') {
                        csvdata.data = responsed[1].request_response.data
                        setState('0')
                    } else {
                        setState('3')
                    }
                }
            }
        }
        else if (data === 'Negative Data') {
            if (creditorName === '' || dataSource === '' || dataType === '' || debtorType === '') {
                toast.warning('Fill in all the details in the panel above!')
            } else {
                var subData = {
                    creditor_name: creditor,
                    data_type: dataType,
                    data_source: dataSource,
                    debtor_type: debtorType,
                    data: arraydata
                }
                if (arraydata.length <= 0) {
                    toast.warning('No file available for upload!')
                } else {
                    setState('2')
                    const responsed = await DataSubmission(String(authTokens.access), 'post', endpoint, subData)
                    if (responsed[0].result === 'success') {
                        csvdata.data = responsed[1].request_response.data
                        setState('0')
                    } else {
                        setState('3')
                    }
                }
            }
        } else {
            var subData = {
                data: arraydata,
                user: userData.data.username
            }
            if (arraydata.length <= 0) {
                toast.warning('No file available for upload!')
            } else {
                setState('2')
                const responsed = await DataSubmission(String(authTokens.access), 'post', endpoint, subData)
                if (responsed[0].result === 'success') {
                    csvdata.data = responsed[1].request_response.data
                    setState('0')
                } else {
                    setState('3')
                }
            }
        }
    }
    return (
        <div className='mainContainer'>
            <div className='header'>
                <h2>Bulk Upload : {data}</h2>
            </div>


            <div className='UploadSecMain'>
                {data === 'Negative Data' &&
                    <NegativeDataUploadPanel
                        caller={'NegativeData'}
                        creditorName={creditorName}
                        debtorType={debtorType}
                        dataType={dataType}
                        dataSource={dataSource}
                        setCreditorName={setCreditorName}
                        setDataSource={setDataSource}
                        setDataType={setDataType}
                        setDebtorType={setDebtorType}
                    />
                }

                {data === 'Active Accounts' &&
                    <NegativeDataUploadPanel
                        caller={'ActiveAccounts'}
                        debtorType={debtorType}
                        creditType={creditType}
                        dataSource={dataSource}
                        setCreditType={setCreditType}
                        setDebtorType={setDebtorType}
                        creditorName={creditorName}
                        setCreditorName={setCreditorName}
                        setDataSource={setDataSource}
                    />
                }

                {data === 'Hire Purchase' &&
                    <NegativeDataUploadPanel
                        caller={'Hire Purchase'}
                        debtorType={debtorType}
                        creditType={creditType}
                        dataSource={dataSource}
                        setCreditType={setCreditType}
                        setDebtorType={setDebtorType}
                        creditorName={creditorName}
                        setCreditorName={setCreditorName}
                        setDataSource={setDataSource}
                    />
                }
                {
                    arraydata.length > 0 ?
                        <div className='previewSection'>
                            <Preview
                                table={
                                    (
                                        data === 'Negative Data' || data === 'Active Accounts' ||
                                        data === 'Hire Purchase'
                                    ) ?
                                        negativetable
                                        :
                                        data
                                }
                                data={arraydata}
                            />
                        </div>
                        :
                        <div className='previewSection'>
                            <h3>No preview data available. Upload excel file</h3>
                        </div>

                }
            </div>

            <div className='UploadSecMainButons'>
                {(data !== 'Negative Data' && data !== 'Active Accounts' && data !== 'Hire Purchase') ?
                    <div className='uploaderInput'>
                        <FileUploader
                            fileName={
                                (data === 'Negative Data' || data === 'Active Accounts' || data === 'Hire Purchase') ?
                                    negativetable
                                    :
                                    data
                            }
                            setVar={setData}
                            setState={setState}
                            debtorType={debtorType}
                        />

                    </div>
                    :
                    <>
                        {(dataSource === '' || creditorName === '' || debtorType === '') ?
                            <div className='uploaderInput'>
                                <p className='nodataptag'>Fill the details above to upload file !</p>
                            </div>
                            :
                            <div className='uploaderInput'>
                                <FileUploader
                                    fileName={
                                        (data === 'Negative Data' || data === 'Active Accounts' || data === 'Hire Purchase') ?
                                            negativetable
                                            :
                                            data
                                    }
                                    setVar={setData}
                                    setState={setState}
                                    debtorType={debtorType}
                                    dataType={dataType}
                                />

                            </div>
                        }
                    </>

                }

                <div className='buttonDiv'>

                    {state === '1' &&
                        <button onClick={submitData} className='UploadButton'>
                            < UploadIcon className='UploadIcon' />
                            <p>Upload Records</p>
                        </button>
                    }


                    {
                        state === '2' &&
                        <CircularProgress />
                    }

                    {//downloading the csv in the case of bulk enquiries
                    (state === '0' && (data === 'Company Credit Reports' || data === 'Individual Credit Reports')) &&
                        <CSVLink data={csvdata.data}
                            filename={
                                data === 'Company Credit Reports' ?
                                    new Date().toJSON().slice(0, 10) + ' Fincheck Company Bulk '
                                    :
                                    new Date().toJSON().slice(0, 10) + ' Fincheck Individual Bulk '
                            }
                        >

                            <button className='UploadButtondone'>
                                < DownloadIcon className='UploadIcon' />
                                <p>Download File</p>
                            </button>
                        </CSVLink>
                    }{
                        (state === '0' &&
                            (data === 'Negative Data' || data === 'Hire Purchase' || data === 'Active Accounts')) &&
                        <lable onClick={() => setState('1')} className='UploadButtondone'>
                            <p>Files Uploaded</p>
                        </lable>
                    }{state === '3' &&
                        <button onClick={() => setState('1')} className='UploadButtonerror'>
                            <p>Files Not Uploaded!. Click to try again</p>
                        </button>
                    }
                </div>

            </div>
        </div>

    )
}

export default BulkForm