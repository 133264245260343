import React, { useState } from 'react'
import '../../css/clientsarena.css'
import { AddEditCompany } from '../Company/AddEditCompany'
import AddEditSubscription from './AddEditSubscription'
import AddeditUser from './AddeditUser'
import AddeditclientNav from './NavaddClient'
import CloseIcon from '@mui/icons-material/Close'

const AddEditClient = ({ setdisplay, visible, setvisible }) => {
  const [displayForm, setDisplayForm] = useState('client')
  const [finsNumber, setFinsNumber] = useState('')
  return (
    <div className={visible === 'yes' ?
      'addClientmodalContainer'
      :
      'addClientmodalContainerhide'}>
      <div className='addclientmain'>
        <div className='addclientnavigation'>
          <AddeditclientNav
            setdisplayForm={setDisplayForm}
            displayform={displayForm}
          />
        </div>
        <div className='addclientforms'>
          {displayForm === 'client' &&
            <AddEditCompany
              caller={'addclient'}
              setCompanyFins={setFinsNumber}
              setactive={setDisplayForm}
            />
          }

          {displayForm === 'adminUser' &&
            <AddeditUser
              caller={'adduserclient'}
              setvisible = {setvisible}
              finsNumber={finsNumber}
              setactive = {setDisplayForm}
              usergroup = {'external'}
              isAdmin = {true}
              refresh = {setdisplay}
            />
          }
        </div>
      </div>
      <div>
        <CloseIcon className='closeIcon' onClick={() => setvisible('no')} />
      </div>
    </div>
  )
}

export default AddEditClient
