import React from 'react'
import { TableData, TableDataHeaders, TableHeading } from '../../Individuals/IndividualReport/ReportTemplates'

export const Auditors = ({ auditors }) => {

    const strippedAuditors = auditors.data !== null ? auditors.data.map(auditor => auditor.auditors_name) : [];
    return (

        <table className="report_table">

            <tr className='row_heading' style={{ margin: '50px 0', backgroundColor: '#b4c6e7', color: 'black' }}>
                <td className='txt_rowheading' colSpan={4}>
                    <h1 className='section_heading' style={{ fontSize: '1rem' }}>
                        Auditors
                    </h1>
                </td>
            </tr>


            <tr>
                <td colSpan={5}>{strippedAuditors}</td>
            </tr>
        </table>
    )
}
