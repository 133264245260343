import React from 'react'
import './../css/Loader.css';
import { CircularProgress } from '@mui/material'

export const Loader = () => {
  return (
    <div className='progress'>
        <p style={{color: "white", marginBottom: "1em"}}>Organizing Data</p>
        <CircularProgress />
    </div> 
  )
}
