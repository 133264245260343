import React, { useContext, useEffect, useState } from 'react';
import reportPNG from './../../backgrounds/report.png';
import { toast } from 'react-toastify'
import { ValuesContext } from './HomeAdmin';
import AuthContext from '../../AuthContext/AuthContext';
import { fetchData } from '../../js/fetchData';
import { NavLink, useNavigate } from 'react-router-dom';


export const BusinessReportsMenu = ({ setActivePage }) => {

  const biuSub = JSON.parse(localStorage.getItem('biu-sub'));
  const bureauSub = JSON.parse(localStorage.getItem('bureau-sub'));
  const [isSubscripted, setIsSubscripted] = useState(biuSub ? biuSub.isSubscripted : null);
  const navigate = useNavigate();
  const { authTokens } = useContext(AuthContext);
  const { userData } = useContext(ValuesContext);
  const [clients, setAllClients] = useState(null);

  useEffect(() => {

    if (
      clients === null ||
      clients.data === null ||
      userData.data === null ||
      Object.keys(userData).length === 0) {

      /*  if ((biuSub && biuSub.isSubscripted) && (bureauSub && bureauSub.isSubscripted === false)) {
         navigate('/admin/business-intelligence')
       } */
      return;
    }

    const client = clients.data.filter(client => client.fins_number === userData.data.company.fins_number);

    if (client.length === 0) {
      setIsSubscripted(false)
      return;
    }

    if (client.length === 1 && client[0].subscription_category === undefined) {
      setIsSubscripted(false);
      return;
    }


    if (client.length === 1) {

      const subscription = client[0].subscription_category.filter(category => category === 'Biu');
      const subscription2 = client[0].subscription_category.filter(category => category === 'Hire Purchase');

      if (subscription.length === 1) {
        setIsSubscripted(true)
        localStorage.setItem('biu-sub', JSON.stringify({ isSubscripted: true }))
        if (subscription2.length === 0) {
          navigate('/admin/business-intelligence');
        }
      } else {
        setIsSubscripted(false);
        localStorage.setItem('biu-sub', JSON.stringify({ isSubscripted: false }))
      }
    }

  }, [clients])

  useEffect(() => {
    fetchData(
      String(authTokens.access),
      setAllClients,
      "client",
      null,
      null
    )
  }, [])

  const message = (isSubscripted) => {
    if (!isSubscripted) {
      toast.warning('Please subscribe for the service')
    }
  }


  return (

    <section className="businees-report-menu">

      <BusinessReportHeaderContainer isSubscripted={isSubscripted} />
      <BussinessReportsNav setActivePage={setActivePage} isSubscripted={isSubscripted} message={message} />

    </section>
  )
}

const BusinessReportHeaderContainer = ({ isSubscripted }) => {

  return (
    <div className="business-reports-header-container" style={{ backgroundColor: isSubscripted === false && 'gray', color: isSubscripted === false && 'black', opacity: isSubscripted === false && 0.7 }}>

      <div className="business-report-icon-container">
        <img src={reportPNG} alt="report  icon" />
      </div>

      <h3 className="business-report-header">
        business reports
      </h3>
    </div>
  )
}

const BussinessReportsNav = ({ setActivePage, isSubscripted, message }) => {

  const activeComponent = (page) => {

    if (isSubscripted) {
      setActivePage(page)
    } else {
      toast.warning('Please subscribe for the service to have access')
    }

  }

  return (

    <nav className="business-reports-nav" style={{ backgroundColor: isSubscripted === false && 'gray', color: isSubscripted === false && 'black', opacity: isSubscripted === false && 0.7 }}>
      <NavLink to={isSubscripted && 'business-intelligence'} onClick={() => message(isSubscripted)}>Dashboard</NavLink>
      <NavLink to={'business-intelligence-report'}>Reports</NavLink>
    </nav>
  )
}