import React from 'react';
import './../../css/BureauDashboard.css';
import AddBusinessIcon from '@mui/icons-material/AddBusiness';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import CarRentalIcon from '@mui/icons-material/CarRental';
import { NavLink, useNavigate } from 'react-router-dom';

export const BureauDashboard = ({ setActivePage }) => {


    return (
        <section className="dashboard-container">

            <Enquries setActivePage={setActivePage} />

        </section>
    )
}

const Enquries = ({ setActivePage }) => {
    const navigate = useNavigate();
    const direct = (route) => {
        return navigate(route)
    }
    return (

        <section className="bureau-dash-enquries">

            <h3>enquiries</h3>

            <div className="company-or-individual">

                <div>
                    <button onClick={() => direct('/admin/search-individuals')}>
                        <PersonAddIcon />
                        <span>individuals</span>
                    </button>
                </div>

                <div onClick={() => direct('/admin/search-companies')}>
                    <button >
                        <AddBusinessIcon />
                        <span> companies</span>
                    </button>
                </div>

                <div onClick={() => direct('/admin/bureau-hpi')}>
                    <button >
                        <CarRentalIcon />
                        <span> hire purchase</span>
                    </button>
                </div>

            </div>
        </section>
    )
}