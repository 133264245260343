import React from 'react'
import { TableData, TableDataHeaders, TableHeading } from './ReportTemplates'

const Employement = ({individual,color}) => {

    return (
        <table className='report_table'>
            <TableHeading
                span={3}
                color={color}
                heading={"Employment History"}
            />

            <tr className='data_row' >
                <TableDataHeaders
                    id={"employee_header"}
                    data={"Employer"}
                />

                <TableDataHeaders
                    id={"employee_email_header"}
                    data={"Position"}
                />

                <TableDataHeaders
                    id={"job_title_header"}
                    data={"Start Date"}
                />
            </tr>

            <tr className='data_row' >
                <TableData
                    id={"employee_field"}
                    data={individual.employer_name ? individual.employer_name : "N/A"}
                />

                <TableData
                    id={"employee_email_field"}
                    data={individual.job_title ? individual.job_title : "N/A"}
                />

                <TableData
                    id={"job_title_field"}
                    data={individual.date_of_employment ? individual.date_of_employment : "N/A"}
                />
            </tr>
        </table>
    )
}

export default Employement