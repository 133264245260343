import React, { useContext } from 'react';
import LogoutIcon from '@mui/icons-material/Logout';
import NotificationsIcon from '@mui/icons-material/Notifications';
import AuthContext from '../../AuthContext/AuthContext';
import './../../css/TopNavbar.css';
import { ValuesContext } from './HomeAdmin';

export const TopNavbar = ({ setActivePage }) => {

    const { logoutUser } = useContext(AuthContext);
    const { userData } = useContext(ValuesContext);

    return (

        <section className="top-navbar">

            <div className="user-details">
                <p className="username">Hello  {(userData.data !== [] && userData.data !== null) && userData.data !== undefined && `${userData.data.firstname} ${userData.data.surname}`} !</p>
                <p className="system-level">{(userData.data !== [] && userData.data !== null) && userData.data !== undefined && `${userData.data.groups[0] === 1 || userData.data.groups[0] === 3 ? 'Adminstrator' : 'User'}`}</p>
            </div>

            {/*  <nav className="top-nav">
                <a href="#" onClick={() => setActivePage("search-hpi")}>Hire Purchase</a>
                <a href="#">view statement</a>
                <a href="#">view HIPI</a>
            </nav> */}

            <div className="top-nav__btns-container">
                {/* <button className="notifications">
                        <NotificationsIcon/>
                        <span>3</span>
                    </button> */}
                <button className="logout-btn" onClick={logoutUser}>
                    <LogoutIcon />
                    logout
                </button>
            </div>
        </section>
    )
}