import React from 'react'
import "../../../css/IndividualReport.css"

export const IndividualReportFooter = () => {
    return (
        <tfoot className='report_footer'>
            <br/>
                <hr className='footer_hr' />
            <tr>
                <div>
                    <p>
                        Disclaimer: This report is confidential and intended solely for the individual or entity
                        to whom it is addressed. Information on this report is valid at the time of enquiry only.
                        If verification is required, please contact us on the details provided above.
                    </p>
                </div>
            </tr>
            <tr>

                <p>
                    <b>
                        Terms and Conditions apply.
                    </b>
                </p>
            </tr>
            <tr>
                <b>
                    <p align="center">Copyrights &copy; CrediSafe Zimbabwe <br />All rights reserved</p>
                </b>
            </tr>
        </tfoot>
    )
}
