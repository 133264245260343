import axios from "axios";
import { useContext } from "react";
import { toast } from "react-toastify";
// import AuthContext from "../../AuthContext/AuthContext";

export const DataSubmission = async (token, method, endPoint, formData, caller) => {
  // const { authTokens, logoutUser } = useContext(AuthContext);
  let headers = {}
  const checking = toast.loading('Proccessing...')
  if (endPoint.slice(0, 9) === '/company/') {
    headers = {
      "Content-Type": "multipart/form-data",
      Authorization: "Bearer " + token
    }
  } else {
    headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + token
      // String(authTokens.access)
    }
  }
  const process = await axios({
    method: method,
    url: endPoint,
    headers: headers,
    data: formData,
  })
    .then((res) => {
      toast.update(checking, { render: "Successfull !", type: "success", isLoading: false, autoClose: 1000 })
      let result = "success";
      return [{ result: result }, { request_response: res }];
    })
    .catch((AxiosError) => {
      if (
        AxiosError.code === "ERR_BAD_REQUEST" ||
        AxiosError.code === "ERR_BAD_OPTION_VALUE" ||
        AxiosError.code === "ERR_FR_TOO_MANY_REDIRECTS" ||
        AxiosError.code === "ERR_NOT_SUPPORT"
      ) {

        var response = AxiosError.response.data
        Object.keys(response).map(
          key => {
            toast.update(checking, { render: key + ' : ' + response[key][0], type: "error", isLoading: false, autoClose: 6000 })
          }
        )



      } else if (
        AxiosError.code === "ERR_NETWORK" ||
        AxiosError.code === "ERR_BAD_RESPONSE"
      ) {
        if (caller == 'adduser' && AxiosError.response.data) {
          if (AxiosError.response.data.includes('SMTPRecipientsRefused')) {
            toast.update(checking, { render: 'User email does not exists !', type: "error", isLoading: false, autoClose: 6000 })
          }
          if (AxiosError.response.data.includes('Proxy error')) {
            toast.update(checking, { render: 'Network error. Check network connection!', type: "error", isLoading: false, autoClose: 6000 })
          }
        } else if (AxiosError.response.data) {
          if (AxiosError.response.data.includes('Proxy error')) {
            toast.update(checking, { render: 'Network error. Check network connection!', type: "error", isLoading: false, autoClose: 6000 })
          }
          else {
            toast.update(checking, { render: "internal system error. Try again later !", type: "error", isLoading: false, autoClose: 6000 })
          }
        }
        else {
          toast.update(checking, { render: "internal system error. Try again later !", type: "error", isLoading: false, autoClose: 6000 })
        }
      }
      else {
        toast.update(checking, { render:"internal system error. Try again later !", type: "error", isLoading: false, autoClose: 6000 })
      }
      let result = "failure";
      return [{ result: result }, { request_response: AxiosError }];
    });
  return process;
};
