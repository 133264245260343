import React, {useEffect, useState} from 'react'
import axios from 'axios';
import InvoiceReportCSS from "../css/FiscalTaxInvoice.module.css";
import finlogo from "../backgrounds/finlog.png";
import { useLocation } from "react-router-dom";
import html2pdf from "html2pdf.js";


export default function ConfirmationLetter() {
    const [data, setData] = useState('');

    const generateElPDF = () => {
     
      // setClassAdded(!isClassAdded);
      const element = document.getElementById("elReport"); // Replace with the actual ID of your HTML element
      html2pdf(element);
    };

    useEffect(() => {
        const fetchData = async () => {
          axios
          .post(`/register_hpi_letter`)
          .then((response) => {
            if (response.status == 200) {
                // console.log(response.data.results);
                setData(response.data);
            } else {
                console.log("Error occured");
            }
          });
        };
    
        fetchData();
      }, []);

    if (!data || data.length === 0) {
        return <div>Fetching data...</div>;
    }
  return (
    <>
    <div id='elReport'>
    <table className="Individual_report">
        <tr className="report_section">
          <th className="report-header">
            <tr className="company-report-header-row">
              <div className="company-report-logo-container" style={{textAlign:'center', height:'34px'}}>
                <img src={finlogo} alt="fincheck logo" style={{
                  display: "block",
                  width: "50%"
                }}/>
                <p className="moto"></p>
              </div>

            </tr>

            <tr className="header-name">
            </tr>
          </th>
        </tr>
        
        {/* border={2} style={{ width: "100%", padding: "20px" }} */}
        <div  style={{
            textAlign: "left",
            marginTop: "50px",
            marginBottom: "10px",
          }}>
        <h5 style={{ fontSize: "18px", fontWeight: "bold" }}>
        CBZ BANK LTD ASSET FINANCE
          </h5>
        </div>
        <div
          style={{
            textAlign: "left",
            marginBottom: "10px",
          }}
        >
          <h5 style={{ fontSize: "12px", fontWeight: "bold" }}>
          TO WHOM IT MAY CONCERN
          </h5>
        </div>
        <div style={{marginBottom:'20px', marginTop:'20px'}}>
            <p>We acknowledge that we received <span style={{fontWeight:'bold'}}>{data.results.length}</span> HPIs for Lien Registration as follows:</p>
        </div>
        <div className={InvoiceReportCSS.floatTheadWrapper}>
          <table
            style={{
              width: "100%",
              border: "1px solid black",
              borderCollapse: "collapse",
            }}
          >
            <thead>
              <tr>
                <th scope="col" style={{ textAlign: "left" }}>
                Vehicle Reg Number  
                </th>
                <th scope="col" style={{ textAlign: "left" }}>
                Full name
                </th>
                
              </tr>
            </thead>
            <tbody>

            {data.results.map((item) => (
                <tr key={item.id}>
                  <th scope="row">{item.regNumber}</th>
                  <td>
                    {item.full_name}
                  </td>
                  
                </tr>
              ))}
            </tbody>
          </table>
          <div
            style={{
              
              marginTop: "10px",
              marginBottom: "10px",
            }}
          >
            <p style={{paddingTop:'20px'}}>Yours faithfully</p>
            <p style={{paddingTop:'20px'}}>Signature</p>
            <p style={{paddingTop:'20px'}}>Data Officer</p>
          </div>
        </div>

        <tr className="report_section"></tr>
      </table>
    </div>
     
      <div
        style={{ marginLeft: "90px", marginTop: "20px", marginBottom: "90px" }}
      >
        <button
          // className={InvoiceReportCSS.removePrintBtn}
          className={InvoiceReportCSS.InvoiceBtn}

          onClick={generateElPDF}
        >
          Download
        </button>
      </div> 
    </>
  )
}
