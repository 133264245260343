import React from 'react';
import "./../css/Home.css";
import MenuIcon from '@mui/icons-material/Menu';
import HomeIcon from '@mui/icons-material/Home';
import InfoIcon from '@mui/icons-material/Info';
import StoreIcon from '@mui/icons-material/Store';
import LiveHelpIcon from '@mui/icons-material/LiveHelp';
import ContactsIcon from '@mui/icons-material/Contacts';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import finlogo from './../backgrounds/finlog.png';

export const Header = () => {

    const toggleNavbar = () => {
        const navbar = document.querySelector(".homepage-nav");
        navbar.classList.toggle("show-homepage-nav");
    }

    return (

        <header id="header">

            <div className="logo-container">
                <img src={finlogo} alt="" className="logo" />
            </div>

            <nav className="homepage-nav">
                <a href="/">
                    <HomeIcon className="navIcon" />
                    Home
                </a>

                <a href="#about-us">
                    <InfoIcon className='navIcon' />
                    about
                </a>

                <a href="#our-products">
                    <StoreIcon className='navIcon' />
                    products
                </a>

                <a href="#faq">
                    <LiveHelpIcon className='navIcon' />
                    faq
                </a>

                <a href="#contact-us">
                    <ContactsIcon className='navIcon' />
                    contact
                </a>

                <a href="/login" className='login-link'>
                    <ExitToAppIcon className='navIcon' />
                    login
                </a>
            </nav>

            <div className="burger-container">
                <MenuIcon
                    className="burger"
                    onClick={toggleNavbar}
                />
            </div>

        </header>
    )
}
