import React from 'react'
import { TableData, TableDataHeaders, TableHeading } from '../../Individuals/IndividualReport/ReportTemplates'

export const BankingDetails = ({ bankDetails }) => {
    return (

        <table className="report_table">

            <tr className='row_heading' style={{ margin: '50px 0', backgroundColor: '#b4c6e7', color: 'black' }}>
                <td className='txt_rowheading' colSpan={5}>
                    <h1 className='section_heading' style={{ fontSize: '1rem' }}>
                        Bankers
                    </h1>
                </td>
            </tr>

            <tr className="data_row">

                <TableDataHeaders
                    data={"Bank"}
                />

                <TableDataHeaders
                    data={"Branch"}
                />

                <TableDataHeaders
                    data={"Account Name"}
                />


                <TableDataHeaders
                    data={"Account Type"}
                />

                <TableDataHeaders
                    data={"Account Number"}
                />



            </tr>

            {
                (bankDetails.data !== null && bankDetails.data.length === 0) &&
                <tr className='data_row'>

                    No Bank Accounts Supplied

                </tr>
            }

            {
                bankDetails.data !== null &&
                bankDetails.data.map((bankDetails, index) => {

                    return (

                        <tr key={index} className="data_row">

                            <TableData
                                data={bankDetails.bank_name}
                            />

                            <TableData

                                data={bankDetails.bankbranch}

                            />

                            <TableData
                                data={bankDetails.bank_name}
                            />


                            <TableData
                                data={bankDetails.account_type}

                            />


                            <TableData

                                data={bankDetails.account_number}
                            />


                        </tr>
                    )
                })
            }
        </table>
    )
}
