import React, { useContext, useState, useEffect, useRef } from "react";
import axios from "axios";
import InvoiceCSS from "../css/Invoice.module.css";
import EditIcon from "@mui/icons-material/Edit";
import { InputContainer } from "./InputContainer";
import AuthContext from "../AuthContext/AuthContext";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { fetchData } from "../js/fetchData";
import InvoiceTableRow from "./InvoiceTableRow";
import FiscalTaxInvoice from "./FiscalTaxInvoice";

import Tspinner from "./Tspinner";
import ModalLoading from "./ModalLoading";
import html2pdf  from "html2pdf.js";
import html2canvas from "html2canvas"
// import moment from 'moment';

function InvoiceList() {
  const [periodFrom, setPeriodFrom] = useState("");
  const [periodTo, setPeriodTo] = useState("");
  const [client, setClient] = useState("");
  const [services, setServices] = useState("");
  const [error, setError] = useState("");
  const [fetchedclient, setfetchedClient] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isfetching, setIsfetching] = useState(false);

  const [showAddForm, setShowAddForm] = useState(false);
  const [periods, setPeriods] = useState({ data: null });
  const [refresh, setRefresh] = useState(1);
  const [token, setToken] = useState(null);
  const [fetchedResults, setFetchedresults] = useState("");
  const [isInvocing, setIsInvocing] = useState(false);
  const [responseOneData, setResponseOnedata] = useState('')
  const [responseTwoData, setResponseTwodata] = useState('')

  const navigate = useNavigate();

  const generatePDF = () => {
    const element = document.getElementById('mainPage'); // Replace with the actual ID of your HTML element
    html2pdf(element);
  };
  useEffect(() => {
    setIsLoading(true);
    axios.get("/client/").then((response) => {
      // 👇️ sort by String property ASCENDING (A - Z)
      const strAscending = [...response.data].sort((a, b) =>
        a.registration_name > b.registration_name ? 1 : -1
      );
      setfetchedClient(strAscending);
      setIsLoading(false);
    });
  }, []);
  
  if (responseOneData && responseTwoData) {
    console.log('testing.....',services)
    navigate("/invoice-report", {
      state: {
        data: responseOneData[0].enqueries_list,
        client: responseOneData[0].client_name,
        invoiceData:responseTwoData,
        service:services,
      },
    });

  }
  const handleBothClickResc = async (e) => {
    setIsfetching(true);
    setFetchedresults("");
    e.preventDefault();
    // console.log(client)
    // console.log(services)
    axios
      .post(`/rescission_list`, {
        from: periodFrom,
        to: periodTo,
        services: services,
      })
      .then((response) => {
        if (response.status == 200) {
          // console.log(response.data.rescission_list);
          // console.log(response.data.bureauEnqZWL[0].company_data);
          navigate("/rescission-report", {
            state: {
              data: response.data.rescission_list,
            },
          });
          // setFetchedresults(response.data);
        } else {
          console.log("Error occured");
        }
        setIsfetching(false);
      });
  };

  const handleSubmit = async (e) => {
    setIsfetching(true);
    setFetchedresults("");
    e.preventDefault();
    // console.log(client)
    // console.log(services)
    axios
      .post(`/generate_invoice`, {
        from: periodFrom,
        to: periodTo,
        client: client,
        services: services,
      })
      .then((response) => {
        if (response.status == 200) {
          // console.log(response.data);
          // console.log(response.data.bureauEnqZWL[0].company_data);

          setFetchedresults(response.data);
        } else {
          console.log("Error occured");
        }
        setIsfetching(false);
      });
  };

  const generateFiscalInvoice = () => {
    const url = `/fiscal-tax-invoice/?client=${client}&service=${services}&from=${periodFrom}&to=${periodTo}`;
    window.open(url, "_blank");
  };

  // const fetchDataAsyc = async () => {
  //   try {
  //     // Make the first request
  //     const response1 = await axios.post('firstEndpoint', { data: 'firstData' });
  
  //     // Handle the response from the first request
  //     console.log('Response from first request:', response1.data);
  
  //     // Make the second request using the data from the first response
  //     const response2 = await axios.post('secondEndpoint', { data: response1.data });
  
  //     // Handle the response from the second request
  //     console.log('Response from second request:', response2.data);
  //   } catch (error) {
  //     // Handle errors from either request
  //     console.error('Error:', error);
  //   }
  // };
  const fetchDataAsyc = (e) => {
    setIsInvocing(true);
    e.preventDefault();
    axios.post(`/run_invoice_lists`, { 
        from: periodFrom,
        to: periodTo,
        client: client,
        services: services,
      })
      .then(response1 => {
        // Handle the response from the first request
        console.log('Response from first request:', response1.data);
        setResponseOnedata(response1.data);
        // Make a second request using the data from the first response
        return axios.post(`/client-fiscal-tax-invoice`,{
          client: client,
          service: services,
          fromDate: periodFrom,
          toDate: periodTo
        });
      })
      .then(response2 => {
        // Handle the response from the second request
        console.log('Response from second request:', response2.data);
        setResponseTwodata(response2.data);
        setIsInvocing(false);
      })
      .catch(error => {
        // Handle errors from either request
        console.error('Error:', error);
        setIsInvocing(false);
      });
  };

  const runLists = async (e) => {
    setIsInvocing(true);
    e.preventDefault();

    axios
      .post(`/run_invoice_lists`, {
        from: periodFrom,
        to: periodTo,
        client: client,
        services: services,
      })
      .then((response) => {
        if (response.status == 200) {
          // console.log(response.data[0].enqueries_list);
          // console.log(response.data[0].client_name);

          // console.log("hello tfa..");
          navigate("/invoice-report", {
            state: {
              data: response.data[0].enqueries_list,
              client: response.data[0].client_name,
            },
          });
        } else {
          console.log("Error occured");
        }
        // setIsfetching(false);
        setIsInvocing(false);
      });
  };

  const handleBothClick = (e) => {
    fetchDataAsyc(e);
    // runLists(e);
    // generateFiscalInvoice();
  };
  return (
    <div id="mainPage" className={InvoiceCSS.page}>
      <h3 className={InvoiceCSS.invoiceHeader}>Invoice Selection </h3>
      {/* {JSON.stringify(fetchedclient, null, 2)} */}
      {/* <button onClick={generatePDF}>Generate PDF</button> */}
      <form onSubmit={handleSubmit} >
        <div
          id="selectionBox"
          class={InvoiceCSS.inputCriteria}
          style={{ width: "564px" }}
        >
          <div className={InvoiceCSS.selectionBoxRow}>
            <div className={InvoiceCSS.selectionLabel}></div>
            <div
              className={InvoiceCSS.selectionLabel}
              style={{ width: "225px" }}
            >
              From:
            </div>
            <div
              className={InvoiceCSS.selectionLabel}
              style={{ width: "170px" }}
            >
              To:
            </div>
          </div>
          <div className={InvoiceCSS.selectionBoxRow}>
            <div className={InvoiceCSS.selectionLabel}>Period</div>

            <input
              type="date"
              style={{ width: "225px" }}
              value={periodFrom}
              onChange={(e) => {
                setPeriodFrom(e.target.value);
              }}
            />
            <input
              type="date"
              style={{ width: "170px" }}
              value={periodTo}
              onChange={(e) => {
                setPeriodTo(e.target.value);
              }}
            />
          </div>

          <div className={InvoiceCSS.selectionBoxRow}>
            <div className={InvoiceCSS.selectionLabel}>Service</div>

            <select
              className={InvoiceCSS.selectionBoxSelect}
              onChange={(e) => setServices(e.target.value)}
            >
              <option value="ALL">[All services]</option>
              <option value="BureauEnqZWL">Bureau Enquiries - ZWL</option>
              <option value="BureauEnqUSD">Bureau Enquiries - USD</option>
              <option value="HirePurZWL">Hire Purchase - ZWL </option>
              <option value="RescissionZWL">Rescissions - ZWL</option>
              <option value="RescissionUSD">Rescissions - USD</option>
              <option value="BusinessRepZWL">Business Reports -ZWL</option>
            </select>
          </div>
          <div className={InvoiceCSS.selectionBoxRow}>
            <div className={InvoiceCSS.selectionLabel}>Client</div>
            {isLoading ? (
              <select
                disabled
                name="selectedDataSetId"
                className={InvoiceCSS.selectionBoxSelect}
              >
                <option value="ALL"> [Please wait...]</option>
              </select>
            ) : (
              <select
                name="selectedDataSetId"
                className={InvoiceCSS.selectionBoxSelect}
                onChange={(e) => setClient(e.target.value)}
              >
                <option value="ALL"> [All clients]</option>

                {fetchedclient.map((client) => {
                  return (
                    <option value={client.fins_number} key={client.fins_number}>
                      {client.registration_name}

                      <hr />
                    </option>
                  );
                })}
              </select>
            )}
          </div>
          <div className={InvoiceCSS.selectionBoxRow}>
            <div className={InvoiceCSS.selectionLabel}></div>
            <div
              className={InvoiceCSS.selectionLabel}
              style={{ width: "295px" }}
            ></div>
            <div
              className={InvoiceCSS.selectionLabel}
              style={{ width: "100px" }}
            >
              <button className={InvoiceCSS.submitBtn} type="submit">
                Submit
              </button>
            </div>
          </div>
        </div>
      </form>

      {/* //invoice list section */}
      <div className={InvoiceCSS.contentDiv}>
        <div
          className={InvoiceCSS.sectionContainer}
          style={{ marginBottom: "30px" }}
        >
          <div>
            <h3 className={InvoiceCSS.cent}>INVOICING LIST</h3>
            {/* <h5>Feb-23 </h5> */}
          </div>
          <div
            className={InvoiceCSS.floatTheadWrapper}
            style={{ position: "relative", clear: "both", overflowX:"auto" }}
          >
            <table
              border="0"
              cellpadding="0"
              cellspacing="0"
              style={{ width: "200px", width: "1410px"}}
            >
              <thead>
                <tr className={InvoiceCSS.tftr}>
                  <th>Acc #</th>
                  <th colspan="2">Client</th>
                  <th>C/I</th>
                  <th colspan="2">Price</th>
                  <th>Qty</th>
                  <th>CHARGE</th>
                  <th>VAT</th>
                  <th>TOTAL</th>
                  <th
                    colspan="2"
                    style={{ backgroundColor: "#258db3", color: "#fff" }}
                    onClick={() => generateFiscalInvoice()}
                  >
                    Run All Lists
                  </th>
                </tr>
              </thead>
              <tbody>
                {/* component one for Bureau ZWL */}

                {fetchedResults !== "" ? (
                  <>
                    {(() => {
                      if (services === "BureauEnqZWL" && fetchedResults.bureauEnqZWL.length != 0)
                        return (
                          <InvoiceTableRow
                            fetchedResults={fetchedResults}
                            services={services}
                            handleBothClick={handleBothClick}
                          />
                          
                        );
                      if (services === "BureauEnqUSD" && fetchedResults.bureauEnqUSD.length != 0)
                        return (
                          <InvoiceTableRow
                            fetchedResults={fetchedResults}
                            services={services}
                            handleBothClick={handleBothClick}
                          />
                        );
                      if (services === "HirePurZWL" && fetchedResults.hirePurZWL.length != 0)
                        return (
                          <InvoiceTableRow
                            fetchedResults={fetchedResults}
                            services={services}
                            handleBothClick={handleBothClick}
                          />
                        );
                      if (services === "RescissionZWL" && fetchedResults.rescissionZWL.length != 0)
                        return (
                          <InvoiceTableRow
                            fetchedResults={fetchedResults}
                            services={services}
                            handleBothClick={handleBothClickResc}
                          />
                        );
                      else return <span>No data</span>;
                    })()}
                  </>
                ) : (
                  ""
                )}

                {/* component one for Bureau USD */}
                {/* <tr>
                  <th
                    colspan="12"
                    style={{
                      paddingBottom: "3px",
                      border: "0px",
                      paddingLeft: "0px",
                    }}
                  >
                    <div>
                      <h5>Bureau Enquiries USD</h5>
                    </div>
                  </th>
                </tr>
                <tr>
                  <th>AFR001</th>
                  <th colspan="2">African Century</th>
                  <th>C</th>
                  <th colspan="2">26003</th>
                  <th>3</th>
                  <th>7,8001</th>
                  <th>1,170</th>
                  <th>8,970</th>
                  <th
                    colspan="2"
                    style={{ backgroundColor: "#258db3", color: "#fff" }}
                  >
                    Run Lists
                  </th>
                </tr>
                <tr>
                  <th>AFR001</th>
                  <th colspan="2">African Century</th>
                  <th>C</th>
                  <th colspan="2">26003</th>
                  <th>3</th>
                  <th>7,8001</th>
                  <th>1,170</th>
                  <th>8,970</th>
                  <th
                    colspan="2"
                    style={{ backgroundColor: "#258db3", color: "#fff" }}
                  >
                    Run Lists
                  </th>
                </tr>
                <tr>
                  <th style={{ border: "0px", paddingLeft: "0px" }}></th>
                  <th
                    colspan="2"
                    style={{
                      paddingLeft: "3px",
                      border: "0px",
                      paddingLeft: "0px",
                    }}
                  >
                    TOTAL
                  </th>
                  <th style={{ border: "0px", paddingLeft: "0px" }}></th>
                  <th colspan="2" style={{ backgroundColor: "#bdc8d1" }}>
                    26003
                  </th>
                  <th style={{ backgroundColor: "#bdc8d1" }}>3</th>
                  <th style={{ backgroundColor: "#bdc8d1" }}>7,8001</th>
                  <th style={{ backgroundColor: "#bdc8d1" }}>1,170</th>
                  <th style={{ backgroundColor: "#bdc8d1" }}>8,970</th>
                  <th
                    colspan="2"
                    style={{ backgroundColor: "#0d475c", color: "#fff" }}
                  >
                    All Bureau USD Lists
                  </th>
                </tr> */}

                {/* component one for hire Purchase */}
                {/* <tr>
                  <th
                    colspan="12"
                    style={{
                      paddingBottom: "3px",
                      border: "0px",
                      paddingLeft: "0px",
                    }}
                  >
                    <div>
                      <h5>Hire Purchase ZWL</h5>
                    </div>
                  </th>
                </tr>
                <tr>
                  <th>AFR001</th>
                  <th colspan="2">African Century</th>
                  <th>C</th>
                  <th colspan="2">26003</th>
                  <th>3</th>
                  <th>7,8001</th>
                  <th>1,170</th>
                  <th>8,970</th>
                  <th
                    colspan="2"
                    style={{ backgroundColor: "#258db3", color: "#fff" }}
                  >
                    Run Lists
                  </th>
                </tr>
                <tr>
                  <th>AFR001</th>
                  <th colspan="2">African Century</th>
                  <th>C</th>
                  <th colspan="2">26003</th>
                  <th>3</th>
                  <th>7,8001</th>
                  <th>1,170</th>
                  <th>8,970</th>
                  <th
                    colspan="2"
                    style={{ backgroundColor: "#258db3", color: "#fff" }}
                  >
                    Run Lists
                  </th>
                </tr>
                <tr>
                  <th style={{ border: "0px", paddingLeft: "0px" }}></th>
                  <th
                    colspan="2"
                    style={{
                      paddingLeft: "3px",
                      border: "0px",
                      paddingLeft: "0px",
                    }}
                  >
                    TOTAL
                  </th>
                  <th style={{ border: "0px", paddingLeft: "0px" }}></th>
                  <th colspan="2" style={{ backgroundColor: "#bdc8d1" }}>
                    26003
                  </th>
                  <th style={{ backgroundColor: "#bdc8d1" }}>3</th>
                  <th style={{ backgroundColor: "#bdc8d1" }}>7,8001</th>
                  <th style={{ backgroundColor: "#bdc8d1" }}>1,170</th>
                  <th style={{ backgroundColor: "#bdc8d1" }}>8,970</th>
                  <th
                    colspan="2"
                    style={{ backgroundColor: "#0d475c", color: "#fff" }}
                  >
                    Hire Purchase ZWL
                  </th>
                </tr> */}

                {/* component one for Rescissions ZWL */}
                {/* <tr>
                  <th
                    colspan="12"
                    style={{
                      paddingBottom: "3px",
                      border: "0px",
                      paddingLeft: "0px",
                    }}
                  >
                    <div>
                      <h5>Rescissions ZWL</h5>
                    </div>
                  </th>
                </tr>
                <tr>
                  <th colspan="3" style={{ textAlign: "center" }}>
                    Company
                  </th>
                  <th>C</th>
                  <th colspan="2">26003</th>
                  <th>3</th>
                  <th>7,8001</th>
                  <th>1,170</th>
                  <th>8,970</th>
                  <th
                    colspan="2"
                    style={{ backgroundColor: "#258db3", color: "#fff" }}
                  >
                    Run Lists
                  </th>
                </tr>
                <tr>
                  <th colspan="3" style={{ textAlign: "center" }}>
                    Individual
                  </th>
                  <th>C</th>
                  <th colspan="2">26003</th>
                  <th>3</th>
                  <th>7,8001</th>
                  <th>1,170</th>
                  <th>8,970</th>
                  <th
                    colspan="2"
                    style={{ backgroundColor: "#258db3", color: "#fff" }}
                  >
                    Run Lists
                  </th>
                </tr>
                <tr>
                  <th style={{ border: "0px", paddingLeft: "0px" }}></th>
                  <th
                    colspan="2"
                    style={{
                      paddingLeft: "3px",
                      border: "0px",
                      paddingLeft: "0px",
                    }}
                  ></th>
                  <th style={{ border: "0px", paddingLeft: "0px" }}></th>
                  <th colspan="2" style={{ backgroundColor: "#bdc8d1" }}>
                    26003
                  </th>
                  <th style={{ backgroundColor: "#bdc8d1" }}>3</th>
                  <th style={{ backgroundColor: "#bdc8d1" }}>7,8001</th>
                  <th style={{ backgroundColor: "#bdc8d1" }}>1,170</th>
                  <th style={{ backgroundColor: "#bdc8d1" }}>8,970</th>
                  <th
                    colspan="2"
                    style={{ backgroundColor: "#0d475c", color: "#fff" }}
                  >
                    All Rescission ZWL Lists
                  </th>
                </tr> */}

                {/* component one for Rescissions USD */}
                {/* <tr>
                  <th
                    colspan="12"
                    style={{
                      paddingBottom: "3px",
                      border: "0px",
                      paddingLeft: "0px",
                    }}
                  >
                    <div>
                      <h5>Rescissions USD</h5>
                    </div>
                  </th>
                </tr>
                <tr>
                  <th colspan="3" style={{ textAlign: "center" }}>
                    Company
                  </th>
                  <th>C</th>
                  <th colspan="2">26003</th>
                  <th>3</th>
                  <th>7,8001</th>
                  <th>1,170</th>
                  <th>8,970</th>
                  <th
                    colspan="2"
                    style={{ backgroundColor: "#258db3", color: "#fff" }}
                  >
                    Run Lists
                  </th>
                </tr>
                <tr>
                  <th colspan="3" style={{ textAlign: "center" }}>
                    Individual
                  </th>
                  <th>C</th>
                  <th colspan="2">26003</th>
                  <th>3</th>
                  <th>7,8001</th>
                  <th>1,170</th>
                  <th>8,970</th>
                  <th
                    colspan="2"
                    style={{ backgroundColor: "#258db3", color: "#fff" }}
                  >
                    Run Lists
                  </th>
                </tr>
                <tr>
                  <th style={{ border: "0px", paddingLeft: "0px" }}></th>
                  <th
                    colspan="2"
                    style={{
                      paddingLeft: "3px",
                      border: "0px",
                      paddingLeft: "0px",
                    }}
                  ></th>
                  <th style={{ border: "0px", paddingLeft: "0px" }}></th>
                  <th colspan="2" style={{ backgroundColor: "#bdc8d1" }}>
                    26003
                  </th>
                  <th style={{ backgroundColor: "#bdc8d1" }}>3</th>
                  <th style={{ backgroundColor: "#bdc8d1" }}> 7,8001</th>
                  <th style={{ backgroundColor: "#bdc8d1" }}>1,170</th>
                  <th style={{ backgroundColor: "#bdc8d1" }}>8,970</th>
                  <th
                    colspan="2"
                    style={{ backgroundColor: "#0d475c", color: "#fff" }}
                  >
                    All Rescission USD Lists
                  </th>
                </tr> */}

                {/* component one for Business Reports ZWL */}
                {/* <tr>
                  <th
                    colspan="12"
                    style={{
                      paddingBottom: "3px",
                      border: "0px",
                      paddingLeft: "0px",
                    }}
                  >
                    <div>
                      <h5>Business Reports ZWL</h5>
                    </div>
                  </th>
                </tr>
                <tr>
                  <th>AFR001</th>
                  <th colspan="2">African Century</th>
                  <th>C</th>
                  <th colspan="2">26003</th>
                  <th>3</th>
                  <th>7,8001</th>
                  <th>1,170</th>
                  <th>8,970</th>
                  <th
                    colspan="2"
                    style={{ backgroundColor: "#258db3", color: "#fff" }}
                  >
                    Run Lists
                  </th>
                </tr>
                <tr>
                  <th>AFR001</th>
                  <th colspan="2">African Century</th>
                  <th>C</th>
                  <th colspan="2">26003</th>
                  <th>3</th>
                  <th>7,8001</th>
                  <th>1,170</th>
                  <th>8,970</th>
                  <th
                    colspan="2"
                    style={{ backgroundColor: "#258db3", color: "#fff" }}
                  >
                    Run Lists
                  </th>
                </tr>
                <tr>
                  <th style={{ border: "0px", paddingLeft: "0px" }}></th>
                  <th
                    colspan="2"
                    style={{
                      paddingLeft: "3px",
                      border: "0px",
                      paddingLeft: "0px",
                    }}
                  >
                    TOTAL
                  </th>
                  <th style={{ border: "0px", paddingLeft: "0px" }}></th>
                  <th colspan="2" style={{ backgroundColor: "#bdc8d1" }}>
                    26003
                  </th>
                  <th style={{ backgroundColor: "#bdc8d1" }}>3</th>
                  <th style={{ backgroundColor: "#bdc8d1" }}>7,8001</th>
                  <th style={{ backgroundColor: "#bdc8d1" }}>1,170</th>
                  <th style={{ backgroundColor: "#bdc8d1" }}>8,970</th>
                  <th
                    colspan="2"
                    style={{ backgroundColor: "#0d475c", color: "#fff" }}
                  >
                    Run All Reports Lists
                  </th>
                </tr> */}

                {/* component one for generate report*/}
                {/* <tr>
                  <th
                    colspan="12"
                    style={{
                      paddingBottom: "3px",
                      border: "0px",
                      paddingLeft: "0px",
                    }}
                  ></th>
                </tr>
                <tr>
                  <th colspan="7">Total Sales - ZWL</th>
                  <th>7,8001</th>
                  <th>1,170</th>
                  <th>8,970</th>
                </tr>
                <tr>
                  <th colspan="7">Total Sales - USD</th>
                  <th>7,8001</th>
                  <th>1,170</th>
                  <th>8,970</th>
                </tr>
                <tr>
                  <th style={{ border: "0px", paddingLeft: "0px" }}></th>
                  <th
                    colspan="2"
                    style={{
                      paddingLeft: "3px",
                      border: "0px",
                      paddingLeft: "0px",
                    }}
                  >
                    TOTAL
                  </th>
                  <th style={{ border: "0px", paddingLeft: "0px" }}></th>
                  <th
                    colspan="4"
                    style={{
                      backgroundColor: "#0d475c",
                      color: "#fff",
                      textAlign: "center",
                    }}
                  >
                    Generate Invoicing List
                  </th>

                  <th
                    colspan="4"
                    style={{ border: "0px", paddingLeft: "0px" }}
                  ></th>
                </tr> */}
              </tbody>
            </table>
            {isfetching ? <Tspinner /> : ""}
            {isInvocing ? <ModalLoading isInvocing={isInvocing} /> : ""}
            {/* <FiscalTaxInvoice/> */}
          </div>
        </div>
      </div>
    </div>
  );
}

const tbRow = () => {
  return (
    <>
      <tr>
        <th
          colspan="12"
          style={{
            paddingBottom: "3px",
            border: "0px",
            paddingLeft: "0px",
          }}
        >
          <div>
            <h5>Bureau Enquiries ZWL</h5>
          </div>
        </th>
      </tr>
      <tr>
        <th>AFR001</th>
        <th colspan="2">African Century</th>
        <th>C</th>
        <th colspan="2">26003</th>
        <th>3</th>
        <th>7,8001</th>
        <th>1,170</th>
        <th>8,970</th>
        <th colspan="2" style={{ backgroundColor: "#258db3", color: "#fff" }}>
          Run Lists
        </th>
      </tr>
      <tr>
        <th>AFR001</th>
        <th colspan="2">African Century</th>
        <th>C</th>
        <th colspan="2">26003</th>
        <th>3</th>
        <th>7,8001</th>
        <th>1,170</th>
        <th>8,970</th>
        <th colspan="2" style={{ backgroundColor: "#258db3", color: "#fff" }}>
          Run Lists
        </th>
      </tr>
      <tr>
        <th style={{ border: "0px", paddingLeft: "0px" }}></th>
        <th
          colspan="2"
          style={{
            paddingLeft: "3px",
            border: "0px",
            paddingLeft: "0px",
          }}
        >
          TOTAL
        </th>
        <th style={{ border: "0px", paddingLeft: "0px" }}></th>
        <th colspan="2" style={{ backgroundColor: "#bdc8d1" }}>
          26003
        </th>
        <th style={{ backgroundColor: "#bdc8d1" }}>3</th>
        <th style={{ backgroundColor: "#bdc8d1" }}>7,8001</th>
        <th style={{ backgroundColor: "#bdc8d1" }}>1,170</th>
        <th style={{ backgroundColor: "#bdc8d1" }}>8,970</th>
        <th colspan="2" style={{ backgroundColor: "#0d475c", color: "#fff" }}>
          All Bureau ZWL Lists
        </th>
      </tr>
    </>
  );
};
export default InvoiceList;
