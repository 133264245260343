import React, { useContext, useEffect, useRef, useState } from 'react';
import './../css/InternalUsers.css';
import { Modal } from './Modal';
import AddeditUser from './Clients/AddeditUser';
import { ValuesContext } from './Admin/HomeAdmin';
import { fetchData } from '../js/fetchData';
import AuthContext from '../AuthContext/AuthContext';
import axios from 'axios';
import { toast } from 'react-toastify';

import DataTable from 'react-data-table-component';


export const ExternalUsers = () => {

    const [showAddModal, setShowAddModal] = useState(false);
    const { authTokens } = useContext(AuthContext);
    const [allExternalUsers, setAllExternalUsers] = useState([]);
    const [modalClose, setModalClose] = useState(0)
    const [userGroup, setUserGroup] = useState('internal');
    const [allUsers, setAllUsers] = useState(null);
    const [externalUsers, setExternalUsers] = useState(null);
    const searchUsersInput = useRef();
    const [showEditModal, setShowEditModal] = useState(false);
    const [selectedUser, setSelectedUser] = useState({})


    useEffect(() => {

        fetchData(
            String(authTokens.access),
            setAllUsers,
            'user',
            null,
            null,
        )

    }, [showAddModal, modalClose])


    useEffect(() => {
        setModalClose(modalClose + 1);
    }, [showEditModal])


    useEffect(() => {

        if (allUsers === null) return;

        const filteredExternalUsers = allUsers.data.filter(user => {
            if (user.groups[0] === 3 || user.groups[0] === 4) return user;
        })

        /*  filteredInternalUsers.sort((a,b) => {
              if (a.company.registration_name < b.company.registration_name) {
                  return -1;
                } else if (a.company.registration_name > b.company.registration_name) {
                  return 1;
                } else {
                  return a.surname - b.surname;
                }
          }); */

        setExternalUsers(filteredExternalUsers)
        setAllExternalUsers(filteredExternalUsers);

    }, [allUsers])


    const showModal = (e) => {
        setShowAddModal(true)
    }

    const deleteUser = (user) => {

        axios.delete(`/user/${user.id}/`, {
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: "Bearer " + String(authTokens.access)
            },
        })
            .then(response => {

                if (response.status >= 200 && response.status < 300) {
                    toast.success('User deleted successfully');
                    const leftUsers = externalUsers.filter(allUser => allUser.id !== user.id);
                    setExternalUsers(leftUsers);
                } else {
                    toast.error('Could not delete user')
                }
            })
            .catch(() => toast.error('Failed to send request,check your connection'));
    }

    const client = (user) => {
        return (
            <td> {user.company.registration_name}</td>
        )
    }
    const searchUsers = (e) => {
        const searchValue = e.target.value;

        if (searchValue === '') {
            setExternalUsers(allExternalUsers);
            return
        }

        const filteredUsers = allExternalUsers.filter(user => {
            const fullName = user.firstname + ' ' + user.surname;


            if (
                user.firstname.toLowerCase().includes(e.target.value.toLowerCase()) ||
                user.surname.toLowerCase().includes(e.target.value.toLowerCase()) ||
                user.company.registration_name.toLowerCase().includes(e.target.value.toLowerCase()) ||
                user.email.toLowerCase().includes(e.target.value.toLowerCase()) ||
                user.username.toLowerCase().includes(e.target.value.toLowerCase()) ||
                fullName.toLowerCase().includes(e.target.value.toLowerCase())
            ) {
                return user
            }
        })

        setExternalUsers(filteredUsers.sort((a, b) => {
            if (a.company.registration_name < b.company.registration_name) {
                return -1;
            } else if (a.company.registration_name > b.company.registration_name) {
                return 1;
            } else {
                return a.surname - b.surname;
            }
        }));

    }

    const clickToShowEditModal = (user) => {
        setSelectedUser(user)
        setShowEditModal(true)
    }

    const columns = [
        {
            name: 'Surname',
            selector: row => row.surname,
            sortable: 'true'
        },
        {
            name: 'First Name',
            selector: row => row.firstname,
            sortable: 'true'
        },
        {
            name: 'Client Name',
            selector: row => row.firstname,
            selector: row => row.company ? row.company.registration_name : 'N/A',
            sortable: 'true'
        },
        {
            name: 'Level',
            selector: row => row.groups[0] === 3 ? 'Admin' : 'User',
            sortable: 'true'
        },
        {
            name: 'Email',
            selector: row => row.email,
            sortable: 'true'
        },
        {
            name: 'Mobile Number',
            selector: row => row.mobile,
            sortable: 'true'

        },
        {
            name: 'Edit',
            cell: row => <div data-tag="allowRowEvents"><button className='edit-internal-user' onClick={() => clickToShowEditModal(row)}>Edit</button></div>,
        },
        {
            name: 'Delete',
            cell: row => <div data-tag="allowRowEvents"><button className='delete-internal-user' onClick={() => deleteUser(row)}>Delete</button></div>,
        }
    ];

    return (

        <section className="internal-users">



            {
                showAddModal === true &&
                <Modal
                    Component={AddEdit}
                    setFormStatus={setShowAddModal}
                    data={userGroup}
                />
            }



            {
                showEditModal &&
                <Modal
                    Component={AddEditEdit}
                    setFormStatus={setShowEditModal}
                    data={selectedUser}
                />
            }



            <h3 className='internal-users-header'>
                External Users
            </h3>


            <div className="search-users-input-container">

                <input
                    ref={searchUsersInput}
                    type="text"
                    placeholder='Search by name / client /  email'
                    onChange={e => searchUsers(e)}
                />
            </div>

            <div className="internal-users-container">

                <div className="internal-users-buttons-container">

                    <button className="add-internal-user-btn" onClick={e => showModal(e)}>
                        +Add User
                    </button>

                </div>

                {
                    externalUsers !== null &&

                    <DataTable
                        columns={columns}
                        data={externalUsers}
                        fixedHeader={true}
                        fixedHeaderScrollHeight={'100%'}

                    />
                }


            </div>
        </section>
    )
}


const AddEdit = ({ data }) => {

    const { userData } = useContext(ValuesContext);
    const fins = userData.data.company.fins_number

    return (
        <AddeditUser
            caller={'addadminuser'}
            finsNumber={fins}
            setactive={null}
            userData={[userData]}
            usergroup={'external'}
        />
    )
}

const AddEditEdit = ({ data }) => {

    const { userData } = useContext(ValuesContext);
    const fins = userData.data.company

    return (
        <AddeditUser
            caller={'edituser'}
            finsNumber={data.company.fins_number}
            userData={[data]}
            setactive={null}
            usergroup={'external'}
        />
    )
}


/* 
const InternalUserForm = ({data}) => {

    const [surname, setSurname] = useState('');
    const [email, setEmail] = useState('');
    const [idNumber,setIdNumber] = useState('');
    const [firstname, setFirstname] = useState('');
    const [position, setPosition] = useState('');
    const [mobile, setMobile] = useState('');
    const  [address, setAddress] = useState('');
    const [IdentificationType, setIdentificationType] = useState('Admin');
    const [accessLevelState, setAccessLevelState] = useState('');

    const 
    return (

        <form className='add-internal-user-form'>

            <div className="internal-user-inputs-container">

                <InputContainer
                    label={'Surname'}
                    name={'surname'}
                    currentValue={surname}
                    setValue={setSurname}
                    type={'text'}
                />

                <InputContainer
                    label={'First Name'}
                    name={'firstName'}
                    currentValue={firstname}
                    setValue={setFirstname}
                    type={'text'}
                />

                <InputContainer
                    label={'Email'}
                    name={'email'}
                    currentValue={email}
                    setValue={setEmail}
                    type={'text'}
                />

                <SelectContainer
                    label={'Identification Type'}
                    currentValue={IdentificationType}
                    setValue={setIdentificationType}
                    name={'IdentificationType'}
                    options={IdType}
                />

                <InputContainer
                    label={'ID Number'}
                    name={'id'}
                    currentValue={idNumber}
                    setValue={setIdNumber}
                    type={'text'}
                />

                <InputContainer
                    label={'Position'}
                    type={'text'}
                    currentValue={position}
                    setValue={setPosition}
                />

                <InputContainer
                    label={'Mobile Number'}
                    type={'text'}
                    currentValue={mobile}
                    setValue={setMobile}
                />

                <InputContainer
                    label={'Address'}
                    type={'text'}
                    currentValue={address}
                    setValue={setAddress}
                />

                <SelectContainer
                    label={'Address'}
                    currentValue={accessLevelState}
                    setValue={setAccessLevelState}
                    options={accessLevel}
                />

            </div>

            <button className="add-internal-user-btn">
                Submit
            </button>
        </form>
    )
}
 */