import React from "react";
import tSpinner from "../backgrounds/tfaSpinner.gif";
// import tLoader from "../backgrounds/tfaIphoneSpinner.gif";

export default function Tspinner() {
  return (
    <div
      style={{ textAlign: "center", paddingBottom: "10%", paddingTop: "10%" }}
    >
      <img src={tSpinner} alt="loading..." />
      <div>Please wait...</div>
    </div>
  );
}
