import axios from "axios";

export const showReqContainer = (setEnqType) => {

  const reqContainer = document.querySelector(".requester-container");
  const submitBtn = document.querySelector(".go-to-report");

  submitBtn.disabled =
    reqContainer.classList.toggle("show-requester-container");
  setEnqType("external-internal")
}


export const searchUser = (requesterInput, users, setSearchedUsers, typeOfDropdown, subscriptions) => {


  showUsersDropDown(typeOfDropdown);
  const lowerCaseInput = requesterInput.current.value.toLowerCase();

  let matchedUsers = [];

  if (users.length === 0) {
    return;
  }

  if (users === null) {
    return;
  }

  if (users.data === null) {
    return;
  }

  if (users !== null) {
    setSearchedUsers(users.data);
    users.data.forEach(user => {


      const lowerCaseUsername = user.username.toLowerCase();

      if (lowerCaseUsername.includes(lowerCaseInput) ||
        user.firstname.toLowerCase().includes(lowerCaseInput) ||
        user.surname.toLowerCase().includes(lowerCaseInput)) {
        matchedUsers.unshift(user);
      }

      return;
    })

  } else {
    setSearchedUsers(subscriptions.data);
    subscriptions.data.forEach(sub => {


      const lowerCaseRegName = sub.registration_name.toLowerCase();

      if (lowerCaseRegName.includes(lowerCaseInput)) {
        matchedUsers.unshift(sub);
      }

      return;
    })
  }

  setSearchedUsers(matchedUsers);

}

const showUsersDropDown = (typeOfDropdown) => {
  const elementClass = "." + typeOfDropdown + "-dropdown";
  const usersDropDown = document.querySelector(elementClass);
  usersDropDown.classList.add("show-users-dropdown");
}


export const checkIfUserInternal = (userData, setReportChecking, data, navigate, setChecked, type) => {
  
  if (userData.data.groups[0] === 1 || userData.data.groups[0] === 2) {
    
    setReportChecking(true);
    /*  setChecked(true); */
  } else {
    localStorage.setItem("data", JSON.stringify(data));
    localStorage.setItem("enq-details", JSON.stringify({ fins: data.fins_number, requester: null, enqType: "external", current_user: userData.data.id, user: userData.data.username }));
    navigate(`/report/${type}-report`)
    console.log("send charging....")
    // console.log(userData)
    console.log(typeof(type))
    if (type == "hpi"){
      console.log(data.debtor_company)
      console.log(data.debtor_individual)
      console.log(data.lesse_type)

      const user_id = userData.data.id
      const client_id = userData.data.company.fins_number
      const type = 'HPI Report'
      const search_record = ""
      const debtor_company = data.debtor_company
      const debtor_individual = data.debtor_individual
      const lesse_type = data.lesse_type

      axios.post(`/charge_hpi`, {
        user_id: user_id,
        client_id: client_id,
        type: type,
        search_record: search_record,
        debtor_company: debtor_company,
        debtor_individual: debtor_individual,
        lesse_type: lesse_type
      })
      .then((response) => {
        console.log(response);
      });
    }
    
  }
}