import React from 'react';
import ReactDOM from 'react-dom/client';
import './../css/OurContact.css';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import CallIcon from '@mui/icons-material/Call';
import EmailIcon from '@mui/icons-material/Email';

export const OurContact = () => {

    return (

        <section id="contact-us" className="our-contact-section">

            <h3 className="our-contact-section__header">
                contact us
            </h3>
            
            <div className="contact-details-container">

                <div className="form-container">

                    <form  className="contact-us-form">
                        
                        <div className="name-input-container">
                            <input 
                                type="text" 
                                placeholder='Enter your name'
                            />
                        </div>

                        <div className="email-input-container">
                            <input 
                                type="email" 
                                placeholder='Enter your email'
                            />
                        </div>

                        <div className="message-input-container">
                        <textarea placeholder='Enter your message'></textarea>
                        </div>

                        <div className="message-submit-btn-container">
                            <button type='submit' className='message-submit-btn'>Send</button>
                        </div>
                    </form>
                </div>

                <div className="full-details-container">

                    

                    <article className="physical-address">

                        <h4 className='physical-address-header'>
                            <LocationOnIcon className="location-icon"/>
                            our physical address
                        </h4>

                        <p>8th Floor Club Chambers.Cnr N. Mandela Avenue/3rd Street Harare, Zimbabwe</p>

                    </article>

                    
                    <article className="telephone">
                        <h4 className='telephone-header'>
                            <CallIcon className="call-icon"/>
                            telephones
                        </h4>
                        
                        <ul>
                            <li>08677008682</li>
                            <li>+263 (04) 704891-5</li>
                            <li>+263 (04) 708698</li>
                            <li>+263(0)732420450-3</li>
                            <li>+263715239711</li>
                            <li>+263(0)732420451</li>
                        </ul>
                        
                    </article>


                    <article className="email">
                        <h4 className='email-header'>
                            <EmailIcon className="email-icon"/>
                            email
                        </h4>
                        
                        <p> enquiries@fincheckzim.com</p>
                        
                    </article>

                </div>
            </div>
        </section>
    )
}