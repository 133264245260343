import React, {useContext} from 'react'
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
import AuthContext from '../AuthContext/AuthContext'


export const fetchData = (token , setAllData, endpoint, dataFetchProgress,setDataFetchProgress )  => {

    axios.get(`/${endpoint}/`,{
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + token
          },
    })
    .then(res => {
        
        handleResponses(res,setAllData)

        if(dataFetchProgress !== undefined){
            setDataFetchProgress !== null && setDataFetchProgress(dataFetchProgress + 20)
        }
        
    }) 
    .catch((err) => {
        handleResponses(err, setAllData)
        if(dataFetchProgress !== undefined){
            setDataFetchProgress !== null && setDataFetchProgress(dataFetchProgress + 20)
        
        }
    })

};

export const handleResponses = (res, setAllData) => {

    if(res.status >= 200 && res.status <= 299){
        setAllData({
            data: res.data,
            message: "fetch was successful"
        })
    }else if(res.status >= 400 && res.status <= 499){
        setAllData({
            data: null,
            message: "record not found"
        })
    }else if(res.status >= 500 && res.status <= 599){
        setAllData({
            data: null,
            message: "Something went wrong,try again"
        })
    }else {
        setAllData({
            data: null,
            message: "Could not fetch,check your network"
        })
    }
}

export const fetchUserData = (token ,endpoint,id, setUserData) => {
    
    axios.post(`/${endpoint}/`,{
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + token
          },
        data: {id}
    })
    .then(res => {
        
        if(res.status === 200){
            
            setUserData({
                data: res.data,
                message: "fetched successful"
            })
        }else {
            toast.error("Failed to fetch user data.Login again")
            setUserData({
                data: null
            })
        }
        
    })
    .catch(err => {
        toast.error("Failed to fetch user data.Login again")
        setUserData({
            data: null
        })
    })
}


export const handleUpdateResponses = (res,setUpdateStatus) => {

    

    if(res.status >= 200 && res.status <= 299){
        setUpdateStatus({
            statusCode: res.status,
            message: "Request was completed successfully"
        })
    }else if(res.status >= 400 && res.status <= 499){
        setUpdateStatus({
            statusCode: res.status,
            message: "record not found"
        })
    }else if(res.status >= 500 && res.status <= 599){
        setUpdateStatus({
            statusCode: res.status,
            message: "Something went wrong,try again"
        })
    }else if(res.code === 'ERR_BAD_RESPONSE'){
        setUpdateStatus({
            statusCode: 500,
            message: "Something went wrong,try again"
        })
    }
    else {
        setUpdateStatus({
            statusCode: res.status,
            message: "An error occured,please check your connection"
        })
    }
}
