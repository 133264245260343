import React from 'react'
import { TableData, TableDataHeaders, TableHeading } from './ReportTemplates'
import { getDate } from '../../../js/getDate'

const Directorships = ({ directorships, type, color, isExec, biu }) => {
    return (
        <table className='report_table'>

            {
                biu ?
                    <TableHeading
                        span={6}
                        color={color}
                        heading={`Principals`}
                    /> :
                    <TableHeading
                        span={6}
                        color={color}
                        heading={` ${isExec ? 'Executive' : 'Directorships'}`}
                    />
            }



            <tr className='data_row' >


                <TableDataHeaders
                    id={"count_header"}
                    data={""}
                />

                {
                    isExec &&
                    <>
                        <TableDataHeaders
                            id={"count_header"}
                            data={"Position"}
                        />

                    </>
                }

                <TableDataHeaders
                    id={"company_header"}
                    data={"Company"}
                />

                <TableDataHeaders
                    id={"post_header"}
                    data={'Year Of Appointment'}
                />
            </tr>

            {//mappping function

                directorships.data !== null &&
                directorships.data.map((directorship, index) => {

                    if (directorship.executive === true && isExec === true) {
                        return <Directorship directorship={directorship} index={index} type={type} isExec />
                    }

                    if (directorship.executive !== true && isExec !== true) {
                        return <Directorship directorship={directorship} index={index} type={type} />
                    }

                })
            }


        </table>
    )
}


const Directorship = ({ directorship, index, type, isExec }) => {

    return (

        <tr className='data_row' >
            <TableData
                id={"count_field"}
                data={index + 1}
            />

            {
                isExec &&
                <TableData
                    id={"post_field"}
                    data={directorship.post}
                />
            }

            <TableData
                id={"company_field"}
                data={directorship.company.registration_name}
            />

            <TableData
                id={"post_field"}
                data={getDate(directorship.date)}
            />

        </tr>
    )
}

export default Directorships