import React, {useContext} from 'react';
import { ClientBIUDashboardView } from './ClientBIUDashboardView';
import { InternalUserBIUDashboardView } from './InternalUserBIUDashboardView';
import { Error500 } from './Error500';
import { ValuesContext } from './Admin/HomeAdmin';
import { Loader } from './Loader';


export const BIUDashboard = () => {

    const {userData} = useContext(ValuesContext);

    if(Object.keys(userData).length === 0 && userData.constructor === Object){
        
        return <Loader/>

    }
    

    if(Object.keys(userData).length >= 1 && userData.constructor === Object){

        if(userData.data !== null && ( userData.data.groups[0] === 1 || userData.data.groups[0] === 2) ){
            
            return <InternalUserBIUDashboardView/>
        }

        if(userData.data !== null && ( userData.data.groups[0] === 3 || userData.data.groups[0] === 4)){
            return  <ClientBIUDashboardView/>
        }

        if(userData.data === null){
            return <Error500/>
        }
    }


}
