import React from 'react';
import finlogo from './../../../backgrounds/finlog.png';

export const CompanyReportHeader = ({ name, hpRegNum, hpiReport }) => {
    return (

        <th className='report-header'>

            <tr className="company-report-header-row">

                <div className="company-report-logo-container">
                    <img src={finlogo} alt="fincheck logo" />
                    <p className="moto">Enhancing Value Through Bussiness Information</p>
                </div>

                <div className="header-address">
                    <p className="txtheader-address">
                        8th Floor Club Chambers<br />
                        Cnr Nelson Mandela Avenue / 3rd Street <br />
                        Harare<br />
                        Zimbabwe
                    </p>
                </div>

                <div className="header-contactinfo">
                    <p className='txtheader-contact'>
                        +263 (0)242704891-5<br />
                        +263 (0)242794488<br />
                        +263 715 239 711<br />
                        enquiries@fincheckzim.com<br />
                        www.fincheckzim.com
                    </p>
                </div>
            </tr>

            <tr className='header-name'>
                <h4 className='creditreporton'>
                    <b>{hpiReport ? 'HPI Report on ' : 'Credit Report on  '} {hpiReport ? hpRegNum : name} as at {
                        new Date().toLocaleDateString("en-US", { month: "long", day: "numeric", year: "numeric" })}
                    </b>
                </h4>
            </tr>
        </th>
    )
}
