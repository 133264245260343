import React from 'react';
import finlogoPNG from './../../backgrounds/finlog.png'
import MenuIcon from '@mui/icons-material/Menu';
import './../../css/LogoSection.css'

export const LogoSection = () => {

  const toggleNavbar = () => {

    const logoContainer = document.querySelector(".admin-logo-container");
    const adminView = document.querySelector(".admin-view-container");
    const sidebarContainer = document.querySelector(".sidebar-container");
    const burger = document.querySelector(".admin-burger");

    logoContainer.classList.toggle("hide-logo");
    adminView.classList.toggle("toggle-sidebar");
    //navigationSection.classList.toggle("hide-sidenav");
    sidebarContainer.classList.toggle(".hide-sidenav")
    burger.classList.toggle("center-burger")
  }


  return (

    <section className="logo-section">

      <div className="admin-logo-container">
        <img src={finlogoPNG} alt="fincheck logo" />
      </div>

      <MenuIcon
        className="admin-burger"
        onClick={toggleNavbar}
      />
    </section>
  )
}
