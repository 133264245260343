import React, { useEffect } from "react";
import ReactDOM from "react-dom/client";
import SearchIcon from "@mui/icons-material/Search";
import FilterListIcon from "@mui/icons-material/FilterList";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import DynamicFeedIcon from "@mui/icons-material/DynamicFeed";
import { useState, useContext } from "react";
import { Modal } from "./Modal";
import { searchRecord } from "../js/searchRecord";
import AuthContext from "./../AuthContext/AuthContext";
import BulkForm from "./BulkUpload/BulkForm";
import { HPIUpload } from "./HPIUpload";
import { AddEditCompany } from "./Company/AddEditCompany";
import AddEditIndividual from "./Individuals/AddEditIndividual";
import { SearchHPI } from "./HPI/SearchHPI";
import { individual } from "./BulkUpload/TableHeadings";

export const FilterRecords = ({
  allSearchResult,
  setAllSearchResult,
  searchTerm,
  showEditForm,
  setShowEditForm,
  setSearchTerm,
  edit,
  bulkText,
  bulkType,
  searchTermLabel,
  searchParameters,
  RecordIcon,
  addRecordText,
  setSearchResult,
  setActiveSearch,
  setActiveDisplay,
  endpoint,
  AddRecordForm,
}) => {
  console.log("===============checking..." + addRecordText);
  const [showAddRecordForm, setShowAddRecordForm] = useState(false);
  const addFormMapping = {
    "add individual": AddEditIndividual,
    "add company": AddEditCompany,
    "add HPI": SearchHPI,
  };

  return (
    <section className="filter-records">
      {showAddRecordForm && (
        <Modal Component={AddRecordForm} setFormStatus={setShowAddRecordForm} />
      )}

      {/* Search fields are responsible for passing info needed to search a record and the submit btn */}

      <SearchFields
        searchTermLabel={searchTermLabel}
        showAddRecordForm={showAddRecordForm}
        searchParameters={searchParameters}
        setSearchResult={setSearchResult}
        setActiveSearch={setActiveSearch}
        setActiveDisplay={setActiveDisplay}
        endpoint={endpoint}
        bulkType={bulkType}
        bulkText={bulkText}
        edit={edit}
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
        showEditForm={showEditForm}
        setShowEditForm={setShowEditForm}
        allSearchResult={allSearchResult}
        setAllSearchResult={setAllSearchResult}
      />
      {/* {addRecordText == "Exclude" ? (
        ''
      ) : (
        <div
          className="add-record-btn-container"
          onClick={() => setShowAddRecordForm(true)}
        >
          {addRecordText !== "add company" &&
            addRecordText !== "EXCLUDE" &&
            addRecordText !== "add individual" && (
              <button className="add-record-btn">
                <span>+{addRecordText}</span>
              </button>
            )}
        </div>
      )} */}
      <div
          className="add-record-btn-container"
          onClick={() => setShowAddRecordForm(true)}
        >
          {addRecordText !== "add company" &&
            addRecordText !== "add individual" && (
              <button className="add-record-btn">
                <span>+{addRecordText}</span>
              </button>
            )}
        </div>
    </section>
  );
};

const SearchFields = ({
  allSearchResult,
  setAllSearchResult,
  searchTerm,
  showEditForm,
  setShowEditForm,
  setSearchTerm,
  edit,
  bulkText,
  searchTermLabel,
  searchParameters,
  setSearchResult,
  setActiveSearch,
  setActiveDisplay,
  endpoint,
  bulkType,
  showAddRecordForm,
}) => {
  const [searchParameter, setSearchParameter] = useState(
    searchParameters[0].value
  );
  const [showBulkModal, setShowBulkModal] = useState(false);
  let { authTokens, logoutUser } = useContext(AuthContext);

  /* useEffect(() => {

        if (searchTerm === '') {
            return;
        }

        if (showAddRecordForm === false) {
            searchRecord(String(authTokens.access), setActiveDisplay, setActiveSearch, searchParameter, searchTerm, setSearchResult, endpoint)
        }
    }, [showAddRecordForm, showEditForm]) */

  return (
    <section className="search-fields">
      {showBulkModal && bulkType === "Individual Hire Purchase" && (
        <Modal
          Component={HPIUpload}
          setFormStatus={setShowBulkModal}
          data={null}
        />
      )}

      {showBulkModal && bulkType !== "Individual Hire Purchase" && (
        <Modal
          Component={BulkForm}
          setFormStatus={setShowBulkModal}
          data={bulkType}
        />
      )}

      {/* For the search term input */}

      <SearchContainer
        searchTermLabel={searchTermLabel}
        setSearchTerm={setSearchTerm}
        setSearchResult={setSearchResult}
        endpoint={endpoint}
        authTokens={authTokens}
        setActiveDisplay={setActiveDisplay}
        setActiveSearch={setActiveDisplay}
        searchParameter={searchParameter}
        setAllSearchResult={setAllSearchResult}
        allSearchResult={allSearchResult}
        searchTerm={searchTerm}
      />

      {/* For the search filter option */}

      {
        /* endpoint !== 'search-company' && */
        <FilterOptionsContainer
          searchParameters={searchParameters}
          setSearchParameter={setSearchParameter}
        />
      }

      <button
        className="search-btn"
        onClick={() =>
          searchRecord(
            String(authTokens.access),
            setActiveDisplay,
            setActiveSearch,
            searchParameter,
            searchTerm,
            setSearchResult,
            endpoint
          )
        }
      >
        <SearchIcon />
        Search
      </button>

      {!edit && (
        <button className="bulk-enq-btn" onClick={() => setShowBulkModal(true)}>
          <DynamicFeedIcon />
          {bulkText}
        </button>
      )}
    </section>
  );
};

// input for the search term
const SearchContainer = ({
  searchTerm,
  allSearchResult,
  setAllSearchResult,
  searchTermLabel,
  setSearchTerm,
  endpoint,
  setSearchResult,
  authTokens,
  setActiveDisplay,
  setActiveSearch,
  searchParameter,
}) => {
  return (
    <div className="search-container">
      <label htmlFor="search">
        <FilterListIcon className="filter-icon" />
        {searchTermLabel}
      </label>

      <div className="search-input-container">
        <input
          type="text"
          onChange={(e) => setSearchTerm(e.target.value)}
          value={searchTerm}
        />
      </div>
    </div>
  );
};

// filter options container

const FilterOptionsContainer = ({ searchParameters, setSearchParameter }) => {
  return (
    <div className="filter-option-container">
      <label htmlFor="filter-options">
        <FilterAltIcon className="filter-icon" />
        Select filter parameter
      </label>

      <div className="select-container">
        <select onChange={(e) => setSearchParameter(e.target.value)}>
          {searchParameters.map((parameter, index) => {
            return (
              <option key={index} value={parameter.value}>
                {parameter.displayedName}
              </option>
            );
          })}
        </select>
      </div>
    </div>
  );
};
