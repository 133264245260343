import React, { useContext, useEffect, useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import './../css/InputContainer.css';
import { searchRecord } from '../js/searchRecord';
import Select from 'react-select'
import AsyncSelect from 'react-select/async'
import axios from 'axios';
import AuthContext from '../AuthContext/AuthContext';
import { Loader } from './Loader';

export const InputContainer = ({ label, type, currentValue, setValue, id, blurFun, error, width }) => {

    return (

        <div className="input-container" style={{ width: width !== undefined ? width : "30%" }}>
            <label>{label}</label>

            <div className="value-container" style={{ border: error && `1px solid red` }}>
                <input
                    name={id}
                    id={id}
                    type={type}
                    value={currentValue}
                    onChange={e => setValue(e.target.value)}
                    onBlur={blurFun && blurFun}

                />
            </div>
        </div>
    )
}

export const InputContainerRequired = ({ label, type, currentValue, setValue, id, blurFun, error, width, placeHolder }) => {

    return (

        <div className="input-container" style={{ width: width !== undefined ? width : "30%" }}>
            <label className='label-required'><p style={{ color: 'red', paddingRight: '5px' }}>* </p>{' ' + label}</label>

            <div className="value-container" style={{ border: error && `1px solid red` }}>
                <input
                    name={id}
                    id={id}
                    type={type}
                    value={currentValue}
                    onChange={e => setValue(e.target.value)}
                    onBlur={blurFun && blurFun}
                    placeholder={placeHolder}
                />
            </div>
        </div>
    )
}


export const SelectContainer = ({ label, currentValue, setValue, options, id, width }) => {

    return (

        <div className="input-container" style={{ width: width !== undefined ? width : "30%" }}>
            <label>{label}</label>

            <div className="value-container">
                <select
                    value={currentValue}
                    onChange={e => setValue(e.target.value)}
                    className="slt_temp"
                    id={id}
                >
                    <option value='' disabled selected> Click to select an option</option>
                    {options.map((item) => {
                        return (
                            <option className='opt_temp'>{item.option}</option>
                        )

                    })}
                </select>
            </div>
        </div>
    )
}

export const MultiSelectContainer = ({ label, currentValue, setValue, options, id, width }) => {

    const HandleChange = (selectedOption) => {
        let options = []
        options = selectedOption.map((item) => {
            return (item.value)
        })
        setValue(selectedOption)
    }

    return (

        <div className="input-container" style={{ width: width !== undefined ? width : "30%" }}>
            <label>{label}</label>

            <Select
                className='react-multiSelect'
                value={currentValue}
                options={options}
                isMulti
                onChange={HandleChange}
            />

        </div>
    )
}

export const InputAreaContainer = ({ label, type, currentValue, setValue, id }) => {

    return (

        <div className="input-area-container">
            <label>{label}</label>

            <div className="valuearea-container">
                <textarea
                    name={id}
                    id={id}
                    type={type}
                    value={currentValue}
                    onChange={e => setValue(e.target.value)}
                ></textarea>
            </div>
        </div>
    )
}

export const InputphoneContainer = ({ label, type, currentValue, setValue, placeHolder, id }) => {

    return (

        <div className="input-container">
            <label>{label}</label>

            <div className="value-container">
                <input
                    name={id}
                    id={id}
                    type={type}
                    value={currentValue}
                    onChange={e => setValue(e.target.value)}
                    placeholder={placeHolder}
                />
            </div>
        </div>
    )
}


export const InputWithDropdownContainer = ({ label, type, currentValue, setValue, id, typeOfEntity, authTokens, setSearchedEntity, width }) => {

    const [showDropdown, setShowDropdown] = useState(false);
    const [returnedEntities, setReturnedEntities] = useState({ data: null, render: 1 });
    const [activeSearch, setActiveSearch] = useState(false);

    const searcDefedentDataInDatabase = () => {
        if (currentValue.length > 4) {
            searchRecord(
                String(authTokens.access),
                null,
                setActiveSearch,
                `${typeOfEntity.toLowerCase() === 'individual' ? 'fulll_name' : 'registration_name'}`,
                currentValue,
                setReturnedEntities,
                `${typeOfEntity.toLowerCase() === 'individual' ? 'search-fullname-national-id-individual' : 'search-company'}`
            )
        }


    }

    const onEntityValueChange = (e) => {
        e.preventDefault();
        searcDefedentDataInDatabase();
    }

    const onInputBlur = () => {
        if (returnedEntities.render === 1) {
            return;
        }

        if (returnedEntities.data === null) {
            toast.error("Failed to fetch " + typeOfEntity + ".Try again.");
            return;
        }

        if (returnedEntities.data.length === 0) {
            toast.error(typeOfEntity + "not found.Please add one the " + typeOfEntity + " before proceeding.");
            return;
        }
    }

    const onEntityClick = (entity) => {

        setSearchedEntity({ data: [entity] });
        setValue(typeOfEntity === "Individual" ? `${entity.firstname} ${entity.surname}` : entity.registration_name)
        setShowDropdown(false);
    }

    return (
        <div className="input-container" style={{ width: width !== undefined ? width : "30%" }}>
            <label>{label}</label>

            <div className="value-container" style={{ display: 'flex', height: 'auto', alignItems: 'center' }}>
                <input
                    style={{ backgroundColor: 'whitesmoke', padding: '7px 0' }}
                    name={id}
                    id={id}
                    type={type}
                    value={currentValue}
                    onChange={e => setValue(e.target.value)}
                    onBlur={onInputBlur}
                    onFocus={() => setShowDropdown(true)}
                />
                <button onClick={e => onEntityValueChange(e)} className="search-button" style={{ padding: '8px', cursor: 'pointer', backgroundColor: 'var(--main-color)', color: 'white' }}>
                    search
                </button>
            </div>

            {
                showDropdown &&
                <section className={`searched-dropdown`} style={{ zIndex: 100 }}>

                    {activeSearch ?
                        <Loader /> :
                        <ul>
                            {
                                (returnedEntities.data !== null && returnedEntities.data.length > 0) &&
                                returnedEntities.data.map((entity, index) => {
                                    return <li key={index} onClick={() => onEntityClick(entity)}>{typeOfEntity === "Individual" ? entity.firstname + ` ${entity.surname}` + ` (${entity.national_id})`
                                        : entity.registration_name + ` (${entity.cb_number == null || entity.cb_number == "" ? 'N/A' : entity.cb_number.slice(0, 6)})`}</li>
                                })
                            }
                        </ul>}
                </section>
            }
        </div>
    )
}

export const fileUploader = ({ label, type, currentValue, setValue, placeHolder, id }) => {

    return (

        <div className="input-container">
            <label>{label}</label>

            <div className="value-container">
                <input
                    name={id}
                    id={id}
                    type='file'
                    value={currentValue}
                    onChange={e => setValue(e.target.files)}
                    placeholder={placeHolder}
                />
            </div>
        </div>
    )
}

export const InputContainerReadOnly = ({ label, type, currentValue, id, blurFun, error, width }) => {

    return (

        <div className="input-container" style={{ width: width !== undefined ? width : "30%" }}>
            <label>{label}</label>

            <div className="value-container" style={{ border: error && `1px solid red` }}>
                <input
                    name={id}
                    id={id}
                    type={type}
                    value={currentValue}
                    onBlur={blurFun && blurFun}
                    readOnly
                />
            </div>
        </div>
    )
}

export const InputContainerTable = ({ label, type, currentValue, setValue, id, blurFun, error, width }) => {

    return (

        <div className="value-container-table" style={{ border: error && `1px solid red` }}>
            <input className='inputtablecss'
                name={id}
                id={id}
                type={type}
                value={currentValue}
                onChange={e => setValue(e.target.value)}
                onBlur={blurFun && blurFun}

            />
        </div>
    )
}

export const InputContainerTableReadOnly = ({ label, type, currentValue, setValue, id, blurFun, error, width }) => {

    return (

        <div className="value-container-table" style={{ border: error && `1px solid red` }}>
            <input className='inputtablecss'
                name={id}
                id={id}
                type={type}
                value={currentValue}
                readOnly
            />
        </div>
    )
}

export const MultiSelectContainerWithSearchHPI = ({ label, currentValue, setValue, id, width, searchValue, subscription }) => {
    const [data, setData] = useState([])
    const [hpClients, setHpClients] = useState([])
    const { authTokens, logoutUser } = useContext(AuthContext);
    let options = []
    let hpsubs = []

    useEffect(() => {
        axios({
            method: 'get',
            url: '/client/',
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: "Bearer " + String(authTokens.access)
            }
        })
            .then((res) => {
                setData(res.data)
            }).catch((AxiosError) => {

                if (AxiosError.code === "ERR_NETWORK") {
                    toast.error("Network Error!")
                } else (
                    toast.error('Internal system error contact system admin!')
                )
            }
            );
        if (data.length !== 0) {
            data.map(client => {
                if (client.subscription_category) {
                    client.subscription_category.map(sub => {
                        if (sub === subscription) {
                            hpsubs = hpsubs.concat(client)
                        }
                    })
                }
            })
            setHpClients(hpsubs)
        }
    }, [data.length])

    hpClients.map(
        client => options = options.concat({ value: client.fins_number, label: client.registration_name })
    )

    const HandleChange = (selectedOption) => {
        setValue(selectedOption)
    }

    const filterClients = (inputValue) => {
        return options.filter((i) =>
            i.label.toLowerCase().includes(inputValue.toLowerCase())
        );
    }

    const loadOptions = (inputValue, callback) => {
        if (inputValue !== '') {
            callback(filterClients(inputValue));
        } else {
            return options
        }
    }

    return (

        <div className="input-container" style={{ width: width !== undefined ? width : "30%" }}>
            <label>{label}</label>

            <AsyncSelect
                className='react-multiSelect'
                loadOptions={loadOptions}
                value={currentValue}
                onChange={HandleChange}
                cacheOptions
                defaultOptions={options}
            />

        </div>
    )
}

export const MultiSelectContainerWithSearch = ({ label, currentValue, setValue, id, width, searchValue }) => {
    const [data, setData] = useState([])
    const { authTokens, logoutUser } = useContext(AuthContext);
    let options = []
    let hpsubs = []

    useEffect(() => {
        axios({
            method: 'get',
            url: '/client/',
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: "Bearer " + String(authTokens.access)
            }
        })
            .then((res) => {
                setData(res.data)
            }).catch((AxiosError) => {

                if (AxiosError.code === "ERR_NETWORK") {
                    toast.error("Network Error!")
                } else (
                    toast.error('Internal system error contact system admin!')
                )
            }
            );
    }, [data.length])


    data.map(
        client => options = options.concat({ value: client.fins_number, label: client.registration_name })
    )

    const HandleChange = (selectedOption) => {
        setValue(selectedOption)
    }

    const filterClients = (inputValue) => {
        return options.filter((i) =>
            i.label.toLowerCase().includes(inputValue.toLowerCase())
        );
    }

    const loadOptions = (inputValue, callback) => {
        if (inputValue !== '') {
            callback(filterClients(inputValue));
        } else {
            return options
        }
    }

    return (

        <div className="input-container" style={{ width: width !== undefined ? width : "100%" }}>
            <label>{label}</label>

            <AsyncSelect
                className='react-multiSelect'
                loadOptions={loadOptions}
                value={currentValue}
                onChange={HandleChange}
                cacheOptions
                defaultOptions={options}
            />

        </div>
    )
}

export const MultiSelectContainerWithSearchUser = ({ label, currentValue, setValue, id, width, searchValue }) => {
    const [data, setData] = useState([])
    const { authTokens, logoutUser } = useContext(AuthContext);
    let options = []
    let hpsubs = []

    useEffect(() => {
        setValue('')
        axios({
            method: 'post',
            url: '/company-users/',
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: "Bearer " + String(authTokens.access)
            },
            data: { data: { fins: searchValue } },
        })
            .then((res) => {
                setData(res.data)
            }).catch((AxiosError) => {

                if (AxiosError.code === "ERR_NETWORK") {
                    toast.error("Network Error!")
                } else (
                    toast.error('Internal system error contact system admin!')
                )
            }
            );
    }, [data.length, searchValue])


    data.map(
        user => options = options.concat({ value: user.username, label: user.firstname + ' ' + user.surname })
    )

    const HandleChange = (selectedOption) => {
        setValue(selectedOption)
    }

    const filterClients = (inputValue) => {
        return options.filter((i) =>
            i.label.toLowerCase().includes(inputValue.toLowerCase())
        );
    }

    const loadOptions = (inputValue, callback) => {
        if (inputValue !== '') {
            callback(filterClients(inputValue));
        } else {
            return options
        }
    }

    return (

        <div className="input-container" style={{ width: width !== undefined ? width : "30%" }}>
            <label>{label}</label>

            <AsyncSelect
                className='react-multiSelect'
                loadOptions={loadOptions}
                value={currentValue}
                onChange={HandleChange}
                cacheOptions
                defaultOptions={options}
            />

        </div>
    )
}