import "./../css/ReportsBIU.css";
import React, { useContext, useState, useEffect } from "react";
import { toast } from "react-toastify";
import { ValuesContext } from "./Admin/HomeAdmin";
import AuthContext from './../AuthContext/AuthContext';
import { fetchData } from "../js/fetchData";
import { getDate } from "../js/getDate";
import { Reports } from "./InternalUserBIUDashboardView";

export const ReportsBIU = ({ admin }) => {
    const [biUReportReqs, setBIUReportReqs] = useState({ data: "" });
    const { authTokens } = useContext(AuthContext);
    const { userData } = useContext(ValuesContext);
    const [searchTerm, setSearchTerm] = useState("");
    const [organisedBIUReports, setOrganisedBIUReports] = useState({});

    useEffect(() => {

        fetchData(
            String(authTokens.access),
            setBIUReportReqs,
            "biu-request/",
            null,
            null
        );

    }, []);

    useEffect(() => {

        if (biUReportReqs.data === "") {
            return;
        }

        if (biUReportReqs.data === null) {
            toast.error("Failured to fetch reports");
            return;
        }

        if (biUReportReqs.data.length > 0) {

            const BIUs = {};

            if (userData.data.groups[0] === 1 || userData.data.groups[0] === 2) {

                // use whole  dataset

                biUReportReqs.data.forEach(report => {

                    const splittedDate = getDate(report.created_date).split(" ");
                    const timeSpace = `${splittedDate[0]} ${splittedDate[2]}`;

                    const availabletimeSpace = BIUs[timeSpace];


                    if (availabletimeSpace === undefined) {
                        BIUs[timeSpace] = [report]
                        return;
                    } else {
                        BIUs[timeSpace] = [...availabletimeSpace, report]
                    }
                })

                setOrganisedBIUReports(BIUs);

            }



            if (userData.data.groups[0] === 3) {

                const filteredBIUs = biUReportReqs.data.filter(report => report.user.username === userData.data.username);


                filteredBIUs.forEach(report => {

                    const splittedDate = getDate(report.created_date).split(" ");
                    const timeSpace = `${splittedDate[0]} ${splittedDate[2]}`;

                    const availabletimeSpace = BIUs[timeSpace];


                    if (availabletimeSpace === undefined) {
                        BIUs[timeSpace] = [report]
                        return;
                    } else {
                        BIUs[timeSpace] = [...availabletimeSpace, report]
                    }
                })

                setOrganisedBIUReports(BIUs);


            }

            if (userData.data.groups[0] === 4) {

                const filteredBIUs = biUReportReqs.data.filter(report => report.client_company.fins_number === userData.data.company);


                filteredBIUs.forEach(report => {

                    const splittedDate = getDate(report.created_date).split(" ");
                    const timeSpace = `${splittedDate[0]} ${splittedDate[2]}`;

                    const availabletimeSpace = BIUs[timeSpace];


                    if (availabletimeSpace === undefined) {
                        BIUs[timeSpace] = [report]
                        return;
                    } else {
                        BIUs[timeSpace] = [...availabletimeSpace, report]
                    }
                })

                setOrganisedBIUReports(BIUs);


            }


        }
    }, [biUReportReqs])


    return (

        <section className="search-companies">

            <div className="search-companies-header">
                <p>Business Reports List </p>
            </div>

            <div className="reports-search-header">

                <div className="month-end">
                    <p>Month End date: 25</p>
                </div>

                <div className="search-report-input-cont">
                    <input
                        type="text"
                        placeholder="Search Report"
                        value={searchTerm}
                        onChange={e => setSearchTerm(e.target.value)}
                    />
                </div>

            </div>

            <div className="reports-timeline-container">

                {
                    Object.keys(organisedBIUReports).length === 0 ?
                        <p style={{ textAlign: "center", marginTop: "1.5em" }}>No Reports Available At The Moment</p> :

                        Object.keys(organisedBIUReports).map((key, index) => {
                            const reports = { data: organisedBIUReports[key] }
                            return (
                                <ReportMonth month={key} reports={reports} />
                            )
                        })
                }
            </div>


        </section>
    )
}

const ReportMonth = ({ month, reports }) => {

    const [showReportTable, setShowReportTable] = useState(false);

    return (

        <>
            <button className="month-button" onClick={() => setShowReportTable(!showReportTable)}>{month}</button>

            {
                showReportTable &&
                <Reports
                    showAddBiuModal={null}
                    setShowAddBiuModal={null}
                    biuReportReqs={reports}
                    setBIUReportReqs={null}
                />
            }


        </>
    )
}
