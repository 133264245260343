import React from 'react'
import './../../css/BureauDashboard.css'
import backPic from '../../backgrounds/dash.jpg'

const HomeTemp = () => {
  return (
    <div className = 'dashboard-container'>
      <img src={backPic} alt="" className='back-pic' />
    </div>
  )
}

export default HomeTemp
