export const getDate = (date) => {

    const parsedDate = new Date(date);
    const final = parsedDate.toLocaleDateString('en-US', {month: 'long', day: 'numeric', year: 'numeric'});
    return final;
}

export const calculateOverdueMonths = (startDate) => {

    const start = new Date(startDate);
    const today = new Date()


    const difference = Math.abs(today - start);
    const days = difference / (1000 * 3600 * 24);
    
    const months = days / 30;

    if(months < 1 && months > 0){
        return "less than a month"
    }
    return months
}

export const calculateReportDays = (startDate) => {

    const start = new Date(startDate);
    const today = new Date()

    const difference = Math.abs(today - start);
    const days = difference / (1000 * 3600 * 24);

    return days;
    
} 