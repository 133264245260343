import React, {useState,useContext} from 'react';
import './../css/AdverseUploads.css';
import {  HPIOptions} from './Company/selectOptionsjson';
import { SelectContainer } from './InputContainer';
import BulkForm from './BulkUpload/BulkForm';


export const HPIUpload = () => {


  return (
    
    <section className="adverse-uploads-container">

        <MultipleUploads/>
        
    </section>
  )
}


const MultipleUploads = () => {

  const [dataTypeState,setDataTypeState] = useState('Individual Hire Purchase')

  return (
    <section className="multiple-adverse-uploads-container">

      <div className='multiple-data-type-container'>
        <SelectContainer
            label={"Data Type"}
            currentValue={dataTypeState}
            setValue={setDataTypeState}
            options={HPIOptions}
            id={""}
        />
      </div>


      <div className="bulk-forms-container">
        <BulkForm data={dataTypeState}/>
      </div>


    </section>
  )
}

