import React from 'react'
import { TableData, TableDataHeaders, TableHeading } from './ReportTemplates'
import { getDate } from './../../../js/getDate'

const CourtRecords = ({ courts, color, enqDetails }) => {
    return (
        <table className='report_table'>
            <TableHeading
                span={7}
                color={color}
                heading={"Court Judgements"}
            />

            <tr className='data_row' >
                <TableDataHeaders
                    id={"count_header"}
                    data={""}
                />

                {/*  <TableDataHeaders
                    id={"case_header"}
                    data={"Case Number"}
                /> */}

                <TableDataHeaders
                    id={"court_header"}
                    data={"Court"}
                />

                <TableDataHeaders
                    id={"plaintiff_header"}
                    data={"Plaintiff"}
                />

                <TableDataHeaders
                    id={"judgement_header"}
                    data={"Case No."}
                />

                <TableDataHeaders
                    id={"currency_header"}
                    data={"Currency"}
                />

                <TableDataHeaders
                    id={"amount_header"}
                    data={"Amount"}
                />





                <TableDataHeaders
                    id={"judgement_header"}
                    data={"Judgement Date"}
                />


            </tr>

            {
                (courts.data !== null && courts.data.filter(court => court.is_closed === false).length === 0 && (enqDetails && enqDetails.type !== 'internal')) &&
                <tr className='data_row'>
                    <td colSpan={10} style={{ color: 'green' }}> CLEAR</td>
                </tr>
            }

            {//mappping function

                courts.data !== null &&
                courts.data.sort((a, b) => a.is_closed - b.is_closed).map((court, index) => {

                    if (court.is_closed === false) {
                        return <CourtCase court={court} index={index} />
                    }

                    if (court.is_closed === true && (enqDetails && enqDetails.type === 'internal')) {

                        return (
                            <>
                                <CourtCase court={court} index={index} />
                                <tr className="closed-claim-details" style={{ background: "rgba(7, 193, 48, 0.26)", textAlign: "center" }}>
                                    <td colSpan={6} style={{ color: 'green' }}>{`Cleared - ${court.coment}`}</td>
                                    <td>{getDate(court.date_closed)}</td>
                                </tr>
                            </>
                        )
                    }

                })
            }


        </table>
    )
}

const CourtCase = ({ court, index }) => {
    const color = court.is_closed ? 'rgba(7, 193, 48, 0.26)' : '';
    return (

        <tr className='data_row' style={{ backgroundColor: color }}>
            <TableData
                id={"count_field"}
                data={index + 1}
            />
            {/* 
                <TableData
                    id={"case_field"}
                    data={court.case_number}
                />

 */}

            <TableData
                id={"court_field"}
                data={court.court_name}
            />

            <TableData
                id={"plaintiff_field"}
                data={court.plaintf_name}
            />


            <TableData
                id={"judgement_field"}
                data={court.case_number}
            />

            <TableData
                id={"currency_field"}
                data={court.currency_type}
            />

            <TableData
                id={"amount_field"}
                data={court.amount}
            />


            <TableData
                id={"judgement_field"}
                data={getDate(court.judgement_date)}
            />

        </tr>

    )
}

export default CourtRecords