import React from 'react'
import exportCredit from './../backgrounds/export-credit.png';
import netone from './../backgrounds/netone.png';
import liquid from './../backgrounds/liquid.png';
import stanbic from './../backgrounds/stanbic.png';
import transUnion from './../backgrounds/trans-union.png';
import tv from './../backgrounds/tv.png';
import { useState } from 'react';
import './../css/OurReviews.css';

export const OurReviews = () => {

    return (

        <section id="reviews" className="reviews-section">

            <h3 className="reviews-section__header">
                our reviews
            </h3>

            <div className="reviews-container">

                <Review
                    img={exportCredit}
                    alt={"Export Credit Guarantee Corporation"}
                    name={"Neo Chitanga"}
                    company={"Export Credit Guarantee Corporation"}
                    review={"I benefited mostly on assessing credit risk because this is part of what I do as a credit underwriter. Therefore, it was most useful to me."}
                />

                <Review
                    img={""}
                    alt={""}
                    name={"Judith  Tekede "}
                    company={"New World Property Management"}
                    review={"Your speed and urgency in sending the required reports is so beneficial to our business. We do not have to chase for our reports.As soon as you are done, you email them directly to us within a day or two which is excellent."}
                />

                <Review
                    img={stanbic}
                    alt={"Stanbic  Bank"}
                    name={"Zenzo Khuphe "}
                    company={"Stanbic Bank"}
                    review={"Fincheck thus far rendering excellent service."}
                />

                <Review
                    img={stanbic}
                    alt={"TV Sales and Home"}
                    name={"H Gwatida "}
                    company={"TV Sales and Home "}
                    review={"We confirm that TV Sales and Home has had business association with Fincheck for more than  10 years. We have found the dealing of the company quite satisfactory particularly on Fincheck Plus; an online credit vetting system. Fincheck has provided our business with healthy support and kind help. Fincheck Pvt Ltd is surely a solid and reliable supplier of ICT Services. An expert in their field in our opinion and belief "}
                />

                <Review
                    img={liquid}
                    alt={"Liquid Telecom"}
                    name={"Tafadzwa Mudadi"}
                    company={"Liquid Telecom"}
                    review={" We have worked with Fincheck for the past 3 years or so. They have really assisted in our debt collection initiative as well as the improvement of our processes"}
                />

                <Review
                    img={netone}
                    alt={"Netone"}
                    name={"Ruth Shoko"}
                    company={"Netone"}
                    review={" Absolute convenience in terms of their system. You just give them the individual's ID number and  all the information comes up quickly"}
                />

            </div>
        </section>
    )
}

const Review = ({ img, alt, name, company, review }) => {

    const [readMore, setReadMore] = useState(false);
    const [btnStatus, setBtnStatus] = useState("read more")
    const splittedReview = review.slice(0, 39);
    const remainderReview = review.slice(39);

    const viewFullReview = () => {

        setReadMore(!readMore);

        if (btnStatus === "read more") {
            setBtnStatus("read less")
        } else {
            setBtnStatus("read more")
        }
    }
    return (

        <div className="review">

            <div className="review-header">
                {
                    alt === "" ? <div className="white-circle"></div> :
                        <div className="review-img-container">
                            <img src={img} alt={alt} />
                        </div>
                }

                <div className="reviewer-details">
                    <p className="reviewer-name">{name}</p>
                    <p className="reviewer-role-company">
                        {company}
                    </p>
                </div>

            </div>

            <article className="review_para">
                <p>{splittedReview}{readMore && <span>{remainderReview}</span>}</p>
            </article>

            <button className="read-more-btn" onClick={viewFullReview}>
                {btnStatus}
            </button>
        </div>

    )
}