import './../css/CompanyHistory.css';
import React from 'react';
import dunbadPNG from './../backgrounds/dun-bad.png';
import transUnion from './../backgrounds/trans-union.png';
import finLogo from './../backgrounds/finlog.png';

export const CompanyHistory = () => {

    return (

        <section className="company-history">

            <div className="company-iteration">
                <div className="dun-logo-container">
                    <img className="dun-logo" src={dunbadPNG} alt="Dun logo" />
                </div>
                <p className="dun-year">1996</p>
            </div>

            <div className="company-iteration">
                <div className="trans-logo-container">
                    <img className="trans-union-logo" src={transUnion} alt="Dun logo" />
                </div>
                <p className="trans-year">2003</p>
            </div>

            <div className="company-iteration">
                <div className="fin-logo-container">
                    <img className="fin-logo" src={finLogo} alt="fin-logo" />
                </div>
                <p className="fin-year">2017</p>
            </div>
        </section>
    )
}
