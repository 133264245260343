import React, { useContext, useRef, useState } from "react"
import AuthContext from "../../AuthContext/AuthContext";
import { CompanyReportHeader } from "../Company/CompanyReport/CompanyReportHeader";
import { TableData, TableDataHeaders } from "../Individuals/IndividualReport/ReportTemplates";
import { getDate } from "../../js/getDate";
import { PrintBtn } from "../Individuals/IndividualReport/IndividualReport";

export const HPIReport = () => {


    const [hp, setHP] = useState(JSON.parse(localStorage.getItem("data")));
    const { authTokens } = useContext(AuthContext);
    const [enqDetails, setEnqDetails] = useState(JSON.parse(localStorage.getItem("enq-details")));
    const [showBtn, setShowBtn] = useState(true)
    const type = useRef("")

    return (

        <>

            <table ref={type} className='Individual_report'>
                <tr className='report_section'>
                    <CompanyReportHeader hpRegNum={hp.reg_number ? hp.reg_number : 'N/A'} hpiReport />
                </tr>

                <br /><br />

                <tr className="report_section">
                    <table className="report_table" border={1} style={{ width: '100%', padding: '20px', marginTop: '2px' }}>

                        <tr className='row_heading' style={{ margin: '20px 0', backgroundColor: 'grey', color: 'black' }}>
                            <td className='txt_rowheading' colSpan={5}>
                                <h3 className='section_heading'>
                                    PRINCIPALS
                                </h3>
                            </td>
                        </tr>

                        <tr className="data_row">
                            <TableDataHeaders
                                data={'Debtor'}
                            />

                            <TableData
                                id={"debtor_field"}
                                data={`${hp.lesse_name ? hp.lesse_name : 'N/A'}`}
                            />
                            <TableDataHeaders
                                data={'Financier:'}
                            />
                            <TableData
                                id={"debtor_field"}
                                data={`${hp.financier_company ? hp.financier_company.registration_name : 'N/A'}`}
                            />

                        </tr>


                    </table>
                </tr>

                <br /><br />

                <tr className="report_section">
                    <table className="report_table" border={1} style={{ width: '100%', padding: '20px', marginTop: '2px' }}>

                        <tr className='row_heading' style={{ margin: '20px 0', backgroundColor: 'grey', color: 'black' }}>
                            <td className='txt_rowheading' colSpan={5}>
                                <h3 className='section_heading'>
                                    AGREEMENT AND PAYMENT TERMS
                                </h3>
                            </td>
                        </tr>

                        <tr className="data_row">
                            <TableDataHeaders
                                data={'Agreement'}
                            />

                            <TableData
                                id={"debtor_field"}
                                data={`${hp.agreement ? hp.agreement : 'N/A'}`}
                            />

                            <TableDataHeaders
                                data={'Agreement Date'}
                            />

                            <TableData
                                id={"debtor_field"}
                                data={`${hp.agreement_start_data ? getDate(hp.agreement_start_data) : 'N/A'}`}
                            />

                        </tr>

                        <tr className="data_row">
                            <TableDataHeaders
                                data={'Amount'}
                            />

                            <TableData
                                id={"debtor_field"}
                                data={`${hp.purchase_amount ? hp.purchase_amount : 'N/A'}`}
                            />

                            <TableDataHeaders
                                data={'Total Paid To Date'}
                            />

                            <TableData
                                id={"debtor_field"}
                                data={`${hp.paid_to_date ? hp.paid_to_date : 'N/A'}`}
                            />

                        </tr>

                        <tr className="data_row">
                            <TableDataHeaders
                                data={'Payment Date'}
                            />

                            <TableData
                                id={"debtor_field"}
                                data={`${hp.payment_date ? hp.payment_date : 'N/A'}`}
                            />

                            <TableDataHeaders
                                data={'Payment Terms'}
                            />

                            <TableData
                                id={"debtor_field"}
                                data={`${hp.payment_terms ? hp.payment_terms : 'N/A'}`}
                            />

                        </tr>

                        <tr className="data_row">
                            <TableDataHeaders
                                data={'Currency'}
                            />

                            <TableData
                                id={"debtor_field"}
                                data={`${hp.currency ? hp.currency : 'N/A'}`}
                            />

                            <TableDataHeaders
                                data={'Payment Terms'}
                            />

                            <TableData
                                id={"debtor_field"}
                                data={`${hp.payment_quality ? hp.payment_quality : 'N/A'}`}
                            />

                        </tr>


                        <tr className="data_row">
                            <TableDataHeaders
                                data={'Due To'}
                            />

                            <TableData
                                id={"debtor_field"}
                                data={`${hp.agreement_due_data ? getDate(hp.agreement_due_data) : 'N/A'}`}
                            />

                            <TableDataHeaders
                                data={'Action'}
                            />

                            <TableData
                                id={"debtor_field"}
                                data={`${hp.action ? hp.action : 'N/A'}`}
                            />

                        </tr>


                    </table>
                </tr>

                <br /><br />


                <tr className="report_section">
                    <table className="report_table" border={1} style={{ width: '100%', padding: '20px', marginTop: '2px' }}>

                        <tr className='row_heading' style={{ margin: '20px 0', backgroundColor: 'grey', color: 'black' }}>
                            <td className='txt_rowheading' colSpan={8}>
                                <h3 className='section_heading'>
                                    VEHICLE DETAILS
                                </h3>
                            </td>
                        </tr>

                        <tr className="data_row">
                            <TableDataHeaders
                                data={'Class Code'}
                            />

                            <TableData
                                id={"debtor_field"}
                                data={`${hp._class ? hp._class : 'N/A'}`}
                            />
                            <TableDataHeaders
                                data={'Make'}
                            />
                            <TableData
                                id={"debtor_field"}
                                data={`${hp.make ? hp.make : 'N/A'}`}
                            />

                            <TableDataHeaders
                                data={'Model'}
                            />
                            <TableData
                                id={"debtor_field"}
                                data={`${hp.model ? hp.model : 'N/A'}`}
                            />
                            <TableDataHeaders
                                data={'State'}
                            />
                            <TableData
                                id={"debtor_field"}
                                data={`${hp.condition ? hp.condition : 'N/A'}`}
                            />

                        </tr>


                        <tr className="data_row">
                            <TableDataHeaders
                                data={'Reg Number'}
                            />

                            <TableData
                                id={"debtor_field"}
                                data={`${hp.reg_number ? hp.reg_number : 'N/A'}`}
                            />
                            <TableDataHeaders
                                data={'Chassis Number'}
                            />
                            <TableData
                                id={"debtor_field"}
                                data={`${hp.chasis ? hp.chasis : 'N/A'}`}
                            />

                            <TableDataHeaders
                                data={'Engine Number'}

                            />
                            <TableData
                                id={"debtor_field"}
                                data={`${hp.engine_number ? hp.engine_number : 'N/A'}`}
                                span={3}
                            />


                        </tr>


                        <tr className="data_row">
                            <TableDataHeaders
                                data={'Serial Number'}
                            />

                            <TableData
                                id={"debtor_field"}
                                data={`${hp.serial ? hp.serial : 'N/A'}`}
                            />
                            <TableDataHeaders
                                data={'Year'}
                            />
                            <TableData
                                id={"debtor_field"}
                                data={`${hp.year ? hp.year : 'N/A'}`}
                            />

                            <TableDataHeaders
                                data={'Status'}
                            />
                            <TableData
                                id={"debtor_field"}
                                data={`${hp.status ? hp.status : 'N/A'}`}
                                span={3}
                            />


                        </tr>


                    </table>
                </tr>


                <br /><br /><br />

            </table>

            {showBtn && <PrintBtn setShowBtn={setShowBtn} type={type} />}
        </>
    )
}