import React from 'react'
import { getDate } from '../../../js/getDate'
import { TableData, TableDataHeaders, TableHeading } from '../IndividualReport/ReportTemplates'

export const CurrentEmploymentDetails = ({ individual }) => {
    return (

        <table className="report_table">

            <TableHeading
                heading={"Current Employment Details"}
                span={8}
            />

            <tr className="data_row">
                <TableDataHeaders
                    data={"Employer"}
                />

                <TableData
                    data={individual.employer_name}
                />
            </tr>

            <tr className="data_row">
                <TableDataHeaders
                    data={"Address"}
                />

                <TableData
                    data={individual.employment_address}
                />
            </tr>

            <tr className="data_row">
                <TableDataHeaders
                    data={"Telephone"}
                />

                <TableData
                    data={individual.telephone_no}
                />
            </tr>

            <tr className="data_row">
                <TableDataHeaders
                    data={"Occupation"}
                />

                <TableData
                    data={individual.job_title}
                />
            </tr>

            <tr className="data_row">
                <TableDataHeaders
                    data={"Period Of Employment"}
                />

                <TableData
                    data={getDate(individual.date_of_employement) + " TO DATE"}
                />
            </tr>

        </table>
    )
}
