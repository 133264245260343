import React, {useState,useContext} from 'react';
import AddIcon from '@mui/icons-material/Add';
import { AddEditCompany } from './Company/AddEditCompany';
import AddEditIndividual from './Individuals/AddEditIndividual';
import {Modal} from './Modal'
import AuthContext from '../AuthContext/AuthContext';
import './../css/AdverseUploads.css';
import {  dataTypeBulk,dataType } from './Company/selectOptionsjson';
import { SelectContainer } from './InputContainer';
import { AddSingleClaim } from './Other/AddSingleClaim';
import { AddSingleCourtRec } from './Other/AddSingleCourtRec';
import { AddSinglePublicRec } from './Other/AddSinglePublicRecord';
import BulkForm from './BulkUpload/BulkForm';


export const AdverseUploads = () => {

  const [showAddModal,setShowAddModal] = useState(false);
  const [showSingleUpload,setShowSingleUpload] = useState(true);
  const [formToDisplay,setFormToDisplay] = useState("");

  const displayForm = (e) => {
    const form = e.target.innerText
    setFormToDisplay(form);
    setShowAddModal(true);
  }

  const switchTab = (e) => {

    if (e.target.innerText === "Single") {
      setShowSingleUpload(true);
      e.target.classList.add("active")
      document.querySelector(".multiple-btn").classList.remove("active");
    } else {
      setShowSingleUpload(false);
      e.target.classList.add("active")
      document.querySelector(".single-btn").classList.remove("active");
    }

    
  }

  return (
    
    <section className="adverse-uploads-container">

        <div className="adverse-uploads-nav">
           
           <div className="single-multiple-btns-container">
             <button className='single-btn active' onClick={e => switchTab(e)}>Single</button>
            <button onClick={e => switchTab(e)} className="multiple-btn">Multiple</button>
           </div>

           <div className="add-individual-company-btn-container">
             <button className="add-individual-btn" onClick={(e) => displayForm(e)}>
              <AddIcon/>
                Add Individual
             </button>

             <button className="add-company-btn" onClick={(e) => displayForm(e)}>
              <AddIcon/>
                Add Company
             </button>
           </div>
        </div>

       {
         showSingleUpload ?
         <div className="upload-section-container">
            <AdverseSingleUpload
              showAddModal={showAddModal}
              setShowAddModal={setShowAddModal}
              formToDisplay={formToDisplay}
            />
        </div> :
        <MultipleUploads showAddModal={showAddModal} formToDisplay={formToDisplay} setShowAddModal={setShowAddModal}/>
        
       }
    </section>
  )
}



const AdverseSingleUpload = ({showAddModal,setShowAddModal,formToDisplay}) => {

  const {authTokens} = useContext(AuthContext)
  const [dataTypeState,setDataTypeState] = useState("Claim");


  return (

    <>     
        
      {
        showAddModal &&
        <Modal
          Component={formToDisplay === "Add Individual" ? AddEditIndividual : AddEditCompany}
          setFormStatus={setShowAddModal}
        />
      }
        
      
      <form className="adverse-single-form">

        <SelectContainer
          label={"Data Type"}
          currentValue={dataTypeState}
          setValue={setDataTypeState}
          options={dataType}
          id={""}
        />


        {
          dataTypeState === "Claim" &&
          <AddSingleClaim
            authTokens={authTokens}
            dataTypeState={dataTypeState}
          />
        }

        
        {
          dataTypeState === "Absconder" &&
          <AddSingleClaim
            authTokens={authTokens}
            dataTypeState={dataTypeState}
          />
        }

        {
          dataTypeState === "Court" &&
          <AddSingleCourtRec
            authTokens={authTokens}
            dataTypeState={dataTypeState}
          />
        }

        {
          dataTypeState === "Public Records" &&
          <AddSinglePublicRec
            authTokens={authTokens}
          />
        }

          
        

      </form>

    </>
  )
}


const MultipleUploads = ({showAddModal,formToDisplay,setShowAddModal}) => {

  const [dataTypeState,setDataTypeState] = useState("Individual Claims")

  return (
    <section className="multiple-adverse-uploads-container">

      {/* <div className='multiple-data-type-container'>
        <SelectContainer
            label={"Data Type"}
            currentValue={dataTypeState}
            setValue={setDataTypeState}
            options={dataTypeBulk}
            id={""}
        />
      </div> */}

      {   
        showAddModal &&
        <Modal
          Component={formToDisplay === "Add Individual" ? AddEditIndividual : AddEditCompany}
          setFormStatus={setShowAddModal}
        />
      }


      <div className="bulk-forms-container">
        <BulkForm data={'Negative Data'}/>
      </div>


    </section>
  )
}

