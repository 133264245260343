export const CompanyorIndividual = [
    {option: "Individual"},
    {option : "Company"}
]

export const AssetTypehpi = [
    {option: "Computers"},
    {option : "Machinery"},
    {option: "Office Equipment"},
    {option: "Vehicles"}
]

export const optdueTo =[
    {option : "Deliquency"},
    {option : "Open & Present"},
    {option : "Sickness"},
    {option : "Unemployment"},
    {option : "Other"},

]

export const optAction = [
    {option : "Bad debt"},
    {option : "Bankrupcy"},
    {option : "Open & Present"},
    {option : "Judgement"},
    {option : "Repossesion"},
    {option : "Other"},
]

export const optClass = [
    {option : "Aircraft"},
    {option : "Earth Moving Equipment"},
    {option : "Farm Equipment"},
    {option : "Sail Craft & Marine"},
    {option : "Space Craft"},
    {option : "Commercial Vehicle"},
    {option : "Caravan & Trailers"},
    {option : "Motor Cyle & Scooter"},
    {option : "Pass Vehicle"},
    {option : "Other"},
]

export const optState = [
    {option : "Old"},
    {option : "New"},
]

export const optStatus = [
    {option : "Open"},
    {option : "Vehicle Has Been Paid"},
]

export const YesorNO = [
    {option : 'YES'},
    {option : 'NO'}
]
