import React from 'react'
import '../../css/clientsarena.css'

const AddeditclientNav = ({ setdisplayForm, displayform }) => {
  return (
    <div className='navigationaddclient'>
      <button onClick={() => setdisplayForm('client')} className={displayform === 'client' ?
        'addeditnavbuttonactive'
        :
        'addeditnavbutton'
      }>
        <h3>
          Client
        </h3>
      </button>

      <button onClick={() => setdisplayForm('adminUser')} className={displayform === 'adminUser' ?
        'addeditnavbuttonactive'
        :
        'addeditnavbutton'
      }>
        <h3>
          Admin User
        </h3>
      </button>

    </div>
  )
}

export default AddeditclientNav
