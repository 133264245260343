import React from 'react'
import { Companies, Company, CompanyActiveAccounts, companyClaims, CompanyCourts, companyHirePurchase, CompanyPublic, individual, IndividualActiveAccounts, IndividualClaims, IndividualCourts, individualHirePurchase, IndividualPublic, Individuals } from './TableHeadings'
import '../../css/bulkupload.css'
import {
    TbodyCompanies,
    TbodyCompany,
    TbodyCompanyActiveAccounts,
    TbodyCompanyHirePurchase,
    TbodyIndividual,
    TbodyIndividualActiveAccounts,
    TbodyIndividualNegetiveData,
    TbodyIndividualHirePurchase,
    TbodyIndividuals,
    TbodyCompanyNegativeData
} from './ExportPreviews'


const Preview = ({ table, data, setuploadData }) => {
    var headings, span
    if (table === 'Individuals BIU' || table === 'Individual Credit Reports') {
        headings = Individuals
        span = 4
    }
    if (table === 'Company Credit Reports') {
        headings = Company
        span = 3
    } if (table === 'Company Claims') {
        headings = companyClaims
        span = 7
    }
    if (table === 'Company Hire Purchase') {
        headings = companyHirePurchase
        span = 10
    }
    if (table === 'Individual Claims') {
        headings = IndividualClaims
        span = 9
    }

    if (table === 'Individual Hire Purchase') {
        headings = individualHirePurchase
        span = 10
    }


    if (table === 'Individual Active Accounts') {
        headings = IndividualActiveAccounts
        span = 7
    }

    if (table === 'Company Active Accounts') {
        headings = CompanyActiveAccounts
        span = 7
    }

    if (table === 'Individuals') {
        headings = Individuals
        span = 4
    }

    if (table === 'Companies') {
        headings = Companies
        span = 3
    }

    return (
        <div className='tablecontainerbulk'>
            <table className='previewTable'>
                <thead className='tableheader'>
                    <tr>
                        <td colSpan={span} align='center'>
                            <h3>The file contains {data.length} records</h3>
                        </td>
                    </tr>
                    <tr>{
                        headings.map((heading) => {
                            return (
                                <th>
                                    {heading.option}
                                </th>
                            )
                        })}
                    </tr>
                </thead>
                {
                    (table === 'Individuals BIU' || table === 'Individual Credit Reports') &&
                    <TbodyIndividuals data={data} />
                }
                {table === 'Company Credit Reports' &&
                    <TbodyCompany data={data} />
                }{
                    table === 'Company Claims' &&
                    <TbodyCompanyNegativeData data={data} />
                }{
                    table === 'Individual Claims' &&
                    <TbodyIndividualNegetiveData data={data} />
                }{
                    table === 'Company Hire Purchase' &&
                    <TbodyCompanyHirePurchase data={data} />
                }{
                    table === 'Individual Hire Purchase' &&
                    <TbodyIndividualHirePurchase data={data} />
                }{
                    table === 'Individual Active Accounts' &&
                    <TbodyIndividualActiveAccounts data={data} />
                }{
                    table === 'Company Active Accounts' &&
                    <TbodyCompanyActiveAccounts data={data} />
                }{
                    table === 'Individuals' &&
                    <TbodyIndividuals data={data} />
                }{
                    table === 'Companies' &&
                    <TbodyCompanies data={data} />
                }
            </table>
        </div>
    )
}

export default Preview
