import React from 'react'
import { getDate } from '../../../js/getDate'
import { TableData, TableDataHeaders, TableHeading } from '../IndividualReport/ReportTemplates'

export const DetailsOfSpouse = ({ spouse, spouseBank }) => {

    if (spouse.data.length === 0) {
        return (
            <table className="report_table">

                <TableHeading
                    heading={"DETAILS OF SPOUSE"}
                    span={8}
                />

                <tr className="data_row">
                    <td colSpan={10}>DETAILS NOT Available</td>
                </tr>
            </table>
        )
    }

    return (
        <>


            {
                (spouse.data !== null && spouse.data.length > 0) &&

                <table className="report_table">

                    <TableHeading
                        heading={"DETAILS OF SPOUSE"}
                        span={8}
                    />

                    <tr className="data_row">
                        <TableDataHeaders
                            data={"Name"}
                        />

                        <TableData
                            data={spouse.data[0].spouse.firstname + ` ${spouse.data[0].spouse.forenames} ${spouse.data[0].spouse.surname}`}
                        />
                    </tr>


                    <tr className="data_row">
                        <TableDataHeaders
                            data={"ID Number"}
                        />

                        <TableData
                            data={spouse.data[0].spouse.national_id}
                        />
                    </tr>

                    <tr className="data_row">
                        <TableDataHeaders
                            data={"Date Of Birth"}
                        />

                        <TableData
                            data={getDate(spouse.data[0].spouse.dob)}
                        />
                    </tr>

                    <tr className="data_row">
                        <TableDataHeaders
                            data={"Mobile"}
                        />

                        <TableData
                            data={spouse.data[0].spouse.mobile}
                        />j
                    </tr>

                    <tr className="data_row">
                        <TableDataHeaders
                            data={"Relationship"}
                        />

                        <TableData
                            data={spouse.data[0].spouse.relationship}
                        />
                    </tr>

                    <tr className="data_row">
                        <TableDataHeaders
                            data={"Bankers"}
                        />

                        <TableData
                            data={
                                spouseBank.data === null ?
                                    "N/A" :
                                    spouseBank.data.length > 0 ?
                                        spouseBank.data[0].bank_name :
                                        "N/A"
                            }
                        />
                    </tr>

                    <tr className="data_row">
                        <TableDataHeaders
                            data={"Branch"}
                        />

                        <TableData
                            data={
                                spouseBank.data === null ?
                                    "N/A" :
                                    spouseBank.data.length > 0 ?
                                        spouseBank.data[0].bankbranch :
                                        "N/A"
                            }
                        />
                    </tr>


                    <tr className="data_row">
                        <TableDataHeaders
                            data={"Account Number"}
                        />

                        <TableData
                            data={
                                spouseBank.data === null ?
                                    "N/A" :
                                    spouseBank.data.length > 0 ?
                                        spouseBank.data[0].account_type :
                                        "N/A"
                            }
                        />
                    </tr>



                </table>
            }

        </>
    )
}
