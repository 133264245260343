
import React from 'react'
import "../../../css/IndividualReport.css"
import { TableData, TableHeading } from './ReportTemplates'

export const RiskClassification = ({score,color}) => {

    return (
        <table className='report_table'>
                <TableHeading
                heading = {"RISK CLASSIFICATION / INDICATOR"}
                span = {3}
                color={color}
                />

            <tr className='data_row'>
                <TableData
                    data={"Low Risk"}
                    id={"low_risk"}
                    score={score}
                />

                <TableData
                    data={"Medium Risk"}
                    id={"medium_risk"}
                    score={score}
                />

                <TableData
                    data={"High Risk"}
                    id={"high_risk"}
                    score={score}
                />
            </tr>
        </table>
    )
}
