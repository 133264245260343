export const individual = [
    { option: 'Name' },
    { option: 'Surname' },
    { option: 'Id' },
    { option: 'Phone Number' }
]

export const Company = [
    { option: 'Registration Number' },
    { option: 'Company Name' },
    { option: 'Legal Status' }
]

export const companyClaims = [
    { option: 'Registratered Name' },
    { option: 'Registration Number' },
    {option : 'Contact Number'},
    {option : 'Account'},
    {option : 'Claim Date'},
    { option: 'Amount' },
    {option : 'Branch'}
]

export const companyHirePurchase = [
    {option : 'Registered Name'},
    { option: 'Registration Number'},
    {option : 'Agreement Number'},
    {option : 'Asset Type'},
    {option : 'Make'},
    {option : 'Model'},
    {option : 'Asset Reg'},
    {option : 'Amount'},
    {option : 'Paid to Date'},
    {option : 'Balance'}
]

export const individualHirePurchase = [
    {option : 'Surname'},
    { option: 'IDNumber'},
    {option : 'Agreement Number'},
    {option : 'Asset Type'},
    {option : 'Make'},
    {option : 'Model'},
    {option : 'Asset Reg'},
    {option : 'Amount'},
    {option : 'Paid to Date'},
    {option : 'Balance'}
]

export const IndividualClaims = [
    { option: 'First Name' },
    { option: 'Surname' },
    { option: 'National ID' },
    { option: 'Account' },
    { option: 'Claim Date' },
    { option: 'Amount' },
    {option: 'Branch'}
]

export const CompanyCourts = [
    {option: 'Case Number'},
    {option: 'Registration Number'},
    {option: 'Plaintiff Type'},
    {option: 'Plaintiff Name'},
    {option: 'Court Name'},
    {option: 'Currency'},
    {option: 'Amount'},  
]

export const IndividualCourts = [
    {option: 'Case Number'},
    {option: 'Registration Number'},
    {option: 'Plaintiff Type'},
    {option: 'Plaintiff Name'},
    {option: 'Court Name'},
    {option: 'Currency'},
    {option: 'Amount'},  
]

export const CompanyPublic =[
    {option : 'Case Number'},
    {option : 'Registration Number'},
    {option : 'Source'},
    {option : 'Date'},
    {option : 'Summary'}
]

export const CompanyBulk= [
    {option : 'Registration Number'},
    {option : 'Registered Name'},
    {option : 'Address'}
]

export const IndividualPublic =[
    {option : 'Case Number'},
    {option : 'Nation Id'},
    {option : 'Source'},
    {option : 'Date'},
    {option : 'Summary'}
]

export const IndividualActiveAccounts = [
    {option : 'Account ID'},
    {option : 'Surname'},
    {option : 'ID Number'},
    {option : 'Currency'},
    {option : 'Loan Amount'},
    {option : 'Installment'},
    {option : 'Overdue Balance'}
]

export const CompanyActiveAccounts = [
    {option : 'Account ID'},
    {option : 'Company Name'},
    {option : 'Reg Number'},
    {option : 'Currency'},
    {option : 'Loan Amount'},
    {option : 'Installment'},
    {option : 'Overdue Balance'}
]

export const Companies = [
    {option : 'Registration Number'},
    {option : 'Registered Name'},
    {option : 'Contact Person'}
]

export const Individuals = [
    {option : 'Name'},
    {option : 'Surname'},
    {option : 'Identification Type'},
    {option : 'Identification Number'}
]