import React from 'react';
import './../css/Main.css'
import mainJPG from './../backgrounds/main.jpg'

export const Main = () => {

    return (

        <main>

            <section className="section-intro-message">

                <article className="intro-message-container">

                    <h2 className="intro-message-header">
                        enhancing value <span>through</span> business information
                    </h2>

                    <p className="intro-message-para">
                        To be the contact of choice pertaining to quality & relevent business information and data management solutions in Zimbabwe.
                    </p>

                    <a href="/login" className="get-started-link">
                        get started
                    </a>
                </article>

            </section>

            <section className="section-illustration">
                <div className="illustration-container">
                    <img src={mainJPG} alt="illustration" />
                </div>
            </section>
        </main>
    )
}
