import React, { useState, useContext } from 'react'
import { InputAreaContainer, InputContainer, InputContainerRequired, InputphoneContainer, SelectContainer } from '../InputContainer';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import '../../css/FormContainerStyle.css';
import '../../css/addcompany.css';
import { slt_IdentificationType, slt_gender, slt_MaritalStatus } from './IndividualselectOptions';
import SaveIcon from '@mui/icons-material/Save'
import AddIcon from '@mui/icons-material/Add'
import { DataSubmission } from '../Other/DataSubmission';
import { toggleForms } from '../../js/toggleForms';
import AuthContext from "../../AuthContext/AuthContext";



const AddEditIndividual = ({
  id,
  setDisplayedForm,
  setIndividualFins,
  caller,
  individualsData
}) => {

  if (individualsData) {
    if (individualsData.length !== 0) {
      var object = individualsData
      Object.keys(object).map(
        key => {
          if (key === 'subscription_category') {
            if (object[key] === undefined || object[key] === null || object[key] === '') {
              object[key] = []
            }
          }
          if (object[key] === undefined || object[key] === null) {
            object[key] = '--'
          }
        }
      )
    }
  }

  const [loading, setLoading] = useState(false)
  const { authTokens, logoutUser } = useContext(AuthContext);
  const addindividual = (e) => {

    e.preventDefault()
    setLoading(true)
    axios.post(`/add-individual`, {
      fins_number: 'ggfng',
      national_id: IdNumber,
      firstname: firstName,
      email_address: email,
      surname: surname,
      dob: dob,
      gender: gender,
      marital_status: maritalStatus,
      address: address,
      mobile: mobileNumber,
      landline: landline,
      employer_name: employer,
      employer_email: employerEmail,
      job_title: jobTitle,
      date_of_employment: dateofEmployment,
      identification_type: idType,
      town: town,
      district: district,
      next_of_kin: nokName,
      phone_number: nokMobile,
      relationship: nokRelationship
    })
    .then((response) => {
      if (response.data['result'] !== 'success'){
        toast.error(response.data['result'])
      }else{
        toast.success('Recorded successfully')
      }
      setLoading(false)

    });
  }

  if (caller !== 'editIndividual'){
    caller = 'addBiu'
  }

  const [surname, setSurname] = useState(() => {
    if (caller == 'editIndividual') {
      return individualsData.surname
    } else {
      return ''
    }
  })



  const [firstName, setFirstName] = useState(() => {
    if (caller === 'editIndividual') {
      return individualsData.firstname
    } else {
      return ''
    }
  })
  const [IdNumber, setIdNumber] = useState(() => {
    if (caller === 'editIndividual') {
      return individualsData.national_id
    } else {
      return ''
    }
  })
  const [email, setEmail] = useState(() => {
    if (caller === 'editIndividual') {
      return individualsData.email_address
    } else {
      return ''
    }
  })
  const [prevEmployer, setPrevEmployer] = useState(() => {
    if (caller === 'editIndividual') {
      return 'not available'
    } else {
      return ''
    }
  })
  const [dob, setDob] = useState(() => {
    if (caller === 'editIndividual') {
      return individualsData.dob
    } else {
      return null
    }
  })
  const [gender, setGender] = useState(() => {
    if (caller === 'editIndividual') {
      return individualsData.gender
    } else {
      return ''
    }
  })
  const [maritalStatus, setMaritalStatus] = useState(() => {
    if (caller === 'editIndividual') {
      return individualsData.marital_status
    } else {
      return ''
    }
  })
  const [mobileNumber, setMobileNumber] = useState(() => {
    if (caller === 'editIndividual') {
      return individualsData.mobile
    } else {
      return ''
    }
  })
  const [landline, setLandline] = useState(() => {
    if (caller === 'editIndividual') {
      return individualsData.landline
    } else {
      return ''
    }
  })
  const [employer, setEmployer] = useState(() => {
    if (caller === 'editIndividual') {
      return individualsData.employer_name
    } else {
      return ''
    }
  })
  const [employerEmail, setEmployerEmail] = useState(() => {
    if (caller === 'editIndividual') {
      return individualsData.employer_email
    } else {
      return ''
    }
  })
  const [prevEmployerEmail, setPrevEmployerEmail] = useState(() => {
    if (caller === 'editIndividual') {
      return 'not available at the moment'
    } else {
      return ''
    }
  })
  const [prevJobTitle, setPrevJobTitle] = useState(() => {
    if (caller === 'editIndividual') {
      return 'not available at the moment'
    } else {
      return ''
    }
  })
  const [jobTitle, setJobTitle] = useState(() => {
    if (caller === 'editIndividual') {
      return individualsData.job_title
    } else {
      return ''
    }
  })
  const [dateofEmployment, setDateofEmployment] = useState(() => {
    if (caller === 'editIndividual') {
      return individualsData.date_of_employment
    } else {
      return null
    }
  })
  const [idType, setIdType] = useState(() => {
    if (caller === 'editIndividual') {
      return individualsData.identification_type
    } else {
      return ''
    }
  })
  const [nokName, setNokName] = useState(() => {
    if (caller === 'editIndividual') {
      return individualsData.next_of_kin
    } else {
      return ''
    }
  })
  const [nokRelationship, setNokRelationship] = useState("")
  const [nokMobile, setNokMobile] = useState("")
  const [address, setAddress] = useState(() => {
    if (caller === 'editIndividual') {
      return individualsData.address
    } else {
      return ''
    }
  })
  const [town, setTown] = useState('')
  const [district, setDistrict] = useState('')
  console.log('H--------------befo---------')

  
  const SubmitIndividual = async (e, action) => {
    e.preventDefault()
    if (
      surname === '' || surname === null || surname === undefined || firstName === '' || firstName === null || firstName === undefined ||
      idType === '' || idType === null || idType === undefined || IdNumber === '' || IdNumber === null || IdNumber === undefined ||
      address === '' || address === null || mobileNumber === '' || mobileNumber === null || mobileNumber === undefined 
    ) {
      if (surname === '' || surname === null || surname === undefined) {
        toast.warning('Surname is required.')
      }

      if (firstName === '' || firstName === null || firstName === undefined) {
        toast.warning('First name is required.')
      }

      if (idType === '' || idType === null || idType === undefined) {
        toast.warning('Select the identification type')
      }

      if (IdNumber === '' || IdNumber === null || IdNumber === undefined) {
        toast.warning('Identification number is required.')
      }

      if (mobileNumber === '' || mobileNumber === null || mobileNumber === undefined ) {
        toast.warning('Phone number is required.')
      }
    } else {
      let responsed = ''
      console.log('H------------------inside-----')
      console.log('H------------------edit----->' + caller)
     
      if (caller === 'editIndividual') {
        responsed = await (DataSubmission(
          String(authTokens.access),
          'put',
          '/individual/' + individualsData.fins_number + '/',
          {
            fins_number: individualsData.fins_number,
            national_id: IdNumber,
            email_address: email,
            firstname: firstName,
            surname: surname,
            dob: dob,
            gender: gender,
            marital_status: maritalStatus,
            address: address,
            mobile: mobileNumber,
            landline: landline,
            employer_name: employer,
            employer_email: employerEmail,
            job_title: jobTitle,
            date_of_employment: dateofEmployment,
            identification_type: idType,
            town: town,
            district: district,
            next_of_kin: nokName,
            phone_number: nokMobile,
            relationship: nokRelationship
          }
        ))


      } else {
      console.log('H------------------ppost-----')

        responsed = await (DataSubmission(
          String(authTokens.access),
          'post',
          '/individual/',
          {
            fins_number: 'ggfng',
            national_id: IdNumber,
            firstname: firstName,
            email_address: email,
            surname: surname,
            dob: dob,
            gender: gender,
            marital_status: maritalStatus,
            address: address,
            mobile: mobileNumber,
            landline: landline,
            employer_name: employer,
            employer_email: employerEmail,
            job_title: jobTitle,
            date_of_employment: dateofEmployment,
            identification_type: idType,
            town: town,
            district: district,
            next_of_kin: nokName,
            phone_number: nokMobile,
            relationship: nokRelationship
          }
        ))
      }


      if (responsed[0].result === 'success') {
        setDisplayedForm(false)
      }
    }
  }

  return (
    <section className="form-container">
      <h3 className="form-header">Add Individual</h3>
      <form className="form">

        <section className='secinputcontainer'>

          <h4 className="sectionHeading"> Personal Details</h4>
          {caller === 'addBiu' ?
            <section className=' secinputdeatails'>

              <InputContainerRequired
                label={"Surname"}
                type={"text"}
                currentValue={surname}
                setValue={setSurname}
                id={'txt_surname'}
              />

              <InputContainer
                label={"First Name"}
                type={"text"}
                currentValue={firstName}
                setValue={setFirstName}
              />

              <SelectContainer
                label={"Identification Type "}
                currentValue={idType}
                setValue={setIdType}
                options={slt_IdentificationType}
              />

              <InputContainerRequired
                label={"Identification Number"}
                type={"text"}
                currentValue={IdNumber}
                setValue={setIdNumber}
                id={'txt_IdNUmber'}
              />

              <SelectContainer
                label={"Gender"}
                currentValue={gender}
                setValue={setGender}
                options={slt_gender}
              />


              <InputContainerRequired
                label={"Date Of Birth"}
                type={"date"}
                currentValue={dob}
                setValue={setDob}
              />

              <SelectContainer
                label={"Marital Status"}
                currentValue={maritalStatus}
                setValue={setMaritalStatus}
                options={slt_MaritalStatus}
              />

              <InputContainerRequired
                label={"Mobile Number"}
                type={"tel"}
                currentValue={mobileNumber}
                setValue={setMobileNumber}
                placeHolder={"eg 00263 xxx xxx xxx"}
                id={'txt_mobileNumber'}
              />

              <InputphoneContainer
                label={"Landline"}
                type={"tel"}
                currentValue={landline}
                setValue={setLandline}
                placeHolder={"eg 00263778000000"}
                id={'txt_landline'}
              />

              <InputAreaContainer
                label={"Address"}
                type={"text"}
                currentValue={address}
                setValue={setAddress}
                id={'txt_address'}
              />

              <InputphoneContainer
                label={"Email Address"}
                type={"text"}
                currentValue={email}
                setValue={setEmail}
                placeHolder={""}
                id={'txt_email'}
              />

              <h4 className="sectionHeading"> Employment Details </h4>

              <InputContainer
                label={"Current Employer"}
                type={"text"}
                currentValue={employer}
                setValue={setEmployer}
                id={'txt_employer'}
              />

              <InputContainer
                label={"Current Employer Email"}
                type={"email"}
                currentValue={employerEmail}
                setValue={setEmployerEmail}
                id={'txt_employerEmail'}
              />

              <InputContainer
                label={"Current Job Title"}
                type={"text"}
                currentValue={jobTitle}
                setValue={setJobTitle}
                id={'txt_jobTitle'}
              />

              <InputContainer
                label={"Date of Employment"}
                type={"date"}
                currentValue={dateofEmployment}
                setValue={setDateofEmployment}
              />

              <InputContainer
                label={"Former Employer"}
                type={"text"}
                currentValue={prevEmployer}
                setValue={setPrevEmployer}
                id={'prev_employer'}
              />

              <InputContainer
                label={"Former Employer Email"}
                type={"email"}
                currentValue={prevEmployerEmail}
                setValue={setPrevEmployerEmail}
                id={'prev_employerEmail'}
              />

              <InputContainer
                label={"Former Job Title"}
                type={"text"}
                currentValue={prevJobTitle}
                setValue={setPrevJobTitle}
                id={'prev_jobTitle'}
              />


              <h4 className="sectionHeading"> Next of Kin </h4>

              <InputContainer
                label={"Next of Kin Fullname"}
                type={"text"}
                currentValue={nokName}
                setValue={setNokName}
                id={'txt_nokName'}
              />

              <InputphoneContainer
                label={"Relationship"}
                type={"text"}
                currentValue={nokRelationship}
                setValue={setNokRelationship}
                placeHolder={"Father , Son, Wife etc"}
                id={'txt_nokRelationship'}
              />

              <InputphoneContainer
                label={"Mobile Number"}
                type={"tel"}
                currentValue={nokMobile}
                setValue={setNokMobile}
                placeHolder={"eg 00263 xxx xxx xxx"}
                id={'txt_nokMobile'}
              />
            </section>
            :
            <section className=' secinputdeatails'>

              <InputContainerRequired
                label={"Surname"}
                type={"text"}
                currentValue={surname}
                setValue={setSurname}
                id={'txt_surname'}
              />

              <InputContainerRequired
                label={"First Name"}
                type={"text"}
                currentValue={firstName}
                setValue={setFirstName}
                id={'txt_firstName'}
              />

              <SelectContainer
                label={"Identification Type "}
                currentValue={idType}
                setValue={setIdType}
                options={slt_IdentificationType}
              />

              <InputContainerRequired
                label={"Identification Number"}
                type={"text"}
                currentValue={IdNumber}
                setValue={setIdNumber}
                id={'txt_IdNUmber'}
              />


              <SelectContainer
                label={"Gender"}
                currentValue={gender}
                setValue={setGender}
                options={slt_gender}
              />


              <InputContainer
                label={"Date Of Birth"}
                type={"date"}
                currentValue={dob}
                setValue={setDob}

              />

              <SelectContainer
                label={"Marital Status"}
                currentValue={maritalStatus}
                setValue={setMaritalStatus}
                options={slt_MaritalStatus}
              />


              <InputAreaContainer
                label={"Address"}
                type={"text"}
                currentValue={address}
                setValue={setAddress}
                id={'txt_address'}
              />

              <InputContainerRequired
                label={"Mobile Number"}
                type={"tel"}
                currentValue={mobileNumber}
                setValue={setMobileNumber}
                placeHolder={"eg 00263 xxx xxx xxx"}
                id={'txt_mobileNumber'}
              />

              <InputphoneContainer
                label={"Landline"}
                type={"tel"}
                currentValue={landline}
                setValue={setLandline}
                placeHolder={"eg 00263778000000"}
                id={'txt_landline'}
              />

              <InputphoneContainer
                label={"Email Address"}
                type={"text"}
                currentValue={email}
                setValue={setEmail}
                placeHolder={""}
                id={'txt_email'}
              />

              <h4 className="sectionHeading"> Employment Details </h4>

              <InputContainer
                label={"Current Employer"}
                type={"text"}
                currentValue={employer}
                setValue={setEmployer}
                id={'txt_employer'}
              />

              <InputContainer
                label={"Current Job Title"}
                type={"text"}
                currentValue={jobTitle}
                setValue={setJobTitle}
                id={'txt_jobTitle'}
              />

              <InputContainer
                label={"Date of Employment"}
                type={"date"}
                currentValue={dateofEmployment}
                setValue={setDateofEmployment}
              />
            </section>
          }

        </section>

        {caller === 'editIndividual' ?

          <section className='secButtons'>

            <button icon="close" className="formButtons" text="Close"
              align="center" onClick={e => SubmitIndividual(e, 'done')} >
              <SaveIcon className='icons' />
              <blockquote>
                Update
              </blockquote>
            </button>

          </section>
          :
          <section className='secButtons'>
            {loading?(
              <button icon="close" className="formButtons" text="Close"
              align="center" disabled >
              <SaveIcon className='icons' />
              <blockquote>
                Processing...
              </blockquote>
            </button>
            ):(
              <button icon="close" className="formButtons" text="Close"
              align="center" onClick={e => addindividual(e)} >
              <SaveIcon className='icons' />
              <blockquote>
                Save And Proceed
              </blockquote>
            </button>
            )}
            

          </section>}

      </form>

    </section>
  )
}

export default AddEditIndividual