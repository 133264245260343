import React from 'react'
import { TableData, TableDataHeaders, TableHeading } from './ReportTemplates'
import "../../../css/IndividualReport.css"


export const ScoreRange = ({score,color}) => {
    return (
        <table className='report_table'>
            <TableHeading
                heading={"Score Range"}
                color={color}
                span={6} />

            <tr className='data_row'>
                <TableDataHeaders
                    id={"class"}
                    data={"Class"}
                />

                <TableDataHeaders
                    id={"indexRange"}
                    data={"Index Range"}
                />

                <TableDataHeaders
                    id={"class"}
                    data={"Class"}
                />


                <TableDataHeaders
                    id={"indexRange"}
                    data={"Index Range"}
                />

                <TableDataHeaders
                    id={"class"}
                    data={"Class"}
                />

                <TableDataHeaders
                    id={"indexRange"}
                    data={"Index Range"}
                />
            </tr>

            <tr className='data_row'>
                <TableData
                    id={"indexvalueLLR"}
                    data={"Low Low Risk [LLR]"}
                    score={score}
                />

                <TableData
                    id={"indexvalueLLR"}
                    data={"500-417"}
                />

                <TableData
                    id={"indexvalueLMR"}
                    data={"Low Medium Risk [LMR]"}
                    score={score}
                />
                <TableData
                    id={"indexvalueLMR"}
                    data={"332-250"}
                />

                <TableData
                    id={"indexvalueLHR"}
                    data={"Low High Risk [LHR]"}
                    score={score}
                />

                <TableData
                    id={"indexvalueLHR"}
                    data={"165-83"}
                />
            </tr>

            <tr className='data_row'>
                <TableData
                    id={"indexvalueHLR"}
                    data={"High Low Risk [HLR]"}
                    score={score}
                />

                <TableData
                    id={"indexvalueHLR"}
                    data={"416-333"}
                />

                <TableData
                    id={"indexvalueHMR"}
                    data={"High Medium Risk [HMR]"}
                    score={score}
                />
                <TableData
                    id={"indexvalueHMR"}
                    data={"249-166"}
                />

                <TableData
                    id={"indexvalueHHR"}
                    data={"High High Risk [HHR]"}
                    score={score}
                />

                <TableData
                    id={"indexvalueHHR"}
                    data={"82-0"}
                />
            </tr>

        </table>
    )
}
