import React, { useEffect, useState, useContext } from 'react'
import '../../css/FormContainerStyle.css';
import '../../css/addcompany.css';
import { toast, ToastContainer } from 'react-toastify';
import { InputAreaContainer, InputContainer, InputContainerRequired, InputphoneContainer, MultiSelectContainer, SelectContainer, fileUploader } from '../InputContainer';
import { Industry, LegalStatus, TradingStatus } from './selectOptionsjson';
import SaveIcon from '@mui/icons-material/Save'
import { DataSubmission } from '../Other/DataSubmission';
import 'react-toastify/dist/ReactToastify.css'
import AuthContext from "../../AuthContext/AuthContext";
import { SignalCellularNull } from '@mui/icons-material';

//addind company form component
/* ___________________________________________________add and edit Bank Accounts____________________________________________________ */
export const AddEditCompany = ({
  editFins,
  companyData,
  setDisplayedForm,
  id,
  setCompanyFins,
  setrun,
  setactive,
  caller }) => {
  const { authTokens, logoutUser } = useContext(AuthContext);

  //removing null values from the data
  if (companyData) {
    if (companyData[0].length !== 0) {
      var object = companyData[0]
      Object.keys(object).map(
        key => {
          if (key === 'subscription_category') {
            if (object[key] === undefined || object[key] === null || object[key] === '') {
              object[key] = []
            }
          }
          if (object[key] === undefined || object[key] === null) {
            object[key] = '--'
          }
        }
      )
    }
  }
  const [regNumber, setRegNumber] = useState(() => {
    if ((caller === 'editBureau' || caller === 'editClient')) {
      return (companyData[0].registration_number)
    } else {
      return ''
    }
  });
  const [registeredName, setRegisteredName] = useState(() => {
    if ((caller === 'editBureau' || caller === 'editClient')) {
      return (companyData[0].registration_name)
    } else {
      return ''
    }
  })

  const [legalStatus, setLegalStatus] = useState('');
  const [tradingStatus, setTradingStatus] = useState('');
  const [industry, setIndustry] = useState(() => {
    if ((caller === 'editBureau' || caller === 'editClient')) {
      return (companyData[0].industry)
    } else {
      return ''
    }
  })
  const [parentCompany, setParentCompany] = useState('');
  const [formerAddress, setFormerAddress] = useState('');
  const [currentAddress, setCurrentAddress] = useState(() => {
    if ((caller === 'editBureau' || caller === 'editClient')) {
      return (companyData[0].current_address)
    } else {
      return ''
    }
  });
  const [mobilePhone, setMobilePhone] = useState(() => {
    if ((caller === 'editBureau' || caller === 'editClient')) {
      return (companyData[0].mobile_phone)
    } else {
      return ''
    }
  });
  const [tradingName, setTradingName] = useState(() => {
    if ((caller === 'editBureau' || caller === 'editClient')) {
      return (companyData[0].trading_name)
    } else {
      return ''
    }
  });
  const [regDate, setRegDate] = useState(() => {
    if ((caller === 'editBureau' || caller === 'editClient')) {
      return (companyData[0].registration_date)
    } else {
      return '2000-01-01'
    }
  });
  const [bpNumber, setBpNumber] = useState('');
  const [landlinePhone, setLandlinePhone] = useState(() => {
    if ((caller === 'editBureau' || caller === 'editClient')) {
      return (companyData[0].land_line_phone)
    } else {
      return ''
    }
  });
  const [vatNumber, setVatNumber] = useState(() => {
    if ((caller === 'editBureau' || caller === 'editClient')) {
      return (companyData[0].vat_number)
    } else {
      return ''
    }
  });
  const [parentDunsNumber, setParentDunsNumber] = useState('');
  const [numberOfEmployees, setNumberOfEmployees] = useState(0);
  const [website, setWebsite] = useState(() => {
    if ((caller === 'editBureau' || caller === 'editClient')) {
      return (companyData[0].website)
    } else {
      return ''
    }
  });
  const [postalAddress, setPostalAddress] = useState('');
  const [trend, setTrend] = useState('');
  const [conditionCo, setConditionCo] = useState('');
  const [email, setEmail] = useState(() => {
    if ((caller === 'editBureau' || caller === 'editClient')) {
      return (companyData[0].email)
    } else {
      return ''
    }
  });
  const [note, setNote] = useState(() => {
    if ((caller === 'editBureau' || caller === 'editClient')) {
      return (companyData[0].note)
    } else {
      return ''
    }
  })
  const [operation, setOperation] = useState('')
  const [dateOfCommitment, setDateOfCommitment] = useState('pending');
  const [twitter, setTwitter] = useState('');
  const [linkedIn, setLinkedIn] = useState('');
  const [instagram, setInstagram] = useState('');
  const [facebook, setFacebook] = useState('');
  const [contactPerson, setContactPerson] = useState('')
  const [judicial, setJudicial] = useState('')
  const [subscriptionContract, setSubscriptionContract] = useState('')
  const [logo, setLogo] = useState('')

  const [subscription, setSubscription] = useState(() => {
    if ((caller === 'editBureau' || caller === 'editClient')) {
      if (companyData[0].subscription_category.length !== 0) {
        let subs = companyData[0].subscription_category
        let subOptions = []
        subs.map((item) => {
          let options = { value: item, label: item }
          subOptions = subOptions.concat(options)
        })
        return subOptions
      }
    } else {
      return []
    }
  })

  const [accountNumber, setAccountNumber] = useState(() => {
    if ((caller === 'editBureau' || caller === 'editClient')) {
      return (companyData[0].account_number)
    } else {
      return ''
    }
  })

  const subscriptions = [
    { value: 'Bureau', label: 'Bureau' },
    { value: 'Biu', label: 'Biu' },
    { value: 'Hire Purchase', label: 'Hire Purchase' },
    { value: 'Debt Recovery', label: 'Debt Recovery' },
  ]


  const handleChange = (e, file) => {
    const uploadedfile = e.target.files

    if (file === 'logo') {
      if (uploadedfile[0].type !== ('image/jpeg' || 'image/jpg' || 'image/png')) {
        toast.warning('Please select a jpeg, jpg or png image format for logo .')
      } else {
        setLogo(uploadedfile[0])
      }
    } else {
      if (uploadedfile[0].type !== 'application/pdf') {
        toast.warning('Please select a pdf document for the signed contract attachment.')
      } else {
        setSubscriptionContract(uploadedfile[0])
      }

    }
  }

  const SubmitCompany = async (e) => {
    e.preventDefault()
    if (regNumber === '' || registeredName === '' || tradingName === '' || regDate === '') {
      if (regNumber === '') {
        toast.warning('Registration Number can not be empty.')
      }

      if (registeredName === '') {
        toast.warning('Registered Name can not be empty.')
      }

      if (tradingName === '') {
        toast.warning('Trading Name can not be empty.')
      }

      if (regDate === '') {
        toast.warning('Please Provide the Registration Date.')
      }

    } else {
      let finsNumber = ''

      if (caller === 'editBureau' || caller === 'editClient') {
        finsNumber = companyData[0].fins_number
      } else {
        finsNumber = 'a'
      }

      let responsed = ''
      let options = []
      if (subscription) {
        options = subscription.map((item) => {
          return (item.value)
        })
      }
      const body = new FormData();
      body.append('fins_number', finsNumber)
      for (var i = 0; i < options.length; i++) {
        body.append('subscription_category', options[i])
      }
      body.append('account_number', accountNumber)
      body.append('registration_number', regNumber)
      body.append('registration_name', registeredName)
      body.append('legal_status', legalStatus)
      body.append('trading_status', tradingStatus)
      body.append('parent_company', parentCompany)
      body.append('former_address', formerAddress)
      body.append('current_address', currentAddress)
      body.append('postal_address', postalAddress)
      body.append('mobile_phone', mobilePhone)
      body.append('trading_name', tradingName)
      body.append('registration_date', regDate)
      body.append('bp_number', bpNumber)
      body.append('land_line_phone', landlinePhone)
      body.append('vat_number', vatNumber)
      body.append('number_of_employees', numberOfEmployees)
      body.append('website', website)
      body.append('trend', trend)
      body.append('condition_co', conditionCo)
      body.append('email', email)
      body.append('date_of_commitment', dateOfCommitment)
      body.append('industry', industry)
      body.append('contact_person', contactPerson)
      body.append('is_under_judicial', judicial)
      body.append('operations', operation)
      body.append('note', note)
      body.append('logo', logo)
      body.append('subscription_contract', subscriptionContract)


      if ((caller === 'editBureau' || caller === 'editClient')) {
        responsed = await DataSubmission(
          String(authTokens.access),
          'put',
          '/company/' + companyData[0].fins_number + '/',
          body
        )
      } else {
        responsed = await DataSubmission(
          String(authTokens.access),
          'post',
          '/company/',
          body
        )
      }

      if (responsed[0].result === 'success' && caller === 'addBIU') {
        const companyFins = responsed[1].request_response.data.fins_number;
        setCompanyFins(companyFins)
        setrun('finished')
        setactive('Branches')
      }

      if (responsed[0].result === 'success' && caller === 'addclient') {
        const companyFins = responsed[1].request_response.data.fins_number;
        setCompanyFins(companyFins)
        setactive('adminUser')

        if (setDisplayedForm) {
          setDisplayedForm(false)
        }
      }
      if (responsed[0].result === 'success' && caller === 'addBureau') {
        if (setDisplayedForm) {
          setDisplayedForm(false)
        }
      }

      if (responsed[0].result === 'success') {
        if (setDisplayedForm) {
          setDisplayedForm(false)
        }
      }
    }


  }
  return (

    < section className="form-container" >
      <h3 className="form-header">Add Company</h3>
      <form className="form">
        {caller === 'addBIU' ?
          <section className='secinputcontainer'>

            <h4 className="sectionHeading"> Company Details </h4>

            <section className=' secinputdeatails'>

              <InputContainerRequired
                label={"Registered Name"}
                type={"text"}
                currentValue={registeredName}
                setValue={setRegisteredName}
              />

              <InputContainerRequired
                label={"Trading Name"}
                type={"text"}
                currentValue={tradingName}
                setValue={setTradingName}
              />

              <InputContainerRequired
                label={"Company Registration Number"}
                type={"text"}
                currentValue={regNumber}
                setValue={setRegNumber}
                id={'regnumber'}
              />


              <InputphoneContainer
                label={"Registration Date"}
                type={"date"}
                currentValue={regDate}
                setValue={setRegDate}
              />

              <InputContainer
                label={"VAT Number"}
                type={"text"}
                currentValue={vatNumber}
                setValue={setVatNumber}
              />

              <InputContainer
                label={"BP Number"}
                type={"text"}
                currentValue={bpNumber}
                setValue={setBpNumber}
              />

              <InputContainer
                label={"Parent Company"}
                type={"text"}
                currentValue={parentCompany}
                setValue={setParentCompany}
              />

              <InputContainer
                label={"Number of Employees"}
                type={"number"}
                currentValue={numberOfEmployees}
                setValue={setNumberOfEmployees}
              />

              <InputContainer
                label={"Condition"}
                type={"text"}
                currentValue={conditionCo}
                setValue={setConditionCo}
              />

              <InputContainer
                label={"Trend"}
                type={"text"}
                currentValue={trend}
                setValue={setTrend}
              />

              <InputContainer
                label={"Date Of Commitment for Operations"}
                type={"date"}
                currentValue={dateOfCommitment}
                setValue={setDateOfCommitment}
              />

              {/*____________________________________________________________________________________________________________ */}
              <h4 className='sectionHeading'> industry trading status and legal status</h4>

              <SelectContainer
                label={"Industry"}
                currentValue={industry}
                setValue={setIndustry}
                options={Industry}
              />
              <SelectContainer
                label={"Legal Status"}
                currentValue={legalStatus}
                setValue={setLegalStatus}
                options={LegalStatus}
              />

              <SelectContainer
                label={"Trading Status"}
                currentValue={tradingStatus}
                setValue={setTradingStatus}
                options={TradingStatus}
              />

              <InputAreaContainer
                label={"Operations"}
                type={"text"}
                currentValue={operation}
                setValue={setOperation}
              />



              {/*____________________________________________________________________________________________________________ */}

              <h4 className='sectionHeading'> Contact Details</h4>
              <InputAreaContainer
                label={"Current Address"}
                type={"text"}
                currentValue={currentAddress}
                setValue={setCurrentAddress}
              />

              <InputAreaContainer
                label={"Former Address"}
                type={"text"}
                currentValue={formerAddress}
                setValue={setFormerAddress}
              />

              <InputAreaContainer
                label={"Postal Address"}
                type={"text"}
                currentValue={postalAddress}
                setValue={setPostalAddress}
              />

              <InputphoneContainer
                label={"Mobile Number"}
                type={"tel"}
                currentValue={mobilePhone}
                setValue={setMobilePhone}
                placeHolder={"eg 00263778000000"}
              />

              <InputphoneContainer
                label={"Landline Phone"}
                type={"tel"}
                currentValue={landlinePhone}
                setValue={setLandlinePhone}
                placeHolder={"eg 00263540000000"}
              />

              <InputContainer
                label={"Email Address"}
                type={"email"}
                currentValue={email}
                setValue={setEmail}
              />

              <InputphoneContainer
                label={"Contact Person"}
                type={"text"}
                currentValue={contactPerson}
                setValue={setContactPerson}
                placeHolder={" eg : Managing Director"}
              />

              {/*____________________________________________________________________________________________________________ */}
              <h4 className='sectionHeading'>On the web</h4>


              <InputContainer
                label={"Website"}
                type={"url"}
                currentValue={website}
                setValue={setWebsite}
              />

              <InputContainer
                label={"On LinkedIn"}
                type={"text"}
                currentValue={linkedIn}
                setValue={setLinkedIn}
              />

              <InputContainer
                label={"On Twitter"}
                type={"text"}
                currentValue={twitter}
                setValue={setTwitter}
              />

              <InputContainer
                label={"On Facebook"}
                type={"text"}
                currentValue={facebook}
                setValue={setFacebook}
              />

              <InputContainer
                label={"On Instagram"}
                type={"text"}
                currentValue={instagram}
                setValue={setInstagram}
              />

            </section>

          </section>

          :

          <section className='secinputcontainer'>
            {/* ................................................Burea............................................... */}

            <h4 className="sectionHeading"> Company Details </h4>

            <section className=' secinputdeatails'>
              {(caller === 'addclient' || caller === 'editClient') &&
                <InputContainer
                  label={"Account Number"}
                  type={"text"}
                  currentValue={accountNumber}
                  setValue={setAccountNumber}
                />
              }

              <InputContainerRequired
                label={"Registered Name"}
                type={"text"}
                currentValue={registeredName}
                setValue={setRegisteredName}
              />

              <InputContainerRequired
                label={"Trading Name"}
                type={"text"}
                currentValue={tradingName}
                setValue={setTradingName}
              />

              <InputContainerRequired
                label={"Company Registration Number"}
                type={"text"}
                currentValue={regNumber}
                setValue={setRegNumber}
                id={'regnumber'}
              />

              <InputphoneContainer
                label={"Registration Date"}
                type={"date"}
                currentValue={regDate}
                setValue={setRegDate}
              />

              <InputContainer
                label={"VAT Number"}
                type={"text"}
                currentValue={vatNumber}
                setValue={setVatNumber}
              />

              <InputAreaContainer
                label={"Current Address"}
                type={"text"}
                currentValue={currentAddress}
                setValue={setCurrentAddress}
              />

              <InputphoneContainer
                label={"Landline Phone"}
                type={"tel"}
                currentValue={landlinePhone}
                setValue={setLandlinePhone}
                placeHolder={"eg 00263540000000"}
              />

              <InputphoneContainer
                label={"Mobile Number"}
                type={"tel"}
                currentValue={mobilePhone}
                setValue={setMobilePhone}
                placeHolder={"eg 00263778000000"}
              />

              <InputContainer
                label={"Email Address"}
                type={"email"}
                currentValue={email}
                setValue={setEmail}
              />

              <InputContainer
                label={"Website"}
                type={"url"}
                currentValue={website}
                setValue={setWebsite}
              />

              <SelectContainer
                label={"Industry"}
                currentValue={industry}
                setValue={setIndustry}
                options={Industry}
              />
              {(caller === 'addclient' || caller === 'editClient') ?
                <MultiSelectContainer
                  setValue={setSubscription}
                  label={'Subscription'}
                  options={subscriptions}
                  currentValue={subscription}
                />
                :
                <InputAreaContainer
                  label={"Note"}
                  type={"text"}
                  currentValue={note}
                  setValue={setNote}
                />
              }

              {(caller === 'addclient' || caller === 'editClient') &&
                <div className="input-container">
                  <label>Subscription Signed Contract</label>
                  <div className="value-container-file">
                    <input
                      id='fileuploader'
                      type='file'
                      onChange={e => handleChange(e, 'contract')}
                    />
                  </div>
                </div>
              }

              {(caller === 'addclient' || caller === 'editClient') &&
                <div className="input-container">
                  <label>logo</label>
                  <div className="value-container-file">
                    <input
                      id='fileuploader'
                      type='file'
                      onChange={e => handleChange(e, 'logo')}
                    />
                  </div>
                </div>
              }


            </section>

          </section>
        }

        {
          (caller === 'editBureau' || caller === 'editClient') ?

            <section className='secButtons'>
              <button icon="close" className="formButtons" text="Close"
                align="center" onClick={e => SubmitCompany(e)} >
                <SaveIcon className='icons' />
                <blockquote>
                  Update
                </blockquote>
              </button>
            </section>
            :

            <section className='secButtons'>
              <button icon="close" className="formButtons" text="Close"
                align="center" onClick={e => SubmitCompany(e)} >
                <SaveIcon className='icons' />
                <blockquote>
                  Save And Proceed
                </blockquote>
              </button>
            </section>
        }

      </form>
    </section >

  )
}
