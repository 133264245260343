import React from 'react'
import { getDate } from '../../../js/getDate'
import { TableData, TableDataHeaders, TableHeading } from '../../Individuals/IndividualReport/ReportTemplates'

export const TradeReferences = ({ tradeReferences }) => {

    return (

        <table className="report_table">

            <tr className='row_heading' style={{ margin: '50px 0', backgroundColor: '#b4c6e7', color: 'black' }}>
                <td className='txt_rowheading' colSpan={8}>
                    <h1 className='section_heading' style={{ fontSize: '1rem' }}>
                        References
                    </h1>
                </td>
            </tr>

            <tr className="data_row">
                <TableDataHeaders
                    data={"Ref Date"}
                />

                <TableDataHeaders
                    data={"Name"}
                />

                <TableDataHeaders
                    data={"Contact Info"}
                />

                <TableDataHeaders
                    data={"Ref Source"}
                />

                <TableDataHeaders
                    data={"Source Position"}
                />

                <TableDataHeaders
                    data={"Credit Limit"}
                />

                <TableDataHeaders
                    data={"Credit Terms"}
                />

                <TableDataHeaders
                    data={"Pay Trend"}
                />
            </tr>

            {
                (tradeReferences.data !== null && tradeReferences.data.length === 0) &&
                <tr className='data_row'>
                    NOT SUPPLIED
                </tr>
            }

            {
                tradeReferences.data !== null &&
                tradeReferences.data.map((tradeReference, index) => {

                    return (
                        <tr key={index} lassName="data_row">

                            <TableData
                                data={getDate(tradeReference.date_of_reference)}
                            />

                            <TableData
                                data={getDate(tradeReference.company_name)}
                            />

                            <TableData
                                data={getDate(tradeReference.contact_info)}
                            />

                            <TableData
                                data={getDate(tradeReference.ref_source)}
                            />

                            <TableData
                                data={getDate(tradeReference.source_position)}
                            />

                            <TableData
                                data={getDate(tradeReference.source_position)}
                            />

                            <TableData
                                data={getDate(tradeReference.selling_terms)}
                            />


                            <TableData
                                data={getDate(tradeReference.paying_record)}
                            />
                        </tr>
                    )
                })
            }
        </table>
    )
}
