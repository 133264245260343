import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import AuthContext from "../../../AuthContext/AuthContext";
import '../../../css/clientsarena.css'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'
import AddIcon from '@mui/icons-material/Add'
import { DataSubmission } from "../../Other/DataSubmission";
import { AdminPanelSettings, VaccinesRounded } from "@mui/icons-material";

export const UsersTable = ({ editFinsNumber, setdisplay, setEditUser }) => {

    const { authTokens, logoutUser } = useContext(AuthContext);
    const [companyUserData, setCompanyUserData] = useState([])

    useEffect(() => {
        axios({
            method: 'post',
            url: '/company-users/',
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: "Bearer " + String(authTokens.access)
            },
            data: { data: { fins: editFinsNumber } },
        })
            .then((res) => {
                setCompanyUserData(result => result.concat(res.data))
            }).catch((AxiosError) => {
                if (AxiosError.response.data) {
                    if (AxiosError.response.data.includes('Proxy error')) {
                        toast.error('Network error. Check network connection!')
                    }
                }
                if (AxiosError.code === "ERR_NETWORK") {
                    toast.error("Network Error!")
                } else (
                    toast.error('Internal system error contact system admin!')
                )
            }
            )
    }, [])

    const DeleteUser = async (id, name) => {
        if (window.confirm('Press oK to delete ' + name)) {
            setdisplay('red')
            await DataSubmission(
                String(authTokens.access),
                'delete',
                '/user/' + id + '/'
            )
            setdisplay('adminUser')
        }
    }

    const EditUser = (id) => {
        var editUser
        setdisplay('red')
        setEditUser([])
        companyUserData.map((user) => {
            if (user.id === id) {
                editUser = user
            }
        })
        setEditUser(user => user.concat(editUser))
        setdisplay('editUser')
    }

    return (
        <div>
            <table className='clientsTable'>
                <thead className='clientstableheader'>
                    <tr>
                        <th colSpan={editFinsNumber !== 'fincheck' ? 4 : 6} className='clientstableheader'> Client Users</th>
                        {editFinsNumber !== 'fincheck' &&
                            <th className='addnewclienttableth'
                                colSpan={2}>
                                  <button icon="close" className="formButtonsClients" text="Close"
                                        align="center" onClick={() => setdisplay('addUser')} >
                                        <AddIcon className='icons' />
                                        <blockquote>
                                            Add User
                                        </blockquote>
                                    </button>
                            </th>
                        }
                    </tr>
                    <tr>
                        <th className='clientstableheader'> Name </th>
                        <th className='clientstableheader'>Surname</th>
                        <th className='clientstableheader'>Username</th>
                        <th className='clientstableheader'>Access Level</th>
                        <th className="clientstableheader" colSpan={2}> Actions </th>
                    </tr>
                </thead>
                <tbody>
                    {companyUserData.map((user) => {
                        return (
                            <tr key={user.id}>
                                <td className='clientstabledata'>{user.firstname}</td>
                                <td className='clientstabledata'>{user.surname}</td>
                                <td className='clientstabledata'>{user.username}</td>
                                <td className='clientstabledata'>
                                    {user.groups[0] === 3 || user.groups[0] === 1 ?
                                        <p>Admin</p>
                                        :
                                        <p>User</p>
                                    }
                                </td>
                                <td className='clientstabledata'>
                                    <EditIcon
                                        className='editiconsclients'
                                        onClick={() => EditUser(user.id)}
                                    />
                                </td>
                                <td className='clientstabledata'>
                                    <DeleteIcon
                                        className='editiconsclientsdelete'
                                        onClick={() => DeleteUser(user.id, user.username)}
                                    />
                                </td>

                            </tr>
                        )
                    })}
                </tbody>
            </table>
        </div>
    )
}

export const SubscriptionTable = ({ editFinsNumber, setdisplay, setEditSubscription }) => {

    const { authTokens, logoutsubsciption } = useContext(AuthContext);
    const [clientSubscription, setClientSubscription] = useState([])

    useEffect(() => {
        axios({
            method: 'post',
            url: '/get-subscription/',
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: "Bearer " + String(authTokens.access)
            },
            data: { data: { fins: editFinsNumber } },
        })
            .then((res) => {
                setClientSubscription(result => result.concat(res.data))
            }).catch((AxiosError) => {
                if (AxiosError.response.data) {
                    if (AxiosError.response.data.includes('Proxy error')) {
                        toast.error('Network error. Check network connection!')
                    }
                }
                if (AxiosError.code === "ERR_NETWORK") {
                    toast.error("Network Error!")
                } else (
                    toast.error('Internal system error contact system admin!')
                )
            }
            )
    }, [])

    const AddSubscription = () => {
        if (clientSubscription.length === 2) {
            toast.error('A client can only have a maximum number of 2 subscriptions !')
        } else {
            setdisplay('addsubscription')
        }
    }
    const DeleteSubscription = async (id) => {
        if (window.confirm('Press oK to delete client subscription')) {
            setdisplay('red')
            await DataSubmission(
                String(authTokens.access),
                'delete',
                '/subscription/' + id + '/'
            )
            setdisplay('subscription')
            toast.warning('Client subscription deleted !')
        }
    }

    const EditSubscription = (id) => {
        var editSubscription
        setdisplay('red')
        setEditSubscription([])
        clientSubscription.map((subsciption) => {
            if (subsciption.id === id) {
                editSubscription = subsciption
            }
        })
        setEditSubscription(subsciption => subsciption.concat(editSubscription))
        setdisplay('editsubscription')
    }

    return (
        <div>
            <table className='clientsTable'>
                <thead className='clientstableheader'>
                    <tr>
                        <th colSpan={4} className='clientstableheader'> Client Subscriptions</th>
                        <th className='addnewclienttableth'
                            onClick={AddSubscription}
                            colSpan={2}>
                            <AddIcon />
                        </th>
                    </tr>
                    <tr>
                        <th className='clientstableheader'>Subscription Cartegory</th>
                        <th className='clientstableheader'>Currency Type</th>
                        <th className='clientstableheader'>Company Charge</th>
                        <th className='clientstableheader'>Individual Charge</th>
                        <th className="clientstableheader" colSpan={2}> Actions </th>
                    </tr>
                </thead>
                <tbody>
                    {clientSubscription.map((subsciption) => {
                        return (
                            <tr key={subsciption.id}>
                                <td className='clientstabledata'>{subsciption.subscription_category}</td>
                                <td className='clientstabledata'>{subsciption.currency_type}</td>
                                <td className='clientstabledata'>{subsciption.company_charge}</td>
                                <td className='clientstabledata'>{subsciption.individual_charge}</td>
                                <td className='clientstabledata'>
                                    <EditIcon
                                        className='editiconsclients'
                                        onClick={() => EditSubscription(subsciption.id)}
                                    />
                                </td>
                                <td className='clientstabledata'>
                                    <DeleteIcon
                                        className='editiconsclientsdelete'
                                        onClick={() => DeleteSubscription(subsciption.id, subsciption.subsciptionname)}
                                    />
                                </td>

                            </tr>
                        )
                    })}
                </tbody>
            </table>
        </div>
    )
}