import React from 'react';
import './../../css/Searchtemplate.css';
import { useState, useContext, useEffect } from 'react';
import ReactDom from 'react-dom/client';
import { useNavigate } from 'react-router-dom';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import EditIcon from '@mui/icons-material/Edit';
import { Modal } from '../Modal';
import { ValuesContext } from '../Admin/HomeAdmin';
import { Loader } from '../Loader';
import { FilterRecords } from '../FilterRecords';
import { EditIndividualFormsContainer } from './EditIndividualFormsContainer';
import { ReportChecking } from '../ReportChecking';
import { checkIfUserInternal } from '../../js/snippets';
import { IndividualUploads } from './IndividualsUploads';
import AddEditIndividual from './AddEditIndividual';
import DeleteIcon from '@mui/icons-material/Delete';
import AuthContext from '../../AuthContext/AuthContext';
import { toast } from 'react-toastify';
import axios from 'axios';
import { searchRecord } from '../../js/searchRecord';

export const SearchIndividuals = ({ edit, enquiries }) => {

    const { fetchedIndividuals, setFetchedIndividuals } = useContext(ValuesContext);
    const [searchTerm, setSearchTerm] = useState("");
    const [activeSearch, setActiveSearch] = useState(false);
    const [activeDisplay, setActiveDisplay] = useState("fetched")
    const [allSearchResult, setAllSearchResult] = useState({
        data: [],
        message: ""
    });
    const [searchResult, setSearchResult] = useState({
        data: [],
        message: ""
    });
    const searchParameters = [
        { value: "full_name", displayedName: "Full Name" },
        { value: "national_id", displayedName: "National ID" }
    ]





    return (

        <section className="search-individuals">

            <div className="search-individuals-header">
                <p>search individuals</p>
            </div>

            <FilterRecords
                searchTermLabel={"filter by name / surname / ID"}
                searchParameters={searchParameters}
                RecordIcon={PersonAddIcon}
                addRecordText={"add individual"}
                allSearchResult={allSearchResult}
                setAllSearchResult={setAllSearchResult}
                setSearchResult={setSearchResult}
                setActiveSearch={setActiveSearch}
                setActiveDisplay={setActiveDisplay}
                endpoint={"search-fullname-national-id-individual"}
                AddRecordForm={IndividualUploads}
                bulkType={'Individual Credit Reports'}
                edit={edit}
                searchTerm={searchTerm}
                setSearchTerm={setSearchTerm}
            />


            <div className="individuals-container">

                <div className="individuals-header">
                    <h4>fornames</h4>
                    <h4>surname</h4>
                    <h4>nation ID</h4>
                    {!edit ? <h4>Select</h4> :
                        <h4>Edit</h4>
                    }
                    {
                        edit &&
                        <h4>Delete</h4>
                    }
                </div>

                {
                    activeDisplay === "fetched" ? <FetchedIndividuals fetchedIndividuals={fetchedIndividuals} setFetchedIndividuals={setFetchedIndividuals} edit={edit} /> :
                        <SearchedIndividuals
                            searchResult={searchResult}
                            setSearchResult={setSearchResult}
                            activeSearch={activeSearch}
                            edit={edit}
                            searchTerm={searchTerm}
                            setSearchTerm={setSearchTerm}
                            enquiries={enquiries}
                            setActiveDisplay={setActiveDisplay}
                        />
                }


            </div>
        </section>
    )
}

const FetchedIndividuals = ({ fetchedIndividuals, setFetchedIndividuals, edit }) => {

    return (

        <>
            {
                fetchedIndividuals.data.length === 0 ? <p className='message'>Search for Individual</p> :

                    fetchedIndividuals.data.map((individual, index) => {

                        return (
                            <Individual
                                index={index}
                                key={individual.national_id}
                                individual={individual}
                                edit={edit}
                                individuals={fetchedIndividuals}
                                setIndividuals={setFetchedIndividuals}
                            />
                        )
                    })
            }
        </>
    )
}

const SearchedIndividuals = ({ activeSearch, searchResult, edit, setSearchResult, searchTerm, setSearchTerm, enquiries, setActiveDisplay }) => {

    const [showAddRecordForm, setShowAddRecordForm] = useState(false);
    //const [displayMessage,setDisplayMessage] = useState(searchResult.data.length === 0 ? true : false)

    useEffect(() => {

        if (showAddRecordForm === true) {
            setSearchTerm('')
        }
    }, [showAddRecordForm])

    return (

        <>
            {showAddRecordForm && <Modal Component={IndividualUploads} setFormStatus={setShowAddRecordForm} />}
            {
                activeSearch ? <Loader /> :
                    searchResult.data === null ? <p className='message'>{`${searchResult.message}`}</p> :

                        // render individuals if available

                        (searchResult.data.length === 0 && searchTerm.length > 0) ?
                            <div className="not-found-container">
                                <p>No search results found for {searchTerm}. Please add individual details by pressing OK</p>
                                <div className="not-found-buttons-container">
                                    <button className="ok-btn" onClick={() => setShowAddRecordForm(true)}>ok</button>
                                </div>
                            </div>
                            :
                            searchResult.data.map((individual, index) => {
                                return (
                                    <Individual index={index} key={individual.national_id} individual={individual} edit={edit} individuals={searchRecord} setIndividuals={setSearchResult} />
                                )
                            })

            }
        </>
    )
}




const Individual = ({ individual, index, edit, individuals, setIndividuals }) => {

    const [checked, setChecked] = useState(false);
    const [reportChecking, setReportChecking] = useState(false)
    const [showEditForm, setShowEditForm] = useState(false);
    const navigate = useNavigate();
    const { userData } = useContext(ValuesContext);
    const { authTokens } = useContext(AuthContext);


    const deleteIndividual = () => {

        axios.delete(`/individual/${individual.fins_number}`, {
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + String(authTokens.access)
            }
        }).
            then(() => {
                toast.success('Individual was deleted')

                const newInd = individuals.data.filter(ind => ind.fins_number !== individual.fins_number);
                setIndividuals({
                    data: newInd
                })
            })
            .catch(err => {

                if (err.response) {
                    toast.error('Internal Server Error,try again')
                } else if (err.request) {
                    toast.error('Error occured while processing request,try again')
                } else {
                    toast.error('An error occured,try again');
                }
            })
    }


    return (
        <article className="individual" style={{ backgroundColor: index % 2 === 0 && 'white' }}>
            {
                showEditForm &&
                <Modal
                    Component={EditIndividual}
                    setFormStatus={setShowEditForm}
                    data={individual}
                />

            }


            {
                /* Show report checking */
                reportChecking && (userData.data.groups[0] === 1 || userData.data.groups[0] === 2) &&
                <Modal
                    Component={ReportChecking}
                    setFormStatus={setReportChecking}
                    data={individual}
                />
            }
            <p>{individual.firstname}</p>
            <p>{individual.surname}</p>
            <p>{individual.national_id}</p>

            {
                !edit ?
                    <p>
                        <RadioButtonUncheckedIcon
                            className={`checkbox ${checked && 'selected'}`}
                            onClick={() => checkIfUserInternal(userData, setReportChecking, individual, navigate, setChecked, "individual")}
                        />
                    </p> :

                    <>
                        <div className="edit-btn-container" onClick={() => setShowEditForm(true)}>
                            <button className="edit-btn">
                                <EditIcon className="edit-icon" />
                            </button>
                        </div>

                    </>
            }

            {
                edit &&
                <p>
                    <button onClick={deleteIndividual} style={{ backgroundColor: 'var(--main-color)', border: 'none', borderRadius: '5px', cursor: 'pointer' }}>
                        <DeleteIcon style={{ color: 'white' }} />
                    </button>
                </p>
            }

        </article>
    )
}

const EditIndividual = ({ data, setFormStatus }) => {

    return (

        <>
            <AddEditIndividual
                caller={'editIndividual'}
                individualsData={data}
                setDisplayedForm={setFormStatus}
            />
        </>
    )
}