import React from 'react'
import { calculateOverdueMonths, getDate } from '../../../js/getDate'
import { TableData, TableDataHeaders, TableHeading } from './ReportTemplates'

const SlowPayer = ({slowpayers}) => {
    return (
        <table className='report_table'>
            <TableHeading
                span={10}
                heading={"SLOW PAYER"}
            />

            <tr className='data_row'  >
                <TableDataHeaders
                    id={"count_header"}
                    data={""}
                />

                <TableDataHeaders
                    id={"creditor_header"}
                    data={"Creditor"}
                />

                <TableDataHeaders
                    id={"creditType_header"}
                    data={"Credit Type"}
                />

                <TableDataHeaders
                    id={"currency_header"}
                    data={"Currency"}
                />

                <TableDataHeaders
                    id={"startDate_header"}
                    data={"Start Date"}
                />

                <TableDataHeaders
                    id={"closingDate_header"}
                    data={"Closing Date"}
                />

                <TableDataHeaders
                    id={"principle_amount_header"}
                    data={"Principle Amount"}
                />

                <TableDataHeaders
                    id={"installment_amount_header"}
                    data={"Installment Amount"}
                />

                <TableDataHeaders
                    id={"overdue_amount_header"}
                    data={"Overdue Amount"}
                />

                <TableDataHeaders
                    id={"months_in_arrears_header"}
                    data={"Months In Arrears"}
                />
            </tr>

            {
            (slowpayers.data !== null && slowpayers.data.length === 0) &&
            <tr className='data_row'>
                <td colSpan={10}>CLEAR TO DATE IN OUR FILES IN THE NAME OF THE BUSINESS AND PRINCIPALS</td>
            </tr>
            }


            {//mappping function

                slowpayers.data !== null &&
                slowpayers.data.map((slowpayer,index) => {

                    return(
                        <>
                            <TableData
                                id={"count_field"}
                                data={index}
                            />

                            <TableData
                                id={'creditor_field'}
                                data={slowpayer.cr_company_creditor_fins ? slowpayer.cr_company_creditor_fins.registration_name : slowpayer.cr_individual_creditor_fins.name + ` ${slowpayer.cr_individual_creditor_fins.surname}`}
                            />

                            <TableData
                                id={'credit_type_field'}
                                data={slowpayer.creditor_type}
                            />

                            <TableData
                                id={'currency_field'}
                                data={slowpayer.currency_type}
                            />

                            <TableData
                                id={'start_date_field'}
                                data={getDate(slowpayer.date_disbursed)}
                            />

                            <TableData
                                id={'closing_date_field'}
                                data={getDate(slowpayer.date_of_last_installment)}
                            />

                            <TableData
                                id={'principle_amount_field'}
                                data={slowpayer.principal_amount}
                            />

                            <TableData
                                id={'installment_amount_field'}
                                data={slowpayer.installment_amount}
                            />

                            <TableData
                                id={'overdue_amount_field'}
                                data={slowpayer.amount_overdue}
                            />

                            <TableData
                                id={'month_in_arrears_field'}
                                data={calculateOverdueMonths(slowpayer.date_of_last_installment)}
                            />
                        </>

                    )
                })
            }

            <tr className='data_row' >
                
            </tr>
        </table>
    )
}

export default SlowPayer