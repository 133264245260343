import React, { useContext, useEffect, useState, useRef } from 'react';
import './../css/InternalUsers.css';
import { Modal } from './Modal';
import AddeditUser from './Clients/AddeditUser';
import { ValuesContext } from './Admin/HomeAdmin';
import { fetchData } from '../js/fetchData';
import AuthContext from '../AuthContext/AuthContext';
import axios from 'axios';
import { toast } from 'react-toastify';
import DataTable from 'react-data-table-component';


export const InternalUsers = () => {

    const [showAddModal, setShowAddModal] = useState(false);
    const { authTokens } = useContext(AuthContext);
    const [modalClose, setModalClose] = useState(0)
    const [allInternalUsers, setAllInternalUsers] = useState([]);
    const [userGroup, setUserGroup] = useState('internal');
    const [allUsers, setAllUsers] = useState(null);
    const [internalUsers, setInternalUsers] = useState(null);
    const [showEditModal, setShowEditModal] = useState(false);
    const searchUsersInput = useRef();
    const [selectedUser, setSelectedUser] = useState({})


    useEffect(() => {
        setModalClose(modalClose + 1);
    }, [showEditModal])

    useEffect(() => {

        fetchData(
            String(authTokens.access),
            setAllUsers,
            'user',
            null,
            null,
        )

    }, [showAddModal, modalClose])

    const deleteUser = (user) => {

        axios.delete(`/user/${user.id}/`, {
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: "Bearer " + String(authTokens.access)
            },
        })
            .then(response => {

                if (response.status >= 200 && response.status < 300) {
                    toast.success('User deleted successfully');
                    const leftUsers = internalUsers.filter(allUser => allUser.id !== user.id);
                    setInternalUsers(leftUsers);
                } else {
                    toast.error('Could not delete user')
                }
            })
            .catch(() => toast.error('Failed to send request,check your connection'));
    }


    useEffect(() => {

        if (allUsers === null) return;

        const filteredInternalUsers = allUsers.data.filter(user => {
            if (user.groups[0] === 1 || user.groups[0] === 2) return user;
        })

        setInternalUsers(filteredInternalUsers)
        setAllInternalUsers(filteredInternalUsers);

    }, [allUsers])


    const showModal = (e) => {
        setShowAddModal(true)
    }

    const clickToShowEditModal = (user) => {
        setSelectedUser(user)
        setShowEditModal(true)
    }


    const searchUsers = (e) => {
        const searchValue = e.target.value;



        if (searchValue === '') {
            setInternalUsers(allInternalUsers);
            return
        }

        const filteredUsers = allInternalUsers.filter(user => {
            const fullName = user.firstname + ' ' + user.surname;

            if (
                user.firstname.toLowerCase().includes(e.target.value.toLowerCase()) ||
                user.surname.toLowerCase().includes(e.target.value.toLowerCase()) ||
                user.email.toLowerCase().includes(e.target.value.toLowerCase()) ||
                user.username.toLowerCase().includes(e.target.value.toLowerCase()) ||
                fullName.toLowerCase().includes(e.target.value.toLowerCase())
            ) {
                return user
            }
        })

        setInternalUsers(filteredUsers);

    }

    const columns = [
        {
            name: 'Surname',
            selector: row => row.surname,
            sortable: 'true'
        },
        {
            name: 'First Name',
            selector: row => row.firstname,
            sortable: 'true'
        },
        {
            name: 'Level',
            selector: row => row.groups[0] === 1 ? 'Admin' : 'User',
            sortable: 'true'
        },
        {
            name: 'Email',
            selector: row => row.email,
            sortable: 'true'
        },
        {
            name: 'Mobile Number',
            selector: row => row.mobile,
            sortable: 'true'

        },
        {
            name: 'Edit',
            cell: row => <div data-tag="allowRowEvents"><button className='edit-internal-user' onClick={() => clickToShowEditModal(row)}>Edit</button></div>,
        },
        {
            name: 'Delete',
            cell: row => <div data-tag="allowRowEvents"><button className='delete-internal-user' onClick={() => deleteUser(row)}>Delete</button></div>,
        }
    ];

    return (

        <section className="internal-users">

            {
                showAddModal === true &&
                <Modal
                    Component={AddEdit}
                    setFormStatus={setShowAddModal}
                    data={userGroup}
                />
            }

            {
                showEditModal &&
                <Modal
                    Component={AddEditEdit}
                    setFormStatus={setShowEditModal}
                    data={selectedUser}
                />
            }

            <h3 className='internal-users-header'>
                Internal Users
            </h3>

            <div className="search-users-input-container">

                <input
                    ref={searchUsersInput}
                    type="text"
                    placeholder='Search by name / email'
                    onChange={e => searchUsers(e)}
                />
            </div>

            <div className="internal-users-container">

                <div className="internal-users-buttons-container">

                    <button className="add-internal-user-btn" onClick={e => showModal(e)}>
                        +Add Intenal User
                    </button>

                </div>

                {
                    internalUsers !== null &&


                    <DataTable
                        columns={columns}
                        data={internalUsers}
                        fixedHeader={true}
                        fixedHeaderScrollHeight={'100%'}
                    />

                }

            </div>
        </section>
    )
}

const AddEdit = ({ data }) => {

    const { userData } = useContext(ValuesContext);
    const fins = userData.data.company.fins_number

    return (
        <AddeditUser
            caller={'addadminuser'}
            finsNumber={fins}
            setactive={null}
            userData={[userData]}
            usergroup={'internal'}
        />
    )
}

const AddEditEdit = ({ data }) => {

    const { userData } = useContext(ValuesContext);
    const fins = userData.data.company

    return (
        <AddeditUser
            caller={'edituser'}
            finsNumber={data.company.fins_number}
            userData={[data]}
            setactive={null}
            usergroup={'internal'}
        />
    )
}

