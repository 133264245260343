import React, { useState, useEffect, useContext, useRef } from 'react';

import { EnquiriesContext } from '../../../App';
import axios from 'axios';
import { fetchData, handleResponses } from '../../../js/fetchData';
import { fetchByFinsNumbers } from '../../../js/searchRecord';
import { filterAbsconderSlowPayerActive } from '../../../js/filterAbsconderSlowPayerActive';
import { Loader } from '../../Loader';
import { RiskClassification } from "../../Individuals/IndividualReport/RiskClassification";
import { CompanyIdentificationAndSummary } from '../CompanyReport/CompanyIdentificationAndSummary';
import { ScoreRange } from "../../Individuals/IndividualReport/ScoreRange";
import { CompanyContactDetails } from '../CompanyReport/CompanyContactDetails';
import { ActiveCreditAccs } from './ActiveCreditAccounts';
import CourtRecords from "../../Individuals/IndividualReport/CourtRecords";
import Claims from "../../Individuals/IndividualReport/Claims";
import HPI from './HirePurchase';
import Inquiries from "../../Individuals/IndividualReport/Inquiries";
import PublicInfo from "../../Individuals/IndividualReport/PublicInfo";
import { BIUReportHeader } from './BIUReportHeader';
import { BIUContactDetails } from './BIUContactDetails';
import { NameChangeHistory } from './NameChangeHistory';
import { Principals } from './Principals';
import { Auditors } from './Auditors';
import { Branches } from './Branches';
import { Subsidiaries } from './Subsidiaries';
import { AssociatedCompanies } from './AssociatedCompanies';
import { Divisions } from './Divisions';
import { HoldingCompany } from './HoldingCompany';
import { ShareHolders } from './ShareHolders';
import { Judicial } from './Judicial';
import { TradeReferences } from './TradeReferences';
import { Insolvency } from './Insolvency';
import SlowPayer from '../../Individuals/IndividualReport/SlowPayer';
import Absconder from '../../Individuals/IndividualReport/Absconder';
import { BankingDetails } from './BankingDetails';
import { BUIDeeds } from './BUIDeeds';
import { BIUBankReport } from './BIUBankReports';
import { Operations } from './Operations';
import { Comment } from './Comment';
import { PrintBtn } from '../../Individuals/IndividualReport/IndividualReport';
import { chargeCompanyBIU } from '../../../js/chargeClient';
import AuthContext from '../../../AuthContext/AuthContext';
import { getDate } from '../../../js/getDate';
import { ReportSummary } from './ReportSummary';
import { OwnershipAndOversight } from './OwnershipAndOversigth';
import BIUClaims from './BIUClaims';
import BIUCourtRecords from './BIUCourts';
import BIUAbsconder from './BIUAbsconder';
import BIUPublicInfo from './BIUPublicInfo';



export const CompanyBiuReport = () => {

   const reportData = JSON.parse(localStorage.getItem('biu-data'));
   const { authTokens } = useContext(AuthContext);
   const company = reportData.data.subject_company;
   const [score, setScore] = useState({ data: null })
   const [failureFetch, setFailureFetch] = useState(false);
   const [search, setSearchResult] = useState({ data: null });
   const [activeCreditAccs, setActiveCreditAccs] = useState({ data: null });
   const [creditAccounts, setCreditAccounts] = useState({ data: null });
   const [historicals, setHistoricals] = useState({ data: null });
   const [claims, setClaims] = useState({ data: null })
   const [absconders, setAbsconders] = useState({ data: null });
   const [slowPayer, setSlowPayer] = useState({ data: null });
   const [courts, setCourts] = useState({ data: null })
   const [hirePurchases, setHirePurchases] = useState({ data: null });
   const [inquiries, setInquries] = useState({ data: null });
   const [publicInfo, setPublicInfo] = useState({ data: null });
   const [directorships, setDirectorships] = useState({ data: null });
   const [auditors, setAuditors] = useState({ data: null })
   const [report, setReport] = useState({ data: null })
   const [subsidiaries, setSubsidiaries] = useState({ data: null })
   const [associatedCompanies, setAssociatedCompanies] = useState({ data: null });
   const [divisions, setDivisions] = useState({ data: null });
   const [branch, setBranch] = useState({ data: null });
   const [shareholders, setShareholders] = useState({ data: null });
   const [tradeReferences, setTradeReferences] = useState({ data: null });
   const [deeds, setDeeds] = useState({ data: null });
   const [bankDetails, setBankDetails] = useState({ data: null })
   const [bankReports, setBankReports] = useState({ data: null })
   const [companyCapital, setCompanyCapital] = useState({ data: null })
   const [nameChange, setNameChange] = useState({ data: null })


   useEffect(() => {

      if (
         claims.message === "fetch was successful" &&
         nameChange.message === "fetch was successful" &&
         companyCapital.message === "fetch was successful" &&
         report.message === "fetch was successful" &&
         courts.message === "fetch was successful" &&
         publicInfo.message === "fetch was successful" &&
         inquiries.message === "fetch was successful" &&
         hirePurchases.message === "fetch was successful" &&
         creditAccounts.message === "fetch was successful" &&
         directorships.message === "fetch was successful" &&
         auditors.message === "fetch was successful" &&
         subsidiaries.message === "fetch was successful" &&
         divisions.message === "fetch was successful" &&
         branch.message === "fetch was successful" &&
         associatedCompanies.message === "fetch was successful" &&
         shareholders.message === "fetch was successful" &&
         shareholders.message === "fetch was successful" &&
         deeds.message === "fetch was successful" &&
         bankReports.message === "fetch was successful" &&
         bankDetails.message === "fetch was successful" &&
         tradeReferences.message === "fetch was successful"
      ) {

         setFailureFetch("done")
         // charging the client

         //chargeCompanyBIU(reportData.data.request_id, setSearchResult);

         filterAbsconderSlowPayerActive(claims, setClaims, creditAccounts, setActiveCreditAccs, setAbsconders, setSlowPayer);

      }

   }, [nameChange, companyCapital, report, bankReports, deeds, bankDetails, tradeReferences, shareholders, claims, courts, publicInfo, inquiries, hirePurchases, creditAccounts, directorships, auditors, subsidiaries, divisions, associatedCompanies, branch]);


   useEffect(() => {

      axios.post(`/claim-search/`, {

         headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + String(authTokens.access),
         }
         ,
         data: {
            searchParameter: "company_debtor_fins",
            fins: company.fins_number
         }
      })
         .then(res => {

            if (res.status !== 200) {
               setFailureFetch(true)
            }
            handleResponses(res, setClaims);

         })
         .catch(err => {
            setFailureFetch(true)
            handleResponses(err, setClaims);
         })



      fetchByFinsNumbers(
         String(authTokens.access),
         company.fins_number,
         "company-credit-details/",
         setCreditAccounts,
         setFailureFetch,
         null
      )

      fetchByFinsNumbers(
         String(authTokens.access),
         company.fins_number,
         'company-court/',
         setCourts,
         setFailureFetch,
         null
      )

      fetchByFinsNumbers(
         String(authTokens.access),
         company.fins_number,
         "company-hire-purchase/",
         setHirePurchases,
         setFailureFetch,
         null
      )

      fetchByFinsNumbers(
         String(authTokens.access),
         company.fins_number,
         "company-enquiries/",
         setInquries,
         setFailureFetch,
         null
      );

      fetchByFinsNumbers(
         String(authTokens.access),
         company.fins_number,
         "company-public-info/",
         setPublicInfo,
         setFailureFetch,
         null,
      )

      fetchByFinsNumbers(
         String(authTokens.access),
         company.fins_number,
         "company-executive/",
         setDirectorships,
         setFailureFetch,
         null,
      )

      fetchByFinsNumbers(
         String(authTokens.access),
         company.fins_number,
         "company-auditor/",
         setAuditors,
         setFailureFetch,
         null,
      )

      fetchByFinsNumbers(
         String(authTokens.access),
         company.fins_number,
         "company-subsidiary-company/",
         setSubsidiaries,
         setFailureFetch,
         null,
      )

      fetchByFinsNumbers(
         String(authTokens.access),
         company.fins_number,
         "company-associated-company/",
         setAssociatedCompanies,
         setFailureFetch,
         null,
      )

      fetchByFinsNumbers(
         String(authTokens.access),
         company.fins_number,
         "company-division/",
         setDivisions,
         setFailureFetch,
         null,
      )

      fetchByFinsNumbers(
         String(authTokens.access),
         company.fins_number,
         "company-branch/",
         setBranch,
         setFailureFetch,
         null,
      )

      fetchByFinsNumbers(
         String(authTokens.access),
         company.fins_number,
         "company-shareholders/",
         setShareholders,
         setFailureFetch,
         null,
      )

      fetchByFinsNumbers(
         String(authTokens.access),
         company.fins_number,
         "company-trade-reference/",
         setTradeReferences,
         setFailureFetch,
         null,
      )

      fetchByFinsNumbers(
         String(authTokens.access),
         company.fins_number,
         "company-bank-account/",
         setBankDetails,
         setFailureFetch,
         null,
      )

      fetchByFinsNumbers(
         String(authTokens.access),
         company.fins_number,
         "search-deed/",
         setDeeds,
         setFailureFetch,
         "owner_company",
      )

      fetchByFinsNumbers(
         String(authTokens.access),
         company.fins_number,
         "company-bank-report/",
         setBankReports,
         setFailureFetch,
         null,
      )

      fetchByFinsNumbers(
         String(authTokens.access),
         company.fins_number,
         "search-company-capital/",
         setCompanyCapital,
         setFailureFetch,
         null,
      )

      fetchByFinsNumbers(
         String(authTokens.access),
         company.fins_number,
         "search-name-change/",
         setNameChange,
         setFailureFetch,
         null,
      )


      /*     fetchByFinsNumbers(
             String(authTokens.access),
             null,
             `biu-report/${reportData.data.report}/`,
             setReport,
             setFailureFetch,
             null,
          ) */

      fetchData(String(authTokens.access), setReport, `biu-report/${reportData.data.report}/`, null, null);


   }, [])


   return (
      <>

         {
            failureFetch === false &&
            <Loader />
         }

         {
            failureFetch === true &&
            <p className='failed-to-fetch'>Something went wrong,try again</p>
         }

         {
            failureFetch === "done" &&
            <IndReports
               activeCreditAccs={activeCreditAccs}
               historicals={historicals}
               courts={courts}
               hirePurchases={hirePurchases}
               inquiries={inquiries}
               publicInfo={publicInfo}
               claims={claims}
               directorships={directorships}
               absconders={absconders}
               slowPayer={slowPayer}
               score={score}
               company={company}
               reportData={reportData}
               auditors={auditors}
               branch={branch}
               subsidiaries={subsidiaries}
               associatedCompanies={associatedCompanies}
               divisions={divisions}
               shareholders={shareholders}
               bankDetails={bankDetails}
               deeds={deeds}
               bankReports={bankReports}
               tradeReferences={tradeReferences}
               report={report}
               companyCapital={companyCapital}
               nameChange={nameChange}

            />
         }



      </>
   )
}

const IndReports = ({ nameChange, companyCapital, report, bankReports, deeds, bankDetails, tradeReferences, shareholders, divisions, associatedCompanies, subsidiaries, branch, auditors, reportData, slowPayer, company, activeCreditAccs, historicals, courts, hirePurchases, inquiries, publicInfo, claims, directorships, absconders, score }) => {


   const [showBtn, setShowBtn] = useState(true);
   const type = useRef("")
   console.log(company)

   return (

      <>
         <table ref={type} className={"company-report"}>

            {/* Report Header */}
            <br />
            <table border={1} style={{ borderCollapse: 'collapse', width: '100%', padding: '.5em 0', borderBottom: '0px transparent', }}>
               <tr>
                  <BIUReportHeader
                     name={company.registration_name}
                     client={reportData.data.client_company.registration_name}
                     score={score}
                  />
               </tr>
               <br />
               <tr className='row_heading' style={{ margin: '50px 0', backgroundColor: '#042464', color: 'white' }}>
                  <td className='txt_rowheading'>
                     <h1 className='section_heading' style={{ fontSize: '1.5rem' }}>
                        BUSINESS INTELLIGENCE REPORT
                     </h1>
                  </td>
               </tr>
               <br />
               <table className='biu-intro' border={1} style={{ borderCollapse: 'collapse', width: '100%' }}>
                  <tr >
                     <th style={{ textAlign: 'left', fontWeight: '400' }}>Target Organisation Registered Name: </th>
                     <td style={{ textAlign: 'center', fontWeight: '600' }}>{company ? company.registration_name : 'N/A'}</td>
                  </tr>
                  <tr>
                     <th style={{ textAlign: 'left', fontWeight: '400' }}>Target Organisation Trading Name:</th>
                     <td style={{ textAlign: 'center', fontWeight: '600' }}>{company ? company.trading_name : 'N/A'}</td>
                  </tr>
                  <tr>
                     <th style={{ textAlign: 'left', fontWeight: '400' }}>Report Date :</th>
                     <td style={{ fontWeight: '400', textAlign: 'center' }}>{reportData ? getDate(reportData.data.created_date) : 'N/A'}</td>
                  </tr>
                  <tr>
                     <th style={{ textAlign: 'left', fontWeight: '400' }}>Client :</th>
                     <td style={{ fontWeight: '400', textAlign: 'center' }}>{reportData ? reportData.data.client_company.registration_name : 'N/A'}</td>
                  </tr>


               </table>

            </table>


            {/* Report Summary */}
            <ReportSummary report={report} />

            <br /><br />
            {/* Report Registration Details */}

            <table border={1} style={{ marginTop: '1em', borderCollapse: 'collapse', width: '55%', padding: '.5em 0' }}>
               <tr className="report-section">
                  <CompanyIdentificationAndSummary company={company} BIU />

               </tr>

               <tr className='report-section'>
                  <NameChangeHistory nameChange={nameChange} color={'#b4c6e7'} />
               </tr>

               <tr className="report_section">
                  <BUIDeeds deeds={deeds} />
               </tr>
            </table>


            <br /><br />

            <table border={1} style={{ marginTop: '1em', borderCollapse: 'collapse', width: '45%', padding: '.5em 0' }}>
               <tr className='report-section'>
                  <BIUContactDetails data={company} type={""} report={report} />
               </tr>
            </table>


            {/* Ownership and oversigth */}

            <OwnershipAndOversight directorships={directorships} shareholders={shareholders} companyCapital={companyCapital} />

            {/* Operations */}
            <br /><br />
            <tr className={"report_section"}>
               <Operations
                  company={company}
                  divisions={divisions}
                  associatedCompanies={associatedCompanies}
                  subsidiaries={subsidiaries}
                  branch={branch}
                  bankDetails={bankDetails}
                  auditors={auditors}
                  tradeReferences={tradeReferences}
                  report={report}
                  companyCapital={companyCapital}
               />
            </tr>

            <br /><br />

            <table border={1} style={{ marginTop: '1em', borderCollapse: 'collapse', width: '45%', padding: '.5em 0', width: '100%' }}>


               <br />
               <tr className='row_heading' style={{ margin: '50px 0', backgroundColor: '#4472c4', color: 'white' }}>
                  <td className='txt_rowheading' colSpan={4}>
                     <h1 className='section_heading' style={{ fontSize: '1.2rem' }}>
                        CREDIT INFORMATION
                     </h1>
                  </td>
               </tr>
               <br />


               <tr className="report_section">
                  <td colSpan={8}><ActiveCreditAccs activeCreditAccs={activeCreditAccs} /></td>
               </tr>
               <br />

               <tr className="report_section">
                  <td colSpan={8}><HPI hirePurchases={hirePurchases} /></td>
               </tr>
               <br />

            </table>

            <br /><br />

            <table border={1} style={{ padding: '1em', marginTop: '1em', borderCollapse: 'collapse', width: '45%', padding: '.5em 0', width: '100%' }}>


               <br />
               <tr className='row_heading' style={{ margin: '50px 0', backgroundColor: '#4472c4', color: 'white' }}>
                  <td className='txt_rowheading' colSpan={4}>
                     <h1 className='section_heading' style={{ fontSize: '1.2rem' }}>
                        ADVERSE RECORDS
                     </h1>
                  </td>
               </tr>
               <br />

               <tr className='report_section'>
                  <Insolvency company={company} />
               </tr>
               <br />

               <tr className="report_section">
                  <BIUClaims slowPayers={slowPayer} claims={claims} />
               </tr>
               <br />

               <tr className="report_section">
                  <BIUAbsconder absconders={absconders} />
               </tr>
               <br />

               <tr className="report_section">
                  <BIUCourtRecords courts={courts} />
               </tr>
               <br />


               <br />


               <tr className="report_section">
                  <BIUPublicInfo publicInfo={publicInfo} />
               </tr>
               <br />

            </table>






            {/* 
        <tr className="report-section">
            <RiskClassification score={score}/>
        </tr>

        <tr className="report-section">
            <ScoreRange score={score}/>
        </tr>

       

        <tr className="report-section">
            <CompanyContactDetails company={company}/>
        </tr>

        <tr className="report_section">
            <ReportSummary
              activeCreditAccs={activeCreditAccs}
              claims={claims}
              courts={courts}
              inquiries={inquiries}
              hirePurchases={hirePurchases}
              absconders={absconders}
              slowPayer={slowPayer}
            />
        </tr>

        <tr className="report_section">
            <ActiveCreditAccs activeCreditAccs={activeCreditAccs}/>
        </tr>


        

       
            
       {/*  <tr className="report_section">
            <Inquiries inquiries={inquiries}/>
        </tr> */}

            {/*  <tr className="report_section">
            <PublicInfo publicInfo={publicInfo}/>
        </tr> */}

            {/* <tr className="report_section">
               <Directorships directorships={directorships} type={"company"}/>
            </tr> */}


         </table>
         {showBtn && <PrintBtn showBtn={showBtn} setShowBtn={setShowBtn} type={type} />}


      </>
   )
}
