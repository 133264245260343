import { useNavigate } from 'react-router-dom';
import React, { useEffect, useState, useRef, useContext } from 'react';
import './../css/ReportChecking.css';
import { fetchData } from '../js/fetchData';
import { showReqContainer, searchUser } from '../js/snippets';
import AuthContext from './../AuthContext/AuthContext';
import { ValuesContext } from './Admin/HomeAdmin';
import { toast } from 'react-toastify';

export const ReportChecking = ({ data }) => {


  const [users, setUsers] = useState([]);
  const [fetchedUsers, setFetchedUsers] = useState({ data: null })
  const { authTokens } = useContext(AuthContext);
  const [selectedUser, setSelectedUser] = useState(null);
  const [client, setClient] = useState(null);
  const { userData } = useContext(ValuesContext)
  const [enqType, setEnqType] = useState("internal")
  const [searchedUsers, setSearchedUsers] = useState([]);
  const [allBranches, setAllBranches] = useState(null);
  const [userBranch, setUserBranch] = useState("")
  const [clientBranches, setClientBranches] = useState([])
  const requesterInput = useRef();
  const branchInput = useRef();
  const navigate = useNavigate();


  useEffect(() => {

    if (fetchedUsers.data === null) {
      return;
    }

    const filterExternalUsers = fetchedUsers.data.filter(user => {

      if (user.company) {
        if (user.company.registration_name !== "fincheck") {
          return user
        }

      }
    })

    setUsers({ data: filterExternalUsers })
  }, [fetchedUsers])

  useEffect(() => {

    if (client === null || allBranches === null || allBranches.data === null) {
      return
    }

    const currentClientBranches = allBranches.data.filter(branch => branch.master_company === client.fins_number);
    setClientBranches(["HQ", ...currentClientBranches]);
    const elementClass = ".branches-dropdown";
    const branchesDropDown = document.querySelector(elementClass);
    branchesDropDown.classList.add("show-branches-dropdown");


  }, [client, allBranches])


  useEffect(() => {

    if (selectedUser === null) {
      return
    }

    setClient(selectedUser.company)

  }, [selectedUser])

  const goToReport = () => {

    /*     if(branchInput.current.value === "" && enqType === 'external-internal'){
          toast.error("Please select a branch to proceed");
          return;
        } */

    let enqDetails = {};


    if (enqType === "internal") {
      enqDetails = JSON.stringify({
        fins: data.fins_number,
        user: null,
        enqType,
        current_user: userData.data.id
      })
    } else if (enqType === "external") {
      enqDetails = JSON.stringify({
        current_user: userData.data.fins_number,
        user: userData.data.id,
        fins: data.fins_number,
        enqType
      })
    }
    else {
      const selectedUser = users.data.filter(user => {
        const name = user.firstname + ' ' + user.surname;

        if (name.toLowerCase() === requesterInput.current.value.toLowerCase()) {
          return user
        }
      })
      enqDetails = JSON.stringify({
        fins: data.fins_number,
        user: selectedUser[0].username,
        enqType,
        current_user: userData.data.id
      })
    }


    localStorage.setItem("enq-details", enqDetails);


    if (data.model !== undefined) {
      navigate("/report/hpi-report");
      return;
    }

    if (data.registration_number === undefined) {
      navigate("/report/individual-report")
    } else {
      navigate("/report/company-report");
    }
  }

  useEffect(() => {

    localStorage.setItem("data", JSON.stringify(data))

    fetchData(
      String(authTokens.access),
      setFetchedUsers,
      "user",
      null,
      null
    )

    fetchData(
      String(authTokens.access),
      setAllBranches,
      "branch",
      null,
      null
    )
  }, [])


  return (

    <section className="report-checking">

      <p>Please choose the type of enquiry:</p>

      <div className="internal-external-enquiry">

        <button className="internal" onClick={goToReport}>
          internal
        </button>

        <button className="external" onClick={(e) => showReqContainer(setEnqType)}>
          external
        </button>
      </div>

      <div className="requester-container">

        <div className="requester-input-container">
          <label htmlFor="User" style={{ fontWeight: '600', marginBottom: '1em' }}>Requester</label>
          <input
            type="text"
            ref={requesterInput}
            onChange={() => searchUser(requesterInput, users, setSearchedUsers, "users", null)}
          />
        </div>
        <UsersDropDown
          requesterInput={requesterInput}
          searchedUsers={searchedUsers}
          setSelectedUser={setSelectedUser}
          typeOfDropdown={"users"}
        />

        <div className="client-name-input-container">
          <label htmlFor="User" style={{ fontWeight: '600', marginBottom: '1em' }}>Client Name</label>
          <input
            type="text"
            value={client !== null ? client.registration_name : ""}
            readOnly
          />
        </div>

        <div className="branch-input-container">
          <label htmlFor="User" style={{ fontWeight: '600', marginBottom: '1em' }}>Branch</label>
          <input
            type="text"
            ref={branchInput}
            readOnly
          /* nChange={() => searchUser(branchInput, clientBranches, setClientBranches,"branches",null)} */
          />
        </div>
        <BranchesDropDown
          branches={clientBranches}
          branchInput={branchInput}
          typeOfDropdown={"branches"}
        />





        <button className="go-to-report" onClick={goToReport}>
          submit
        </button>
      </div>
    </section>
  )
}

export const UsersDropDown = ({ searchedUsers, requesterInput, setSelectedUser, typeOfDropdown }) => {

  const enableSubBtn = (e, user) => {
    const elementClass = "." + typeOfDropdown + "-dropdown"
    const submitBtn = document.querySelector(".go-to-report");
    const usersDropDown = document.querySelector(elementClass);

    if (submitBtn !== null) {
      submitBtn.disabled = false;
    }

    requesterInput.current.value = e.target.innerHTML;
    setSelectedUser !== null && setSelectedUser(user);
    usersDropDown.classList.remove("show-users-dropdown");
  }

  return (

    <section className={`${typeOfDropdown}-dropdown`}>
      <ul>
        {
          searchedUsers.length > 0 &&
          searchedUsers.map((user, index) => {
            return <li key={index} onClick={e => enableSubBtn(e, user)}>{typeOfDropdown === "users" ? `${user.firstname} ${user.surname}` : user.registration_name}</li>
          })
        }
      </ul>
    </section>
  )
}

export const BranchesDropDown = ({ branches, branchInput, typeOfDropdown }) => {

  const enableSubBtn = (e) => {
    const elementClass = "." + typeOfDropdown + "-dropdown"
    const submitBtn = document.querySelector(".go-to-report");
    const branchesDropDown = document.querySelector(elementClass);

    if (submitBtn !== null) {
      submitBtn.disabled = false;
    }

    branchInput.current.value = e.target.innerHTML;
    branchesDropDown.classList.remove("show-" + typeOfDropdown + "-dropdown");
  }

  return (

    <section className={`${typeOfDropdown}-dropdown`}>
      <ul>
        {
          branches.length > 0 &&
          branches.map((branch, index) => {
            return <li key={index} onClick={e => enableSubBtn(e)}>{branch === "HQ" ? branch : branch.branch_name}</li>
          })
        }
      </ul>
    </section>
  )
}
