import React from 'react'
import { TableHeading, TableDataHeaders, TableData } from './ReportTemplates'


export const ReportSummary = ({ activeCreditAccs, claims, courts, inquiries, hirePurchases, absconders, slowPayer, color, enqDetails, historicals }) => {


    const activeClaims = claims.data.filter(claim => claim.is_closed === false);
    const activeCourts = courts.data.filter(court => court.is_closed === false);

    return (

        <table className="report_table" style={{ marginBottom: '15px' }}>

            <TableHeading
                span={8}
                heading={"REPORT SUMMARY"}
                color={color}
            />

            <tr className="data_row">

                <td style={{ backgroundColor: '#b4c6e7', fontSize: '.9rem' }} className='txt_section_data'>
                    <b>
                        CREDIT ACCOUNTS
                    </b>
                </td>

                <TableDataHeaders
                    id="active_header"
                    data={"Active:"}
                    textAlignClass={'text_section_left_align'}
                />

                <TableData
                    id={"active_field"}
                    data={`${activeCreditAccs.data !== null ? activeCreditAccs.data.length : "N/A"}`}
                />

                <TableDataHeaders
                    id="hire_purchase_header"
                    data={"Hire Purchase:"}
                    textAlignClass={'text_section_left_align'}
                />

                <TableData
                    id={"hire_purchase_field"}
                    data={`${hirePurchases.data !== null ? hirePurchases.data.length : "N/A"}`}
                />

                <TableDataHeaders
                    id="historical_header"
                    data={"Historical:"}
                    textAlignClass={'text_section_left_align'}
                />

                <TableData
                    id={"historical_field"}
                    data={`${historicals.data !== null ? historicals.data.length : "N/A"}`}
                />



            </tr>


            <tr className="data_row">


                <td style={{ backgroundColor: '#b4c6e7', fontSize: '.9rem' }} className='txt_section_data'>
                    <b style={{ borderTop: '1px solid black', width: '100%' }}>
                        ADVERSE RECORDS
                    </b>
                </td>

                <TableDataHeaders
                    id="claims_header"
                    data={"Claims:"}
                    textAlignClass={'text_section_left_align'}
                />

                <TableData
                    id={"claims_field"}
                    data={`${claims.data !== null ? activeClaims.length : "N/A"}`}
                />


                <TableDataHeaders
                    id="absconder_header"
                    data={"Absconder:"}
                    textAlignClass={'text_section_left_align'}
                />

                <TableData
                    id={"absconder_field"}
                    data={`${absconders.data !== null ? absconders.data.filter(absconder => absconder.is_closed === false).length : "N/A"}`}
                />

                <TableDataHeaders
                    id="court_records_header"
                    data={"Court Judgements:"}
                    textAlignClass={'text_section_left_align'}
                />

                <TableData
                    id={"court_records_field"}
                    data={`${courts.data !== null ? activeCourts.length : "N/A"}`}
                />

            </tr>

            <tr className="data_row">


                <td style={{ backgroundColor: '#b4c6e7', fontSize: '.9rem' }} className='txt_section_data'>
                    <b>
                        ENQUIRIES
                    </b>
                </td>

                <TableData
                    id={"enquiries_field"}
                    data={`${inquiries.data !== null ? inquiries.data.filter(inq => inq.charge !== '0.00').length : "N/A"}`}
                />
            </tr>


        </table>
    )
}