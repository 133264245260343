import React, { ChangeEvent, useState, useEffect } from 'react'
import { toast, ToastContainer } from 'react-toastify';
import { read, utils, writeFile } from 'xlsx';
import '../../css/bulkupload.css'



const FileUploader = ({ fileName, setVar, setState, dataType, debtorType }) => {
    const [updata, setUpdata] = useState('');

    useEffect(() => {
        const uploader = document.getElementById('fileuploader')
        uploader.value = null
    }, [debtorType, dataType])


    const fileSelected = ($event) => {
        const files = $event.target.files;
        var filedata
        setState('1')

        if (
            files[0].type === "text/csv"
            || files[0].type === "application/vnd.ms-excel"
            || files[0].type === "application/wps-office.xls"
            || files[0].type === "application/wps-office.xlsx"
            || files[0].type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") {
            if (files.length) {
                const file = files[0];
                const reader = new FileReader();
                reader.onload = (event) => {
                    const wb = read(event.target.result);
                    const sheets = wb.SheetNames;
                    if (sheets.length) {
                        const rows = utils.sheet_to_json(wb.Sheets[sheets[0]]);
                        filedata = rows
                    }
                    if (filedata.length === 0) {
                        toast.warning('Uploaded file is Empty')
                        $event.target.value = null
                    }
                    else if (
                        (!filedata[0].NationalID && (fileName === ('Individual Credit Reports' || 'Individuals BIU'))) ||
                        (!filedata[0].RegistrationNumber && (fileName === ('Company Credit Reports' || 'Company Claims'))) ||
                        ((!filedata[0].NationalID || !filedata[0].AccountNumber) && fileName === 'Individual Claims') ||
                        ((!filedata[0].RegistrationNumber || !filedata[0].AccountNumber) && fileName === 'Company Claims') ||
                        ((!filedata[0].IDNumber || !filedata[0].LoanAmount) && fileName === 'Individual Active Accounts') ||
                        ((!filedata[0].IDNumber || !filedata[0].PurchaseAmount) && fileName === 'Individual Hire Purchase') ||
                        ((!filedata[0].RegistrationNumber || !filedata[0].PurchaseAmount) && fileName === 'Company Hire Purchase') ||
                        ((!filedata[0].RegistrationNumber || !filedata[0].LoanAmount) && fileName === 'Company Active Accounts') ||
                        ((!filedata[0].Surname || !filedata[0].NationalID) && fileName === ('Individuals' || 'Individual Credit Reports' || 'Individuals BIU'))
                    ) {
                        toast.error("Wrong file selected or Wrong Headings used!")
                        $event.target.value = null
                    } else {

                        setVar(filedata)
                        toast.warning(
                            'Records highlighted in red contain  missing information so they wont be submitted for processing'
                        )
                    }


                }
                reader.readAsArrayBuffer(file);
            }
        } else {
            toast.error('Invalid file Type. Upload an Excel file or csv file')
            $event.target.value = null
        }
    }

    return (
        <div className='fileuploder'>

            <div className='infosec'>
                <div className='file'>
                    <input
                        id='fileuploader'
                        type='file'
                        onChange={fileSelected}
                    />
                </div>
            </div>
        </div>
    )
}

export default FileUploader
