import React from 'react'
import finlogo from './../../../backgrounds/finlog.png';
import './../../../css/BIUReportHeader.css';
import { TableHeading } from '../../Individuals/IndividualReport/ReportTemplates';


export const BIUReportHeader = ({ name, client }) => {


    return (

        <th className="biu-report-header" colSpan={8} style={{ width: '100%' }}>

            <tr className='biu_logo_header'>

                <div className="fincheck-logo-container">
                    <img src={finlogo} alt="" srcset="" />
                </div>
            </tr>

        </th>
    )
}
