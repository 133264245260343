import React from 'react'
import { TableDataHeaders ,TableData,TableHeading} from '../../Individuals/IndividualReport/ReportTemplates'

export const ActiveCreditAccs = ({activeCreditAccs,isHistorical,color}) => {
  return (
    
    <table className="report_table" style={{marginBottom: isHistorical && '20px'}}>

        <TableHeading
            span={8}
            color={color}
            heading={`${isHistorical ? 'Historic Credit Accounts' : "Active Credit Accounts"}`}
        />

        <tr className="data_row">

            <TableDataHeaders
                id={"creditor_header"}
                data={"Credit"}
            />

            <TableDataHeaders
                id={"account_number_header"}
                data={"Account No.:"}
            />
            
            <TableDataHeaders
                id={"credit_type_header"}
                data={"Credit Type:"}
            />

            <TableDataHeaders
                id={"currency_header"}
                data={"Currency:"}
            />

            <TableDataHeaders
                id={"start_date_header"}
                data={"Start Date:"}
            />

            <TableDataHeaders
                id={"closing_date_header"}
                data={"End Date:"}
            />

            <TableDataHeaders
                id={"principal_amount_header"}
                data={"Principal Amount:"}
            />

            <TableDataHeaders
                id={"installement_amount_header"}
                data={"Installment Amount:"}
            />

            {
                !isHistorical &&
                <TableDataHeaders
                    id={"installement_amount_header"}
                    data={"Overdue:"}
                />
            }


        </tr>

        {
            activeCreditAccs.data !== null &&
            activeCreditAccs.data.map(account => {

                return <ActiveAccount account={account} isHistorical={isHistorical}/>
            })
        }
       
    </table>
  )
}

const ActiveAccount = ({account,isHistorical}) => {

    return (
        <tr className="data_row">

            <TableData
                id={"creditor_field"}
                data={`${account.cr_company_creditor_fins.registration_name}`}
            />

            <TableData
                id={"account_number_field"}
                data={account.account_number ? account.account_number : 'N/A'} 
            />
            
            <TableData
                id={"credit_type_field"}
                data={account.credit_type ? account.credit_type : 'N/A'}
            />

            <TableData
                id={"currency_field"}
                data={account.currency_type ? account.currency_type : 'N/A'}
            />

            <TableData
                id={"start_date_field"}
                data={account.date_disbursed ? account.date_disbursed : account.date}
            />

            <TableData
                id={"closing_date_field"}
                data={account.end_date ? account.end_date : 'N/A'}
            />

            <TableData
                id={"principal_amount_field"}
                data={account.principal_amount ? account.principal_amount : 'N/A'}
            />

            <TableData
                id={"installement_amount_field"}
                data={account.installment_amount ? account.installment_amount : 'N/A'}
            />

            {
                !isHistorical &&

                <TableData
                    id={"installement_amount_field"}
                    data={account.overdue ? account.overdue : 'N/A'}
                />
            }


        </tr>
    )

}
