import React from 'react'
import finlogo from './../backgrounds/finlog.png'
import './../css/Footer.css';

export const Footer = () => {

    const year = new Date().getUTCFullYear()
    return (

        <footer>

            <div className="links-container">

                <div className="company-logo-container">
                    <img src={finlogo} alt="fincheck logo" />
                </div>
                <div className="useful-links">

                    <div className="links-header-container">
                        <h3>useful links</h3>
                    </div>
                    <a href="#faq">Faq</a>
                    <a href="#header">Home</a>
                    <a href="#reviews">reviews</a>
                </div>

                <div className="important-links">
                    <div className="links-header-container">
                        <h3>important links</h3>
                    </div>
                    <a href="/login">login</a>
                    <a href="#our-products">our products</a>
                    <a href="#contact-us">contact us</a>
                </div>
            </div>

            <p className="copyrights">
                Copyright © {year} Fincheck | All rigths reserved
            </p>
        </footer>
    )
}