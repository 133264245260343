import React, {useState,useContext} from 'react';
import './../../css/AdverseUploads.css';
import AddIcon from '@mui/icons-material/Add';
import {HirePurchase} from './HirePurchase';
import AddEditIndividual from '../Individuals/AddEditIndividual';
import { AddEditCompany } from '../Company/AddEditCompany';
import { Modal } from '../Modal';


export const UploadHPI = ({data,setDisplayedForm}) => {

  const [showAddModal,setShowAddModal] = useState(false);
  const [showSingleUpload,setShowSingleUpload] = useState(true);
  const [formToDisplay,setFormToDisplay] = useState("");

  const displayForm = (e) => {
    const form = e.target.innerText
    setFormToDisplay(form);
    setShowAddModal(true);
  }

  const switchTab = (e) => {

    if (e.target.innerText === "Single") {
      setShowSingleUpload(true);
      e.target.classList.add("active")
      document.querySelector(".multiple-btn").classList.remove("active");
    } else {
      setShowSingleUpload(false);
      e.target.classList.add("active")
      document.querySelector(".single-btn").classList.remove("active");
    }

    
  }
    
    return (
    
        <section className="adverse-uploads-container">
    
    
            <div className="adverse-uploads-nav">
    
               <div className="add-individual-company-btn-container">
                 <button className="add-individual-btn" onClick={(e) => displayForm(e)}>
                  <AddIcon/>
                    Add Individual
                 </button>
    
                 <button className="add-company-btn" onClick={(e) => displayForm(e)}>
                  <AddIcon/>
                    Add Company
                 </button>
               </div>
            </div>

            <div className="upload-section-container">
                <AdverseSingleUpload
                showAddModal={showAddModal}
                setShowAddModal={setShowAddModal}
                formToDisplay={formToDisplay}
                data={data}
                setDisplayedForm={setDisplayedForm}
                />
            </div>
    
        </section>
      )
  
}


const AdverseSingleUpload = ({showAddModal,setShowAddModal,formToDisplay,data,setDisplayedForm}) => {

  

    return (
  
      <>     
          
        {
          showAddModal &&
          <Modal
            Component={formToDisplay === "Add Individual" ? AddEditIndividual : AddEditCompany}
            setFormStatus={setShowAddModal}
          />
        }
          
        <HirePurchase 
          hirePurchaseData={data} 
          setDisplayedForm={setDisplayedForm}
        />
  
      </>
    )
  }


