import React from 'react'
import './../css/OurValues.css';

export const OurValues = () => {

    return (

        <div className="our-values-container">

            <h3 className="our-values-header">
                our  values
            </h3>

            <ul className="values">
                <li><span>integrity</span></li>
                <li><span>efficiency</span></li>
                <li> <span>effectiveness</span></li>
                <li><span>Professionalism</span></li>
                <li><span>Excellence</span></li>
            </ul>

        </div>
    )
}