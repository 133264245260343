import axios from "axios";
import { handleResponses } from "./fetchData";

export const chargeCompanyCreditReport = (companyFins, requester, setSearchResult, setScore, token) => {

    const enqDetails = JSON.parse(localStorage.getItem('enq-details'));

    axios.post(`/company-charge-cr-report/`, {

        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
        }
        ,
        data: {
            fins: companyFins,
            user: enqDetails.user,
            current_user: enqDetails.current_user
        }
    })

    .then(res => {
        
        if(res.status >= 200 && res.status <= 299){
           setScore({data: res.data.score})
        }
        handleResponses(res,setSearchResult);
    
    })
    .catch(err => {
        handleResponses(err,setSearchResult);
    })


}

export const chargeIndividualCreditReport = (individualFins, requester, setSearchResult, setScore) => {

    const enqDetails = JSON.parse(localStorage.getItem('enq-details'));
    axios.post(`/individual-charge-cr-report/`, {

        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${JSON.parse(localStorage.getItem("authTokens")).access}`
        },
        data: {
            fins: individualFins,
            user: enqDetails.user,
            current_user: enqDetails.current_user
        }
    })
        .then(res => {

            if (res.status >= 200 && res.status <= 299) {
                setScore({ data: res.data.score })
            }
            handleResponses(res, setSearchResult);

        })
        .catch(err => {
            handleResponses(err, setSearchResult);
        })

}


export const chargeCompanyBIU = (requestID, setSearchResult, setScore) => {


    axios.post(`/single-company-charge-biu/`, {

        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "Authorization": `Bearer ${JSON.parse(localStorage.getItem("authTokens")).access}`
        }
        ,
        data: {
            requestID
        }
    })
        .then(res => {

            if (res.status >= 200 && res.status <= 299) {
                //setScore({data: res.data.score})
            }
            handleResponses(res, setSearchResult);

        })
        .catch(err => {
            handleResponses(err, setSearchResult);
        })

}

export const chargeIndividualBIU = (requestID, setSearchResult, setScore) => {

    axios.post(`/single-individual-charge-biu/`, {

        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "Authorization": `Bearer ${JSON.parse(localStorage.getItem("authTokens")).access}`
        }
        ,
        data: {
            requestID

        }
    })
        .then(res => {

            if (res.status >= 200 && res.status <= 299) {
                //setScore({data: res.data.score})
            }
            handleResponses(res, setSearchResult);

        })
        .catch(err => {
            handleResponses(err, setSearchResult);
        })

}