import React from 'react'
import { TableHeading } from '../../Individuals/IndividualReport/ReportTemplates'

export const Insolvency = ({ company }) => {
  return (

    <table className="report_table">

      <tr className='row_heading' style={{ margin: '50px 0', backgroundColor: '#b4c6e7', color: 'black' }}>
        <td className='txt_rowheading' colSpan={4}>
          <h1 className='section_heading' style={{ fontSize: '1rem' }}>
            Insolvency / Judicial Management
          </h1>
        </td>
      </tr>

      <tr>
        {
          company.is_under_judicial ?
            <td>The company is under insolvency</td> :
            <td></td>
        }
      </tr>


    </table>
  )
}
