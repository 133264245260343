import React from "react";
import RenderService from "./RenderService";

export default function InvoiceTableRow({
  fetchedResults,
  services,
  handleBothClick,
}) {
  // console.log(fetchedResults.bureauEnqUSD.length);
  return (
    <>
      {(() => {
        if (services === "BureauEnqZWL") {
          return (
            <>
              <tr>
                <th
                  colspan="12"
                  style={{
                    paddingBottom: "3px",
                    border: "0px",
                    paddingLeft: "0px",
                  }}
                >
                  <div>
                    <RenderService services={services} />
                  </div>
                </th>
              </tr>
              <tr>
                <th>AFR001</th>
                <th colspan="2">
                  {fetchedResults.bureauEnqZWL[0].individual_data[0].client}
                </th>
                <th>
                  {fetchedResults.bureauEnqZWL[0].individual_data[0].individual}
                </th>
                <th colspan="2">
                  {fetchedResults.bureauEnqZWL[0].individual_data[0].price}
                </th>
                <th>{fetchedResults.bureauEnqZWL[0].individual_data[0].qty}</th>
                <th>
                  {fetchedResults.bureauEnqZWL[0].individual_data[0].charge}
                </th>
                <th>{fetchedResults.bureauEnqZWL[0].individual_data[0].vat}</th>
                <th>
                  {fetchedResults.bureauEnqZWL[0].individual_data[0].total}
                </th>
                <th
                  colspan="2"
                  rowSpan={2}
                  style={{ backgroundColor: "#258db3", color: "#fff" }}
                  onClick={handleBothClick}
                >
                  Run Lists
                </th>
              </tr>
              <tr>
                <th>AFR001</th>
                <th colspan="2">
                  {fetchedResults.bureauEnqZWL[0].company_data[0].client}
                  {/* {fetchedResults.bureauEnqZWL[0].company_data[0].client.length > 0
                    ? fetchedResults.bureauEnqZWL[0].company_data[0].client
                    : "N/A"} */}
                </th>
                <th>
                  {fetchedResults.bureauEnqZWL[0].company_data[0].company}
                </th>
                <th colspan="2">
                  {fetchedResults.bureauEnqZWL[0].company_data[0].price}
                </th>
                <th>{fetchedResults.bureauEnqZWL[0].company_data[0].qty}</th>
                <th>{fetchedResults.bureauEnqZWL[0].company_data[0].charge}</th>
                <th>{fetchedResults.bureauEnqZWL[0].company_data[0].vat}</th>
                <th>{fetchedResults.bureauEnqZWL[0].company_data[0].total}</th>
                {/* <th colspan="2" style={{ backgroundColor: "#258db3", color: "#fff" }} onClick={runLists}>
    Run Lists
  </th> */}
              </tr>
              <tr>
                <th style={{ border: "0px", paddingLeft: "0px" }}></th>
                <th
                  colspan="2"
                  style={{
                    paddingLeft: "3px",
                    border: "0px",
                    paddingLeft: "0px",
                  }}
                >
                  TOTAL
                </th>
                <th style={{ border: "0px", paddingLeft: "0px" }}></th>
                <th colspan="2" style={{ backgroundColor: "#bdc8d1" }}>
                  {Number(
                    fetchedResults.bureauEnqZWL[0].individual_data[0].price
                  ) +
                    Number(
                      fetchedResults.bureauEnqZWL[0].company_data[0].price
                    )}
                </th>
                <th style={{ backgroundColor: "#bdc8d1" }}>
                  {Number(
                    fetchedResults.bureauEnqZWL[0].individual_data[0].qty
                  ) +
                    Number(fetchedResults.bureauEnqZWL[0].company_data[0].qty)}
                </th>
                <th style={{ backgroundColor: "#bdc8d1" }}>
                  {Number(
                    fetchedResults.bureauEnqZWL[0].individual_data[0].charge
                  ) +
                    Number(
                      fetchedResults.bureauEnqZWL[0].company_data[0].charge
                    )}
                </th>
                <th style={{ backgroundColor: "#bdc8d1" }}>
                  {Number(
                    fetchedResults.bureauEnqZWL[0].individual_data[0].vat
                  ) +
                    Number(fetchedResults.bureauEnqZWL[0].company_data[0].vat)}
                </th>
                <th style={{ backgroundColor: "#bdc8d1" }}>
                  {Number(
                    fetchedResults.bureauEnqZWL[0].individual_data[0].total
                  ) +
                    Number(
                      fetchedResults.bureauEnqZWL[0].company_data[0].total
                    )}
                </th>
                <th
                  colspan="2"
                  style={{ backgroundColor: "#0d475c", color: "#fff" }}
                >
                  All Bureau ZWL Lists
                </th>
              </tr>
            </>
          );
        }
        if (services === "RescissionZWL") {
          return (
            <>
              <tr>
                <th
                  colspan="12"
                  style={{
                    paddingBottom: "3px",
                    border: "0px",
                    paddingLeft: "0px",
                  }}
                >
                  <div>
                    <h5>Rescissions USD</h5>
                  </div>
                </th>
              </tr>
              <tr>
                <th colspan="3" style={{ textAlign: "center" }}>
                  Company
                </th>
                <th>C</th>
                <th colspan="2">{fetchedResults.rescissionZWL[0].company_data[0].price}</th>
                <th>{fetchedResults.rescissionZWL[0].company_data[0].qty}</th>
                <th>{fetchedResults.rescissionZWL[0].company_data[0].charge}</th>
                <th>{fetchedResults.rescissionZWL[0].company_data[0].vat}</th>
                <th>{fetchedResults.rescissionZWL[0].company_data[0].total}</th>
                <th
                  colspan="2"
                  style={{ backgroundColor: "#258db3", color: "#fff" }}
                >
                  Run Lists
                </th>
              </tr>
              <tr>
                <th colspan="3" style={{ textAlign: "center" }}>
                  Individual
                </th>
                <th>I</th>
                <th colspan="2">{fetchedResults.rescissionZWL[0].individual_data[0].price}</th>
                <th>{fetchedResults.rescissionZWL[0].individual_data[0].qty}</th>
                <th>{fetchedResults.rescissionZWL[0].individual_data[0].charge}</th>
                <th>{fetchedResults.rescissionZWL[0].individual_data[0].vat}</th>
                <th>{fetchedResults.rescissionZWL[0].individual_data[0].total}</th>
                <th
                  colspan="2"
                  style={{ backgroundColor: "#258db3", color: "#fff" }}
                >
                  Run Lists
                </th>
              </tr>
              <tr>
                <th style={{ border: "0px", paddingLeft: "0px" }}></th>
                <th
                  colspan="2"
                  style={{
                    paddingLeft: "3px",
                    border: "0px",
                    paddingLeft: "0px",
                  }}
                ></th>
                <th style={{ border: "0px", paddingLeft: "0px" }}></th>
                <th colspan="2" style={{ border: "0px", paddingLeft: "0px" }}>
                
                </th>
                <th style={{ backgroundColor: "#bdc8d1" }}>
                {Number(
                    fetchedResults.rescissionZWL[0].individual_data[0].qty
                  ) +
                    Number(fetchedResults.rescissionZWL[0].company_data[0].qty)}
                </th>
                <th style={{ backgroundColor: "#bdc8d1" }}>
                {Number(
                    fetchedResults.rescissionZWL[0].individual_data[0].charge
                  ) +
                    Number(
                      fetchedResults.rescissionZWL[0].company_data[0].charge
                    )}
                </th>
                <th style={{ backgroundColor: "#bdc8d1" }}>
                {Number(
                    fetchedResults.rescissionZWL[0].individual_data[0].vat
                  ) +
                    Number(fetchedResults.rescissionZWL[0].company_data[0].vat)}
                </th>
                <th style={{ backgroundColor: "#bdc8d1" }}>
                {Number(
                    fetchedResults.rescissionZWL[0].individual_data[0].total
                  ) +
                    Number(
                      fetchedResults.rescissionZWL[0].company_data[0].total
                    )}
                </th>
                <th
                  colspan="2"
                  style={{ backgroundColor: "#0d475c", color: "#fff" }}
                  onClick={handleBothClick}
                >
                  All Rescission USD Lists
                </th>
              </tr>
            </>
          );
        }
        if (services === "BureauEnqUSD"){
          return (
            <>
              <tr>
                <th
                  colspan="12"
                  style={{
                    paddingBottom: "3px",
                    border: "0px",
                    paddingLeft: "0px",
                  }}
                >
                  <div>
                    <RenderService services={services} />
                  </div>
                </th>
              </tr>
              <tr>
                <th>AFR001</th>
                <th colspan="2">
                  {fetchedResults.bureauEnqUSD[0].individual_data[0].client}
                </th>
                <th>
                  {fetchedResults.bureauEnqUSD[0].individual_data[0].individual}
                </th>
                <th colspan="2">
                  {fetchedResults.bureauEnqUSD[0].individual_data[0].price}
                </th>
                <th>{fetchedResults.bureauEnqUSD[0].individual_data[0].qty}</th>
                <th>
                  {fetchedResults.bureauEnqUSD[0].individual_data[0].charge}
                </th>
                <th>{fetchedResults.bureauEnqUSD[0].individual_data[0].vat}</th>
                <th>
                  {fetchedResults.bureauEnqUSD[0].individual_data[0].total}
                </th>
                <th
                  colspan="2"
                  rowSpan={2}
                  style={{ backgroundColor: "#258db3", color: "#fff" }}
                  onClick={handleBothClick}
                >
                  Run Lists
                </th>
              </tr>
              <tr>
                <th>AFR001</th>
                <th colspan="2">
                  {fetchedResults.bureauEnqUSD[0].company_data[0].client}
                </th>
                <th>
                  {fetchedResults.bureauEnqUSD[0].company_data[0].company}
                </th>
                <th colspan="2">
                  {fetchedResults.bureauEnqUSD[0].company_data[0].price}
                </th>
                <th>{fetchedResults.bureauEnqUSD[0].company_data[0].qty}</th>
                <th>{fetchedResults.bureauEnqUSD[0].company_data[0].charge}</th>
                <th>{fetchedResults.bureauEnqUSD[0].company_data[0].vat}</th>
                <th>{fetchedResults.bureauEnqUSD[0].company_data[0].total}</th>
                {/* <th colspan="2" style={{ backgroundColor: "#258db3", color: "#fff" }} onClick={runLists}>
    Run Lists
  </th> */}
              </tr>
              <tr>
                <th style={{ border: "0px", paddingLeft: "0px" }}></th>
                <th
                  colspan="2"
                  style={{
                    paddingLeft: "3px",
                    border: "0px",
                    paddingLeft: "0px",
                  }}
                >
                  TOTAL
                </th>
                <th style={{ border: "0px", paddingLeft: "0px" }}></th>
                <th colspan="2" style={{ backgroundColor: "#bdc8d1" }}>
                  {Number(
                    fetchedResults.bureauEnqUSD[0].individual_data[0].price
                  ) +
                    Number(
                      fetchedResults.bureauEnqUSD[0].company_data[0].price
                    )}
                </th>
                <th style={{ backgroundColor: "#bdc8d1" }}>
                  {Number(
                    fetchedResults.bureauEnqUSD[0].individual_data[0].qty
                  ) +
                    Number(fetchedResults.bureauEnqUSD[0].company_data[0].qty)}
                </th>
                <th style={{ backgroundColor: "#bdc8d1" }}>
                  {Number(
                    fetchedResults.bureauEnqUSD[0].individual_data[0].charge
                  ) +
                    Number(
                      fetchedResults.bureauEnqUSD[0].company_data[0].charge
                    )}
                </th>
                <th style={{ backgroundColor: "#bdc8d1" }}>
                  {Number(
                    fetchedResults.bureauEnqUSD[0].individual_data[0].vat
                  ) +
                    Number(fetchedResults.bureauEnqUSD[0].company_data[0].vat)}
                </th>
                <th style={{ backgroundColor: "#bdc8d1" }}>
                  {Number(
                    fetchedResults.bureauEnqUSD[0].individual_data[0].total
                  ) +
                    Number(
                      fetchedResults.bureauEnqUSD[0].company_data[0].total
                    )}
                </th>
                <th
                  colspan="2"
                  style={{ backgroundColor: "#0d475c", color: "#fff" }}
                >
                  All Bureau USD Lists
                </th>
              </tr>
            </>
          );
        }
        if (services === "HirePurZWL")
          return (
            <>
              <tr>
                <th
                  colspan="12"
                  style={{
                    paddingBottom: "3px",
                    border: "0px",
                    paddingLeft: "0px",
                  }}
                >
                  <div>
                    <RenderService services={services} />
                  </div>
                </th>
              </tr>
              <tr>
                <th>AFR001</th>
                <th colspan="2">
                  {fetchedResults.hirePurZWL[0].individual_data[0].client}
                </th>
                <th>
                  {fetchedResults.hirePurZWL[0].individual_data[0].individual}
                </th>
                <th colspan="2">
                  {fetchedResults.hirePurZWL[0].individual_data[0].price}
                </th>
                <th>{fetchedResults.hirePurZWL[0].individual_data[0].qty}</th>
                <th>
                  {fetchedResults.hirePurZWL[0].individual_data[0].charge}
                </th>
                <th>{fetchedResults.hirePurZWL[0].individual_data[0].vat}</th>
                <th>
                  {fetchedResults.hirePurZWL[0].individual_data[0].total}
                </th>
                <th
                  colspan="2"
                  rowSpan={2}
                  style={{ backgroundColor: "#258db3", color: "#fff" }}
                  onClick={handleBothClick}
                >
                  Run Lists
                </th>
              </tr>
              <tr>
                <th>AFR001</th>
                <th colspan="2">
                  {fetchedResults.hirePurZWL[0].company_data[0].client}
                </th>
                <th>
                  {fetchedResults.hirePurZWL[0].company_data[0].company}
                </th>
                <th colspan="2">
                  {fetchedResults.hirePurZWL[0].company_data[0].price}
                </th>
                <th>{fetchedResults.hirePurZWL[0].company_data[0].qty}</th>
                <th>{fetchedResults.hirePurZWL[0].company_data[0].charge}</th>
                <th>{fetchedResults.hirePurZWL[0].company_data[0].vat}</th>
                <th>{fetchedResults.hirePurZWL[0].company_data[0].total}</th>
                {/* <th colspan="2" style={{ backgroundColor: "#258db3", color: "#fff" }} onClick={runLists}>
    Run Lists
  </th> */}
              </tr>
              <tr>
                <th style={{ border: "0px", paddingLeft: "0px" }}></th>
                <th
                  colspan="2"
                  style={{
                    paddingLeft: "3px",
                    border: "0px",
                    paddingLeft: "0px",
                  }}
                >
                  TOTAL
                </th>
                <th style={{ border: "0px", paddingLeft: "0px" }}></th>
                <th colspan="2" style={{ backgroundColor: "#bdc8d1" }}>
                  {Number(
                    fetchedResults.hirePurZWL[0].individual_data[0].price
                  ) +
                    Number(
                      fetchedResults.hirePurZWL[0].company_data[0].price
                    )}
                </th>
                <th style={{ backgroundColor: "#bdc8d1" }}>
                  {Number(
                    fetchedResults.hirePurZWL[0].individual_data[0].qty
                  ) +
                    Number(fetchedResults.hirePurZWL[0].company_data[0].qty)}
                </th>
                <th style={{ backgroundColor: "#bdc8d1" }}>
                  {Number(
                    fetchedResults.hirePurZWL[0].individual_data[0].charge
                  ) +
                    Number(
                      fetchedResults.hirePurZWL[0].company_data[0].charge
                    )}
                </th>
                <th style={{ backgroundColor: "#bdc8d1" }}>
                  {Number(
                    fetchedResults.hirePurZWL[0].individual_data[0].vat
                  ) +
                    Number(fetchedResults.hirePurZWL[0].company_data[0].vat)}
                </th>
                <th style={{ backgroundColor: "#bdc8d1" }}>
                  {Number(
                    fetchedResults.hirePurZWL[0].individual_data[0].total
                  ) +
                    Number(
                      fetchedResults.hirePurZWL[0].company_data[0].total
                    )}
                </th>
                <th
                  colspan="2"
                  style={{ backgroundColor: "#0d475c", color: "#fff" }}
                >
                  All HPI ZWL Lists
                </th>
              </tr>
            </>
          );
        else return <span>No data</span>;
      })()}
    </>
  );
}
