import React from "react";
import Directorships from "../../Individuals/IndividualReport/Directorships";
import { Principals } from "./Principals";

export const OwnershipAndOversight = ({ shareholders, directorships, companyCapital }) => {

    return (
        <table border={1} style={{ marginTop: '1em', borderCollapse: 'collapse', width: '100%', padding: '.5em 0' }}>
            <tr className='row_heading' style={{ margin: '50px 0', backgroundColor: '#4472c4', color: 'white' }}>
                <td className='txt_rowheading' colSpan={4}>
                    <h1 className='section_heading' style={{ fontSize: '1.2rem' }}>
                        OWNERSHIP & OVERSIGHT
                    </h1>
                </td>
            </tr>
            <tr className='row_heading' style={{ margin: '50px 0', backgroundColor: '#b4c6e7', color: 'black' }}>
                <td className='txt_rowheading' colSpan={4}>
                    <h1 className='section_heading' style={{ fontSize: '1rem' }}>
                        Shareholding
                    </h1>
                </td>
            </tr>

            <br />

            <tr>
                <th style={{ textAlign: 'left', fontWeight: '400' }}>Authorised Capital:</th>
                <td>{(companyCapital.data === null || companyCapital.data.length === 0) ? 'N/A' : companyCapital.data[0].auth}</td>
                <th style={{ textAlign: 'left', fontWeight: '400' }}>Paid Up Capital:</th>
                <td>{(companyCapital.data === null || companyCapital.data.length === 0) ? 'N/A' : companyCapital.data[0].paid_up}</td>
            </tr>

            <br />

            <tr style={{ backgroundColor: 'lightgrey' }}>
                <th>Shareholders:</th>
                <th></th>
                <th colSpan={2}>Percentage Shares:</th>
            </tr>


            {
                shareholders.data !== null &&
                shareholders.data.map(shareholder => {
                    return (
                        <tr key={shareholder.id}>
                            <td>{shareholder.name}</td>
                            <td></td>
                            <td colSpan={2}>{shareholder.percentage}</td>
                        </tr>
                    )
                })
            }


            <br />
            <tr>
                <td colSpan={4}><Principals directorships={directorships} /></td>
            </tr>
        </table>
    )
}