import React from 'react'
import { TableData, TableDataHeaders } from '../../Individuals/IndividualReport/ReportTemplates'
import { calculateOverdueMonths, getDate } from '../../../js/getDate'

const BIUAbsconder = ({ absconders, color, enqDetails }) => {
    return (
        <table className='report_table'>
            <tr className='row_heading' style={{ margin: '50px 0', backgroundColor: '#b4c6e7', color: 'black' }}>
                <td className='txt_rowheading' colSpan={8}>
                    <h1 className='section_heading' style={{ fontSize: '1rem' }}>
                        Absconder
                    </h1>
                </td>
            </tr>

            <tr className='data_row' >
                <TableDataHeaders
                    id={"count_header"}
                    data={""}
                />

                <TableDataHeaders
                    id={"creditor_header"}
                    data={"Creditor"}
                />

                {/*  <TableDataHeaders
                    id={"creditType_header"}
                    data={"Credit Type"}
                /> */}

                <TableDataHeaders
                    id={"currency_header"}
                    data={"Currency"}
                />

                <TableDataHeaders
                    id={"principle_amount_header"}
                    data={"Amount"}
                />

                <TableDataHeaders
                    id={"startDate_header"}
                    data={"Start Date"}
                />



                {/* <TableDataHeaders
                    id={"installment_amount_header"}
                    data={"Installment Amount"}
                />

                <TableDataHeaders
                    id={"overdue_amount_header"}
                    data={"Overdue Amount"}
                />

                <TableDataHeaders
                    id={"months_in_arrears_header"}
                    data={"Months In Arrears"}
                />*/}
            </tr>



            {
                (absconders.data !== null && absconders.data.filter(absconder => absconder.is_closed === false).length === 0) &&
                <tr className='data_row'>
                    <td colSpan={10} style={{ color: 'green' }}></td>
                </tr>
            }


            {//mappping function
                absconders.data !== null &&
                absconders.data.sort((a, b) => a.is_closed - b.is_closed).map((absconder, index) => {

                    if (absconder.is_closed === false) {
                        return <Absc absconder={absconder} index={index} />
                    }

                    /*  if (absconder.is_closed === true && enqDetails.enqType === "internal") {
 
                         return (
                             <>
                                 <Absc absconder={absconder} index={index} />
                                 <tr className="closed-claim-details" style={{ background: "rgba(7, 193, 48, 0.26)", textAlign: "center" }}>
                                     <td colSpan={4} style={{ color: 'green' }}>{`Cleared - ${absconder.comment}`}</td>
                                     <td>{getDate(absconder.date_closed)}</td>
                                 </tr>
                             </>
                         )
                     } */

                })
            }


        </table>
    )
}

const Absc = ({ absconder, index }) => {
    const color = absconder.is_closed ? 'rgba(7, 193, 48, 0.26)' : '';
    return (

        <>
            <tr className='data_row' style={{ backgroundColor: color }}>
                <TableData
                    id={"count_field"}
                    data={index + 1}
                />

                <TableData
                    id={'creditor_field'}
                    data={absconder.company_creditor_fins ? absconder.company_creditor_fins.registration_name : absconder.individual_creditor_fins ? absconder.individual_creditor_fins.firstname : "N/A"}
                />
                {/* 
                <TableData
                    id={'credit_type_field'}
                    data={absconder.company_creditor_fins ? "Company" : "Individual"}
                /> */}

                <TableData
                    id={'currency_field'}
                    data={absconder.currency_type}
                />

                <TableData
                    id={'principle_amount_field'}
                    data={absconder.amount ? absconder.amount : "N/A"}
                />

                <TableData
                    id={'start_date_field'}
                    data={getDate(absconder.date_of_claim)}
                />




                {/* <TableData
                    id={'installment_amount_field'}
                    data={absconder.installement_amount ? absconder.installement_amount : "N/A"}
                />

                <TableData
                    id={'overdue_amount_field'}
                    data={absconder.overdue_balance ? absconder.overdue_balance : "N/A"}
                />

                <TableData
                    id={'month_in_arrears_field'}
                    data={calculateOverdueMonths(absconder.date_of_claim)}
                /> */}
            </tr>
        </>
    )
}

export default BIUAbsconder