export const slt_gender = [
    {option : "Male"},
    {option : "Female"},
]

export const slt_IdentificationType = [
    {option : "Passport"},
    {option : "National ID"},
    {option : "Service ID"},
    {option : "Alien Registration"},
]

export const slt_MaritalStatus = [
    {option : "Single"},
    {option : "Married"},
    {option : "Divorced"},
    {option : "Widow"},
    {option : "Widower"},
]

export const slt_AccesLevel = [
    {option : "Admin"},
    {option : "User"},
]