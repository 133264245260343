import React from 'react'
import '../../../css/clientsarena.css'

const EditClientNav = ({ displayform, setdisplayForm }) => {
    return (
        <div className='navigationaddclient'>
            <button onClick={() => setdisplayForm('client')} className={displayform === 'client' ?
                'addeditnavbuttonactive'
                :
                'addeditnavbutton'
            }>
                <h3>
                    Client
                </h3>
            </button>

            <button onClick={() => setdisplayForm('adminUser')} className={
                (displayform === 'addUser' || displayform === 'adminUser') ?
                    'addeditnavbuttonactive'
                    :
                    'addeditnavbutton'
            }>
                <h3>
                    Client Users
                </h3>
            </button>

        </div>
    )
}

export default EditClientNav