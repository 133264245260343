import React from 'react'
import { TableData, TableDataHeaders, TableHeading } from '../../Individuals/IndividualReport/ReportTemplates'

export const BUIDeeds = ({ deeds, color }) => {
    console.log(deeds)
    return (

        <table className="report_table">

            <tr className='row_heading' style={{ margin: '50px 0', backgroundColor: '#b4c6e7', color: 'black' }}>
                <td className='txt_rowheading' colSpan={4}>
                    <h1 className='section_heading' style={{ fontSize: '1rem' }}>
                        Properties / Deeds
                    </h1>
                </td>
            </tr>

            {/*  <tr className="data_row">

                <TableDataHeaders
                    data={"Owner"}
                />

                <TableDataHeaders
                    data={"Amount"}
                />

                <TableDataHeaders
                    data={"Currency Type"}
                />

                <TableDataHeaders
                    data={"Address"}
                />
            </tr> */}
            {
                (deeds.data !== null && deeds.data.length === 0) &&
                <tr className='data_row'>
                    NOT SUPPLIED
                </tr>
            }

            {
                deeds.data !== null &&
                deeds.data.map((deed, index) => {

                    return (

                        <tr key={index} className="data_row">

                            <tr>
                                <td colSpan={7}>{deed.address}</td>
                            </tr>


                        </tr>
                    )
                })
            }
        </table>
    )
}
