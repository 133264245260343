import React, { useContext, useState } from 'react'
import '../../../css/Search.css'
import { InputContainer, InputphoneContainer, SelectContainer } from '../../InputContainer'
import SearchIcon from '@mui/icons-material/Search'
import SelectIcon from '@mui/icons-material/Adjust'
import axios from 'axios'
import { toast, ToastContainer } from 'react-toastify'
import AuthContext from '../../../AuthContext/AuthContext'
import { CircularProgress } from '@mui/material'
import AddModal from './AddModal'

const Search = ({ setdisplay, setname, searching, setFins, setid }) => {
    let { authTokens, logoutUser } = useContext(AuthContext);
    const [searchValue, setSearchValue] = useState('')
    const [type, setType] = useState('')
    const [fetchData, setFetchData] = useState('no')
    const [searchData, setSearchData] = useState([])
    const [displayadd, setDisplayAdd] = useState(false)
   
    const searchInfo = (e) => {
        e.preventDefault()
        if (searchValue === '' ) {
            toast.warning('Please provide search value')
        } else {

            setFetchData('fetching')
            var url = ''

            if (searching === 'Individual') {

                url = '/search-fullname-national-id-individual/'

            } else {
                url = '/search-company/'
            }
            axios(
                {
                    method: 'post',
                    url: url,
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        Authorization: "Bearer " + String(authTokens.access)
                    },
                    data: { data: { search: searchValue } }
                }
            ).then((res) => {
                if (res.data.length === 0) {
                    setFetchData('nodata')
                } else {
                    setFetchData('fetched')
                    setSearchData(res.data)
                }
            }).catch((err) => {
                setFetchData('error')
                toast.error('Internal system error !!')
            }
            )
        }
    }
    return (
        <div className='searchMainDiv'>
            <AddModal
                display={displayadd}
                setDisplay={setDisplayAdd}
                add={searching}
            />
            <h4 className="sectionHeadinghpilessee">Search : {searching} </h4>
            <div className='search-text'>

                <InputphoneContainer
                    label={
                        searching === 'Individual' ?
                            "Search by name/  nationalId"
                            :
                            'Search by Reg Number / company name'}
                    type={"text"}
                    placeHolder={''}
                    currentValue={searchValue}
                    setValue={setSearchValue}
                    id={'search_value'}
                />

                <div className='search-button-div'>
                    <button className='search-search-button ' onClick={(e) => { searchInfo(e) }}>
                        <SearchIcon />
                        <h3>Search</h3>
                    </button>
                </div>
            </div>
            <div className='search-table-div'>
                {(searchData.length == 0 && fetchData === 'no') &&
                    <div className='search-nodata'>
                        <h1>No data available</h1>
                    </div>
                }

                {fetchData === 'fetching' &&
                    <div className='search-progress'>
                        <CircularProgress />
                    </div>

                }

                {fetchData === 'nodata' &&
                    <div className='search-progress'>
                        <h1 className='search-norecord'>Record Not Found !</h1>
                        <h1 onClick={() => { setDisplayAdd(!displayadd) }} className='search-norecord-add'>Click to add</h1>
                    </div>
                }

                {fetchData === 'error' &&
                    <h1 className='search-error'>Error !</h1>
                }

                {(searching === 'Individual' && searchData.length != 0 && fetchData === 'fetched') &&
                    <table className='search-table'>
                        <thead className='search-table-header'>
                            <tr>
                                <th>Id Number</th>
                                <th>Surname</th>
                                <th>Name</th>
                                <th>Phone Number</th>
                                <th>Select</th>
                            </tr>
                        </thead>
                        <tbody>
                            {searchData.map((item) => {
                                return (
                                    <tr className='search-table-datarow' key={item.fins_number}>
                                        <td className='search-table-data'>{item.national_id}</td>
                                        <td className='search-table-data'>{item.surname}</td>
                                        <td className='search-table-data'>{item.firstname}</td>
                                        <td className='search-table-data'>{item.telephone_no}</td>
                                        <td className='search-table-data'>
                                            <SelectIcon
                                                className='search-select-icon'
                                                onClick={
                                                    () => {
                                                        setname(item.firstname + ' ' + item.surname);
                                                        setFins(item.fins_number);
                                                        setid(item.national_id);
                                                        if (setdisplay) {
                                                            setdisplay(false)
                                                        }
                                                    }
                                                }
                                            />
                                        </td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                }
                {(searching === 'Company' && searchData.length != 0 && fetchData === 'fetched') &&
                    <table className='search-table'>
                        <thead className='search-table-header'>
                            <tr>
                                <th>Registration Number</th>
                                <th>Registered Name</th>
                                <th>Trading Name</th>
                                <th>Contact Number</th>
                                <th>Select</th>
                            </tr>
                        </thead>
                        <tbody>
                            {searchData.map((item) => {
                                return (
                                    <tr className='search-table-datarow' key={item.fins_number}>
                                        <td className='search-table-data'>{item.registration_number}</td>
                                        <td className='search-table-data'>{item.registration_name}</td>
                                        <td className='search-table-data'>{item.trading_name}</td>
                                        <td className='search-table-data'>{item.mobile_phone}</td>
                                        <td className='search-table-data'>
                                            <SelectIcon className='search-select-icon' onClick={() => {
                                                setname(item.registration_name);
                                                setFins(item.fins_number);
                                                setid(item.registration_number)
                                            }} />
                                        </td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                }
            </div>
        </div>
    )
}

export default Search
