import React, { useEffect } from "react";
import "./../css/ClearanceLetter.css";
import finlogo from "./../backgrounds/finlog.png";
import { getDate } from "../js/getDate";
import { toast } from "react-toastify";

export const ClearanceLetter = () => {
  const { cleared, userData } = JSON.parse(
    localStorage.getItem("clearanceDetails")
  );
  console.log(userData);
  const firstname = cleared.firstname;

  const printLetter = () => {
    if (firstname === undefined) {
      if (
        cleared.registration_name === null ||
        ((cleared.registration_name === "") === cleared.registration_number) ===
          null ||
        cleared.registration_number === ""
      ) {
        toast.error(
          "Cannot print this letter because some information is missing"
        );
        return;
      }
    }

    if (firstname !== undefined) {
      if (firstname === null || firstname === "") {
        toast.error("Cannot print this letter because firstname is missing");
        return;
      }

      if (cleared.surname === null || cleared.surname === "") {
        toast.error("Cannot print this letter because surname is missing");
        return;
      }
    }

    window.print();
  };

  return (
    <div
      className="clearance-letter"
      lang="en-ZW"
      link="#000080"
      vlink="#800000"
      dir="ltr">
      <p lang="en-GB" align="center" className="blank-line-top">
        <br />
      </p>
      <p
        style={{
          lineHeight: "100%",
          textIndent: "2.58cm",
          marginBottom: "0cm",
        }}>
        <img
          align="center"
          src={finlogo}
          onLoad={() => printLetter()}
          name="Picture 1"
          width="436"
          height="135"
          border="0"
        />
      </p>
      <p
        align="center"
        style={{
          textIndent: "3.2cm",
          lineHeight: "100%",
          marginBottom: "0cm",
        }}>
        <font size="2" style={{ fontSize: "9pt" }}>
          8
        </font>
        <sup>
          <font size="2" style={{ fontSize: "9pt" }}>
            th
          </font>
        </sup>
        <font size="2" style={{ fontSize: "9pt" }}>
          Floor, Club Chambers, Cnr Nelson Mandela Avenue/3
        </font>
        <sup>
          <font size="2" style={{ fontSize: "9pt" }}>
            rd
          </font>
        </sup>
        <font size="2" style={{ fontSize: "9pt" }}>
          {" "}
          Street, Harare
        </font>
      </p>
      <p align="center" style={{ lineHeight: "100%", textIndent: "3.2cm", marginBottom: "0cm" }}>
        <font size="2" style={{ fontSize: "9pt" }}>
          Email: enquiries@fincheckzim.com; Tel. No: 0242-794488;
          Mobile:0715239711
        </font>
      </p>
      <p lang="en-US" class="western" style={{ marginBottom: "0.35cm" }}></p>
      <p lang="en-US" class="western" style={{ marginBottom: "0.35cm" }}></p>
      <br />
      <p
        lang="en-US"
        class="western"
        align="justify"
        style={{ marginBottom: "0.35cm" }}>
        <font face="Arial Narrow, serif">{getDate(new Date())}  </font>
      </p>
      <p
        lang="en-US"
        class="western"
        align="justify"
        style={{ marginBottom: "0.35cm" }}>
        <br />
        <br />
      </p>
      <h2 lang="en-US" class="western" align="justify">
        <font face="Arial Narrow, serif">
          <span style={{ fontStyle: "normal" }}>
            <u>TO WHOM IT MAY CONCERN</u>
          </span>
        </font>
      </h2>
      <p
        lang="en-US"
        class="western"
        align="justify"
        style={{ marginBottom: "0.35cm" }}>
        <br />
        <br />
      </p>
      <p
        lang="en-US"
        class="western"
        align="justify"
        style={{ marginBottom: "0.35cm" }}>
        <a name="_GoBack"></a>
        <font face="Arial Narrow, serif">
          This letter serves to confirm that{" "}
        </font>
        <font face="Arial Narrow, serif">
          <b>{firstname ? `${cleared.firstname.toUpperCase()} ${cleared.forenames !== null ? cleared.forenames.toUpperCase() : ''} ${cleared.surname.toUpperCase()}` :  `${cleared.registration_name.toUpperCase()}`} </b>
        </font>
        <font face="Arial Narrow, serif">with</font>
        <font face="Arial Narrow, serif">
          <b> ID Number {cleared.national_id ? cleared.national_id : ' '  } </b>
        </font>
        <font face="Arial Narrow, serif">
          is clear of any previous record to date in our files.
        </font>
        <font face="Arial Narrow, serif">
          <b> </b>
        </font>
      </p>
      <p
        align="justify"
        style={{ lineHeight: "100%", marginBottom: "0cm" }}></p>
      <p
        lang="en-US"
        class="western"
        align="justify"
        style={{ marginBottom: "0.35cm" }}>
        <font face="Arial Narrow, serif">
          Any further queries concerning his/her name should be forwarded to the
          undersigned.
        </font>
      </p>
      <p
        lang="en-US"
        class="western"
        align="justify"
        style={{ marginBottom: "0.35cm" }}>
        <br />
        <br />
      </p>
      <p
        lang="en-US"
        class="western"
        align="justify"
        style={{ marginBottom: "0.35cm" }}>
        <br />
        <br />
      </p>
      <p
        lang="en-US"
        class="western"
        align="justify"
        style={{ marginBottom: "0.35cm" }}>
        <font face="Arial Narrow, serif">Yours faithfully</font>
      </p>
      <p
        lang="en-US"
        class="western"
        align="justify"
        style={{ marginBottom: "0.35cm" }}>
        <br />
        <br />
      </p>
      <p
        lang="en-US"
        class="western"
        align="justify"
        style={{ marginBottom: "0.35cm" }}>
        <br />
        <br />
      </p>
      <p
        lang="en-US"
        class="western"
        align="justify"
        style={{ marginBottom: "0.35cm" }}>
        <br />
        <br />
      </p>
      <p
        lang="en-US"
        class="western"
        align="justify"
        style={{ marginBottom: "0.35cm" }}>
        <font face="Arial Narrow, serif">{`${userData.firstname.toUpperCase()} ${userData.lastname.toUpperCase()}`}</font>
      </p>
      <p
        lang="en-US"
        align="justify"
        style={{ lineHeight: "100%", marginBottom: "0.11cm" }}>
        <font face="Cambria, serif">
          <font size="3" style={{ fontSize: "12pt" }}>
            <font face="Arial Narrow, serif">
              <b>Administrator</b>
            </font>
          </font>
        </font>
      </p>
      <p
        lang="en-US"
        class="western"
        align="justify"
        style={{ marginBottom: "0.35cm" }}>
        <br />
        <br />
      </p>
      <p
        lang="en-US"
        class="western"
        align="justify"
        style={{ marginBottom: "0.35cm" }}></p>
      <p lang="en-US" class="western" style={{ marginBottom: "0.35cm" }}>
        <br />
        <br />
      </p>
    </div>
  );
};
