import React from 'react'
import { TableHeading } from '../IndividualReport/ReportTemplates'

export const DynamicRating = () => {
  return (
    
    <table border={1} className="dynamic_rating" style={{width: "100%", marginTop: "20px"}}>

        <TableHeading
            heading={"Dynamic Rating"}
            span={10}
        />

        <tr>

            <td>RATING</td>
            <td>PROBABILITY OF FAILURE</td>
            <td>GUIDE TO INTERPRETATION</td>
        </tr>

        <tr>
            <td>1</td>
            <td>Minimal risk</td>
            <td>Proceed with transaction-offer terms required</td>
        </tr>

        <tr>
            <td>2</td>
            <td>Low risk</td>
            <td>Proceed with transaction</td>
        </tr>
        
        <tr>
            <td>3</td>
            <td>Greater than average risk</td>
            <td>Proceed with transaction but monitor closely</td>
        </tr>

        <tr>
            <td>4</td>
            <td>Significant level of risk</td>
            <td>Take suitable assurances before extending credit</td>
        </tr>

        <tr>
            <td>NQ</td>
            <td>Out Of business</td>
            <td>No public information available to indicate trading activity</td>
        </tr>
    </table>
  )
}
