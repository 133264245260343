import React, { useState, useEffect } from "react";
import axios from "axios";
import PDFTaxInvoice from "./PDFTaxInvoice";

import Box from '@mui/material/Box';

export default function FiscalTaxInvoice(props) {
  const [isClicked, setIsClicked] = useState(false);
  const [data, setData] = useState(null);
  const url = window.location.href
  const searchParams = new URLSearchParams(url.split('?')[1]);
    // Access individual parameters
  const client = searchParams.get('client');
  const service = searchParams.get('service');
  const fromDate = searchParams.get('from');
  const toDate = searchParams.get('to');


  useEffect(() => {
    const fetchData = async () => {
      axios
      .post(`/client-fiscal-tax-invoice`,{
        client: searchParams.get('client'),
        service: searchParams.get('service'),
        fromDate: searchParams.get('from'),
        toDate: searchParams.get('to')
      })
      .then((response) => {
        if (response.status == 200) {
          setData(response.data);
        } else {
          console.log("Error occured");
        }
      });
    };

    fetchData();
  }, [client,service, fromDate, toDate]);
  // console.log(data)


  return (
    // <PDFTaxInvoice/>
    <>
      {data ? (
        <PDFTaxInvoice data={data} service={service}/>
      ) : (
        <div style={{display: "flex", justifyContent: "center"}}>
        <p >Generating FISCAL TAX INVOICE...</p>

        </div>
      )}
    </>
  );
}
