import React, { useState, useContext } from 'react';
import { AddEditCompany } from './AddEditCompany';
import { Modal } from '../Modal'
import AuthContext from '../../AuthContext/AuthContext';
import './../../css/AdverseUploads.css';
import { dataTypeBulk, dataType } from './selectOptionsjson';
import BulkForm from '../BulkUpload/BulkForm';
import { SearchEntity } from '../Individuals/IndividualsUploads';


export const CompanyUploads = ({ setFormStatus }) => {

  const [showSingleUpload, setShowSingleUpload] = useState(true);

  const switchTab = (e) => {

    if (e.target.innerText === "Single") {
      setShowSingleUpload(true);
      e.target.classList.add("active")
      document.querySelector(".multiple-btn").classList.remove("active");
    } else {
      setShowSingleUpload(false);
      e.target.classList.add("active")
      document.querySelector(".single-btn").classList.remove("active");
    }


  }

  return (

    <section className="adverse-uploads-container">

      <div className="adverse-uploads-nav">

        <div className="single-multiple-btns-container">
          <button className='single-btn active' onClick={e => switchTab(e)}>Single</button>
          <button onClick={e => switchTab(e)} className="multiple-btn">Multiple</button>
        </div>

      </div>

      {
        showSingleUpload ?
          <div className="upload-section-container" style={{ padding: "1em 0" }}>
            <AddEditCompany caller={"addBureau"} setDisplayedForm={setFormStatus} />
          </div> :
          <MultipleUploads />

      }
    </section>
  )
}


const MultipleUploads = () => {

  const [dataTypeState, setDataTypeState] = useState("Claim")

  return (
    <section className="multiple-adverse-uploads-container">

      <div className="bulk-forms-container">
        <BulkForm data={'Companies'} />
      </div>


    </section>
  )
}

