import React, { useState, useEffect, useContext } from "react"
import { debtorType, CurrencyType } from "../Company/selectOptionsjson"
import { searchRecord } from "./../../js/searchRecord"
import { toast } from "react-toastify";
import CircularProgress from '@mui/material/CircularProgress';
import { InputContainer, SelectContainer, InputAreaContainer, InputWithDropdownContainer } from "../InputContainer";
import { checkIfSearchedEntityIsAvailable } from "../../js/checkIfSearchedEntityIsAvailable";
import { uploadSingleCourtRec } from "../../js/uploadSingleAdverseData";
import { ValuesContext } from "../Admin/HomeAdmin";


export const AddSingleCourtRec = ({ authTokens, dataTypeState }) => {

    const [defedentErr, setDefendentErr] = useState(false);
    const { userData } = useContext(ValuesContext)
    const [uploading, setUploading] = useState(false);
    const [dataSource, setDataSource] = useState((userData.data.groups[0] === 3 || userData.data.groups[0] === 4) ? `${userData.data.firstname} ${userData.data.surname}` : '');
    const [caseNumber, setCaseNumber] = useState("");
    const [plantifType, setPlantifType] = useState("Individual");
    const [plantifName, setPlantifName] = useState((userData.data.groups[0] === 3 || userData.data.groups[0] === 4) ? userData.data.company.registration_name : '');
    const [defendentType, setDefendentType] = useState("Individual");
    const [userInputDefendentType, setUserInputDefendentType] = useState("");
    const [searchedDefendent, setSearchedDefendent] = useState("");
    const [judgementDate, setJudgementDate] = useState("");
    const [courtName, setCourtName] = useState("");
    const [CurrencyTypeState, setCurrencyTypeState] = useState("USD");
    const [amount, setAmount] = useState("");


    const clearFields = () => {
        setDataSource('');
        setDefendentErr(false);
        setCaseNumber("");
        setPlantifType("Individual");
        setPlantifName("");
        setDefendentType("Individual");
        setUserInputDefendentType("");
        setSearchedDefendent("");
        setJudgementDate("");
        setCourtName("");
        setCurrencyTypeState("");
        setAmount("");
    }


    const sendAdverseData = (e) => {

        e.preventDefault();


        if (
            searchedDefendent.data.length !== 1
        ) {
            toast.error(`Please provide the ${defendentType} for this data to proceed`)
            return;
        }

        setUploading(true);
        uploadSingleCourtRec(
            userData.data.groups[0] === 1 || userData.data.groups[0] === 2 ? true : false,
            dataSource,
            caseNumber,
            plantifType,
            plantifName,
            defendentType,
            searchedDefendent,
            String(authTokens.access),
            judgementDate,
            courtName,
            CurrencyTypeState,
            amount,
            clearFields,
            setUploading
        )
    }


    return (
        <>
            <form className="adverse-single-form">

                <h3 style={{ marginBottom: '25px', backgroundColor: 'var(--main-color)', color: 'white', fontSize: '.9rem', textAlign: 'center', padding: '10px', width: '100%' }}>
                    Creditor
                </h3>


                {
                    (userData.data.groups[0] === 1 || userData.data.groups[0] === 2) &&
                    <InputContainer
                        label={"Creditor Name"}
                        type={"text"}
                        currentValue={plantifName}
                        setValue={setPlantifName}
                        id={""}
                    />
                }



                {
                    (userData.data.groups[0] === 1 || userData.data.groups[0] === 2) &&
                    <InputContainer
                        label={"Data Source"}
                        type={"text"}
                        currentValue={dataSource}
                        setValue={setDataSource}
                        id={""}
                    />
                }



                <InputContainer
                    label={"Negative Data Date /  Judgement Date"}
                    type={"Date"}
                    currentValue={judgementDate}
                    setValue={setJudgementDate}
                    id={""}
                />





                <h3 style={{ marginBottom: '25px', backgroundColor: 'var(--main-color)', color: 'white', fontSize: '.9rem', textAlign: 'center', padding: '10px', width: '100%' }}>
                    Debtor
                </h3>

                <SelectContainer
                    label={"Debtor Type"}
                    currentValue={defendentType}
                    setValue={setDefendentType}
                    options={debtorType}
                    id={""}
                />


                <InputWithDropdownContainer
                    label={"Debtor  ID / Name / Reg Num"}
                    type={"text"}
                    currentValue={userInputDefendentType}
                    setValue={setUserInputDefendentType}
                    id={""}
                    typeOfEntity={defendentType}
                    authTokens={authTokens}
                    setSearchedEntity={setSearchedDefendent}
                />



                <InputContainer
                    label={"Court Name"}
                    type={"text"}
                    currentValue={courtName}
                    setValue={setCourtName}
                    id={""}
                />

                <SelectContainer
                    label={"Currency"}
                    currentValue={CurrencyTypeState}
                    setValue={setCurrencyTypeState}
                    options={CurrencyType}
                    id={""}
                />

                <InputContainer
                    label={"Amount"}
                    type={"text"}
                    currentValue={amount}
                    setValue={setAmount}
                    id={""}
                />

                <InputContainer
                    label={"Reference Number / Case Number"}
                    type={"text"}
                    currentValue={caseNumber}
                    setValue={setCaseNumber}
                    id={""}
                />


            </form>


            {
                uploading === true &&
                <CircularProgress />
            }

            {
                uploading === false &&
                <button className="btn-adverse-submit" onClick={e => sendAdverseData(e)}>
                    Submit
                </button>
            }
        </>

    )
}
