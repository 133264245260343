import React, { useContext, useState } from 'react'
import { InputContainer } from '../InputContainer';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import '../../css/FormContainerStyle.css';
import '../../css/addcompany.css';
import CheckIcon from '@mui/icons-material/Check'
import AddeditUser from '../Clients/AddeditUser';
import { ValuesContext } from '../Admin/HomeAdmin';
import { DataSubmission } from './DataSubmission';
import AuthContext from '../../AuthContext/AuthContext';

/* ___________________________________________________Password____________________________________________________ */
export const ChangePasswordForm = () => {

    const [newPassword, setNewPassword] = useState('')
    const [oldPassword, setOldPassword] = useState('')
    const [confirmNewPassword, setConfirmNewPassword] = useState('')
    let { userData } = useContext(ValuesContext)
    const { authTokens, logoutUser } = useContext(AuthContext);

    const Submit = async (e) => {
        e.preventDefault()
        if (oldPassword === "" && newPassword === "" && confirmNewPassword === "") {
            toast.warning("Provide all the information needed below!")
        }
        else if (oldPassword === "") {
            toast.warning("Old password cannot be empty")
        }
        else if (newPassword === "") {
            toast.warning("NewPassword cannot be empty!")
        }
        else if (confirmNewPassword === "") {
            toast.warning("Please provide the password confirmation")
        }
        else if (newPassword !== confirmNewPassword) {
            toast.warning("New password and Confirmation do not match!")
        }
        else if (
            newPassword === confirmNewPassword && oldPassword !== "" && confirmNewPassword !== "" && newPassword !== ""
        ) {
            var response = DataSubmission(
                String(authTokens.access),
                'post',
                '/user-change-password/',
                {
                    data: {
                        username: userData.data.username,
                        old_password: oldPassword,
                        new_password: newPassword
                    }
                }
            )
        }
    }


    return (
        <section className="form-container">
            <h3 className="form-header">Profile</h3>
            <form className="form">
                <section className='secinputcontainer'>

                    <h4 className="sectionHeading"> change Password  </h4>

                    <section className=' secinputdeatails'>

                        <InputContainer
                            label={"Old Password"}
                            type={"password"}
                            currentValue={oldPassword}
                            setValue={setOldPassword}
                            id={'txt_oldPassword'}
                        />

                        <InputContainer
                            label={"New Password"}
                            type={"password"}
                            currentValue={newPassword}
                            setValue={setNewPassword}
                            id={'txt_newPassword'}
                        />

                        <InputContainer
                            label={"Confirm New Password"}
                            type={"password"}
                            currentValue={confirmNewPassword}
                            setValue={setConfirmNewPassword}
                            id={'txt_confirmNewPassword'}
                        />

                    </section>

                </section>

                <section className='secButtons'>


                    <button type='submit' className="formButtons"
                        align="center" onClick={e => Submit(e)} >
                        <CheckIcon className='icons' />
                        <blockquote>
                            Update
                        </blockquote>
                    </button>

                </section>

            </form>

        </section>
    )
}
