import React from 'react';
import ReactDOM from 'react-dom/client';
import { Header } from './Header';
import { Main } from './Main';
import { CompanyHistory } from './CompanyHistory';
import { AboutFincheck } from './AboutFincheck';
import { OurValues } from './OurValues';
import { OurProducts } from './OurProducts';
import { FAQ } from './FAQ';
import { OurReviews } from './OurReviews';
import { OurContact } from './ContactUs';
import { Footer } from './Footer';



export const Home = () => {
    
  return (
    <>
      <Header/>
      <Main/>
      <CompanyHistory/>
      <AboutFincheck/>
      <OurValues/>
      <OurProducts/>
      <FAQ/>
      <OurReviews/>
      <OurContact/>
      <Footer/>
    </>
  )
}
