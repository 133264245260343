import React, { useState, useEffect, useContext } from 'react';
import creditPng from './../../backgrounds/credit.png';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Modal } from '../Modal'
import { AdverseUploads } from '../AdverseUploads';
import { IndividualUploads } from '../Individuals/IndividualsUploads';
import { CompanyUploads } from '../Company/CompanyUploads';
import { ActiveAccsUploads } from '../ActiveAccsUpload';
import { toast } from 'react-toastify';
import { ValuesContext } from './HomeAdmin';
import AuthContext from '../../AuthContext/AuthContext';
import { fetchData } from '../../js/fetchData';
import { NavLink, useNavigate } from 'react-router-dom';


export const BureauSection = ({ setActivePage }) => {

  const [showModal, setShowModal] = useState(false);
  const bureauSub = JSON.parse(localStorage.getItem('bureau-sub'));
  const navigate = useNavigate();
  const { userData } = useContext(ValuesContext);
  const { authTokens } = useContext(AuthContext);
  const [isSubscripted, setIsSubscripted] = useState(bureauSub ? bureauSub.isSubscripted : null);
  const [clients, setAllClients] = useState(null);
  const [componentToShow, setComponentToShow] = useState("");
  const componentsMapping = {
    adverse: AdverseUploads,
    activeAccounts: ActiveAccsUploads,
    individual: IndividualUploads,
    company: CompanyUploads
  }


  useEffect(() => {

    if (
      clients === null ||
      clients.data === null ||
      userData.data === null ||
      Object.keys(userData).length === 0) {
      console.log('whatsup homie')

      /*  if (bureauSub && bureauSub.isSubscripted === true) {
         navigate('/admin/bureau');
       } */
      return;
    }

    const client = clients.data.filter(client => client.fins_number === userData.data.company.fins_number);

    if (client.length === 0) {
      setIsSubscripted(false)
      return
    }

    if (client.length === 1 && client[0].subscription_category === undefined) {
      setIsSubscripted(false);
      return;
    }



    if (client.length === 1) {

      const subscription = client[0].subscription_category.filter(category => category === 'Bureau');

      if (subscription.length === 1) {
        setIsSubscripted(true);
        localStorage.setItem('bureau-sub', JSON.stringify({ isSubscripted: true }))
        navigate('/admin/bureau');
      } else {
        setIsSubscripted(false);
        localStorage.setItem('bureau-sub', JSON.stringify({ isSubscripted: false }))
      }
    }


  }, [clients])

  useEffect(() => {
    fetchData(
      String(authTokens.access),
      setAllClients,
      "client",
      null,
      null
    )
  }, [])




  const toggleRecordsMenu = () => {

    const expand = document.querySelector(".expand");
    const recordsMenu = document.querySelector(".records-menu");

    recordsMenu.classList.toggle("show-records-menu");
    expand.classList.toggle("rotate-expand")
  }

  const message = (isSubscripted) => {
    if (!isSubscripted) {
      toast.warning('Please subscribe for the service')
    }
  }

  return (

    <>
      {
        showModal &&
        <Modal
          Component={componentsMapping[componentToShow]}
          setFormStatus={setShowModal}
          data={null}
        />
      }
      <BureauHeaderContainer isSubscripted={isSubscripted} />

      <nav className="bureau-nav" style={{ backgroundColor: isSubscripted === false && 'gray', color: isSubscripted === false && 'black', opacity: isSubscripted === false && 0.7 }}>

        <NavLink id="sidebar-link" to={isSubscripted && 'bureau'} onClick={() => message(isSubscripted)} className='active-side-link'>dashboard</NavLink>
        <button className='records-btn' onClick={toggleRecordsMenu}>
          Records
          <ExpandMoreIcon className='expand' />
        </button>
        <RecordsDropDown
          setActivePage={setActivePage}
          setShowModal={setShowModal}
          setComponentToShow={setComponentToShow}
          toggleRecordsMenu={toggleRecordsMenu}
          isSubscripted={isSubscripted}
          message={message}
        />
        <a href="#">Reports</a>

      </nav>

    </>
  )
}


const BureauHeaderContainer = ({ isSubscripted }) => {

  return (
    <section className="bureau-header-container" style={{ backgroundColor: isSubscripted === false && 'gray', opacity: isSubscripted === false && 0.7 }}>

      <div className="bureau-icon-container">
        <img src={creditPng} alt="" />
      </div>
      <h3 className="bureau-header">
        bureau
      </h3>
    </section>

  )
}

const RecordsDropDown = ({ setActivePage, setShowModal, setComponentToShow, toggleRecordsMenu, isSubscripted, message }) => {

  const { userData } = useContext(ValuesContext);

  const showComponent = (componentToShow) => {

    if (isSubscripted) {
      setComponentToShow(componentToShow);
      setShowModal(true);
      toggleRecordsMenu();
    } else {
      toast.warning('Please subscribe for the service to have access')
    }

  }

  const activeComponent = (page) => {

    if (isSubscripted) {
      setActivePage(page)
      toggleRecordsMenu();
    } else {
      toast.warning('Please subscribe for the service to have access')
    }

  }

  /*  const toggleActiveMenu = () => {
 
     const expand = document.querySelector(".active-expand");
     const recordsMenu = document.querySelector(".records-menu-active-accounts");
 
     recordsMenu.classList.toggle("show-records-menu");
     expand.classList.toggle("rotate-expand")
  } */

  return (

    <nav className="records-menu" style={{ backgroundColor: isSubscripted === false && 'gray', color: isSubscripted === false && 'black' }}>
      {
        (userData.data.groups[0] === 1 || userData.data.groups[0] === 2) &&
        <>
          <NavLink to={isSubscripted && 'close-records'} onClick={() => message(isSubscripted)}>Close Record</NavLink>
          <NavLink to={isSubscripted && 'individuals'} onClick={() => message(isSubscripted)}>Individual</NavLink>
          <NavLink to={isSubscripted && 'company'} onClick={() => message(isSubscripted)}>Company</NavLink>
        </>
      }
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <a href='#' onClick={() => showComponent("adverse")}>Adverse Data</a>
        {
          (userData.data.groups[0] === 1 || userData.data.groups[0] === 2) &&
          <NavLink to={'approve-adverse-record'} style={{ width: '15px', height: '15px', borderRadius: '50%', backgroundColor: 'red' }}></NavLink>
        }
      </div>
      <a href='#' onClick={() => showComponent("activeAccounts")} className="activeMenu-btn">Active Accounts</a>
    </nav>
  )
}

/* const ActiveDropdown = ({activeComponent,setShowModal,setComponentToShow,toggleRecordsMenu,toggleActiveMenu}) => {


  const showComponent = (componentToShow) => {

    setComponentToShow(componentToShow);
    setShowModal(true);
    toggleRecordsMenu();
    toggleActiveMenu();

  }

  return (

    <nav className="records-menu-active-accounts">
      <a href='#' onClick={() => showComponent("activeAccounts")}>Current Active</a>
      <a href='#' onClick={() => activeComponent("uploadHPI")}>HPI</a>
    </nav>
  )
} */