
import React, { useState, useEffect, useContext,useRef } from 'react';
import { ValuesContext } from '../../Admin/HomeAdmin';
import { fetchByFinsNumbers } from '../../../js/searchRecord';
import { handleResponses } from '../../../js/fetchData';
import "../../../css/IndividualReport.css"
import Absconder from '../IndividualReport/Absconder';
import Claims from '../IndividualReport/Claims';
import CourtRecords from '../IndividualReport/CourtRecords';
import Directorships from '../IndividualReport/Directorships';
import Employement from '../IndividualReport/Employement';
import axios from 'axios';
import HPI from '../IndividualReport/HPI';
import IdentifictaionContactDetails from '../IndividualReport/IdentifictaionContactDetails';
import { filterAbsconderSlowPayerActive } from '../../../js/filterAbsconderSlowPayerActive';
import { IndividualReportFooter } from '../IndividualReport/IndividualReportFooter';
import { BIUReportHeader } from '../../Company/CompanyBIUReport/BIUReportHeader';
import Inquiries from '../IndividualReport/Inquiries';
import PublicInfo from '../IndividualReport/PublicInfo'
import { RiskClassification } from '../IndividualReport/RiskClassification';
import { Loader } from '../../Loader';
import { ScoreRange } from '../IndividualReport/ScoreRange';
import { EnquiriesContext } from '../../../App';
import { BIUContactDetails } from '../../Company/CompanyBIUReport/BIUContactDetails';
import { BIUContactInfo } from './BIUContactInfo';
import { BankingDetails } from '../../Company/CompanyBIUReport/BankingDetails';
import { CurrentEmploymentDetails } from './CurrentEmploymentHistory';
import { FormerEmploymentDetails } from './FormerEmploymentDetails';
import { DetailsOfSpouse } from './DetailsOfSpouse';
import { DetailsOfNextOfKin } from './DetailsOfNextOfKin';
import SlowPayer from '../IndividualReport/SlowPayer';
import { Comment } from '../../Company/CompanyBIUReport/Comment';
import { DynamicRating } from './DynamicRating';
import { chargeIndividualBIU } from '../../../js/chargeClient';
import AuthContext from '../../../AuthContext/AuthContext';


export const IndividualBIUReport = () => {

  const {reportData} = useContext(EnquiriesContext);
  const {authTokens} = useContext(AuthContext)
  const individual = reportData.data.subject_indvidual;
  const [enqDetails,setEnqDetails] = useState(JSON.parse(localStorage.getItem("enq-details")));
  const [failureFetch,setFailureFetch] = useState(false);
  const [score,setScore] = useState({data: null})
  const [search,setSearchResult] = useState({data: null});
  const [activeCreditAccs, setActiveCreditAccs] = useState({data: null});
  const [creditAccounts,setCreditAccounts] = useState({data: null});
  const [historicals,setHistoricals] = useState({data: null});
  const [claims, setClaims] = useState({data: null})
  const [absconders,setAbsconders] = useState({data: null});
  const [slowPayer,setSlowPayer] = useState({data: null});
  const [courts,setCourts] = useState({data: null})
  const [hirePurchases,setHirePurchases] = useState({data: null});
  const [inquiries,setInquries] = useState({data: null});
  const [publicInfo,setPublicInfo] = useState({data: null});
  const [directorships,setDirectorships] = useState({data: null});
  const [bankDetails,setBankDetails] = useState({data: null});
  const [nextOfKin,setNextOfKin] = useState({data: null});
  const [workHistory,setWorkHistory] = useState({data: null});
  const [spouse,setSpouse] = useState({data: null});
  const [spouseBank,setSpouseBank] = useState({data: null});

 
  useEffect(() => {
      
      if(
          inquiries.message === "fetch was successful" &&
          hirePurchases.message === "fetch was successful" &&
          creditAccounts.message === "fetch was successful" &&
          claims.message === "fetch was successful" &&
          courts.message === "fetch was successful" &&
          nextOfKin.message === "fetch was successful" &&
          workHistory.message === "fetch was successful" &&
          spouse.message === "fetch was successful" &&
          bankDetails.message === "fetch was successful"
          )
      {

          setFailureFetch("done")
          // charging the client
          chargeIndividualBIU(reportData.data.request_id,setSearchResult);
          filterAbsconderSlowPayerActive(claims,setClaims,creditAccounts,setActiveCreditAccs,setAbsconders,setSlowPayer);

      }
  },[spouse,workHistory,nextOfKin,bankDetails,inquiries,hirePurchases,creditAccounts,claims,courts])


  useEffect(() => {
    if(spouse.data === null){
        return
    }
    
    
    
    if(spouse.data.length !== 0 && spouse.message === "fetch was successful"){
        fetchByFinsNumbers(
            spouse.data[0].spouse.fins_number,
            "individual-bank-account/",
            setSpouseBank,
            setFailureFetch,
            null,
         )
    }

   


  },[spouse])


  useEffect(() => {

    axios.post(`/claim-search/`,{
       
        headers: {
           Accept: "application/json",
           "Content-Type": "application/json",
           Authorization: "Bearer " + String(authTokens.access),
        }
        ,
        data: {
           searchParameter: "individual_debtor_fins",
           fins: individual.fins_number
        }
     })
     .then(res => {
 
        if(res.status !== 200){
           setFailureFetch(true)
        }
        handleResponses(res,setClaims);
        
     })
     .catch(err => {
        setFailureFetch(true)
        handleResponses(err,setClaims);
     })



      fetchByFinsNumbers(
        String(authTokens.access),
         individual.fins_number,
         'individual-court/',
         setCourts,
         setFailureFetch,
         null
      );

      fetchByFinsNumbers(
        String(authTokens.access),
         individual.fins_number,
         "individual-hire-purchase/",
         setHirePurchases,
         setFailureFetch,
         null
      );


      fetchByFinsNumbers(
        String(authTokens.access),
          individual.fins_number,
          "individual-enquiry/",
          setInquries,
          setFailureFetch,
          null,
       );

         
      fetchByFinsNumbers(
        String(authTokens.access),
          individual.fins_number,
          "individual-credit-details/",
          setCreditAccounts,
          setFailureFetch,
          null,
      );
      
    fetchByFinsNumbers(
      String(authTokens.access),
        individual.fins_number,
        "individual-bank-account/",
        setBankDetails,
        setFailureFetch,
        null,
     )

     fetchByFinsNumbers(
      String(authTokens.access),
        individual.fins_number,
        "individual-next-of-kin/",
        setNextOfKin,
        setFailureFetch,
        null,
     )

     fetchByFinsNumbers(
      String(authTokens.access),
      individual.fins_number,
      "individual-work-history/",
      setWorkHistory,
      setFailureFetch,
      null,
     )

     fetchByFinsNumbers(
      String(authTokens.access),
      individual.fins_number,
      "individual-spouse/",
      setSpouse,
      setFailureFetch,
      null,
     )

      
   },[])

  return (
      <>
          {
              failureFetch === false &&
              <Loader/>
          }
  
          {
              failureFetch === true &&
              <p className='failed-to-fetch'>Something went wrong,try again</p>
          }
  
          {
              failureFetch === "done" &&
              <Reports
                  activeCreditAccs={activeCreditAccs}
                  historicals={historicals}
                  courts={courts}
                  hirePurchases={hirePurchases}
                  inquiries={inquiries}
                  publicInfo={publicInfo}
                  claims={claims}
                  directorships={directorships}
                  absconders={absconders}
                  slowPayer={slowPayer}
                  individual={individual}
                  score={score}
                  enqDetails={enqDetails}
                  reportData={reportData}

                  bankDetails={bankDetails}
                  workHistory={workHistory}
                  spouse={spouse}
                  nextOfKin={nextOfKin}
                  spouseBank={spouseBank}
              />
          }
      </>
  )
}


const Reports = ({spouseBank,nextOfKin,slowPayer,spouse,workHistory,bankDetails,reportData,individual,enqDetails,courts,claims,hirePurchases,inquiries,publicInfo,directorships,absconders,score}) => {
    
  const [showBtn, setShowBtn] = useState(true);
  const type = useRef("")
  const individualName =  individual.firstname + " " + individual.surname;

  
  return (
      <>

          <table ref={type} className='Individual_report'>

                    
               <tr>
                  <BIUReportHeader 
                    name={individualName}
                    client={reportData.data.client_company.registration_name}
                  />
              </tr>

              <tr>
                <BIUContactInfo individual={individual}/>
              </tr>

              <tr className="data_row">
                <BankingDetails bankDetails={bankDetails} />
              </tr>

              <tr className='report_section'>
                <Inquiries inquiries={inquiries} enqDetails={enqDetails}/>
              </tr>

              <tr className='report_section'>
                  <HPI hirePurchases={hirePurchases}/>
              </tr>

              <tr className='report_section'>
                  <Absconder absconders={absconders}/>
              </tr>

              <tr className='report_section'>
                  <CourtRecords courts={courts}/>
              </tr>

              <tr className='report_section'>
                  <Claims claims={claims}/>
              </tr>

              <tr className="report_section">
                <SlowPayer slowpayers={slowPayer}/>
              </tr>


              <tr className="report_section">
                <CurrentEmploymentDetails individual={individual}/>
              </tr>

              <tr className='report_section'>
                <FormerEmploymentDetails workHistory={workHistory}/>
              </tr>

              <tr className='report_section'>
                <DetailsOfSpouse spouse={spouse} spouseBank={spouseBank}/>
              </tr>

              <tr className='report_section'>
                <DetailsOfNextOfKin nextOfKin={nextOfKin}/>
              </tr>

              <tr className='report_section'>
                <Comment comment={""}/>
              </tr>

             
            
             <DynamicRating/>
              
          </table>
          
          {showBtn && <PrintBtn showBtn={showBtn} setShowBtn={setShowBtn} type={type}/>}

      </>
  )
}

export const PrintBtn = ({showBtn, setShowBtn,type}) => {

  const printReport = () => {

      type.current.classList.add("full-width");
      setShowBtn(false);

     
      setTimeout(() => {
          window.print();
      },500)

      setTimeout(() => {
          type.current.classList.remove("full-width");
          setShowBtn(true)
      },1000)
     
  }

  return (
      
      <div className="report" onClick={() => printReport()}>
          <button  className="print-btn">
              Print
          </button>
      </div>
  )
}


