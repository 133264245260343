import React, { useContext, useEffect } from 'react'
import axios from 'axios';
import './../css/Targets.css';
import EditIcon from '@mui/icons-material/Edit';
import { InputContainer } from './InputContainer';
import { useState } from 'react';
import AuthContext from '../AuthContext/AuthContext';
import { toast } from 'react-toastify';
import { fetchData } from '../js/fetchData';
import { getDate } from '../js/getDate';

export const InvoicingPeriods = () => {

  const headers = [
    'period',
    'name',
    'start',
    'end',
  ]
  const [showAddForm, setShowAddForm] = useState(false)
  const { authTokens } = useContext(AuthContext);
  const [name, setName] = useState('');
  const [start, setStart] = useState('');
  const [end, setEnd] = useState('');
  const [periods, setPeriods] = useState({ data: null });
  const [refresh, setRefresh] = useState(0)

  useEffect(() => {

    fetchData(
      String(authTokens.access),
      setPeriods,
      'invoice-period',
      null,
      null
    )
  }, [refresh])


  const addTarget = () => {

    axios.post('invoice-period/', {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + String(authTokens.access)
      },
      period_name: name,
      period_start: start,
      period_end: end,
    })
      .then(function (response) {

        if (response.status >= 200 && response.status < 300) {
          toast.success('Accounting Period Added')
          const currentPeriods = periods.data !== null && [...periods.data];
          setPeriods({ data: [...currentPeriods, response.data] })
        } else {
          toast.error('Period Failed to Add')
        }
      })
      .catch(() => {
        toast.error('Period Failed to Add')
      });
  }

  return (

    <section className="targets-coontainer">

      <h3 className="targets-header">
        Accounting Periods
      </h3>

      <div className="account-periods-heading">
        <p className='acc-year--'>year end</p>
        <p className='acc-date'>{'25-Dec-' + new Date().getFullYear()}</p>
      </div>


      <Table
        headers={headers}
        periods={periods}
        setPeriods={setPeriods}
        refresh={refresh}
        setRefresh={setRefresh}
      />

      <button className="add-target-btn" onClick={() => setShowAddForm(!showAddForm)}>
        Add
      </button>

      {
        showAddForm &&
        <section className="add-target-section">
          <div className='add-target-inputs'>
            <InputContainer
              label={'Period Name'}
              currentValue={name}
              setValue={setName}
              type={'date'}
              name={'periodName'}
            />

            <InputContainer
              label={'Start'}
              currentValue={start}
              setValue={setStart}
              type={'date'}
              name={'start'}
            />

            <InputContainer
              label={'End'}
              currentValue={end}
              setValue={setEnd}
              type={'date'}
              name={'end'}
            />
          </div>

          <button className="edit-targets-submit" onClick={addTarget}>
            Submit
          </button>
        </section>
      }

    </section>
  )
}

const Table = ({ headers, periods, setPeriods, refresh, setRefresh }) => {

  return (

    <table className="qty-table" border={1}>

      <tr>
        {
          headers.map((header, index) => {

            return (
              <th key={index}>{header}</th>
            )
          })
        }
      </tr>

      {
        periods.data !== null &&
        periods.data.map((period, index) => {

          return <TableRowEntity
            key={index}
            period={period}
            index={index}
            setPeriods={setPeriods}
            refresh={refresh}
            setRefresh={setRefresh}
          />
        })
      }


    </table>



  )
}

const TableRowEntity = ({ period, index, setRefresh, refresh }) => {

  const [editForm, setEditForm] = useState(false)
  const { authTokens } = useContext(AuthContext);
  const [name, setName] = useState(period.period_name);
  const [start, setStart] = useState(period.period_start);
  const [end, setEnd] = useState(period.period_end);

  const submitEditTargets = () => {

    axios.put(`invoice-period/${period.id}/`, {

      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + String(authTokens.access)
      },
      id: period.id,
      period_name: name,
      period_start: start,
      period_end: end,

    })
      .then((response) => {

        if (response.status >= 200 && response.status < 300) {
          toast.success('Target Updated');
          setEditForm(false);
          setRefresh(refresh + 1)
        } else {
          toast.error('Target Not Modified')
        }
      })
      .catch(() => toast.error('Target Not Modified'))
  }



  return (

    <>
      <tr>
        <td>{period.id}</td>
        <td>{getDate(period.period_name)}</td>
        <td>{getDate(period.period_start)}</td>
        <td>{getDate(period.period_end)}</td>
        <td onClick={() => setEditForm(!editForm)} style={{ backgroundColor: 'var(--main-color)' }}><EditIcon style={{ color: 'white' }} /></td>
      </tr>

      {
        editForm &&

        <tr className="edit-target">
          <td colSpan={7}>
            <div className="edit-target-inputs">
              <InputContainer
                label={'Name'}
                currentValue={name}
                setValue={setName}
                type={'date'}
                name={'periodName'}
              />

              <InputContainer
                label={'Start'}
                currentValue={start}
                setValue={setStart}
                type={'date'}
                name={'start'}
              />

              <InputContainer
                label={'End'}
                currentValue={end}
                setValue={setEnd}
                type={'date'}
                name={'end'}
              />

            </div>

            <button className="edit-targets-submit" onClick={submitEditTargets}>
              Submit
            </button>
          </td>
        </tr>
      }
    </>
  )
}
