import React from 'react'
import '../../../css/CompanyEdit.css'
import CloseIcon from '@mui/icons-material/Close'
import { AddEditAssociatedCompanies } from '../../Company/AddEditAssociatedCompanies'
import { AddEditCompany } from '../../Company/AddEditCompany'
import AddEditIndividual from '../../Individuals/AddEditIndividual'
import Search from './Search'
import Searchclients from './Searchclients'

const SearchModal = ({ setname, searching, setFins, setid, setDisplay, display }) => {
    return (
        <div className={display === true ?
            'EditModalcssbiu'
            :
            'EditModalcssbiuHidden'}>

            <Search
                setname={setname}
                searching={searching}
                setFins={setFins}
                setid={setid}
                setdisplay={setDisplay}
            />

            <div className='closeIconDivBIU'>
                <CloseIcon className='closeIconBiu' onClick={() => { setDisplay('close') }} />
            </div>
        </div>
    )
}

export default SearchModal