import React from 'react';
import "../../../css/IndividualReport.css"
import { setRiskColor } from '../../../js/setRiskColor';

export const TableHeading = ({heading,span,color}) => {
    return (
        <tr className='row_heading'>
            <td colSpan={span} className='txt_rowheading' style={{backgroundColor: color && color, color: color === '#305496' && 'white'}}>
                <h3 className='section_heading'>
                    {heading}
                </h3>
            </td>
        </tr>
    )
}

export const TableData = ({data,id,span,score,textAlignClass}) => {

    const determineToShowScore = () => {


        if(score !== undefined  && score.data !== null){

            if(id === "low_risk" && (score.data >= 333 && score.data <= 500)){
                return `[${Math.round(score.data)}]`;
            }
    
            if(id === "medium_risk" && (score.data >= 166 && score.data <= 332)){
                return `[${Math.round(score.data)}]`;
            }
    
            if(id === "high_risk" && (score.data >= 0 && score.data <= 165)){
                return `[${Math.round(score.data)}]`;
            }

            return "";
        }else {
            return ""
        }

        
    }



    return (
        <td id={id} style={{backgroundColor: setRiskColor(score,id) !== undefined && setRiskColor(score,id).backgroundColor, color: setRiskColor(score,id) && setRiskColor(score,id).color}}  className={`txt_section_data ${textAlignClass}`} colSpan={span}>
            {data} {` ${determineToShowScore()}`}
        </td>
    )
}

export const TableDataHeaders = ({data,id,span,textAlignClass}) => {
    
    return (
        <td id={id} style={{fontSize: '.9rem'}} className={`txt_section_data ${textAlignClass}`} colSpan={span}>
            <b>
            {data}
            </b>
        </td>
    )
}