import { createContext, useState, useEffect } from "react";
import { backendUrl } from "../js/data";
import jwt_decode from "jwt-decode";
//import { useNavigate } from "react-router-dom";
import React from "react";
import axios from 'axios'
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Error } from "@mui/icons-material";
const AuthContext = createContext();

export default AuthContext;

export const AuthProvider = ({ children }) => {
  //let Navigate = useNavigate();

  const [authTokens, setAuthTokens] = useState(() =>
    localStorage.getItem("authTokens")
      ? JSON.parse(localStorage.getItem("authTokens"))
      : null
  );
  const [user, setUser] = useState(() =>
    localStorage.getItem("authTokens")
      ? jwt_decode(localStorage.getItem("authTokens"))
      : null
  );
  const [loading, setLoading] = useState();

  //const password = useNavigate();

  const loginUser = async (username, password) => {
    const response = await axios({
      method: "POST",
      url: `/get-auth-token/`,
      headers: {
        "Content-Type": "application/json",
      },
      data: {
        username : username,
        password : password,
      },
    }).then((res) => {
      return res
    }).catch(err => {
      if (err.response.status === 401) {
        if(err.response.data){
          let data = err.response.data
          if(data.detail){
            toast.error(data.detail + ' .Check username and password')
          }
        }
        //toast.error('Opps!!!!! Client has been suspended. For more information contact service provider!!!')
      }
      else {
        toast.error("Server is down at the moment our team is working on it. We apologise for any inconvenience caused !");
      }
      return err
    });

    const data = await response;

    if (response.status === 200) {
      setAuthTokens(data.data);
      setUser(jwt_decode(data.data.access));
      localStorage.setItem("authTokens", JSON.stringify(data.data));
      window.location.href = "/admin";
    }
  };

  const updateToken = async () => {
    const response = await fetch("/refresh-auth-token/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
         Authorization: `Bearer ${authTokens.access ? authTokens.access : null }`
      },
      body: JSON.stringify({ refresh: authTokens ? authTokens.refresh : null }),
    });

    const data = await response.json();

    if (response.status === 200) {
      setAuthTokens(data);
      setUser(jwt_decode(data.access));
      localStorage.setItem("authTokens", JSON.stringify(data));
    } else {
      logoutUser();
    }

    if (loading) {
      setLoading(false);
    }
  };

  const logoutUser = () => {
    setAuthTokens(null);
    setUser(null);
    localStorage.removeItem('bureau-sub');
    localStorage.removeItem('hp-sub');
    localStorage.removeItem('biu-sub');
    localStorage.removeItem("authTokens");
    window.location.href = "/login";
  };

  const contextData = {
    user,
    setUser,
    authTokens,
    setAuthTokens,
    loginUser,
    logoutUser,
  };

  useEffect(() => {
    if (authTokens) {
      setUser(jwt_decode(authTokens.access));
    }
    setLoading(false);

    if (loading) {
      updateToken();
    }

    let fourMinutes = 100000 * 60 * 50;

    let interval = setInterval(() => {
      if (authTokens) {
        updateToken();
      }
    }, fourMinutes);
    return () => clearInterval(interval);

  }, [authTokens, loading]);

  return (
    <AuthContext.Provider value={contextData}>
      {loading ? null : children}
    </AuthContext.Provider>
  );
};
