import React from 'react';
import ReactDOM from 'react-dom/client';
import './../css/Modal.css';
import CloseIcon from '@mui/icons-material/Close';
import { CircularProgress } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import ErrorIcon from '@mui/icons-material/Error';
import { Claims } from './Claims';

export const Modal = ({Component,setFormStatus, data}) => {
    
    return (
        
        <section className="modal">

            <CloseIcon
                onClick={() => setFormStatus(false)}
                className="close-modal-icon"
            />

            <div className="add-record-form-component-container">
                <Component data={data} setFormStatus={setFormStatus} setDisplayedForm={setFormStatus}/>
            </div>
            
        </section>
    )
}

export const LoadingModal = ({closeStatus, setShowModal}) => {

    return (

        <section className="modal">

            <CloseIcon
                onClick={() => setShowModal(false)}
                className="close-modal-icon"
            />

            <div className="loader-container">
                {
                    closeStatus === "" ? <CircularProgress/> :
                    closeStatus.statusCode < 300 ?
                    <ResponseMessage
                        Icon={CheckIcon}
                        status={closeStatus.statusCode}
                        message={closeStatus.message}
                    /> :
                    <ResponseMessage
                        Icon={ErrorIcon}
                        status={closeStatus.statusCode}
                        message={closeStatus.message}
                    />
                    
                }
            </div>
        </section>
    )
}

const ResponseMessage = ({Icon, status, message}) => {

    return (

        <div className="response-message">
            <Icon  style={{color: status < 300 ? "green" : "red", fontSize: "1.5rem"}}/>
            <p>{message}</p>
        </div>
    )
}