import React from 'react'
import './../css/FAQ.css';
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';

export const FAQ = () => {

    
    return (

        <section id="faq" className="faq-section">

            <h3 className="faq-section-header">
                frequently asked questions
            </h3>

            <Question
                number={0}
                question={"who is fincheck?"}
                answer={"Fincheck (Pvt) Ltd is a Zimbabwean company that provides vital business information."}
            />

            <Question
                number={1}
                question={"How do I become a Fincheck client?"}
                answer={"Sign up to a contract, pay your subscription fee (which is dependent on the service you require), and you are signed up as a client."}
            />

            <Question
                 number={2}
                question={"How do I access credit reports?"}
                answer={"If you are a subscriber, you simply click “client login” at the top of our website, sign in with your user name and password and you are ready to go."}
            />

            <Question
                 number={3}
                question={"What are Fincheck's sources of information?"}
                answer={"Information is gathered from the courts, government institutions, regulators, businesses, financial institutions and credit providers."}
            />

            <Question
                number={4}
                question={"What sets you apart from other credit bureaus?"}
                answer={"Fincheck is not only a credit bureau, but a business information provider whose aim is to help Zimbabwean businesses retain and enhance value."}
            />

            <Question
                 number={5}
                question={"What is the difference between Fincheck and other debt recovery consultants?"}
                answer={"Fincheck strictly abides by the laws prevailing in the country.Collection commission is levied to the debtor and not taken from the principal amount"}
            />


        </section>
    )
}

const Question =  ({question,answer,number}) => {

    const collapsible = () => {

        const content = document.querySelectorAll(".content");
        content[number].classList.toggle("display-content");
    }

    return (
        
        <div className="question">
            <button className="collapsible" onClick={collapsible}>{question}<ExpandMoreOutlinedIcon/></button>
            <div className="content">
                <p>{answer}</p>
            </div>  
        </div>
    )
}