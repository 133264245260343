import React from 'react'
import { TableData, TableDataHeaders, TableHeading } from '../IndividualReport/ReportTemplates'

export const FormerEmploymentDetails = ({ workHistory }) => {

    if (workHistory.data.length === 0) {
        return (
            <table className="report_table">

                <TableHeading
                    heading={"Former Employment Details"}
                    span={8}
                />

                <tr className="data_row">
                    <td colSpan={10}>No Work History Available</td>
                </tr>
            </table>
        )
    }

    return (

        <table className="report_table">

            <TableHeading
                heading={"Former Employment Details"}
                span={8}
            />

            <tr className="data_row">
                <TableDataHeaders
                    data={"Employer"}
                />

                <TableData
                    data={workHistory.data[0].old_working_place}
                />
            </tr>


            <tr className="data_row">
                <TableDataHeaders
                    data={"Occupation"}
                />

                <TableData
                    data={workHistory.data[0].occupation}
                />
            </tr>

            <tr className="data_row">
                <TableDataHeaders
                    data={"Period Of Employment"}
                />

                <TableData
                    data={workHistory.data[0].period_of_employement}
                />
            </tr>

        </table>
    )
}
