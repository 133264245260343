import React, { useEffect, useContext, useState } from 'react';
import { handleResponses } from '../js/fetchData';
import { fetchByFinsNumbers } from '../js/searchRecord';
import { filterAbsconderSlowPayerActive } from '../js/filterAbsconderSlowPayerActive';
import "./../css/Searchtemplate.css";
import { Loader } from './Loader';
import CloseIcon from '@mui/icons-material/Close';
import { LoadingModal, Modal } from './Modal';
import axios from 'axios';
import { InputWithDropdownContainer, SelectContainer } from './InputContainer';
import { debtorType } from './Company/selectOptionsjson';
import AuthContext from '../AuthContext/AuthContext';
import { useNavigate } from 'react-router-dom';
import { ValuesContext } from './Admin/HomeAdmin';
import { closeIndivdualRecord, deleteRecord } from '../js/closeIndividualClaim';
import { type } from '@testing-library/user-event/dist/type';
import { getDate } from '../js/getDate';

export const Claims = () => {

    const { authTokens } = useContext(AuthContext);
    const navigate = useNavigate();
    const { userData } = useContext(ValuesContext);
    const [searchedEntity, setSearchedEntity] = useState("");
    const [showModal, setShowModal] = useState(false);
    const [closeStatus, setCloseStatus] = useState(false);
    const [debtorTypeState, setDebtorTypeState] = useState("Individual");
    const [creditAccounts, setCreditAccounts] = useState({ data: [], message: '' });
    const [activeAccs, setActiveCreditAccs] = useState("");
    const [failureFetch, setFailureFetch] = useState("");
    const [fetching, setFetching] = useState(false);
    const [claims, setClaims] = useState({ data: [], message: '' });
    const [courts, setCourts] = useState({ data: [], message: '' });
    const [absconders, setAbsconders] = useState({ data: [], message: '' });
    const [slowPayers, setSlowPayers] = useState({ data: [], message: '' });


    const closeClaim = () => {
        setShowModal(true)
        axios.post(`/close-records/`, {
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ${authTokens.access}`
            },
            data: {
                searchParameter: debtorTypeState.toLowerCase(),
                fins: searchedEntity.data[0].fins_number,
                closed_by: `${userData.data.firstname} ${userData.data.surname}`
            }
        })
            .then(res => {
                setCloseStatus({ statusCode: res.status, message: 'All records were closed successfully' });
                localStorage.setItem('clearanceDetails', JSON.stringify({
                    userData: { firstname: userData.data.firstname, lastname: userData.data.surname, position: userData.data.job_title },
                    cleared: { ...searchedEntity.data[0] }
                }))
                setTimeout(() => {
                    navigate("/letter/print-clearance-letter");
                }, 2000)
            })
            .catch(err => {
                setCloseStatus({ statusCode: err.status, message: 'Some records were not closed,try again' })
            })
    }


    useEffect(() => {

        if (
            claims.message === "fetch was successful" &&
            courts.message === "fetch was successful" &&
            creditAccounts.message === "fetch was successful"
        ) {

            setFailureFetch("done")
            // charging the client
            filterAbsconderSlowPayerActive(claims, setClaims, creditAccounts, setActiveCreditAccs, setAbsconders, setSlowPayers);

        }

    }, [claims, courts, creditAccounts])


    useEffect(() => {

        if (searchedEntity === "") {
            return;
        }

        axios.post(`/claim-search/`, {

            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ${authTokens.access}`
            }
            ,
            data: {
                searchParameter: `${debtorTypeState.toLowerCase()}_debtor_fins`,
                fins: searchedEntity.data[0].fins_number,
            }
        })
            .then(res => {

                if (res.status !== 200) {
                    setFailureFetch(true)
                }
                handleResponses(res, setClaims);

            })
            .catch(err => {
                setFailureFetch(true)
                handleResponses(err, setClaims);
            })



        fetchByFinsNumbers(
            String(authTokens.access),
            searchedEntity.data[0].fins_number,
            `${debtorTypeState.toLowerCase()}-credit-details/`,
            setCreditAccounts,
            setFailureFetch,
            null
        )

        fetchByFinsNumbers(
            String(authTokens.access),
            searchedEntity.data[0].fins_number,
            `${debtorTypeState.toLowerCase()}-court/`,
            setCourts,
            setFailureFetch,
            null
        )


    }, [searchedEntity])

    return (

        <section className="search-claims">
            {showModal && <LoadingModal closeStatus={closeStatus} setShowModal={setShowModal} />}
            <div className="search-claims-header">
                <p>Close Records</p>
            </div>


            <SearchSection
                setSearchedEntity={setSearchedEntity}
                debtorTypeState={debtorTypeState}
                setDebtorTypeState={setDebtorTypeState}
            />


            {
                searchedEntity !== "" &&
                <div className="searched-entity-details">

                    <div className="entity-id-reg">
                        <h4 className="entity-id-reg__header">
                            {debtorTypeState === "Individual" ? "ID Number: " : "Registration Number: "}
                        </h4>

                        <p className='entity-id-reg-value'>
                            {debtorTypeState === "Individual" ? searchedEntity.data[0].national_id : `${searchedEntity.data[0].registration_number}`}
                        </p>
                    </div>

                    <div className="entity-name">
                        <h4 className="entity-name__header">
                            {debtorTypeState === "Individual" ? "Name: " : "Registration Name: "}
                        </h4>

                        <p className='entity-name-value'>
                            {debtorTypeState === "Individual" ? searchedEntity.data[0].firstname + ` ${searchedEntity.data[0].surname}` : `${searchedEntity.data[0].registration_name}`}
                        </p>
                    </div>
                </div>


            }


            <div className="claims-container">

                <div className="claims-header">
                    <h4>Record Type</h4>
                    <h4>Creditor</h4>
                    <h4>Account Number</h4>
                    <h4>Record Date</h4>
                    <h4>Data Source</h4>
                    <h4>Currency</h4>
                    <h4>Amount</h4>
                    <h4>Delete</h4>
                </div>

                {
                    failureFetch === true &&
                    <p style={{ color: "red", textAlign: "center", marginTop: "1em" }}>Failed to retrieve all data</p>
                }

                {
                    (fetching === true && failureFetch === false) &&
                    <Loader />
                }

                {
                    (claims.data !== null && courts.data !== null && creditAccounts.data !== null) &&
                    (claims.data.length === 0 && courts.data.length === 0 && creditAccounts.data.length === 0) &&
                    (claims.message === 'fetch was successful' && courts.message === 'fetch was successful' && creditAccounts.message === 'fetch was successful') &&
                    <p style={{ textAlign: "center", marginTop: "1em" }}>No records found</p>
                }

                {
                    failureFetch === "done" &&
                    claims.data.length > 0 &&
                    claims.data.map((claim, index) => {

                        if (claim.is_closed !== true) {
                            return (
                                <Claim
                                    claim={claim}
                                    index={index}
                                    typeOfRecord={"claim"}
                                    authTokens={authTokens}
                                    userData={userData}
                                    setShowModal={setShowModal}
                                    setCloseStatus={setCloseStatus}
                                    searchedEntity={searchedEntity}
                                    navigate={navigate}
                                    claims={claims}
                                    setClaims={setClaims}
                                />
                            )
                        }
                    })
                }

                {
                    failureFetch === "done" &&
                    absconders.data.length > 0 &&
                    absconders.data.map((claim, index) => {
                        if (claim.is_closed !== true) {
                            return (
                                <Claim
                                    claim={claim}
                                    index={index}
                                    typeOfRecord={"absconder"}
                                    authTokens={authTokens}
                                    userData={userData}
                                    setShowModal={setShowModal}
                                    setCloseStatus={setCloseStatus}
                                    searchedEntity={searchedEntity}
                                    navigate={navigate}
                                    claims={claims}
                                    setClaims={setClaims}
                                />
                            )
                        }
                    })
                }

                {
                    failureFetch === "done" &&
                    slowPayers.data.length > 0 &&
                    slowPayers.data.map((slowPayer, index) => {

                        if (slowPayer.is_closed !== true) {
                            return (
                                <SlowPayer
                                    slowPayer={slowPayer}
                                    index={index}
                                    authTokens={authTokens}
                                    userData={userData}
                                    setShowModal={setShowModal}
                                    setCloseStatus={setCloseStatus}
                                    searchedEntity={searchedEntity}
                                    navigate={navigate}
                                    slowPayers={slowPayers}
                                    setSlowPayers={setSlowPayers}
                                />
                            )
                        }
                    })
                }

                {
                    failureFetch === "done" &&
                    courts.data.length > 0 &&
                    courts.data.map((court, index) => {
                        if (court.is_closed !== true) {
                            return (
                                <Court
                                    court={court}
                                    index={index}
                                    typeOfRecord={"court"}
                                    authTokens={authTokens}
                                    userData={userData}
                                    setShowModal={setShowModal}
                                    setCloseStatus={setCloseStatus}
                                    searchedEntity={searchedEntity}
                                    navigate={navigate}
                                    courts={courts}
                                    setCourts={setCourts}
                                />
                            )
                        }
                    })
                }



            </div>


            {
                (failureFetch === "done" && (claims.data.filter(claim => !claim.is_closed).length > 0 || courts.data.filter(court => !court.is_closed).length > 0 || slowPayers.data.filter(slow => !slow.is_closed).length > 0 || absconders.data.filter(abs => !abs.is_closed).length > 0)) &&
                <div className="close-records-btn-container">
                    <button
                        className="delete-btn"
                        onClick={closeClaim}
                    >
                        <CloseIcon />
                        Close All Records
                    </button>
                </div>
            }



        </section>
    )
}


const SearchSection = ({ setSearchedEntity, debtorTypeState, setDebtorTypeState }) => {

    const [searchTerm, setSearchTerm] = useState("");

    const { authTokens } = useContext(AuthContext);


    return (
        <div className="search-container-section">

            <form className="search-container-form">


                <div className="search-parameter-input-container">
                    <SelectContainer
                        label={"Select Filter"}
                        currentValue={debtorTypeState}
                        setValue={setDebtorTypeState}
                        options={debtorType}
                        id={""}
                        width={"100%"}

                    />
                </div>

                <div className="search-term-input-container">
                    <InputWithDropdownContainer
                        label={"Enter Name / ID  / Reg Number"}
                        type={"text"}
                        currentValue={searchTerm}
                        setValue={setSearchTerm}
                        id={""}
                        typeOfEntity={debtorTypeState}
                        setSearchedEntity={setSearchedEntity}
                        authTokens={authTokens}
                        width={"100%"}
                    />
                </div>


                {/* 
                <div className="search-section-btn-container">
                    <button 
                        className="search-button" 
                    >
                        Search
                    </button>
                </div> */}
            </form>
        </div>
    )
}

const Claim = ({ claim, index, typeOfRecord, authTokens, userData, setShowModal, setCloseStatus, searchedEntity, navigate, claims, setClaims }) => {

    return (
        <article className="claim" style={{ backgroundColor: index % 2 === 0 && 'white' }}>
            <p style={{ textTransform: 'capitalize' }}>{typeOfRecord}</p>
            <p>{claim.company_creditor_fins !== null ? claim.company_creditor_fins.registration_name : (claim.individual_creditor_fins ? `${claim.individual_creditor_fins.firstname} ${claim.individual_creditor_fins.surname}` : 'N/A')}</p>
            <p>{claim.account_number}</p>
            <p>{claim.date_of_claim}</p>
            <p>{claim.data_souce}</p>
            <p>{`${claim.currency_type}`}</p>
            <p>{claim.amount} </p>
            <p><button onClick={() => deleteRecord(claim.claim_number, 'claim', authTokens, claims, setClaims)} style={{ color: 'white', background: 'red', width: '100%', padding: '.3em 0', border: 'none', cursor: 'pointer' }}>Delete</button></p>
        </article>
    )

}

const SlowPayer = ({ slowPayer, index, authTokens, userData, setShowModal, setCloseStatus, searchedEntity, navigate, slowPayers, setSlowPayers }) => {


    return (
        <article className="claim" style={{ backgroundColor: index % 2 === 0 && 'white' }}>
            <p>Slowpayer</p>
            <p>{slowPayer.company_creditor !== null ? slowPayer.company_creditor.registration_name : `${slowPayer.individual_creditor.firstname} ${slowPayer.individual_creditor.surname}`}</p>
            <p>{slowPayer.acount_number}</p>
            <p>{slowPayer.data}</p>
            <p>{slowPayer.data_souce}</p>
            <p>{`${slowPayer.currency_type}`}</p>
            <p>{slowPayer.principal_amount} </p>
            <p><button onClick={() => deleteRecord(slowPayer.credit_detail, 'slowpayer', authTokens, slowPayers, setSlowPayers)} style={{ color: 'white', background: 'red', width: '100%', padding: '.3em 0', border: 'none', cursor: 'pointer' }}>Delete</button></p>
        </article>
    )

}

const Court = ({ court, index, typeOfRecord, authTokens, userData, setShowModal, setCloseStatus, searchedEntity, navigate, courts, setCourts }) => {

    return (
        <article className="claim" style={{ backgroundColor: index % 2 === 0 && 'white' }}>
            <p>Court</p>
            <p>{court.plaintf_name}</p>
            <p>{court.case_number}</p>
            <p>{getDate(court.date)}</p>
            <p>{court.data_souce}</p>
            <p>{`${court.currency_type}`}</p>
            <p>{court.amount} </p>
            <p><button onClick={() => deleteRecord(court.case_number, typeOfRecord, authTokens, courts, setCourts)} style={{ color: 'white', background: 'red', width: '100%', padding: '.3em 0', border: 'none', cursor: 'pointer' }}>Delete</button></p>
        </article>
    )

}
