import React, { useState, useEffect, useContext } from "react"
import { debtorType, CurrencyType } from "../Company/selectOptionsjson"
import { searchRecord } from "./../../js/searchRecord"
import { toast } from "react-toastify";
import { InputContainer, SelectContainer, InputAreaContainer, InputWithDropdownContainer } from "../InputContainer";
import { checkIfSearchedEntityIsAvailable } from "../../js/checkIfSearchedEntityIsAvailable";
import CircularProgress from '@mui/material/CircularProgress';
import { uploadSingleClaim } from "../../js/uploadSingleAdverseData";
import { ValuesContext } from '../Admin/HomeAdmin'

//
export const AddSingleClaim = ({ authTokens, dataTypeState }) => {

    const [claimAmount, setClaimAmount] = useState("");
    const { userData } = useContext(ValuesContext)
    const [uploading, setUploading] = useState(false)
    const [accountNumber, setAccountNumber] = useState("");
    const [typeIdentificationState, setTypeIdentificationState] = useState("");
    const [creditorIdentificationState, setCreditorIdentificationState] = useState('');
    const [debtorTypeState, setDebtorTypeState] = useState("Individual");
    const [creditorTypeState, setCreditorTypeState] = useState("Company");
    const [searchedEntity, setSearchedEntity] = useState((userData.data.groups[0] === 3 || userData.data.groups[0] === 4) ? { data: [userData.data.company] } : '');
    const [searchedCreditor, setSearchedCreditor] = useState((userData.data.groups[0] === 3 || userData.data.groups[0] === 4) ? { data: [userData.data.company] } : '');
    const [CurrencyTypeState, setCurrencyTypeState] = useState("USD");
    const [comment, setComment] = useState("dfsdfsf");
    const [dataDate, setDataDate] = useState("");
    const [dataSource, setDataSource] = useState((userData.data.groups[0] === 3 || userData.data.groups[0] === 4) ? `${userData.data.firstname} ${userData.data.surname}` : '');


    const clearFields = () => {
        setClaimAmount("");
        setAccountNumber("");
        setTypeIdentificationState("");
        setCreditorIdentificationState("");
        setDebtorTypeState("Individual");
        setCreditorTypeState("Individual");
        setSearchedEntity("");
        setSearchedCreditor("");
        setCurrencyTypeState("RTGS");
        setComment("");
        setDataDate("");
    }



    const sendAdverseData = (e) => {

        e.preventDefault();



        if (
            ((userData.data.groups[0] !== 2 || userData.data.groups[0] !== 1)) &&
            (searchedEntity.data.length !== 1 ||
                searchedCreditor.data.length !== 1)
        ) {
            toast.error(`Please provide the ${debtorTypeState} for this data to proceed`)
            return;
        }

        setUploading(true);

        uploadSingleClaim(
            userData.data.groups[0] === 1 || userData.data.groups[0] === 2 ? true : false,
            dataSource,
            searchedEntity,
            searchedCreditor,
            claimAmount,
            comment,
            dataDate,
            CurrencyTypeState,
            String(authTokens.access),
            debtorTypeState,
            creditorTypeState,
            dataTypeState,
            accountNumber,
            clearFields,
            setUploading
        )
    }




    return (
        <>
            <form className="adverse-single-form">

                <h3 style={{ marginBottom: '25px', backgroundColor: 'var(--main-color)', color: 'white', fontSize: '.9rem', textAlign: 'center', padding: '10px', width: '100%' }}>
                    Creditor
                </h3>


                {
                    (userData.data.groups[0] === 1 || userData.data.groups[0] === 2) &&
                    <InputWithDropdownContainer
                        label={"Creditor ID/Reg #/Name"}
                        type={"text"}
                        currentValue={creditorIdentificationState}
                        setValue={setCreditorIdentificationState}
                        id={""}
                        typeOfEntity={creditorTypeState}
                        authTokens={authTokens}
                        setSearchedEntity={setSearchedCreditor}
                    />
                }




                <InputContainer
                    label={"Data Date"}
                    type={"Date"}
                    currentValue={dataDate}
                    setValue={setDataDate}
                    id={""}
                />

                {
                    (userData.data.groups[0] === 1 || userData.data.groups[0] === 2) &&
                    <InputContainer
                        label={"Data Source"}
                        type={"text"}
                        currentValue={dataSource}
                        setValue={setDataSource}
                        id={""}
                    />
                }





                <h3 style={{ marginBottom: '25px', backgroundColor: 'var(--main-color)', color: 'white', fontSize: '.9rem', textAlign: 'center', padding: '10px', width: '100%' }}>
                    Debtor
                </h3>

                <SelectContainer
                    label={"Debtor Type"}
                    currentValue={debtorTypeState}
                    setValue={setDebtorTypeState}
                    options={debtorType}
                    id={""}
                />


                <InputWithDropdownContainer
                    label={"Debtor ID/Reg #/Name"}
                    type={"text"}
                    currentValue={typeIdentificationState}
                    setValue={setTypeIdentificationState}
                    id={""}
                    typeOfEntity={debtorTypeState}
                    authTokens={authTokens}
                    setSearchedEntity={setSearchedEntity}
                />


                <InputContainer
                    label={"Account Number"}
                    type={"text"}
                    currentValue={accountNumber}
                    setValue={setAccountNumber}
                    id={""}
                />


                <SelectContainer
                    label={"Currency"}
                    currentValue={CurrencyTypeState}
                    setValue={setCurrencyTypeState}
                    options={CurrencyType}
                    id={""}
                />

                <InputContainer
                    label={"Claim Amount"}
                    type={"text"}
                    currentValue={claimAmount}
                    setValue={setClaimAmount}
                    id={""}
                />

            </form>


            {
                uploading === true &&
                <CircularProgress
                    size={'.9rem'}
                />
            }

            {
                uploading === false &&
                <button className="btn-adverse-submit" onClick={e => sendAdverseData(e)}>
                    Submit
                </button>
            }
        </>

    )
}
