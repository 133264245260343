import axios from 'axios'
import '../../css/clientsarena.css'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'
import React, { useContext, useEffect, useState } from 'react'
import { toast, ToastContainer } from 'react-toastify'
import AuthContext from '../../AuthContext/AuthContext'
import AddIcon from '@mui/icons-material/Add'
import { Delete } from '@mui/icons-material'
import { DataSubmission } from '../Other/DataSubmission'
import { CircularProgress } from '@mui/material'
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord'
import { InputContainer, InputphoneContainer } from '../InputContainer'
import { async } from 'q'

const TableClients = ({
    setdisplay,
    setDisplayAddModal,
    setDisplayEditModal,
    setEditFinsNumber,
    displayaddmodal,
    displayeditmodal
}) => {
    let { authTokens, logoutUser } = useContext(AuthContext);
    const [allClientsData, setAllClientsData] = useState([])
    const [clientsData, setClientsData] = useState([])
    const [activeComponent, setActiveComponent] = useState('loading')
    const [search, setSearch] = useState('')


    function isBigEnough(value) {
        return value >= 10;
    }

    const filtered = [12, 5, 8, 130, 44].filter(isBigEnough);

    useEffect(
        //fetching clients
        () => {
            axios({
                method: 'get',
                url: '/client/',
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + String(authTokens.access)
                }
            })
                .then((res) => {
                    setClientsData(res.data)
                    setAllClientsData(res.data)
                    setActiveComponent('table')
                }).catch((AxiosError) => {
                    if (AxiosError.code === "ERR_NETWORK") {
                        toast.error("Network Error!")
                    } else (
                        toast.error('Internal system error contact system admin!')
                    )
                    setActiveComponent('error')
                }
                )
        }, [displayaddmodal, displayeditmodal]
    )

    useEffect(() => {
        if (search === '') {
            setClientsData(allClientsData)
        } else {
            setClientsData(allClientsData.filter((i) =>
                i.registration_name.toLowerCase().includes(search.toLowerCase()) ||
                i.registration_number.toLowerCase().includes(search.toLowerCase())
            )
            )
        }
    }, [search])


    const NewUser = () => {
        setDisplayAddModal('yes')

    }

    const Delete = async (id) => {
        if (window.confirm('Please confirm to delete !')) {
            setdisplay('loading')
            await DataSubmission(
                String(authTokens.access),
                'DELETE',
                '/client/' + id,
            )
            setdisplay('table')
        }
    }

    const EditClient = (fins) => {
        setDisplayEditModal('yes')
        setEditFinsNumber(fins)
    }

    const ClientSuspension = async (fins, name) => {
        if (window.confirm('Press OK to suspend or activate ' + name)) {
            setdisplay('loading')
            await DataSubmission(
                String(authTokens.access),
                'post',
                '/suspend-client/',
                { data: fins }

            )
            setdisplay('table')
        }
    }

    if (clientsData.length !== 0) {

        clientsData.sort((a, b) => a.registration_name.localeCompare(b.registration_name))
    }

    return (
        <div className='clientsTableContainer'>
            <div className='searchpanel'>
                <label>Search Client :  </label>
                <input className='inputsearchclientstable'
                    placeholder='Client name/ registaration number'
                    onChange={e => setSearch(e.target.value)}
                    value={search}></input>
            </div>
            {activeComponent === 'table' &&
                <div className='tablecontainerclientstablescroll'>
                    <table className='clientsTable'>
                        <thead className='clientstableheader'>
                            <tr>
                                <th className='clientstableheaderadd' colSpan={5}>
                                </th>
                                <th className='addnewclienttableth'
                                    colSpan={3}
                                    onClick={NewUser}
                                >
                                    <button icon="close" className="formButtonsClients" text="Close"
                                        align="center" >
                                        <AddIcon className='icons' />
                                        <blockquote>
                                            Add Client
                                        </blockquote>
                                    </button>
                                </th>
                            </tr>
                            <tr>
                                <th className='clientstableheader'>
                                    Account Number
                                </th>
                                <th className='clientstableheader'>
                                    Client Name
                                </th>
                                <th className='clientstableheader'>
                                    Registration Number
                                </th>
                                <th className='clientstableheader'>
                                    Email
                                </th>
                                <th className='clientstableheader'>
                                    Contact number
                                </th>
                                <th className='clientstableheader' colSpan={2}>
                                    Actions
                                </th>
                                <th className='clientstableheader'>
                                    Activation Status
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                clientsData.map((item) => {
                                    return (
                                        <tr key={item.fins_number}>
                                            <td className='clientstabledata'>{item.account_number}</td>
                                            <td className='clientstabledata'>{item.registration_name}</td>
                                            <td className='clientstabledata'>{item.registration_number}</td>
                                            <td className='clientstabledata'>{item.email}</td>
                                            <td className='clientstabledata'>{item.mobile_phone}</td>
                                            <td className='clientstabledataicons'>
                                                <EditIcon className='editiconsclients'
                                                    onClick={() => EditClient(item.fins_number)} />
                                            </td>
                                            <td className='clientstabledataicons'>
                                                <DeleteIcon className='editiconsclientsdelete'
                                                    onClick={() => Delete(item.fins_number)}
                                                />
                                            </td>
                                            {item.is_suspended === false ?
                                                <td className='clientstabledata-active'>
                                                    <FiberManualRecordIcon className='activeclient'
                                                        onClick={() => ClientSuspension(item.fins_number, item.registration_name)} />
                                                </td>

                                                :

                                                <td className='clientstabledata-suspended'>
                                                    <FiberManualRecordIcon className='suspendedclient'
                                                        onClick={() => ClientSuspension(item.fins_number, item.registration_name)} />
                                                </td>
                                            }
                                        </tr>
                                    )
                                })}
                        </tbody>
                    </table>
                </div>
            }
            {activeComponent === 'loading' &&
                <div className='circularprogressdivclientstanle'>
                    <CircularProgress className='loadingstatusclient' />
                </div>
            }
        </div>

    )
}

export default TableClients
