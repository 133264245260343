import React from 'react'
import { TableDataHeaders, TableHeading, TableData } from '../../Individuals/IndividualReport/ReportTemplates'

export const NameChangeHistory = ({ color, nameChange }) => {
    return (

        <table className="report_table">

            <tr className='row_heading' style={{ margin: '50px 0', backgroundColor: '#b4c6e7', color: 'black' }}>
                <td className='txt_rowheading' colSpan={4}>
                    <h1 className='section_heading' style={{ fontSize: '1rem' }}>
                        Name Change History
                    </h1>
                </td>
            </tr>


            {
                nameChange.data !== null &&
                nameChange.data.map(change => {

                    return (
                        <tr className="data_row">

                            <TableDataHeaders
                                data={"Previous Name:"}
                                span={1}
                                id={"date_heading"}
                            />

                            <TableData
                                id={"registered_name_field"}
                                data={`${change.old_name}`}
                                textAlignClass={'text_section_left_align'}
                            />

                            <TableDataHeaders
                                data={"Date Last Used:"}
                                span={1}
                                id={"date_heading"}
                            />

                            <TableData
                                id={"registered_name_field"}
                                data={`${change.created}`}
                                textAlignClass={'text_section_left_align'}
                            />
                        </tr>
                    )
                })
            }



        </table>
    )
}
