import React from 'react'
import { useRouteError } from "react-router-dom";

export const Error500 = () => {

  const error = useRouteError();

  return (
    <div className='error-500' style={{ width: "100%", height: "100%", display: "grid", placeItems: "center" }}>

      <p className="error-500-msg" style={{ color: 'red', fontSize: ".9rem", fontWeight: "500", textAlign: "center", marginTop: "-5em" }}>
        {error.statusText || error.message}
      </p>
    </div>
  )
}
