
import React, { useState, useContext } from 'react'
import { InputContainer, SelectContainer } from '../InputContainer';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import '../../css/FormContainerStyle.css';
import '../../css/addcompany.css';
import SaveIcon from '@mui/icons-material/Save'
import AddIcon from '@mui/icons-material/Add'
import { CurrencyType } from './selectOptionsjson';
import { DataSubmission } from '../Other/DataSubmission';
import { toggleForms } from '../../js/toggleForms';
import AuthContext from "../../AuthContext/AuthContext";

/* ___________________________________________________add and edit company Capital____________________________________________________ */
export const CapitalForm = ({
  finsNumber, capitalData, id, setDisplayedForm, caller, setrun, setactive
}) => {
  const { authTokens, logoutUser } = useContext(AuthContext);
  const [formofPayment, setFormofPayment] = useState(() => {
    if (capitalData.length > 0) {
      return capitalData[0].form_of_payment
    } else {
      return ''
    }
  }
  )
  const [currency, setCurrency] = useState(() => {
    if (capitalData.length > 0) {
      return capitalData[0].currency_type
    } else {
      return ''
    }
  })
  const [authorised, setAuthorised] = useState(() => {
    if (capitalData.length > 0) {
      return capitalData[0].auth
    } else {
      return ''
    }
  })
  const [networth, setNetworth] = useState(() => {
    if (capitalData.length > 0) {
      return capitalData[0].networth
    } else {
      return ''
    }
  })
  const [paidUp, setPaidUp] = useState(() => {
    if (capitalData.length > 0) {
      return capitalData[0].paid_up
    } else {
      return ''
    }
  })
  const [value, setValue] = useState(() => {
    if (capitalData.length > 0) {
      return capitalData[0].networth
    } else {
      return ''
    }
  })
  const [norminal, setNorminal] = useState(() => {
    if (capitalData.length > 0) {
      return capitalData[0].norminal_capital
    } else {
      return ''
    }
  })
  const [raised, setRaised] = useState(() => {
    if (capitalData.length > 0) {
      return capitalData[0].capital_raised
    } else {
      return ''
    }
  })

  const SubmitCompanyCapital = async (e, action) => {
    e.preventDefault()
    let responsed

    if (capitalData.length > 0) {
      responsed = await DataSubmission(
        String(authTokens.access),
        'put',
        '/company-capital/' + capitalData[0].capital_id + '/',
        {
          capital_id: capitalData[0].capital_id,
          form_of_payment: formofPayment,
          networth: networth,
          value: value,
          currency_type: currency,
          auth: authorised,
          paid_up: paidUp,
          norminal_capital: norminal,
          capital_raised: raised,
          company: finsNumber
        }
      )
    } else {
      responsed = await DataSubmission(
        String(authTokens.access),
        'post',
        '/company-capital/',
        {
          form_of_payment: formofPayment,
          networth: networth,
          value: value,
          currency_type: currency,
          auth: authorised,
          paid_up: paidUp,
          norminal_capital: norminal,
          capital_raised: raised,
          company: finsNumber
        }
      )
    }

    if (caller === 'editCapital') {
      if (action === 'done' && responsed[0].result === 'success') {
      }
    } else {
      if (action === 'done' && responsed[0].result === 'success') {
       setrun('finished'); setactive('Comment')
      }
    }

  }


  return (
    <section className="form-container">
      <h3 className="form-header">Company Capital Form</h3>
      <form className="form">

        <section className='secinputcontainer'>

          <h4 className="sectionHeading"> Capital Details </h4>

          <section className=' secinputdeatails'>

            <SelectContainer
              label={"Currency"}
              currentValue={currency}
              setValue={setCurrency}
              options={CurrencyType}
            />

            <InputContainer
              label={"Networth"}
              type={"number"}
              currentValue={networth}
              setValue={setNetworth}
              id={' networth'}
            />

            <InputContainer
              label={"Form of Payment"}
              type={"text"}
              currentValue={formofPayment}
              setValue={setFormofPayment}
              id={'formof_payment'}
            />

            <InputContainer
              label={"Capital Value"}
              type={"number"}
              currentValue={value}
              setValue={setValue}
              id={'value'}
            />

            <InputContainer
              label={"Authorised Capital"}
              type={"number"}
              currentValue={authorised}
              setValue={setAuthorised}
              id={'authorised'}
            />

            <InputContainer
              label={"Paid up Capital"}
              type={"number"}
              currentValue={paidUp}
              setValue={setPaidUp}
              id={'paidup'}
            />

            <InputContainer
              label={"Norminal Capital"}
              type={"number"}
              currentValue={norminal}
              setValue={setNorminal}
              id={'norminal'}
            />

            <InputContainer
              label={"Raised Capital"}
              type={"number"}
              currentValue={raised}
              setValue={setRaised}
              id={'date_aquired'}
            />
          </section>

        </section>

        <section className='secButtons'>

          <button icon="close" className="formButtons" text="Close"
            align="center" onClick={e => SubmitCompanyCapital(e, 'done')} >
            <SaveIcon className='icons' />
            <blockquote>
              Save And Proceed
            </blockquote>
          </button>

        </section>

      </form>

    </section>
  )
}
