import React, { useState, useContext, useEffect } from "react";
import { InputContainer } from "../InputContainer";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../../css/FormContainerStyle.css";
import "../../css/addcompany.css";
import SaveIcon from "@mui/icons-material/Save";
import AddIcon from "@mui/icons-material/Add";
import { DataSubmission } from "../Other/DataSubmission";
import { toggleForms } from "../../js/toggleForms";
import AuthContext from "../../AuthContext/AuthContext";

/* ___________________________________________________add and Edit associted Companies____________________________________________________ */

export const AddEditAssociatedCompanies = ({
  companyFins,
  associatedCompanies,
  id,
  setDisplayedForm,
  setactive,
  setstatus,
  caller
}) => {
  const { authTokens, logoutUser } = useContext(AuthContext);

  const [companyName, setCompanyName]
    =
    useState(
      () => {
        if (caller === 'editassoc') {
          return (associatedCompanies.company_name)
        } else {
          return ('')
        }
      }
    );


  const SubmitAssociatedCompany = async (e, action) => {
    setstatus('loading')
    e.preventDefault();
    let responsed = ''
    /*
    if (caller === 'edit' || caller === 'editassoc') {
      setrun('loading')
    }
    */

    if (caller === 'editassoc') {
      responsed = await DataSubmission(
        String(authTokens.access),
        "put",
        "/associated-company/" + associatedCompanies.assoc_id + '/',
        {
          assoc_id: associatedCompanies.assoc_id,
          company_name: companyName,
          assoc_with: associatedCompanies.assoc_with,
        }
      );
    } else {
      responsed = await DataSubmission(
        String(authTokens.access),
        "post",
        "/associated-company/",
        {
          company_name: companyName,
          assoc_with: companyFins,
        }
      );
    }
    if (caller === 'editassoc') {
      if (responsed[0].result === "success") {
        setstatus('finished')
      }
    }
  };

  return (
    <section className="form-container-Biu" style={{ marginLeft: 'auto', marginRight: 'auto' }}>
      <form className="form">
        <InputContainer
          label={"Company Name"}
          type={"text"}
          currentValue={companyName}
          setValue={setCompanyName}
          id={"company_name"}
        />

        <button
          icon="close"
          className="formButtons"
          text="Close"
          align="center"
          onClick={(e) => SubmitAssociatedCompany(e, "done")}
        >
          <SaveIcon className="icons" />
          <blockquote>Update</blockquote>
        </button>
      </form>
    </section>
  );
};
