import React, { useContext, useEffect, useState } from 'react'
import '../../css/FormContainerStyle.css';
import '../../css/addcompany.css';
import { toast, ToastContainer } from 'react-toastify';
import { InputAreaContainer, InputContainer, InputphoneContainer, SelectContainer } from '../InputContainer';
import { Industry, LegalStatus, TradingStatus } from './selectOptionsjson';
import SaveIcon from '@mui/icons-material/Save'
import { DataSubmission } from '../Other/DataSubmission';
import 'react-toastify/dist/ReactToastify.css'
import { AxiosError } from 'axios';
import { toggleForms } from '../../js/toggleForms';
import AuthContext from '../../AuthContext/AuthContext';

//addind company form component
/* ___________________________________________________add and edit Bank Accounts____________________________________________________ */
export const EditCompany = ({ companyData, }) => {

  let { authTokens, logoutUser } = useContext(AuthContext);
  if (companyData) {
    if (companyData[0].length !== 0) {
      var object = companyData[0]
      Object.keys(object).map(
        key => {
          if (key === 'subscription_category') {
            if (object[key] === undefined || object[key] === null || object[key] === '') {
              object[key] = []
            }
          }
          if (object[key] === undefined || object[key] === null) {
            object[key] = '--'
          }
        }
      )
    }
  }
  const [regNumber, setRegNumber] = useState(companyData[0].registration_number);
  const [registeredName, setRegisteredName] = useState(companyData[0].registration_name)
  const [legalStatus, setLegalStatus] = useState(companyData[0].legal_status);
  const [tradingStatus, setTradingStatus] = useState(companyData[0].trading_status);
  const [industry, setIndustry] = useState(companyData[0].industry)
  const [parentCompany, setParentCompany] = useState(companyData[0].parent_company);
  const [formerAddress, setFormerAddress] = useState(companyData[0].former_address);
  const [currentAddress, setCurrentAddress] = useState(companyData[0].current_address);
  const [mobilePhone, setMobilePhone] = useState(companyData[0].mobile_phone);
  const [tradingName, setTradingName] = useState(companyData[0].trading_name);
  const [regDate, setRegDate] = useState(companyData[0].registration_date);
  const [bpNumber, setBpNumber] = useState(companyData[0].bp_number);
  const [landlinePhone, setLandlinePhone] = useState(companyData[0].land_line_phone);
  const [vatNumber, setVatNumber] = useState(companyData[0].vat_number);
  const [parentDunsNumber, setParentDunsNumber] = useState(companyData[0].parent_duns_number);
  const [numberOfEmployees, setNumberOfEmployees] = useState(companyData[0].number_of_employees);
  const [website, setWebsite] = useState(companyData[0].website);
  const [postalAddress, setPostalAddress] = useState(companyData[0].postal_address);
  const [trend, setTrend] = useState(companyData[0].trend);
  const [conditionCo, setConditionCo] = useState(companyData[0].condition_co);
  const [email, setEmail] = useState(companyData[0].email);
  const [dateOfCommitment, setDateOfCommitment] = useState('pending');
  const [twitter, setTwitter] = useState(companyData[0].twitter);
  const [linkedIn, setLinkedIn] = useState(companyData[0].linkedIn);
  const [instagram, setInstagram] = useState(companyData[0].instagram);
  const [facebook, setFacebook] = useState(companyData[0].facebook);
  const [contactPerson, setContactPerson] = useState(companyData[0].contact_person)
  const [judicial, setJudicial] = useState(companyData[0].is_under_judicial)

  const SubmitCompany = async (e) => {
    e.preventDefault()
    const responsed = await DataSubmission(
      String(authTokens.access),
      'put',
      '/company/' + companyData[0].fins_number + '/',
      {
        fins_number: companyData[0].fins_number,
        registration_number: regNumber,
        registration_name: registeredName,
        legal_status: legalStatus,
        trading_status: tradingStatus,
        parent_company: parentCompany,
        former_address: formerAddress,
        current_address: currentAddress,
        postal_address: postalAddress,
        mobile_phone: mobilePhone,
        trading_name: tradingName,
        registration_date: regDate,
        bp_number: bpNumber,
        land_line_phone: landlinePhone,
        vat_number: vatNumber,
        number_of_employees: numberOfEmployees,
        website: website,
        trend: trend,
        condition_co: conditionCo,
        email: email,
        date_of_commitment: dateOfCommitment,
        industry: industry,
        contact_person: contactPerson,
        is_under_judicial: judicial
      }
    )

  }
  return (
    <section className="form-container">
      <h3 className="form-header">Edit Company : {registeredName}</h3>
      <form className="form">

        <section className='secinputcontainer'>

          <h4 className="sectionHeading"> Company Details </h4>

          <section className=' secinputdeatails'>
            <InputContainer
              label={"Company Registration Number"}
              type={"text"}
              currentValue={regNumber}
              setValue={setRegNumber}
              id={'regnumber'}
            />

            <InputContainer
              label={"Registered Name"}
              type={"text"}
              currentValue={registeredName}
              setValue={setRegisteredName}
            />

            <InputContainer
              label={"Registration Date"}
              type={"date"}
              currentValue={regDate}
              setValue={setRegDate}
            />

            <InputContainer
              label={"Trading Name"}
              type={"text"}
              currentValue={tradingName}
              setValue={setTradingName}
            />

            <InputContainer
              label={"VAT Number"}
              type={"text"}
              currentValue={vatNumber}
              setValue={setVatNumber}
            />

            <InputContainer
              label={"BP Number"}
              type={"text"}
              currentValue={bpNumber}
              setValue={setBpNumber}
            />

            <InputContainer
              label={"Parent Company"}
              type={"text"}
              currentValue={parentCompany}
              setValue={setParentCompany}
            />

            <InputContainer
              label={"Number of Employees"}
              type={"number"}
              currentValue={numberOfEmployees}
              setValue={setNumberOfEmployees}
            />

            <InputContainer
              label={"Condition"}
              type={"text"}
              currentValue={conditionCo}
              setValue={setConditionCo}
            />

            <InputContainer
              label={"Trend"}
              type={"text"}
              currentValue={trend}
              setValue={setTrend}
            />

            <InputContainer
              label={"Date Of Commitment for Operations"}
              type={"date"}
              currentValue={dateOfCommitment}
              setValue={setDateOfCommitment}
            />

            {/*____________________________________________________________________________________________________________ */}
            <h4 className='sectionHeading'> industry trading status and legal status</h4>

            <SelectContainer
              label={"Industry"}
              currentValue={industry}
              setValue={setIndustry}
              options={Industry}
            />
            <SelectContainer
              label={"Legal Status"}
              currentValue={legalStatus}
              setValue={setLegalStatus}
              options={LegalStatus}
            />

            <SelectContainer
              label={"Trading Status"}
              currentValue={tradingStatus}
              setValue={setTradingStatus}
              options={TradingStatus}
            />

            {/*____________________________________________________________________________________________________________ */}

            <h4 className='sectionHeading'> Contact Details</h4>

            <InputContainer
              label={"Former Address"}
              type={"text"}
              currentValue={formerAddress}
              setValue={setFormerAddress}
            />

            <InputContainer
              label={"Current Address"}
              type={"text"}
              currentValue={currentAddress}
              setValue={setCurrentAddress}
            />

            <InputContainer
              label={"Postal Address"}
              type={"text"}
              currentValue={postalAddress}
              setValue={setPostalAddress}
            />

            <InputphoneContainer
              label={"Mobile Number"}
              type={"tel"}
              currentValue={mobilePhone}
              setValue={setMobilePhone}
              placeHolder={"eg 00263778000000"}
            />

            <InputphoneContainer
              label={"Landline Phone"}
              type={"tel"}
              currentValue={landlinePhone}
              setValue={setLandlinePhone}
              placeHolder={"eg 00263540000000"}
            />

            <InputContainer
              label={"Email Address"}
              type={"email"}
              currentValue={email}
              setValue={setEmail}
            />

            <InputphoneContainer
              label={"Contact Person"}
              type={"text"}
              currentValue={contactPerson}
              setValue={setContactPerson}
              placeHolder={" eg : Managing Director"}
            />

            {/*____________________________________________________________________________________________________________ */}
            <h4 className='sectionHeading'>On the web</h4>


            <InputContainer
              label={"Website"}
              type={"url"}
              currentValue={website}
              setValue={setWebsite}
            />

            <InputContainer
              label={"On LinkedIn"}
              type={"text"}
              currentValue={linkedIn}
              setValue={setLinkedIn}
            />

            <InputContainer
              label={"On Twitter"}
              type={"text"}
              currentValue={twitter}
              setValue={setTwitter}
            />

            <InputContainer
              label={"On Facebook"}
              type={"text"}
              currentValue={facebook}
              setValue={setFacebook}
            />

            <InputContainer
              label={"On Instagram"}
              type={"text"}
              currentValue={instagram}
              setValue={setInstagram}
            />

          </section>

        </section>

        <section className='secButtons'>
          <button icon="close" className="formButtons" text="Close"
            align="center" onClick={e => SubmitCompany(e)} >
            <SaveIcon className='icons' />
            <blockquote>
              Update
            </blockquote>
          </button>
        </section>

      </form>
    </section>





  )
}
