import React, { useContext, useState, useEffect } from 'react'
import axios from 'axios';
import './../css/Targets.css';
import EditIcon from '@mui/icons-material/Edit';
import { getDate } from './../js/getDate';
import { InputContainer } from './InputContainer';
import AuthContext from '../AuthContext/AuthContext';
import { toast } from 'react-toastify';
import { fetchData } from '../js/fetchData';

export const Targets = () => {

  const headers = [
    'period',
    'period name',
    'enquiries (Qty)',
    'rescissions (Qty)',
    'hp (Qty)',
    'Business (Qty)',
  ]
  const { authTokens } = useContext(AuthContext);
  const [periodName, setPeriodName] = useState('');
  const [enquires, setEnquires] = useState('');
  const [rescission, setRescission] = useState('');
  const [hp, setHp] = useState('');
  const [businessReports, setBusinessReports] = useState('');
  const [showAddForm, setShowAddForm] = useState(false)
  const [periods, setPeriods] = useState({ data: null })
  const [refresh, setRefresh] = useState(1)

  useEffect(() => {

    fetchData(
      String(authTokens.access),
      setPeriods,
      'target',
      null,
      null
    )
  }, [refresh])

  const resetFields = () => {
    setPeriodName('');
    setEnquires('');
    setRescission('');
    setHp('');
    setBusinessReports('');
  }


  const addTarget = () => {

    axios.post('target/', {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + String(authTokens.access)
      },
      period_name: periodName,
      enqueries: enquires,
      rescissions: rescission,
      hp: hp,
      s_reports: businessReports


    })
      .then(function (response) {

        if (response.status >= 200 && response.status < 300) {
          toast.success('Target Added')
          const currentPeriods = periods.data !== null && [...periods.data];
          setPeriods({ data: [...currentPeriods, response.data] })
          resetFields()
        } else {
          toast.error('Target Failed to Add')
        }
      })
      .catch((err) => {
        console.error(err)
        toast.error('Target failed to add,check your network')
      });
  }

  return (

    <section className="targets-coontainer">

      <h3 className="targets-header">
        Targets
      </h3>


      <Table
        headers={headers}
        periods={periods}
        setPeriods={setPeriods}
        refresh={refresh}
        setRefresh={setRefresh}
      />

      <button className="add-target-btn" onClick={() => setShowAddForm(!showAddForm)}>
        Add
      </button>

      {
        showAddForm &&
        <section className="add-target-section">
          <div className='add-target-inputs'>
            <InputContainer
              label={'Period Name'}
              currentValue={periodName}
              setValue={setPeriodName}
              type={'date'}
              name={'periodName'}
            />

            <InputContainer
              label={'Enquires'}
              currentValue={enquires}
              setValue={setEnquires}
              type={'number'}
              name={'enquires'}
            />

            <InputContainer
              label={'Rescissions'}
              currentValue={rescission}
              setValue={setRescission}
              type={'number'}
              name={'rescission'}
            />

            <InputContainer
              label={'HP'}
              currentValue={hp}
              setValue={setHp}
              type={'number'}
              name={'hp'}
            />

            <InputContainer
              label={'Business Reports'}
              currentValue={businessReports}
              setValue={setBusinessReports}
              type={'number'}
              name={'businessReports'}
            />
          </div>

          <button className="edit-targets-submit" onClick={addTarget}>
            Submit
          </button>
        </section>
      }

    </section>
  )
}

const Table = ({ headers, periods, setPeriods, refresh, setRefresh }) => {

  return (

    <table className="qty-table" border={1}>

      <tr>
        {
          headers.map((header, index) => {

            return (
              <th key={index}>{header}</th>
            )
          })
        }
      </tr>

      {
        periods.data !== null &&
        periods.data.map((period, index) => {

          return <TableRowEntity
            key={index}
            period={period}
            index={index}
            setPeriods={setPeriods}
            setRefresh={setRefresh}
            refresh={refresh}
          />
        })
      }


    </table>



  )
}

const TableRowEntity = ({ period, index, setPeriods, periods, refresh, setRefresh }) => {

  const [editForm, setEditForm] = useState(false)
  const { authTokens } = useContext(AuthContext);
  const [periodName, setPeriodName] = useState(period.period_name);
  const [enquires, setEnquires] = useState(period.enqueries);
  const [rescission, setRescission] = useState(period.rescissions);
  const [hp, setHp] = useState(period.hp);
  const [businessReports, setBusinessReports] = useState(period.s_reports);

  const submitEditTargets = () => {

    axios.put(`target/${period.id}/`, {

      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + String(authTokens.access)
      },
      id: period.id,
      period_name: periodName,
      enqueries: enquires,
      rescissions: rescission,
      hp,
      s_reports: businessReports

    })
      .then((response) => {

        if (response.status >= 200 && response.status < 300) {
          toast.success('Target Updated');
          setEditForm(false)
          setRefresh(refresh + 1)
        } else {
          toast.error('Target Not Modified')
        }
      })
      .catch((err) => {
        toast.error('Target Not Modified')
      })
  }



  return (

    <>
      <tr>
        <td>{period.id}</td>
        <td>{getDate(period.period_name)}</td>
        <td>{period.enqueries}</td>
        <td>{period.rescissions}</td>
        <td>{period.hp}</td>
        <td>{period.s_reports}</td>
        <td onClick={() => setEditForm(!editForm)} style={{ backgroundColor: 'var(--main-color)' }}><EditIcon style={{ color: 'white' }} /></td>
      </tr>

      {
        editForm &&

        <tr className="edit-target">
          <td colSpan={7}>
            <div className="edit-target-inputs">
              <InputContainer
                label={'Period Name'}
                currentValue={periodName}
                setValue={setPeriodName}
                type={'date'}
                name={'periodName'}
              />

              <InputContainer
                label={'Enquires'}
                currentValue={enquires}
                setValue={setEnquires}
                type={'number'}
                name={'enquires'}
              />

              <InputContainer
                label={'Rescissions'}
                currentValue={rescission}
                setValue={setRescission}
                type={'number'}
                name={'rescission'}
              />

              <InputContainer
                label={'HP'}
                currentValue={hp}
                setValue={setHp}
                type={'number'}
                name={'hp'}
              />

              <InputContainer
                label={'Business Reports'}
                currentValue={businessReports}
                setValue={setBusinessReports}
                type={'number'}
                name={'businessReports'}
              />
            </div>

            <button className="edit-targets-submit" onClick={submitEditTargets}>
              Submit
            </button>
          </td>
        </tr>
      }
    </>
  )
}

