import React, { useState, useEffect, useContext } from 'react';
import "./../css/ClientBUIDashboardView.css"
import { Reports, AddReportBtnTable } from './InternalUserBIUDashboardView';
import { fetchData } from '../js/fetchData';
import { ValuesContext } from './Admin/HomeAdmin';
import AuthContext from '../AuthContext/AuthContext';

export const ClientBIUDashboardView = () => {

  const [showAddBiuModal, setShowAddBiuModal] = useState(false);
  const { authTokens } = useContext(AuthContext)
  const [biuReportReqs, setBIUReportReqs] = useState({ data: null });
  const [clientBius, setClientBius] = useState({ data: null })
  const { userData } = useContext(ValuesContext);

  useEffect(() => {

    fetchData(
      String(authTokens.access),
      setBIUReportReqs,
      "biu-request",
      null,
      null
    );

  }, [showAddBiuModal])

  useEffect(() => {

    if (biuReportReqs.data !== null) {
      const clientBius = biuReportReqs.data.filter(biuReq => {

        if (biuReq.user !== null && (biuReq.user.username === userData.data.username)) {
          return biuReq;
        }
      });
      setClientBius({ data: clientBius });
    }

  }, [biuReportReqs])




  return (

    <section className="client-biu-dash-view">

      <Reports
        showAddBiuModal={showAddBiuModal}
        setShowAddBiuModal={setShowAddBiuModal}
        biuReportReqs={clientBius}
        setBIUReportReqs={setClientBius}
      />

      <AddReportBtnTable
        showAddBiuModal={showAddBiuModal}
        setShowAddBiuModal={setShowAddBiuModal}
      />

    </section>
  )

}

