import React, { useContext } from 'react'
import '../../../css/clientsarena.css'
import { AddEditCompany } from '../../Company/AddEditCompany'
import CloseIcon from '@mui/icons-material/Close'
import EditClientNav from './EditClientNav'
import { useState, useEffect } from 'react'
import axios from 'axios'
import { toast, ToastContainer } from 'react-toastify';
import AuthContext from '../../../AuthContext/AuthContext'
import { CircularProgress } from '@mui/material'
import { SubscriptionTable, UsersTable } from './ExportTables'
import AddeditUser from '../AddeditUser'
import AddEditSubscription from '../AddEditSubscription'

const EditClientContainer = ({
    setdisplay,
    visible,
    setvisible,
    editFinsNumber,
    setEditFinsNumber
}) => {
    const [editDisplayForm, setEditDisplayForm] = useState('client')
    const [editClientData, setEditClientData] = useState([])
    const [status, setStatus] = useState('red')
    const [editUserDetails, setEditUserDetails] = useState([])
    const [editSubscription, setEditSubscription] = useState([])
    const { authTokens, logoutUser } = useContext(AuthContext);

    useEffect(() => {
        axios({
            method: 'get',
            url: '/company/' + editFinsNumber + '/',
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: "Bearer " + String(authTokens.access)
            }
        })
            .then((res) => {
                setEditClientData(result => result.concat(res.data))
                setStatus('green')
            }).catch((AxiosError) => {
                if (AxiosError.code === "ERR_NETWORK") {
                    toast.error("Network Error!")
                } else (
                    toast.error('Internal system error contact system admin!')
                )
            }
            )
    }, [editFinsNumber])


    return (
        <div className={visible === 'yes' ?
            'editClientContainerModal'
            :
            'editClientContainerModalHide'
        } >
            <div className='editclientmain'>
                <div className='addclientnavigation'>
                    <EditClientNav
                        setdisplayForm={setEditDisplayForm}
                        displayform={editDisplayForm}
                    />

                </div>

                <div className='addclientforms'>
                    {(editDisplayForm === 'client' && status === 'green') &&
                        <AddEditCompany
                            companyData={editClientData}
                            caller={'editClient'}
                        />
                    }

                    {(editDisplayForm === 'adminUser' && status === 'green') &&
                        <UsersTable
                            editFinsNumber={editFinsNumber}
                            setdisplay={setEditDisplayForm}
                            setEditUser={setEditUserDetails}
                        />
                    }

                    {(editDisplayForm === 'addUser' && status === 'green') &&
                        <AddeditUser
                            finsNumber={editFinsNumber}
                            caller={'adduserclient'}
                            setactive={setEditDisplayForm}
                            usergroup={'external'}
                            setdisplay = {setEditDisplayForm}
                        />
                    }

                    {(editDisplayForm === 'editUser' && status === 'green') &&
                        <AddeditUser
                            finsNumber={editFinsNumber}
                            caller='edituser'
                            setactive={setEditDisplayForm}
                            userData={editUserDetails}
                            usergroup={'external'}
                            setdisplay = {setEditDisplayForm}
                        />
                    }

                    {(editDisplayForm === 'subscription' && status === 'green') &&
                        <SubscriptionTable
                            editFinsNumber={editFinsNumber}
                            setdisplay={setEditDisplayForm}
                            setEditSubscription={setEditSubscription}
                        />
                    }

                    {(editDisplayForm === 'addsubscription' && status === 'green') &&
                        <AddEditSubscription
                            finsNumber={editFinsNumber}
                            caller={'addsubscriptionclientedit'}
                            setdisplay={setEditDisplayForm}
                        />
                    }

                    {(editDisplayForm === 'editsubscription' && status === 'green') &&
                        <AddEditSubscription
                            finsNumber={editFinsNumber}
                            caller={'editsubscription'}
                            setdisplay={setEditDisplayForm}
                            subData = {editSubscription}
                        />
                    }

                    {status === 'red' &&
                        <div className='loadingcircleclients'>
                            <CircularProgress />
                        </div>
                    }
                </div>

                <div>
                    <CloseIcon className='closeIcon' onClick={() => { setvisible('no'); setEditFinsNumber('') }} />
                </div>
            </div>
        </div>
    )
}

export default EditClientContainer