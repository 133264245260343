import React from 'react'
import { TableHeading } from '../../Individuals/IndividualReport/ReportTemplates'

export const Comment = ({comment}) => {

  return (
    
    <table className="report_table">

        <TableHeading
            heading={"COMMENT"}
        />

        <tr>
            {
               <td>CLEAR TO DATE IN OUR FILES IN THE NAME OF THE BUSINESS AND PRINCIPALS</td>
            }
        </tr>


    </table>
  )
}
