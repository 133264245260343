
import React from "react";

export default function RenderBankAcc({ service }) {
  if (service === "BureauEnqZWL") {
    return (
        <>
            <p>First Capital Bank</p>
            <p>21576486189</p>
            <p>Pearl House</p>
        </>
    )
  } else if (service === "BureauEnqUSD") {
    return (
        <>
            <p>First Capital Bank</p>
            <p>21573761936</p>
            <p>Pearl House</p>
        </>
    )
  }else {
    return ''
  }

}
