import React, { useState } from 'react'
import '../../css/clientsarena.css'
import TableClients from './TableClients'
import { CircularProgress } from '@mui/material'
import AddEditClient from './AddEditClient'
import EditClientContainer from './EditClients/EditClientContainer'

const LandingClient = ({ section }) => {
    const [display, setDisplay] = useState(section)
    const [displayAddModal, setDisplayAddModal] = useState('no')
    const [displayEditModal, setDisplayEditModal] = useState('no')
    const [editFinsNumber, setEditFinsNumber] = useState('')

    return (
        <div className='clientsMainDiv'>
            <div className='clientsarenaheader'>
                <h2>Clients</h2>
            </div>

            {display === 'table' &&
                <TableClients
                    displayaddmodal={displayAddModal}
                    displayeditmodal={displayEditModal}
                    setdisplay={setDisplay}
                    setDisplayAddModal={setDisplayAddModal}
                    setDisplayEditModal={setDisplayEditModal}
                    setEditFinsNumber={setEditFinsNumber}
                />
            }

            {display === 'loading' &&
                <CircularProgress className='clientstableloading' />
            }


            <AddEditClient
                setdisplay={setDisplay}
                visible={displayAddModal}
                setvisible={setDisplayAddModal}
            />
            {editFinsNumber !== '' &&
                <EditClientContainer
                    setdisplay={setDisplay}
                    visible={displayEditModal}
                    setvisible={setDisplayEditModal}
                    editFinsNumber={editFinsNumber}
                    setEditFinsNumber={setEditFinsNumber}
                />
            }


        </div>
    )
}

export default LandingClient 
