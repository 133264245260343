import React from 'react';
import CompanyEdit from './Edit/CompanyEdit';
import './../../css/EditReport.css';
import { useParams } from 'react-router-dom';

export const CompanyBIUEdit = () => {

  const {id} = useParams();

  return (
    
    <section className="edit-report-container">
        <CompanyEdit id={id}/>
    </section>
  )
}
