import React, { useState, useContext } from 'react';
import AddIcon from '@mui/icons-material/Add';
import { AddEditCompany } from '../Company/AddEditCompany';
import AuthContext from '../../AuthContext/AuthContext';
import axios from 'axios';
import AddEditIndividual from './AddEditIndividual';
import { Modal } from '../Modal'
import './../../css/AdverseUploads.css';
import { dataTypeBulk, dataType } from '../Company/selectOptionsjson';
import { InputContainer, SelectContainer } from '../InputContainer';
import { AddSingleClaim } from '../Other/AddSingleClaim';
import { AddSingleCourtRec } from '../Other/AddSingleCourtRec';
import { AddSinglePublicRec } from '../Other/AddSinglePublicRecord';
import BulkForm from '../BulkUpload/BulkForm';
import { toast } from 'react-toastify';

export const IndividualUploads = ({ setFormStatus }) => {

  const [showAddModal, setShowAddModal] = useState(false);
  const [showSingleUpload, setShowSingleUpload] = useState(true);
  const [formToDisplay, setFormToDisplay] = useState("");

  const displayForm = (e) => {
    const form = e.target.innerText
    setFormToDisplay(form);
    setShowAddModal(true);
  }

  const switchTab = (e) => {

    if (e.target.innerText === "Single") {
      setShowSingleUpload(true);
      e.target.classList.add("active")
      document.querySelector(".multiple-btn").classList.remove("active");
    } else {
      setShowSingleUpload(false);
      e.target.classList.add("active")
      document.querySelector(".single-btn").classList.remove("active");
    }


  }

  return (

    <section className="adverse-uploads-container">

      {
        <div className="adverse-uploads-nav">

          <div className="single-multiple-btns-container">
            <button className='single-btn active' onClick={e => switchTab(e)}>Single</button>
            <button onClick={e => switchTab(e)} className="multiple-btn">Multiple</button>
          </div>

        </div>
      }

      {
        showSingleUpload === true &&
        <div className="upload-section-container" style={{ padding: "1em 0" }}>
          <AddEditIndividual setDisplayedForm={setFormStatus} />
        </div>

      }

      {
        showSingleUpload === false &&
        <MultipleUploads />
      }
    </section>
  )
}



export const SearchEntity = ({ entityToBeSearched }) => {

  const [entityName, setEntity] = useState('');
  const { authTokens } = useContext(AuthContext);
  const endpoint = entityToBeSearched === 'Search Individual' ? 'search-individual' : 'search-company';
  const searchParameter = entityToBeSearched === 'Search Individual' ? 'firstname' : 'registration_name';

  const search = (endpoint, token, searchTerm, searchParameter) => {

    axios.post(`/${endpoint}/`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token
      },
      data: {
        search: searchTerm,
        searchParameter
      }
    })
      .then(response => {

        if (response.status >= 200 && response.status < 300) {

          if (response.data.length > 0) {
            toast.success('Entry is already in the database');
            return;
          }

          if (response.data.length === 0) {
            toast.success('Entry is not available,you can add.');
          }


        } else {
          toast.error('Something went wrong,try again later')
        }

      })
      .catch(() => toast.error('Failed to send request,check your connection'));
  }

  return (

    <section className="search-entity">

      <InputContainer
        label={entityToBeSearched}
        type={'text'}
        currentValue={entityName}
        setValue={setEntity}
      />

      <button className='search-entity-btn' onClick={() => search(endpoint, String(authTokens.access), entityName, searchParameter)}>
        Search
      </button>
    </section>
  )
}

const MultipleUploads = () => {

  return (
    <section className="multiple-adverse-uploads-container">
      <div className="bulk-forms-container">
        <BulkForm data={'Individuals'} />
      </div>


    </section>
  )
}

