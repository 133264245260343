import React, { useState, useContext } from 'react'
import { InputAreaContainer, InputContainer } from '../InputContainer';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import '../../css/FormContainerStyle.css';
import '../../css/addcompany.css';
import SaveIcon from '@mui/icons-material/Save'
import CloseIcon from '@mui/icons-material/Close'
import AddIcon from '@mui/icons-material/Add'
import { DataSubmission } from './DataSubmission';
import { toggleForms } from '../../js/toggleForms';
import AuthContext from "../../AuthContext/AuthContext";
/* ___________________________________________________add Public Record____________________________________________________ */
export const PublicRecordsForm = (
    { finsNumber, publicRecordsData, id, setDisplayedForm, caller, setrun, setactive }
) => {
    const { authTokens, logoutUser } = useContext(AuthContext);

    const [date, setDate] = useState(() => {
        if (caller === 'editRecord') {
            return publicRecordsData[0].date
        } else {
            return ''
        }
    })

    const [source, setSource] = useState(() => {
        if (caller === 'editRecord') {
            return publicRecordsData[0].source
        } else {
            return ''
        }
    })

    const [link, setLink] = useState(() => {
        if (caller === 'editRecord') {
            return publicRecordsData[0].link
        } else {
            return ''
        }
    })

    const [caseNumber, setCaseNumber] = useState(() => {
        if (caller === 'editRecord') {
            return publicRecordsData[0].case_number
        } else {
            return ''
        }
    })

    const [summary, setSummary] = useState(() => {
        if (caller === 'editRecord') {
            return publicRecordsData[0].summary
        } else {
            return ''
        }
    })

    const SubmitRecord = async (e, action) => {
        e.preventDefault()
        let responsed = ''
        var data

        if (caller === 'editRecord') {
            if (id === 'edit-company-executives') {
                data = {
                    id: publicRecordsData[0].id,
                    date: date,
                    company: finsNumber,
                    source: source,
                    link: link,
                    case_number: caseNumber,
                    summary: summary
                }

            } else {
                data = {
                    id: publicRecordsData[0].id,
                    date: date,
                    individual: finsNumber,
                    source: source,
                    link: link,
                    case_number: caseNumber,
                    summary: summary
                }
            }
            responsed = await DataSubmission(
                String(authTokens.access),
                'put',
                '/public-record/' + publicRecordsData[0].id + '/',
                data
            )
        } else {
            if (caller === 'addCompany') {
                data = {
                    date: date,
                    company: finsNumber,
                    source: source,
                    link: link,
                    case_number: caseNumber,
                    summary: summary
                }

            } else {
                data = {
                    date: date,
                    individual: finsNumber,
                    source: source,
                    link: link,
                    case_number: caseNumber,
                    summary: summary
                }
            }
            responsed = await DataSubmission(
                String(authTokens.access),
                'post',
                '/public-record/',
                data
            )
        }

        if (caller === 'edit' || caller === 'editRecord') {
            if (action === 'done' && responsed[0].result === 'success') {
                if (id === 'edit-company-executives') {
                    setrun('finished'); setactive('PublicRecords')
                } else {
                    setrun('finished'); setactive('TradeReferences')
                }
            }
            else if (action === 'add' && responsed[0].result === 'success') {
                setCaseNumber(''); setDate(null); setLink(''); setSource(''); setSummary('')
                var txt_summary = document.getElementById('txt_summary').value;
                txt_summary = '';
            }
        } else {
            if (action === 'done' && responsed[0].result === 'success') {

                if (caller === 'addCompany') {
                    setrun('finished'); setactive('TradeReferences')
                } else {
                    toggleForms(null, "edit-individual-bank-accounts", setDisplayedForm, id)
                }
            }
            else if (action === 'add' && responsed[0].result === 'success') {
                setCaseNumber(''); setDate(null); setLink(''); setSource(''); setSummary('')
                var txt_summary = document.getElementById('txt_summary').value;
                txt_summary = '';
            }
        }
    }


    return (
        <section className="form-container">
            <h3 className="form-header">Public Records Form</h3>
            <form className="form" name="publicRecord">

                <section className='secinputcontainer'>

                    <h4 className="sectionHeading"> Record Details  </h4>

                    <section className=' secinputdeatails'>

                        <InputContainer
                            label={"Source"}
                            type={"text"}
                            currentValue={source}
                            setValue={setSource}
                            id={'txt_source'}
                        />


                        <InputContainer
                            label={"Date Publishd"}
                            type={"date"}
                            currentValue={date}
                            setValue={setDate}
                            id={'txt_date'}
                        />

                        <InputContainer
                            label={"Case Number"}
                            type={"text"}
                            currentValue={caseNumber}
                            setValue={setCaseNumber}
                            id={'txt_caseNumber'}
                        />

                        <InputContainer
                            label={"Link"}
                            type={"url"}
                            currentValue={link}
                            setValue={setLink}
                            id={'txt_link'}
                        />

                        <InputAreaContainer
                            label={"Summary"}
                            type={"text"}
                            currentValue={summary}
                            setValue={setSummary}
                            id={'txt_summary'}
                        />

                    </section>

                </section>
                {caller === 'editRecord' ?
                    <section className='secButtons'>
                        <button className="formButtons" text="Close" onClick={e => SubmitRecord(e, "done")}>
                            <SaveIcon className='icons' />
                            <blockquote>
                                Update
                            </blockquote>
                        </button>

                    </section>
                    :
                    <section className='secButtons'>

                        <button className="formButtons" text="Close" onClick={e => SubmitRecord(e, "add")}>
                            <AddIcon className='icons' />
                            <blockquote>
                                Save and Add Another
                            </blockquote>
                        </button>

                        <button className="formButtons" text="Close" onClick={e => SubmitRecord(e, "done")}>
                            <SaveIcon className='icons' />
                            <blockquote>
                                Save and Proceed
                            </blockquote>
                        </button>

                    </section>
                }
            </form>


        </section>
    )
}

