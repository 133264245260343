import { TableData, TableDataHeaders, TableHeading } from '../../Individuals/IndividualReport/ReportTemplates'
import React from 'react'

export const Principals = ({ directorships }) => {

    return (

        <table className="report_table" style={{ width: '100%' }}>

            <tr className='row_heading' style={{ margin: '50px 0', backgroundColor: '#b4c6e7', color: 'black' }}>
                <td className='txt_rowheading' colSpan={6}>
                    <h1 className='section_heading' style={{ fontSize: '1rem' }}>
                        Principals
                    </h1>
                </td>
            </tr>

            <tr className="data_row">

                <TableDataHeaders
                    data={"Full Name"}
                    id={"surname_header"}
                />

                <TableDataHeaders
                    data={"ID"}
                    id={"id_header"}
                />


                <TableDataHeaders
                    data={"Position"}
                    id={"position_header"}
                />

                <TableDataHeaders
                    data={"Address"}
                    id={"address_header"}
                />

                <TableDataHeaders
                    data={"Email"}
                    id={"address_header"}
                />

                <TableDataHeaders
                    data={"Contact Number"}
                    id={"address_header"}
                />


            </tr>

            {
                (directorships.data !== null && directorships.data.length === 0) &&
                <tr className='data_row'>
                    NOT SUPPLIED
                </tr>
            }

            {
                directorships.data !== null &&
                directorships.data.map(directorship => {

                    return (
                        <tr className="data_row">

                            <TableData
                                id={"surname_field"}
                                data={`${directorship.individual.firstname} ${directorship.individual.surname}`}
                            />

                            <TableData
                                id={"id_field"}
                                data={directorship.individual.national_id}
                            />

                            <TableData
                                id={"position_field"}
                                data={directorship.post}
                            />


                            <TableData
                                id={"address_field"}
                                data={directorship.individual.address}
                            />

                            <TableData
                                id={"firstname_field"}
                                data={directorship.individual.email}
                            />

                            <TableData
                                id={"firstname_field"}
                                data={directorship.individual.mobile}
                            />






                        </tr>
                    )
                })
            }

        </table>
    )
}
