import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import finlogo from "../backgrounds/finlog.png";

import InvoiceReportCSS from "../css/FiscalTaxInvoice.module.css";
import RenderBankAcc from "./RenderBankAcc";
import USDorZWL from "./USDorZWL"

export default function PDFTaxInvoice({ data, service }) {
  const location = useLocation();
  const [isClicked, setIsClicked] = useState(false);

  console.log('fhjfhjffhjfhj',service);
  // console.log(data[0].individual_data[0]);

  const printReport = () => {
    setIsClicked(true);
    setTimeout(() => {
      window.print();
    }, 500);
  };
  return (
    <>
      <table className="Individual_report">
        <tr className="report_section">
          <th className="report-header">
            <tr className="company-report-header-row">
              <div className="company-report-logo-container">
                <img src={finlogo} alt="fincheck logo" />
                <p className="moto"></p>
              </div>

              {/* <div className="header-address">
                    <p className="txtheader-address">
                      8th Floor Club Chambers
                      <br />
                      Cnr Nelson Mandela Avenue / 3rd Street <br />
                      Harare
                      <br />
                      Zimbabwe
                    </p>
                  </div> */}

              <div className="" style={{ textAlign: "right" }}>
                FINCHECK PVT LTD <br />
                <p style={{ textAlign: "right" }}>8th Floor Club Chambers</p>
                <p style={{ textAlign: "right" }}>
                  Corner Nelson Mandela Avenue and Third Street
                </p>
                <p style={{ textAlign: "right" }}>Zimbabwe</p>
                <p style={{ textAlign: "right" }}>VAT Number: 10010440</p>
                <p style={{ textAlign: "right" }}>TIN: 2000032265</p>
                <p style={{ textAlign: "right" }}>accounts@fincheckzim.com</p>
                <p style={{ textAlign: "right" }}>(242)-704891/4</p>

              </div>
            </tr>

            <tr className="header-name">
              <h4 className="creditreporton"></h4>
            </tr>
          </th>
        </tr>
        <div
          style={{
            width: "100%",
            marginBottom: "10px",
            border: "1px solid grey",
          }}
        ></div>
        {/* border={2} style={{ width: "100%", padding: "20px" }} */}
        <div
          style={{
            textAlign: "center",
            marginTop: "10px",
            marginBottom: "10px",
          }}
        >
          <h5 style={{ fontSize: "34px", fontWeight: "bold" }}>
            FISCAL TAX INVOICE
          </h5>
        </div>
        <div>
          <p>Document Number: 1114{Math.floor(Math.random() * (99 - 10 + 1)) + 10} </p>
          <p>
            Bill to:{" "}<br/>
            <span style={{ fontWeight: "bold" }}>
              {data[0].individual_data[0].client !== "N/A"
                ? data[0].individual_data[0].client
                : data[0].company_data[0].client}
            </span>{" "}
          </p>
          <p>
            Address:{" "}
            {data[0].individual_data[0].address.length > 0
              ? data[0].individual_data[0].address
              : data[0].company_data[0].address}
          </p>
          <p>
            Email:
            {data[0].individual_data[0].email.length > 0
              ? data[0].individual_data[0].email
              : data[0].company_data[0].email}
          </p>
          <p>
            
            VAT No.:
            {data[0].individual_data[0].vat_number.length > 0
              ? data[0].individual_data[0].vat_number
              : data[0].company_data[0].vat_number}
          </p>
          <p>
            TIN.:
            
          </p>
          
          <p>
            Date:{" "}
            <b>
              {new Date().toLocaleDateString("en-US", {
                month: "long",
                day: "numeric",
                year: "numeric",
              })}
            </b>
          </p>
        </div>
        <div className={InvoiceReportCSS.floatTheadWrapper}>
          <table
            style={{
              width: "100%",
              border: "1px solid black",
              borderCollapse: "collapse",
            }}
          >
            <thead>
              <tr>
                <th scope="col" style={{ textAlign: "center" }}>
                  DESCRIPTION
                </th>
                <th scope="col" style={{ textAlign: "center" }}>
                  Price(Inc)
                </th>
                <th scope="col" style={{ textAlign: "center" }}>
                  QTY
                </th>
                <th scope="col" style={{ textAlign: "center" }}>
                  VAT
                </th>
                <th scope="col" style={{ textAlign: "center" }}>
                  Total (Inc)
                </th>
              </tr>
            </thead>
            <tbody>
              {data[0].individual_data[0].client !== "N/A" ? (
                <tr>
                  <th scope="row">Consumer Enquiries</th>
                  <td style={{ textAlign: "center" }}>
                    {Number(Number(data[0].individual_data[0].price)*1.15).toFixed(2)}
                  </td>
                  <td style={{ textAlign: "center" }}>
                    {data[0].individual_data[0].qty}
                  </td>
                  <td style={{ textAlign: "center" }}>
                    {Number(Number(Number(data[0].individual_data[0].price)*1.15)*Number(data[0].individual_data[0].qty)*Number(15/115)).toFixed(2)}
                  </td>
                  <td style={{ textAlign: "center" }}>
                    {Number(Number(data[0].individual_data[0].price)*1.15*Number(data[0].individual_data[0].qty)).toFixed(2)}
                  </td>
                </tr>
              ) : (
                ""
                // <tr>
                //   <th scope="row">Consumer Enquiries</th>
                //   <td style={{ textAlign: "end" }}>
                //     0
                //   </td>
                //   <td style={{ textAlign: "end" }}>
                //     0
                //   </td>
                //   <td style={{ textAlign: "end" }}>
                //     0
                //   </td>
                //   <td style={{ textAlign: "end" }}>
                //     0
                //   </td>
                // </tr>
              )}

              {data[0].company_data[0].client !== "N/A" ? (
                <tr>
                  <th scope="row">Company Enquiries</th>
                  <td style={{ textAlign: "center" }}>
                  {Number(Number(data[0].company_data[0].price)*1.15).toFixed(2)}
                  </td>
                  <td style={{ textAlign: "center" }}>
                    {data[0].company_data[0].qty}
                  </td>
                  <td style={{ textAlign: "center" }}>
                  {Number(Number(Number(data[0].company_data[0].price)*1.15)*Number(data[0].company_data[0].qty)*Number(15/115)).toFixed(2)}
                  </td>
                  <td style={{ textAlign: "center" }}>
                    {Number(Number(data[0].company_data[0].price)*1.15*Number(data[0].company_data[0].qty)).toFixed(2)}
                  </td>
                </tr>
              ) : (
                ""
                // <tr>
                //   <th scope="row">Company Enquiries</th>
                //   <td style={{ textAlign: "end" }}>
                //     0
                //   </td>
                //   <td style={{ textAlign: "end" }}>
                //     0
                //   </td>
                //   <td style={{ textAlign: "end" }}>
                //     0
                //   </td>
                //   <td style={{ textAlign: "end" }}>
                //     0
                //   </td>
                // </tr>
              )}
              {/* <tr>
                  <th scope="row">Business Report</th>
                  <td style={{ textAlign: "end" }}>
                    40
                  </td>
                  <td style={{ textAlign: "end" }}>
                    1
                  </td>
                  <td style={{ textAlign: "end" }}>
                    0
                  </td>
                  <td style={{ textAlign: "end" }}>
                    40
                  </td>
                </tr> */}
            </tbody>
          </table>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: "10px",
            }}
          >
            <div>
              <h5
                style={{
                  fontSize: "18px",
                  fontWeight: "bold",
                  marginBottom: "5px",
                }}
              >
                Banking Details
              </h5>
              <RenderBankAcc service={service} />
            </div>
            <div style={{ width: "50%" }}>
              <table
                style={{
                  width: "100%",
                  border: "1px solid black",
                  borderCollapse: "collapse",
                }}
              >
                <thead></thead>
                <tbody>
                  <tr>
                    <th scope="row" style={{ textAlign: "end" }}>
                      Total (Excluding VAT)
                    </th>
                    <td style={{ textAlign: "center" }}>
                      {Number(Number(Number(Number(data[0].individual_data[0].price)*1.15*Number(data[0].individual_data[0].qty))+Number(Number(data[0].company_data[0].price)*1.15*Number(data[0].company_data[0].qty)))*Number(100/115)).toFixed(2)}
                      
                    </td>
                  </tr>

                  <tr>
                    <th scope="row" style={{ textAlign: "end" }}>
                      Discount
                    </th>
                    <td style={{ textAlign: "center" }}>-</td>
                  </tr>
                  <tr>
                    <th scope="row" style={{ textAlign: "end" }}>
                      {" "}
                      VAT Total
                    </th>
                    <td style={{ textAlign: "center" }}>
                      {Number(Number(Number(Number(data[0].individual_data[0].price)*1.15)*Number(data[0].individual_data[0].qty)*Number(15/115))+Number(Number(Number(Number(data[0].company_data[0].price)*1.15)*Number(data[0].company_data[0].qty)*Number(15/115)))).toFixed(2)}
                    </td>
                  </tr>

                  <tr>
                    <th scope="row" style={{ textAlign: "end" }}>
                      Invoice Total <USDorZWL service={service}/>
                    </th>
                    <td style={{ textAlign: "center" }}>
                      {Number(Number(Number(Number(Number(data[0].individual_data[0].price)*1.15*Number(data[0].individual_data[0].qty))+Number(Number(data[0].company_data[0].price)*1.15*Number(data[0].company_data[0].qty)))*Number(100/115))+Number(Number(Number(Number(Number(data[0].individual_data[0].price)*1.15)*Number(data[0].individual_data[0].qty)*Number(15/115))+Number(Number(Number(Number(data[0].company_data[0].price)*1.15)*Number(data[0].company_data[0].qty)*Number(15/115)))))).toFixed(2)}
                    </td>
                  </tr>
                </tbody>

                {/* <tbody>
                  <tr>
                    <th scope="row" style={{ textAlign: "end" }}>
                      Total (Excluding VAT)
                    </th>
                    <td style={{ textAlign: "end" }}>{Number(data[0].company_data[0].charge)}</td>
                  </tr>

                  <tr>
                    <th scope="row" style={{ textAlign: "end" }}>
                      Discount
                    </th>
                    <td style={{ textAlign: "end" }}>0.00</td>
                  </tr>
                  <tr>
                    <th scope="row" style={{ textAlign: "end" }}>
                      {" "}
                      V.A.T
                    </th>
                    <td style={{ textAlign: "end" }}>{data[0].company_data[0].vat}</td>
                  </tr>

                  <tr>
                    <th scope="row" style={{ textAlign: "end" }}>
                      Invoice Total ($ZWL)
                    </th>
                    <td style={{ textAlign: "end" }}>{data[0].company_data[0].total}</td>
                  </tr>
                </tbody> */}
              </table>
            </div>
          </div>
        </div>

        <tr className="report_section"></tr>
      </table>
      {/* <div
        style={{ marginLeft: "90px", marginTop: "20px", marginBottom: "90px" }}
      >
        <button
          // className={InvoiceReportCSS.removePrintBtn}
          className={isClicked ? InvoiceReportCSS.removePrintBtn : InvoiceReportCSS.submitBtn}

          onClick={() => printReport()}
        >
          Print
        </button>
      </div> */}
    </>
  );
}

