import { calculateReportDays } from "./getDate";

export const reportColorStatus = (report) => {

    let colorStatus = "";


    if (report.action_typing_date !== null && report.status !== 'Active') {

        const days = calculateReportDays(report.action_typing_date);

        if (days > 3) {
            colorStatus = "red";
        } else {
            colorStatus = "orange"
        }

    } else {
        switch (report.status) {

            case "Pending":
                colorStatus = "green";
                break;
            case "Suspended":
                colorStatus = "black";
                break;
            default:
                break;

        }
    }


    return colorStatus;


}