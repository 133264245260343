const creditBureau = "We provide quality data that enables us to categorize the level of risk associated with giving credit to a particular individual or entity. Consumer profile checker - For individual credit history Commercial profile checker - For credit culture of business entities.Hire Purchase checker - We provide credit information when a client wants to buy a car on hire purchase";


const businessIntelligenceReports = "Information is money. Business Intelligence serves you with an in-depth commercial report for risk analysis and credit assessment purposes. We provide these reports for both local and external clients";

const debtRecovery = "With a team with over 70 years of debt recovery experience, and the most efficient debt management software, we provide ethical but stringent debt recovery services. We are respected and valued by our clients for the professionalism and effectiveness we bring to the process of recovering lost value";

const training = "In our fast-paced world, Individuals and businesses that are given proper training and support in their professional development are likely to be more motivated with an advanced knowledge base leading to an increase in overall productivity";

export const productsDescs = {
    creditBureau,
    businessIntelligenceReports,
    debtRecovery,
    training
}