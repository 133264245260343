import React, { useState, useContext } from "react";
import { InputContainer } from "../InputContainer";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../../css/FormContainerStyle.css";
import "../../css/addcompany.css";
import SaveIcon from "@mui/icons-material/Save";
import AddIcon from "@mui/icons-material/Add";
import { DataSubmission } from "../Other/DataSubmission";
import { toggleForms } from "../../js/toggleForms";
import AuthContext from "../../AuthContext/AuthContext";

/* ___________________________________________________add and edit company Division____________________________________________________ */
export const AddEditCompanyDivision = ({
  companyFins,
  companyDivisionData,
  id,
  setDisplayedForm,
  caller,
  setrun,
  setactive
}) => {
  const [divisionName, setDivisionName] = useState(
    () => {
      if (caller === 'editDivision') {
        return companyDivisionData[0].division
      } else {
        return ''
      }
    }
  );
  const { authTokens, logoutUser } = useContext(AuthContext);

  const SubmitCompanyDivision = async (e, action) => {
    e.preventDefault();
    let responsed = ''

    if (caller === 'editDivision') {
      responsed = await DataSubmission(
        String(authTokens.access),
        "put",
        "/division/" + companyDivisionData[0].division_id + '/',
        {
          division_id: companyDivisionData[0].division_id,
          division: divisionName,
          company: companyFins,
        }
      );
    } else {
      responsed = await DataSubmission(
        String(authTokens.access),
        "post",
        "/division/",
        {
          division: divisionName,
          company: companyFins,
        }
      );
    }

    if (caller === 'edit' || caller === 'editDivision') {
      if (action === "done" && responsed[0].result === "success") {
        setrun('finished'); setactive('CompanyDivisions')
      } else if (action === "add" && responsed[0].result === "success") {
        setDivisionName("");
      }
    } else {
      if (action === "done" && responsed[0].result === "success") {
       setrun('finished'); setactive('AssociatedCompanies')
      } else if (action === "add" && responsed[0].result === "success") {
        setDivisionName("");
      }
    }
  };

  return (
    <section className="form-container">
      <h3 className="form-header">Company Division Form</h3>
      <form className="form">
        <section className="secinputcontainer">
          <h4 className="sectionHeading"> Division Details </h4>

          <section className=" secinputdeatails">
            <InputContainer
              label={"Division Name"}
              type={"text"}
              currentValue={divisionName}
              setValue={setDivisionName}
              id={"division"}
            />

          </section>
        </section>
        {caller === 'editDivision' ?
          <section className="secButtons">
            <button
              icon="close"
              className="formButtons"
              text="Close"
              align="center"
              onClick={(e) => SubmitCompanyDivision(e, "done")}
            >
              <SaveIcon className="icons" />
              <blockquote>Update</blockquote>
            </button>
          </section>
          :

          <section className="secButtons">
            <button
              type="submit"
              className="formButtons"
              text="Close"
              align="center"
              onClick={(e) => SubmitCompanyDivision(e, "add")}
            >
              <AddIcon className="icons" />
              <blockquote>Save And Add Another</blockquote>
            </button>

            <button
              icon="close"
              className="formButtons"
              text="Close"
              align="center"
              onClick={(e) => SubmitCompanyDivision(e, "done")}
            >
              <SaveIcon className="icons" />
              <blockquote>Save And Proceed</blockquote>
            </button>
          </section>
        }

      </form>
    </section>
  );
};
