import React, { useContext, useEffect, useState, useRef } from 'react';
import './../css/InternalUsers.css';
import { Modal } from './Modal';
import AddeditUser from './Clients/AddeditUser';
import { ValuesContext } from './Admin/HomeAdmin';
import { fetchData } from '../js/fetchData';
import AuthContext from '../AuthContext/AuthContext';
import axios from 'axios';
import { toast } from 'react-toastify';
import DataTable from 'react-data-table-component';


export const ClientAminUsersAdd = () => {

    const [showAddModal, setShowAddModal] = useState(false);
    const { userData } = useContext(ValuesContext)
    const { authTokens } = useContext(AuthContext);
    const [modalClose, setModalClose] = useState(0)
    const [userGroup, setUserGroup] = useState('internal');
    const [allUsers, setAllUsers] = useState(null);
    const [allInternalUsers, setAllInternalUsers] = useState(null)
    const [internalUsers, setInternalUsers] = useState(null);
    const [selectedUser, setSelectedUser] = useState({});
    const [showEditModal, setShowEditModal] = useState(false);
    const searchUsersInput = useRef();

    //search user
    const searchUsers = (e) => {
        const searchValue = e.target.value;


        if (searchValue === '') {
            setInternalUsers(allInternalUsers);
            return
        }

        const filteredUsers = allInternalUsers.filter(user => {
            const fullName = user.firstname + ' ' + user.surname;
            if (
                user.firstname.toLowerCase().includes(e.target.value.toLowerCase()) ||
                user.surname.toLowerCase().includes(e.target.value.toLowerCase()) ||
                user.email.toLowerCase().includes(e.target.value.toLowerCase()) ||
                user.username.toLowerCase().includes(e.target.value.toLowerCase()) ||
                fullName.toLowerCase().includes(e.target.value.toLowerCase())
            ) {
                return user
            }
        })

        setInternalUsers(filteredUsers);

    }



    useEffect(() => {

        fetchData(
            String(authTokens.access),
            setAllUsers,
            'user',
            null,
            null,
        )

    }, [showAddModal, modalClose])


    useEffect(() => {

        if (allUsers === null) return;
        if (Object.keys(userData).length === 0) return;
        if (userData.data === null) return;

        const filteredInternalUsers = allUsers.data.filter(user => {

            const userFins = user.company !== null && user.company.fins_number;
            const companyfins = userData.data.company !== null && userData.data.company.fins_number;

            if (user.company === null || userData.data.company === null) return;
            if (userFins === companyfins) {

                return user;
            };
        })

        setInternalUsers(filteredInternalUsers)
        setAllInternalUsers(filteredInternalUsers)

    }, [allUsers, userData])

    const clickToShowEditModal = (user) => {
        setSelectedUser(user)
        setShowEditModal(true)
    }

    const deleteUser = (user) => {

        axios.delete(`/user/${user.id}`, {
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: "Bearer " + String(authTokens.access)
            },
        })
            .then(response => {

                if (response.status >= 200 && response.status < 300) {
                    toast.success('User deleted successfully');
                    const leftUsers = internalUsers.filter(allUser => allUser.id !== user.id);
                    setInternalUsers(leftUsers);
                } else {
                    toast.error('Could not delete user')
                }
            })
            .catch(() => toast.error('Failed to send request,check your connection'));
    }


    const showModal = (e) => {
        setShowAddModal(true)
    }

    const columns = [
        {
            name: 'Surname',
            selector: row => row.surname,
            sortable: 'true'
        },
        {
            name: 'First Name',
            selector: row => row.firstname,
            sortable: 'true'
        },
        {
            name: 'Level',
            selector: row => row.groups[0] === 3 ? 'Admin' : 'User',
            sortable: 'true'
        },
        {
            name: 'Email',
            selector: row => row.email,
            sortable: 'true'
        },
        {
            name: 'Mobile Number',
            selector: row => row.mobile,
            sortable: 'true'

        },
        {
            name: 'Edit',
            cell: row => <div data-tag="allowRowEvents"><button className='edit-internal-user' onClick={() => clickToShowEditModal(row)}>Edit</button></div>,
        },
        {
            name: 'Delete',
            cell: row => <div data-tag="allowRowEvents"><button className='delete-internal-user' onClick={() => deleteUser(row)}>Delete</button></div>,
        }
    ];

    return (

        <section className="internal-users">

            {
                showAddModal === true &&
                <Modal
                    Component={AddEdit}
                    setFormStatus={setShowAddModal}
                    data={userGroup}
                />
            }

            {
                showEditModal &&
                <Modal
                    Component={AddEditEdit}
                    setFormStatus={setShowEditModal}
                    data={selectedUser}
                />
            }

            <h3 className='internal-users-header'>
                Users
            </h3>

            <div className="search-users-input-container">

                <input
                    ref={searchUsersInput}
                    type="text"
                    placeholder='Search by name / email'
                    onChange={e => searchUsers(e)}
                />
            </div>

            <div className="internal-users-container">

                <div className="internal-users-buttons-container">

                    <button className="add-internal-user-btn" onClick={e => showModal(e)}>
                        +Add User
                    </button>

                </div>

                {
                    internalUsers !== null &&

                    <DataTable
                        columns={columns}
                        data={internalUsers}
                        fixedHeader={true}
                        fixedHeaderScrollHeight={'100%'}
                    />
                }

            </div>
        </section>
    )
}



const AddEdit = ({ data }) => {

    const { userData } = useContext(ValuesContext);
    const fins = userData.data.company.fins_number

    return (
        <AddeditUser
            caller={'addadminuser'}
            finsNumber={fins}
            setactive={null}
            userData={[userData]}
            usergroup={'external'}
        />
    )
}

const AddEditEdit = ({ data }) => {

    const { userData } = useContext(ValuesContext);
    const fins = userData.data.company

    return (
        <AddeditUser
            caller={'edituser'}
            finsNumber={data.company.fins_number}
            userData={[data]}
            setactive={null}
            usergroup={'external'}
        />
    )
}


/* 
const InternalUserForm = ({data}) => {

    const [surname, setSurname] = useState('');
    const [email, setEmail] = useState('');
    const [idNumber,setIdNumber] = useState('');
    const [firstname, setFirstname] = useState('');
    const [position, setPosition] = useState('');
    const [mobile, setMobile] = useState('');
    const  [address, setAddress] = useState('');
    const [IdentificationType, setIdentificationType] = useState('Admin');
    const [accessLevelState, setAccessLevelState] = useState('');

    const 
    return (

        <form className='add-internal-user-form'>

            <div className="internal-user-inputs-container">

                <InputContainer
                    label={'Surname'}
                    name={'surname'}
                    currentValue={surname}
                    setValue={setSurname}
                    type={'text'}
                />

                <InputContainer
                    label={'First Name'}
                    name={'firstName'}
                    currentValue={firstname}
                    setValue={setFirstname}
                    type={'text'}
                />

                <InputContainer
                    label={'Email'}
                    name={'email'}
                    currentValue={email}
                    setValue={setEmail}
                    type={'text'}
                />

                <SelectContainer
                    label={'Identification Type'}
                    currentValue={IdentificationType}
                    setValue={setIdentificationType}
                    name={'IdentificationType'}
                    options={IdType}
                />

                <InputContainer
                    label={'ID Number'}
                    name={'id'}
                    currentValue={idNumber}
                    setValue={setIdNumber}
                    type={'text'}
                />

                <InputContainer
                    label={'Position'}
                    type={'text'}
                    currentValue={position}
                    setValue={setPosition}
                />

                <InputContainer
                    label={'Mobile Number'}
                    type={'text'}
                    currentValue={mobile}
                    setValue={setMobile}
                />

                <InputContainer
                    label={'Address'}
                    type={'text'}
                    currentValue={address}
                    setValue={setAddress}
                />

                <SelectContainer
                    label={'Address'}
                    currentValue={accessLevelState}
                    setValue={setAccessLevelState}
                    options={accessLevel}
                />

            </div>

            <button className="add-internal-user-btn">
                Submit
            </button>
        </form>
    )
}
 */