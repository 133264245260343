import React, { useState, useEffect, useContext, useRef } from 'react';
import RemoveIcon from '@mui/icons-material/Remove';
import CircularProgress from '@mui/material/CircularProgress';
import ClearIcon from '@mui/icons-material/Clear';
import CheckIcon from '@mui/icons-material/Check';
import { ValuesContext } from './Admin/HomeAdmin';
import "./../css/InternalBUIDashboardView.css";
import AddIcon from '@mui/icons-material/Add';
import { fetchData } from '../js/fetchData';
import { getDate } from '../js/getDate';
import { deleteInquiry } from '../js/deleteInquiry';
import { Modal } from './Modal';
import { AddBIURequest } from './AddBIURequest';
import { reportColorStatus } from '../js/reportColorStatus';
import { useNavigate } from 'react-router-dom';
import AuthContext from '../AuthContext/AuthContext';
import { EnquiriesContext } from '../App';

export const InternalUserBIUDashboardView = () => {

    const [showAddBiuModal, setShowAddBiuModal] = useState(false);
    const [allBiuReportsReqs, setAllBiuReportsReqs] = useState({ data: null });
    const [biuReportReqs, setBIUReportReqs] = useState({ data: null });
    let { authTokens, logoutUser } = useContext(AuthContext)
    const [refresh, setRefresh] = useState(0);
    const searchReportsInput = useRef()


    useEffect(() => {

        if (biuReportReqs.data === null) {
            return;
        }

        if (allBiuReportsReqs.data !== null) {
            return
        }

        setAllBiuReportsReqs(biuReportReqs)
    }, [biuReportReqs])

    useEffect(() => {

        fetchData(
            String(authTokens.access),
            setBIUReportReqs,
            "biu-request",
            null,
            null
        );

    }, [showAddBiuModal])


    const searchReport = (e) => {
        const searchValue = e.target.value;
        if (searchValue === '') {
            setBIUReportReqs({ data: allBiuReportsReqs.data });
            return
        }

        const filteredReports = allBiuReportsReqs.data.filter(report => {
            if (
                report.subject_company.registration_name.toLowerCase().includes(e.target.value.toLowerCase()) ||
                report.subject_company.registration_number.toLowerCase().includes(e.target.value.toLowerCase()) ||
                report.client_company.registration_name.toLowerCase().includes(e.target.value.toLowerCase())
            ) {
                return report
            }
        })

        setBIUReportReqs({ data: filteredReports });

    }

    return (

        <section className="internal-biu-dash-view">

            <div className="search-users-input-container" style={{ marginTop: '0', padding: '.5em' }}>

                <input
                    ref={searchReportsInput}
                    type="text"
                    placeholder='Search for report'
                    onChange={e => searchReport(e)}
                />
            </div><br></br>

            <Reports
                showAddBiuModal={showAddBiuModal}
                setShowAddBiuModal={setShowAddBiuModal}
                biuReportReqs={biuReportReqs}
                setBIUReportReqs={setBIUReportReqs}
            />

            <AddReportBtnTable
                showAddBiuModal={showAddBiuModal}
                setShowAddBiuModal={setShowAddBiuModal}
                refresh={refresh}
                setRefresh={refresh}
            />

        </section>

    )
}

export const Reports = ({ showAddBiuModal, setShowAddBiuModal, biuReportReqs, setBIUReportReqs, refresh, setRefresh }) => {

    const { userData } = useContext(ValuesContext);
    const typeOfUser = userData.data.groups[0];

    return (

        <div className="bui-internal-table-container">
            {
                showAddBiuModal &&
                <Modal
                    Component={AddBIURequest}
                    setFormStatus={setShowAddBiuModal}
                    data={{ refresh: refresh, setRefresh: setRefresh }}
                />
            }
            <table border={2} className="bui-internal-reports-reqs">

                <tr className='bui-internal-reports__heading' style={{ position: 'sticky', top: '0' }}>
                    <td colSpan={8}>
                        <div className="report-heading-div">
                            <h4>Reports</h4>
                        </div>
                    </td>
                </tr>

                <tr className="bui-internal-reports__data-headings" style={{ position: 'sticky', top: '39px' }}>
                    {(typeOfUser === 1 || typeOfUser === 2) && <td><b>Ticket No</b></td>}
                    <td><b>Date of request</b></td>
                    <td><b>Target Company/Individual Name</b></td>
                    <td><b>Target Reg No/ID</b></td>
                    {(typeOfUser === 1 || typeOfUser === 2) && <td><b>Client</b></td>}
                    {(typeOfUser === 1 || typeOfUser === 2) && <td><b>Client User Name</b></td>}
                    <td><b>Report Status</b></td>
                    {/*  <td colSpan={2}><b>Action</b></td> */}
                </tr>

                {/* Data  of reports */}

                {
                    userData.data.groups[0] === 3 || userData.data.groups[0] === 4 ?

                        biuReportReqs.data !== null &&
                        biuReportReqs.data.map(report => {

                            return (
                                <ReportsReqDetails
                                    report={report}
                                    biuReportReqs={biuReportReqs}
                                    setBIUReportReqs={setBIUReportReqs}
                                />
                            )
                        })

                        :

                        biuReportReqs.data !== null &&
                        biuReportReqs.data.filter(req => req.status !== 'Active').map(report => {

                            return (
                                <ReportsReqDetails
                                    report={report}
                                    biuReportReqs={biuReportReqs}
                                    setBIUReportReqs={setBIUReportReqs}
                                />
                            )
                        })


                }


            </table>

        </div>
    )
}


const ReportsReqDetails = ({ report, biuReportReqs, setBIUReportReqs }) => {

    const [deleting, setDeleting] = useState(false);
    const navigate = useNavigate();
    const { userData } = useContext(ValuesContext);
    const { setReportData } = useContext(EnquiriesContext);
    const typeOfUser = userData.data.groups[0];

    const goToBIU = () => {

        const typeOfBIU = report.subject_company ? "company" : "individual";
        localStorage.setItem('biu-data', JSON.stringify({ data: report, typeOfBIU }))
        return `/report/${typeOfBIU}-biu-report`
        //navigate(`${typeOfBIU}-biu-report`);
    }


    const goToBIUEdit = () => {

        const id = report.request_id;
        navigate(`${report.subject_company ? 'company' : 'indvidual'}-edit-biu-report/${id}`)
    }

    return (
        <>



            <tr key={report.request_id} className="report-req-details">

                {
                    (typeOfUser === 1 || typeOfUser === 2) &&
                    <td>{report.request_id}</td>
                }

                <td>{getDate(report.created_date)}</td>

                <td>
                    {report.subject_company ?
                        report.subject_company.registration_name :
                        `${report.subject_indvidual.firstname} ${report.subject_indvidual.surname}`
                    }
                </td>

                <td>
                    {report.subject_company ?
                        report.subject_company.registration_number :
                        (`${report.subject_indvidual.national_id}`)
                    }
                </td>

                {
                    (typeOfUser === 1 || typeOfUser === 2) &&
                    <td>{report.client_company.registration_name}</td>
                }

                {
                    (typeOfUser === 1 || typeOfUser === 2) &&
                    <td>{report.user ? `${report.user.firstname} ${report.user.surname}` : "N/A"}</td>
                }

                <td style={{ backgroundColor: reportColorStatus(report), color: report.status === "Active" ? "black" : "white" }}>
                    {(report.action_typing_date === null && report.status !== 'Active') ? report.status : "In Progress"}
                </td>


                <td>

                    {
                        report.status === "Active" &&

                        <a href={goToBIU()}>
                            <button className="view-BIU-btn">
                                view report
                            </button>

                        </a>



                    }

                    {
                        (typeOfUser === 3 || typeOfUser === 4) && report.status !== "Active" &&
                        <p className="can-view-now-text">
                            Can't View Report Now
                        </p>
                    }

                    {
                        ((typeOfUser === 1 || typeOfUser === 2) && report.status !== "Active") &&
                        <a href={`/report/${report.subject_company ? 'company' : 'individual'}-edit-biu-report/${report.request_id}`}>
                            <button className="edit-information"  >
                                work on report
                            </button>
                        </a>

                    }



                </td>

                {
                    setBIUReportReqs !== null &&
                    <td className='remove-icon-container' onClick={() => deleteInquiry(report, setDeleting, biuReportReqs, setBIUReportReqs)}>
                        {deleting === false && <RemoveIcon className='remove-icon' />}
                        {deleting === "deleting" && <CircularProgress size={"1.5rem"} />}
                        {deleting === "failed" && <ClearIcon style={{ color: "red" }} />}
                        {deleting === true && <CheckIcon style={{ color: "green" }} />}
                    </td>
                }

            </tr>
        </>
    )
}

export const AddReportBtnTable = ({ showAddBiuModal, setShowAddBiuModal }) => {

    return (
        <tr className="add-new-request">
            <td colSpan={8}>
                <button className="add-new-request-btn" onClick={() => setShowAddBiuModal(true)}>
                    Add New Request
                    <AddIcon />
                </button>
            </td>
        </tr>
    )
}

