import React, { useState, useContext, useEffect } from "react";
import {
  InputAreaContainer,
  InputContainer,
  InputContainerReadOnly,
  InputContainerRequired,
  InputphoneContainer,
  MultiSelectContainerWithSearchHPI,
  MultiSelectContainerWithSearchUser,
  SelectContainer,
} from "../InputContainer";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../../css/FormContainerStyle.css";
import "../../css/addcompany.css";
import SaveIcon from "@mui/icons-material/Save";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import {
  AssetTypehpi,
  CompanyorIndividual,
} from "./SelectOptions";
import { AssetCondition, CurrencyType } from "../Company/selectOptionsjson";
import { DataSubmission } from "./DataSubmission";
import AuthContext from "../../AuthContext/AuthContext";
import Search from "./Search/Search";
import { ValuesContext } from '../Admin/HomeAdmin'

/* ___________________________________________________Vehicle HIre Purchase____________________________________________________ */

export const HirePurchase = ({ hirePurchaseData, close, setDisplayedForm }) => {
  const { userData } = useContext(ValuesContext)
  const { authTokens, logoutUser } = useContext(AuthContext);
  const [financierName, setFinancierName] = useState(() => {
    if (hirePurchaseData) {
      console.log("INFO-INSPECTION***********************")
      return {
        value: hirePurchaseData.financier_company.fins_number,
        label: hirePurchaseData.financier_company.registration_name
      }
    }
    else {
      if (userData.data.groups[0] >= 3) {
        return userData.data.company.registration_name
      } else {
        return ''
      }
    }
  })
  const [dataSource, setDataSource] = useState(() => {
    if (hirePurchaseData) {
      return hirePurchaseData.data_source
    }
    else {
      if (userData.data.groups[0] >= 3) {
        return userData.data.username
      } else {
        return ''
      }
    }
  })
  const [dataDate, setDataDate] = useState(
    () => {
      if (hirePurchaseData) {
        return hirePurchaseData.data_date
      } else {
        return ''
      }
    }
  )
  const [lesseType, setLesseeType] = useState(
    () => {
      if (hirePurchaseData) {
        return hirePurchaseData.lesse_type
      } else {
        return ''
      }
    })
  const [lesseeName, setLesseeName] = useState(
    () => {
      if (hirePurchaseData) {
        return hirePurchaseData.lesse_name
      } else {
        return ''
      }
    }
  )
  const [agreementNumber, setAgreementNumber] = useState(
    () => {
      if (hirePurchaseData) {
        return hirePurchaseData.agreement_number
      } else {
        return ''
      }
    }
  )
  const [lesseeFins, setLesseeFins] = useState(
    () => {
      if (hirePurchaseData) {
        return (
          hirePurchaseData.debtor_company ?
            hirePurchaseData.debtor_company
            :
            hirePurchaseData.debtor_individual
        )
      } else {
        return ''
      }
    }
  )
  const [assetType, setAssetType] = useState(
    () => {
      if (hirePurchaseData) {
        return hirePurchaseData.asset_type
      } else {
        return ''
      }
    }
  )
  const [make, setMake] = useState(
    () => {
      if (hirePurchaseData) {
        return hirePurchaseData.make
      } else {
        return ''
      }
    }
  )
  const [model, setModel] = useState(
    () => {
      if (hirePurchaseData) {
        return hirePurchaseData.model
      } else {
        return ''
      }
    }
  )
  const [year, setYear] = useState(
    () => {
      if (hirePurchaseData) {
        return hirePurchaseData.year
      } else {
        return ''
      }
    }
  )
  const [condition, setCondition] = useState(
    () => {
      if (hirePurchaseData) {
        return hirePurchaseData.condition
      } else {
        return ''
      }
    }
  )
  const [assetReg, setAssetRegistration] = useState(
    () => {
      if (hirePurchaseData) {
        return hirePurchaseData.asset_reg_number
      } else {
        return ''
      }
    }
  )
  const [chassisNumber, setChasisNumber] = useState(
    () => {
      if (hirePurchaseData) {
        return hirePurchaseData.chasis
      } else {
        return ''
      }
    }
  )

  const [engineNumber, setEngineNumber] = useState(
    () => {
      if (hirePurchaseData) {
        return hirePurchaseData.engine_number
        return ''
      }
    }
  )
  const [serialNumber, setSerialNumber] = useState(
    () => {
      if (hirePurchaseData) {
        return hirePurchaseData.serial
      } else {
        return ''
      }
    }
  )
  const [currency, setCurrency] = useState(
    () => {
      if (hirePurchaseData) {
        return hirePurchaseData.currency
      } else {
        return ''
      }
    }
  )
  const [purchaseAmount, setPurchaseAmount] = useState(
    () => {
      if (hirePurchaseData) {
        return hirePurchaseData.purchase_amount
      } else {
        return ''
      }
    }
  )
  const [installmentAmount, setInstallmentAmount] = useState(
    () => {
      if (hirePurchaseData) {
        return hirePurchaseData.installment_amount
      } else {
        return ''
      }
    }
  )
  const [installmentDate, setInstallmentDate] = useState(
    () => {
      if (hirePurchaseData) {
        return hirePurchaseData.installment_date
      } else {
        return ''
      }
    }
  )
  const [totalPaidToDate, setTotalPaidToDate] = useState(
    () => {
      if (hirePurchaseData) {
        return hirePurchaseData.paid_to_date
      } else {
        return ''
      }
    }
  )
  const [balance, setBalance] = useState(
    () => {
      if (hirePurchaseData) {
        return hirePurchaseData.balance_owing
      } else {
        return ''
      }
    }
  )
  const [agreementStartDate, setAgreementStartDate] = useState(
    () => {
      if (hirePurchaseData) {
        return hirePurchaseData.agreement_start_date
      } else {
        return ''
      }
    }
  )
  const [agreementEndDate, setAgreementEndDate] = useState(
    () => {
      if (hirePurchaseData) {
        return hirePurchaseData.agreement_due_date
      } else {
        return ''
      }
    }
  )
  const [id, setId] = useState('')
  const [currentForm, setCurrentForm] = useState('')
  const [search, setSearch] = useState('')


  useEffect(() => {
    if ((lesseeFins !== '' || lesseeName !== '') && !hirePurchaseData) {
      setLesseeFins('')
      setLesseeName('')
    }
  }, [lesseType])

  useEffect(() => {
    setBalance(purchaseAmount - totalPaidToDate)
  }, [totalPaidToDate, purchaseAmount])


  const AddHirePurchase = async (e, action) => {
    e.preventDefault()
    if (balance < 0 || installmentDate > 31 || installmentDate < 1 || dataDate === '' || agreementEndDate === '' || agreementStartDate === '' || installmentDate === '') {
      if (balance < 0) {
        toast.warning('Negetive Balance owing : Confirm amount paid and the purchase amount')
      }
      if (installmentDate > 31 || installmentDate < 1 || installmentDate === '') {
        toast.warning('Invalid installment date !')
      }
      if (dataDate === '') {
        toast.warning('Data date can not be empty !')
      }
      if (agreementEndDate === '') {
        toast.warning('Agreement end date can not be empty !')
      }
      if (agreementStartDate === '') {
        toast.warning('Agreement start date can not be empty !')
      }
    } else {

      var data;
      let responsed = ''
      if (hirePurchaseData) {
        if (userData.data.groups[0] >= 3) {
          if (window.confirm('Your Submission will be suspended pending payment. Continue ?')) {
            if (lesseType === 'Company') {
              data = {
                id: hirePurchaseData.id,
                debtor_company: lesseeFins,
                financier_company: userData.data.company.fins_number,
                data_source: dataSource,
                data_date: dataDate,
                lesse_type: lesseType,
                lesse_name: lesseeName,
                agreement_number: agreementNumber,
                company_reg_number: id,
                asset_type: assetType,
                make: make,
                model: model,
                status: 0,
                year: year,
                condition: condition,
                reg_number: assetReg,
                chasis: chassisNumber,
                engine_number: engineNumber,
                serial: serialNumber,
                currency: currency,
                purchase_amount: purchaseAmount,
                installment_amount: installmentAmount,
                installment_date: installmentDate,
                paid_to_date: totalPaidToDate,
                balance_owing: balance,
                agreement_start_date: agreementStartDate,
                agreement_due_date: agreementEndDate
              }
            } else {
              data = {
                id: hirePurchaseData.id,
                debtor_individual: lesseeFins,
                financier_company: userData.data.company.fins_number,
                data_source: dataSource,
                data_date: dataDate,
                lesse_type: lesseType,
                lesse_name: lesseeName,
                agreement_number: agreementNumber,
                national_id: id,
                asset_type: assetType,
                make: make,
                status: 0,
                model: model,
                year: year,
                condition: condition,
                reg_number: assetReg,
                chasis: chassisNumber,
                engine_number: engineNumber,
                serial: serialNumber,
                currency: currency,
                purchase_amount: purchaseAmount,
                installment_amount: installmentAmount,
                installment_date: installmentDate,
                paid_to_date: totalPaidToDate,
                balance_owing: balance,
                agreement_start_date: agreementStartDate,
                agreement_due_date: agreementEndDate
              }
            }

            responsed = await DataSubmission(
              String(authTokens.access),
              "put",
              "/hire-purchase/" + hirePurchaseData.id + '/',
              data
            )
          }
        } else {
          if (lesseType === 'Company') {
            data = {
              id: hirePurchaseData.id,
              debtor_company: lesseeFins,
              financier_company: financierName.value,
              data_source: dataSource,
              data_date: dataDate,
              lesse_type: lesseType,
              lesse_name: lesseeName,
              agreement_number: agreementNumber,
              asset_type: assetType,
              company_reg_number: id,
              make: make,
              model: model,
              status: 1,
              year: year,
              condition: condition,
              reg_number: assetReg,
              chasis: chassisNumber,
              engine_number: engineNumber,
              serial: serialNumber,
              currency: currency,
              purchase_amount: purchaseAmount,
              installment_amount: installmentAmount,
              installment_date: installmentDate,
              paid_to_date: totalPaidToDate,
              balance_owing: balance,
              agreement_start_date: agreementStartDate,
              agreement_due_date: agreementEndDate
            }
          } else {
            data = {
              id: hirePurchaseData.id,
              debtor_individual: lesseeFins,
              financier_company: financierName.value,
              data_source: dataSource,
              data_date: dataDate,
              lesse_type: lesseType,
              lesse_name: lesseeName,
              agreement_number: agreementNumber,
              asset_type: assetType,
              national_id: id,
              make: make,
              model: model,
              status: 1,
              year: year,
              condition: condition,
              reg_number: assetReg,
              chasis: chassisNumber,
              engine_number: engineNumber,
              serial: serialNumber,
              currency: currency,
              purchase_amount: purchaseAmount,
              installment_amount: installmentAmount,
              installment_date: installmentDate,
              paid_to_date: totalPaidToDate,
              balance_owing: balance,
              agreement_start_date: agreementStartDate,
              agreement_due_date: agreementEndDate
            }
          }

          responsed = await DataSubmission(
            String(authTokens.access),
            "put",
            "/hire-purchase/" + hirePurchaseData.id + '/',
            data
          )

        }
      } else {
        if (userData.data.groups[0] >= 3) {
          if (window.confirm('Your Submission will be suspended pending payment. Continue ?')) {
            if (lesseType === 'Company') {
              data = {
                debtor_company: lesseeFins,
                financier_company: userData.data.company.fins_number,
                data_source: dataSource,
                data_date: dataDate,
                lesse_type: lesseType,
                lesse_name: lesseeName,
                agreement_number: agreementNumber,
                company_reg_number: id,
                asset_type: assetType,
                make: make,
                model: model,
                status: 0,
                year: year,
                condition: condition,
                reg_number: assetReg,
                chasis: chassisNumber,
                engine_number: engineNumber,
                serial: serialNumber,
                currency: currency,
                purchase_amount: purchaseAmount,
                installment_amount: installmentAmount,
                installment_date: installmentDate,
                paid_to_date: totalPaidToDate,
                balance_owing: balance,
                agreement_start_date: agreementStartDate,
                agreement_due_date: agreementEndDate
              }
            } else {
              data = {
                debtor_individual: lesseeFins,
                financier_company: userData.data.company.fins_number,
                data_source: dataSource,
                data_date: dataDate,
                lesse_type: lesseType,
                lesse_name: lesseeName,
                agreement_number: agreementNumber,
                national_id: id,
                asset_type: assetType,
                make: make,
                status: 0,
                model: model,
                year: year,
                condition: condition,
                reg_number: assetReg,
                chasis: chassisNumber,
                engine_number: engineNumber,
                serial: serialNumber,
                currency: currency,
                purchase_amount: purchaseAmount,
                installment_amount: installmentAmount,
                installment_date: installmentDate,
                paid_to_date: totalPaidToDate,
                balance_owing: balance,
                agreement_start_date: agreementStartDate,
                agreement_due_date: agreementEndDate
              }
            }

            responsed = await DataSubmission(
              String(authTokens.access),
              "post",
              "/hire-purchase/",
              data
            )
          }
        } else {
          if (lesseType === 'Company') {
            data = {
              debtor_company: lesseeFins,
              financier_company: financierName.value,
              data_source: dataSource,
              data_date: dataDate,
              lesse_type: lesseType,
              lesse_name: lesseeName,
              agreement_number: agreementNumber,
              asset_type: assetType,
              company_reg_number: id,
              make: make,
              model: model,
              status: 1,
              year: year,
              condition: condition,
              reg_number: assetReg,
              chasis: chassisNumber,
              engine_number: engineNumber,
              serial: serialNumber,
              currency: currency,
              purchase_amount: purchaseAmount,
              installment_amount: installmentAmount,
              installment_date: installmentDate,
              paid_to_date: totalPaidToDate,
              balance_owing: balance,
              agreement_start_date: agreementStartDate,
              agreement_due_date: agreementEndDate
            }
          } else {
            data = {
              debtor_individual: lesseeFins,
              financier_company: financierName.value,
              data_source: dataSource,
              data_date: dataDate,
              lesse_type: lesseType,
              lesse_name: lesseeName,
              agreement_number: agreementNumber,
              asset_type: assetType,
              national_id: id,
              make: make,
              model: model,
              status: 1,
              year: year,
              condition: condition,
              reg_number: assetReg,
              chasis: chassisNumber,
              engine_number: engineNumber,
              serial: serialNumber,
              currency: currency,
              purchase_amount: purchaseAmount,
              installment_amount: installmentAmount,
              installment_date: installmentDate,
              paid_to_date: totalPaidToDate,
              balance_owing: balance,
              agreement_start_date: agreementStartDate,
              agreement_due_date: agreementEndDate
            }
          }

          responsed = await DataSubmission(
            String(authTokens.access),
            "post",
            "/hire-purchase/",
            data
          )

        }
      }
      if (responsed !== '') {
        if (action === "done" && responsed[0].result === "success") {
          if (setDisplayedForm !== undefined) {
            setDisplayedForm(false)
          }
        }
        if (action === "add" && responsed[0].result === "success") {
          setAgreementEndDate(''); setAgreementNumber(''); setAgreementStartDate('');
          setAssetRegistration(''); setAssetType(''); setBalance('');
          setChasisNumber(''); setCondition(''); setCurrency(''); setDataDate('');
          setDataSource(''); setEngineNumber(''); setFinancierName(''); setInstallmentAmount('');
          setInstallmentDate(''); setLesseeFins(''); setLesseeName('');
          setMake(''); setLesseeType(''); setModel(''); setPurchaseAmount('');
          setSerialNumber(''); setSearch(''); setTotalPaidToDate('');
          setYear('')
        }
      } else {
      }
    }
  }

  const CloseRecord = async(e) => {
    e.preventDefault()
    if(window.confirm('Are sure you want to close the record ?')){
      var responsed = await DataSubmission(
        String(authTokens.access),
        "post",
        "/close-hire-purchase/",
        {data :{
          id : hirePurchaseData.id
        }
      }
      )

      if (responsed[0].result === "success") {
        if (setDisplayedForm !== undefined) {
          setDisplayedForm(false)
        }
      }
    }
  }

  return (
    <div>
      <section className="form-container">
        <h3 className="form-headerhpimain">Hire Purchase Form</h3>
        <form className="form">
          <section className="secinputcontainer">
            <h4 className="sectionHeadinghpifinancier">Financier </h4>
            <section className=" secinputdeatails">

              {(userData.data.groups[0] === 3 || userData.data.groups[0] === 4) ?
                <InputContainerReadOnly
                  label={'Financier Name'}
                  type={'text'}
                  currentValue={financierName}
                  id={'financierName'}
                />
                :
                <MultiSelectContainerWithSearchHPI
                  label={'Financier Name'}
                  currentValue={financierName}
                  setValue={setFinancierName}
                  id={'financierName'}
                  subscription={'Hire Purchase'}
                />

              }

              {(userData.data.groups[0] === 3 || userData.data.groups[0] === 4) ?
                <InputContainerReadOnly
                  label={'Data Source'}
                  type={'text'}
                  currentValue={dataSource}
                  id={'financierName'}
                />
                :
                <>
                  {financierName !== '' &&
                    <InputContainerRequired
                      label={'Data Source'}
                      type={'text'}
                      currentValue={dataSource}
                      setValue={setDataSource}
                      searchValue={financierName.value}
                      id={'financierName'}
                    />
                  }
                </>

              }

              <InputContainerRequired
                label={'Data Date'}
                type={'date'}
                currentValue={dataDate}
                setValue={setDataDate}
                id={'dataDate'}
              />

              <h4 className="sectionHeadinghpilessee">Lessee </h4>

              <SelectContainer
                label={'Lesse Type'}
                currentValue={lesseType}
                setValue={setLesseeType}
                options={CompanyorIndividual}
              />

              {(lesseeFins !== '' && !hirePurchaseData) &&
                <InputContainerReadOnly
                  label={'Lesse ID/Registration Number'}
                  type={'text'}
                  currentValue={id}
                  id={'lesseeFins'}
                />
              }

              {lesseeName !== '' &&
                < InputContainerReadOnly
                  label={'Name'}
                  type={'text'}
                  currentValue={lesseeName}
                  setValue={setLesseeName}
                  id={'lesseName'}
                />
              }

              {((lesseType === 'Company' || lesseType === 'Individual') && lesseeFins === '') &&
                <Search
                  searching={lesseType}
                  setid={setId}
                  setFins={setLesseeFins}
                  setname={setLesseeName}
                  setcurrent={setCurrentForm}
                  setSearch={setSearch}
                />
              }
              {lesseeFins !== '' &&
                <section className=" secinputdeatails">
                  <h4 className="sectionHeadingdetailshpi">Hire Purchase Details</h4>

                  <InputContainerRequired
                    label={'Agreement Number'}
                    type={'text'}
                    currentValue={agreementNumber}
                    setValue={setAgreementNumber}
                    id={'agreementNumber'}
                  />

                  <SelectContainer
                    label={'Asset Type'}
                    currentValue={assetType}
                    setValue={setAssetType}
                    options={AssetTypehpi}
                  />

                  <InputContainer
                    label={'Make'}
                    type={'text'}
                    currentValue={make}
                    setValue={setMake}
                    id={'make'}
                  />

                  <InputContainer
                    label={'Model'}
                    type={'text'}
                    currentValue={model}
                    setValue={setModel}
                    id={'model'}
                  />

                  <InputContainer
                    label={'Year'}
                    type={'number'}
                    currentValue={year}
                    setValue={setYear}
                    id={'year'}
                  />

                  <SelectContainer
                    label={'Condition'}
                    currentValue={condition}
                    setValue={setCondition}
                    options={AssetCondition}
                  />
                  {assetType === 'Vehicles' &&
                    <InputContainer
                      label={'Asset Registration Number'}
                      type={'text'}
                      currentValue={assetReg}
                      setValue={setAssetRegistration}
                      id={'assetReg'}
                    />
                  }
                  {assetType === 'Vehicles' &&
                    <InputContainer
                      label={'Chasis Number'}
                      type={'text'}
                      currentValue={chassisNumber}
                      setValue={setChasisNumber}
                      id={'chasisNumber'}
                    />
                  }
                  {assetType === 'Vehicles' &&
                    <InputContainer
                      label={'Engine Number'}
                      type={'text'}
                      currentValue={engineNumber}
                      setValue={setEngineNumber}
                      id={'engineNumber'}
                    />
                  }
                  {assetType !== 'Vehicles' &&
                    <InputContainer
                      label={'Serial Number'}
                      type={'text'}
                      currentValue={serialNumber}
                      setValue={setSerialNumber}
                      id={'serialNumber'}
                    />}

                  <SelectContainer
                    label={'Currency'}
                    currentValue={currency}
                    setValue={setCurrency}
                    options={CurrencyType}
                  />

                  <InputContainerRequired
                    label={'Purchase Amount'}
                    type={'number'}
                    currentValue={purchaseAmount}
                    setValue={setPurchaseAmount}
                    id={'purchaseAmount'}
                  />

                  <InputContainerRequired
                    label={'Installment Amount '}
                    type={'number'}
                    currentValue={installmentAmount}
                    setValue={setInstallmentAmount}
                    id={'installmentAmount'}
                  />

                  <InputContainerRequired
                    label={'Installment Date'}
                    type={'number'}
                    currentValue={installmentDate}
                    setValue={setInstallmentDate}
                    id={'installmentAmount'}
                  />

                  <InputContainer
                    label={'Total Paid To Date'}
                    type={'number'}
                    currentValue={totalPaidToDate}
                    setValue={setTotalPaidToDate}
                    id={'totalPaidToDate'}
                  />

                  <InputContainerReadOnly
                    label={'Balance'}
                    type={'number'}
                    currentValue={balance}
                    setValue={setBalance}
                    id={'balance'}
                  />

                  <InputContainerRequired
                    label={'Agreement Start Date'}
                    type={'date'}
                    currentValue={agreementStartDate}
                    setValue={setAgreementStartDate}
                    id={'agreementStartDate'}
                  />

                  <InputContainerRequired
                    label={'Agreement End Date'}
                    type={'date'}
                    currentValue={agreementEndDate}
                    setValue={setAgreementEndDate}
                    id={'agreementEndDate'}
                  />
                </section>
              }
            </section>
          </section>
          {!hirePurchaseData ?
            <section className="secButtons">
              <button
                type="submit"
                className="formButtons"
                text="Close"
                align="center"
                onClick={e => AddHirePurchase(e, 'add')}
              >
                <AddIcon className="icons" />
                <blockquote>Save And Add</blockquote>
              </button>

              <button
                icon="close"
                className="formButtons"
                text="Close"
                align="center"
                onClick={e => AddHirePurchase(e, 'done')}
              >
                <SaveIcon className="icons" />
                <blockquote>Save And Exit</blockquote>
              </button>
            </section>
            :
            <section className="secButtons">
              <button
                type="submit"
                className="formButtonscloseRecord"
                align="center"
                onClick = {e => CloseRecord(e)}
              >
                <CloseIcon className="icons" />
                <blockquote>Close Record</blockquote>
              </button>

              <button
                icon="close"
                className="formButtons"
                text="Close"
                align="center"
                onClick={e => AddHirePurchase(e, 'done')}
              >
                <SaveIcon className="icons" />
                <blockquote>Update</blockquote>
              </button>
            </section>
          }
        </form>
      </section>
    </div>
  );
};
