import React, { useContext, useState, useEffect } from 'react';
import './../../css/SideBar.css';
import { BureauSection } from './Bureau';
import { BusinessReportsMenu } from './BusinessReportsMenu';
import { fetchData } from '../../js/fetchData';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { HirePurchaseMenu } from './HirePurchaseMenu';
import { ValuesContext } from './HomeAdmin';
import AuthContext from '../../AuthContext/AuthContext';
import { ShapeLineOutlined } from '@mui/icons-material';
import { NavLink } from 'react-router-dom';


const Navbar = ({ setActivePage }) => {

  const { userData } = useContext(ValuesContext);
  const [isAdmin, setIsAdmin] = useState(false);
  const [isClientUser, setIsClientUser] = useState(false)
  const { authTokens } = useContext(AuthContext);


  useEffect(() => {

    if (userData === {} || Object.keys(userData).length === 0 || userData.data === undefined || userData.data === null) {
      return;
    }

    if (userData.data.groups[0] === 1 || userData.data.groups[0] === 3) {
      setIsAdmin(true)
    }

    if (userData.data.groups[0] === 4) {
      setIsClientUser(true);
    }
  }, [userData])



  return (

    <div className="sidebar-container">
      <BureauSection setActivePage={setActivePage} />
      <HirePurchaseMenu setActivePage={setActivePage} />
      <BusinessReportsMenu setActivePage={setActivePage} />

      
      {!isAdmin && <a href="/static/media/manuals/manual.pdf" style={{ color: 'white', padding: '1em', textDecoration: 'none' }}>User Manual</a>}
      {
        isAdmin && <SystemsAdminsLink setActivePage={setActivePage} />
      }
      <hr/>
      <br/>
      <NavLink to={'profile'} style={{ color: 'white', padding: '1em', textDecoration: 'none'}}>Profile</NavLink>
    </div>
  )
}



const SystemsAdminsLink = ({ setActivePage }) => {


  /* 
    const toggleAdminMenu = () => {
    
      const expand = document.querySelector(".systems-admin-expand");
      const recordsMenu = document.querySelector(".systems-admin-menu");
      recordsMenu.classList.toggle("show-systems-admin-menu");
      expand.classList.toggle("rotate-expand")
   }
   */
  return (

    <>
      <nav className="systems-admin-link-container">
        <h3 href="#" className="systems-admin-text">
          Admin
        </h3>
      </nav>
      <SystemsAdminDropDown setActivePage={setActivePage} />
    </>
  )
}

const SystemsAdminDropDown = ({ setActivePage }) => {

  const [showInvoicingMenu, setShowInvoicingMenu] = useState(false)
  const { userData } = useContext(ValuesContext);
  const [showClientMenu, setShowClientMenu] = useState(false)

  const showPage = (page) => {
    setActivePage(page)
  }


  return (
    <nav className="systems-admin-menu show-systems-admin-menu">

      {
        userData.data.groups[0] === 1 &&
        <a href='#' onClick={() => setShowInvoicingMenu(!showInvoicingMenu)}>Invoicing</a>
      }
      {showInvoicingMenu && <InvoicingDropDown showPage={showPage} />}

      {
        userData.data.groups[0] === 1 &&
        <>
          <NavLink to={'internal-users'}>Internal Users</NavLink>
          <NavLink to={'external-users'}>External Users</NavLink>
          <NavLink to={'clients'}>Clients </NavLink>
          <NavLink to={'#'}>Reports</NavLink>
          <NavLink to={'settings'}>Settings</NavLink>
        </>
      }

      {
        userData.data.groups[0] === 3 &&
        <>
          <NavLink to='client-add-user'>Users</NavLink>
          <a href='#'>User Manual - Admin</a>
          <a href='#'>User Manual - User </a>
        </>
      }
      
      
      
      {/*   {showClientMenu && <ClientsDropDown showPage={showPage}/>} */}


    </nav>
  )
}

const InvoicingDropDown = ({ showPage }) => {


  return (
    <nav className="invoicing-dropdown-menu">
      <NavLink to={'invoice-list'}>Invoicing List</NavLink>
      <NavLink to={'pricing'}>Pricing</NavLink>
      <NavLink to={'targets'}>Targets</NavLink>
      <NavLink to={'invoicing-periods'}>Invoicing Periods</NavLink>
    </nav>
  )


}

const ClientsDropDown = ({ showPage }) => {

  return (
    <nav className="invoicing-dropdown-menu">
      <a href='#'>Add / Edit</a>
      <a href='#'>Client Users</a>
      <a href='#'>Activation Status</a>
    </nav>
  )
}






export default Navbar