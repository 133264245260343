import React from 'react'
import { getDate } from '../../../js/getDate'
import { TableData, TableDataHeaders } from '../../Individuals/IndividualReport/ReportTemplates'

const BIUPublicInfo = ({ publicInfo, color }) => {

    return (
        <table className='report_table'>

            <tr className='row_heading' style={{ margin: '50px 0', backgroundColor: '#b4c6e7', color: 'black' }}>
                <td className='txt_rowheading' colSpan={7}>
                    <h1 className='section_heading' style={{ fontSize: '1rem' }}>
                        PUBLIC INFORMATION
                    </h1>
                </td>
            </tr>

            <tr className='data_row' >
                <TableDataHeaders
                    id={"count_header"}
                    data={""}
                />

                <TableDataHeaders
                    id={"record_date_header"}
                    data={"Record Date"}
                />

                <TableDataHeaders
                    id={"source_header"}
                    data={"Source"}
                />

                <TableDataHeaders
                    id={"summary_header"}
                    data={"Summary"}
                />

                <TableDataHeaders
                    id={"link_header"}
                    data={"Link"}
                />
            </tr>

            {
                (publicInfo.data !== null && publicInfo.data.length === 0) &&
                <tr className='data_row'>
                    {/*  CLEAR TO DATE IN OUR FILES IN THE NAME OF THE BUSINESS AND PRINCIPALS */}
                </tr>
            }

            {//mappping function

                publicInfo.data !== null &&
                publicInfo.data.map((info, index) => <Info info={info} index={index} />)
            }


        </table>
    )
}

const Info = ({ info, index }) => {

    return (
        <tr className='data_row' >
            <TableData
                id={"count_field"}
                data={index + 1}
            />

            <TableData
                id={"date_of_record_field"}
                data={info.date_added ? getDate(info.date_added) : 'N/A'}
            />

            <TableData
                id={"source_field"}
                data={info.source ? info.source : 'N/A'}
            />

            <TableData
                id={"summary_field"}
                data={info.summary ? info.summary : 'N/A'}
            />

            <TableData
                id={"link_field"}
                data={info.link ? info.link : 'N/A'}
            />
        </tr>
    )
}

export default BIUPublicInfo