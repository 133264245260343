
export const Industry = [
    { option: "Agriculture" },
    { option: "Accounting" },
    { option: "Aviation" },
    { option: "Banking" },
    { option: "Construction" },
    { option: "Consultancy" },
    { option: "Distribution" },
    { option: "Education" },
    { option: "Energy" },
    { option: "Entertainment" },
    { option: "Finance" },
    { option: "Government" },
    { option: "Health services" },
    { option: "Hospitality and Tourism" },
    { option: "Information Technology" },
    { option: "Legal" },
    { option: "Manufacturing" },
    { option: "Media" },
    { option: "Microfinance" },
    { option: "Mining" },
    { option: "Pharmaceutical" },
    { option: "Professional Services" },
    { option: "Real Estate" },
    { option: "Retail" },
    { option: "Telecommunication" },
    { option: "Transport" },
    { option: "Utilities" },
    { option: "other" },
]

export const TradingStatus = [
    { option: "Insolvent" },
    { option: "Dormant" },
    { option: "Actively Trading" },
    { option: "Under Receivership" },
    { option: "Liquidation" },
    { option: "Liquidated" },
    { option: "Under Judicial Management" },
    { option: "Dissolved" },
    { option: "Insolvent" },
    { option: "Struck off by Registrar" },
    { option: "Other" },
]

export const LegalStatus = [
    { option: "Church" },
    { option: "Club/ Association" },
    { option: "Government Department" },
    { option: "Investiment Group" },
    { option: "NGO" },
    { option: "Parastatal" },
    { option: "Partnership" },
    { option: "Private Business Corperation" },
    { option: "Private Limited Company" },
    { option: "Public Company" },
    { option: "School" },
    { option: "Society" },
    { option: "Sole Proprietor" },
    { option: "Other" },
]

export const AccountType = [
    { option : "Nostro"},
    { option : "Local Currency Account"}
]

export const AssetCondition = [
    {option : 'New'},
    {option : 'Second Hand'}
]

export const CurrencyType = [
    {option : "USD"},
    {option : "ZWL"},
    {option: "Rands"}
]

export const dataType = [
    {option : 'Claim'},
    {option : 'Absconder'},
    {option : 'Court'},
    {option : 'Public Records'},
]

export const BulkDataType = [
    {option : 'Claim'},
    {option : 'Absconder'},
    {option : 'Court'},
    {option : 'Public Records'},
]

export const dataTypeBulk = [
    {option : 'Individual Claims'},
    {option : 'Company Claims'},
    {option : 'Company Court Records'},
    {option : 'Individual Court Records'},
    {option : 'Individual Public Records'},
    {option : 'Company Public Records'},
]

export const activeAccountsForm = [
    {option : 'Individual Active Accounts'},
    {option : 'Company Active Accounts'},
]

export const HPIOptions = [
    {option : 'Individual Hire Purchase'},
    {option : 'Company Hire Purchase'},
]

export const debtorType = [
    {option : 'Individual'},
    {option : 'Company'},
]

export const BulkDebtorType = [
    {option : 'Individual'},
    {option : 'Company'},
]

export const BulkCreditType = [
    {option : 'Bank Loan'},
    {option : 'Car Loan'},
    {option : 'Company Loan'},
    {option : 'Goods Loan'},
    {option : 'Hire Purchase'},
    {option : 'Individual Loan'},
    {option : 'Microfinance Loan'},
    {option : 'Mortgage'},
    {option : 'Retail Loan'},
    {option : 'Round Credit'},
    {option : 'SME Loan'},
    {option : 'Staff Loan'},
    {option : 'Stand Loan'},
]

export const Subscription =[
    {option : 'Biu'},
    {option : 'Burea'}
] 

export const accessLevel =  [
    {option: 'Admin'},
    {option: 'User'}

]

export const IdType = [
    {option : 'National ID'},
    {option : 'Passport'},
    {option : 'Drivers License'}
]