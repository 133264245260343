import React from 'react'
import '../../css/bulkupload.css'

export const TbodyIndividual = ({ data, setupdata }) => {
    var cleandata
    return (
        <tbody className='prevTableBody'>
            {data.map((item) => {
                if (!item.Surname || !item.National || !item.Address || !item.City
                    || !item.Amount || !item.Gender || !item.Phone || !item.Mobile) {
                    var index = data.indexOf(item)
                    delete data[index]
                    return (
                        <tr className='corrupt'>
                            <td className='BulkTableData'>
                                {item.Names}
                            </td>
                            <td className='BulkTableData'>
                                {item.Surname}
                            </td>
                            <td className='BulkTableData'>
                                {item.National}
                            </td>
                            <td className='BulkTableData'>
                                {item.Phone}
                            </td>
                        </tr>
                    )
                } else {
                    return (
                        <tr>
                            <td className='BulkTableData'>
                                {item.Names}
                            </td>
                            <td className='BulkTableData'>
                                {item.Surname}
                            </td>
                            <td className='BulkTableData'>
                                {item.National}
                            </td>
                            <td className='BulkTableData'>
                                {item.Phone}
                            </td>
                        </tr>
                    )
                }
            })}
        </tbody>
    )
}

export const TbodyCompany = ({ data }) => {

    return (
        <tbody className='prevTableBody'>
            {data.map((item) => {
                if (!item.RegistrationNumber || !item.CompanyName || !item.LegalStatus) {
                    var index = data.indexOf(item)
                    delete data[index]
                    return (
                        <tr className='corrupt'>
                            <td className='BulkTableData'>
                                {item.RegistrationNumber}
                            </td>
                            <td className='BulkTableData'>
                                {item.CompanyName}
                            </td>
                            <td className='BulkTableData'>{item.LegalStatus}</td>
                        </tr>)
                } else {
                    return (
                        <tr>
                            <td className='BulkTableData'>
                                {item.RegistrationNumber}
                            </td>
                            <td className='BulkTableData'>
                                {item.CompanyName}
                            </td>
                            <td className='BulkTableData'>{item.LegalStatus}</td>
                        </tr>
                    )
                }
            })}
        </tbody>
    )
}

export const TbodyCompanyNegativeData = ({ data }) => {
    return (
        <tbody className='prevTableBody'>
            {data.map((item) => {
                if (!item.RegistrationNumber || !item.RegisteredName || !item.ContactNumber || !item.Amount ||
                    !item.DateOfClaim || !item.AccountNumber) {
                    var index = data.indexOf(item)
                    delete data[index]
                    return (
                        <tr className='corrupt'>
                            <td className='BulkTableData'>{item.RegisteredName}</td>
                            <td className='BulkTableData'>{item.RegistrationNumber}</td>
                            <td className='BulkTableData'>{item.ContactNumber}</td>
                            <td className='BulkTableData'>{item.AccountNumber}</td>
                            <td className='BulkTableData'>{item.DateOfClaim}</td>
                            <td className='BulkTableData'>{item.Amount}</td>
                            <td className='BulkTableData'>{item.Branch}</td>
                        </tr>
                    )
                } else {
                    return (
                        <tr align='center'>
                            <td className='BulkTableData'>{item.RegisteredName}</td>
                            <td className='BulkTableData'>{item.RegistrationNumber}</td>
                            <td className='BulkTableData'>{item.ContactNumber}</td>
                            <td className='BulkTableData'>{item.AccountNumber}</td>
                            <td className='BulkTableData'>{item.DateOfClaim}</td>
                            <td className='BulkTableData'>{item.Amount}</td>
                            <td className='BulkTableData'>{item.Branch}</td>
                        </tr>
                    )
                }
            })}
        </tbody>
    )
}

export const TbodyIndividualNegetiveData = ({ data, setupdata }) => {
    var cleandata
    return (
        <tbody className='prevTableBody'>
            {data.map((item) => {
                if (!item.Surname || !item.NationalID || !item.FirstName || !item.Address
                    || !item.Amount || !item.AccountNumber || !item.ClaimDate) {
                    var index = data.indexOf(item)
                    delete data[index]
                    return (
                        <tr className='corrupt'>
                            <td className='BulkTableData'>
                                {item.FirstName}
                            </td>
                            <td className='BulkTableData'>
                                {item.Surname}
                            </td>
                            <td className='BulkTableData'>
                                {item.NationalID}
                            </td>
                            <td className='BulkTableData'>
                                {item.AccountNumber}
                            </td>
                            <td className='BulkTableData'>
                                {item.ClaimDate}
                            </td>
                            <td className='BulkTableData'>
                                {item.Amount}
                            </td>
                            <td className='BulkTableData'>
                                {item.Branch}
                            </td>
                        </tr>
                    )
                } else {
                    return (
                        <tr>
                            <td className='BulkTableData'>
                                {item.FirstName}
                            </td>
                            <td className='BulkTableData'>
                                {item.Surname}
                            </td>
                            <td className='BulkTableData'>
                                {item.NationalID}
                            </td>
                            <td className='BulkTableData'>
                                {item.AccountNumber}
                            </td>
                            <td className='BulkTableData'>
                                {item.ClaimDate}
                            </td>
                            <td className='BulkTableData'>
                                {item.Amount}
                            </td>
                            <td className='BulkTableData'>
                                {item.Branch}
                            </td>
                        </tr>
                    )
                }
            })}
        </tbody>
    )
}

export const TbodyCompanyHirePurchase = ({ data, setupdata }) => {
    var cleandata
    return (
        <tbody className='prevTableBody'>
            {data.map((item) => {
                if (!item.RegistrationNumber || !item.CompanyName || !item.AgreementNumber
                    || !item.AssetType || !item.Make || !item.AssetReg_SerialNumber || !item.PurchaseAmount
                    || !item.InstallmentAmount || !item.StartDate || !item.TotalPaidToDate) {
                    var index = data.indexOf(item)
                    delete data[index]
                    return (
                        <tr className='corrupt'>
                            <td className='BulkTableData'>
                                {item.CompanyName}
                            </td>
                            <td className='BulkTableData'>
                                {item.RegistrationNumber}
                            </td>
                            <td className='BulkTableData'>
                                {item.AgreementNumber}
                            </td>
                            <td className='BulkTableData'>
                                {item.AssetType}
                            </td>
                            <td className='BulkTableData'>
                                {item.Make}
                            </td>
                            <td className='BulkTableData'>
                                {item.Model}
                            </td>
                            <td className='BulkTableData'>
                                {item.AssetReg_SerialNumber}
                            </td>
                            <td className='BulkTableData'>
                                {item.PurchaseAmount}
                            </td>
                            <td className='BulkTableData'>
                                {item.TotalPaidToDate}
                            </td>
                            <td className='BulkTableData'>
                                {item.BalanceOutstanding}
                            </td>
                        </tr>
                    )
                } else {
                    return (
                        <tr >
                            <td className='BulkTableData'>
                                {item.CompanyName}
                            </td>
                            <td className='BulkTableData'>
                                {item.RegistrationNumber}
                            </td>
                            <td className='BulkTableData'>
                                {item.AgreementNumber}
                            </td>
                            <td className='BulkTableData'>
                                {item.AssetType}
                            </td>
                            <td className='BulkTableData'>
                                {item.Make}
                            </td>
                            <td className='BulkTableData'>
                                {item.Model}
                            </td>
                            <td className='BulkTableData'>
                                {item.AssetReg_SerialNumber}
                            </td>
                            <td className='BulkTableData'>
                                {item.PurchaseAmount}
                            </td>
                            <td className='BulkTableData'>
                                {item.TotalPaidToDate}
                            </td>
                            <td className='BulkTableData'>
                                {item.BalanceOutstanding}
                            </td>
                        </tr>
                    )
                }
            })}
        </tbody>
    )
}

export const TbodyIndividualHirePurchase = ({ data, setupdata }) => {
    var cleandata
    return (
        <tbody className='prevTableBody'>
            {data.map((item) => {
                if (!item.IDNumber || !item.Surname || !item.Forename || !item.AgreementNumber
                    || !item.AssetType || !item.Make || !item.AssetReg_SerialNumber || !item.PurchaseAmount
                    || !item.InstallmentAmount || !item.StartDate || !item.TotalPaidToDate) {
                    var index = data.indexOf(item)
                    delete data[index]
                    return (
                        <tr className='corrupt'>
                            <td className='BulkTableData'>
                                {item.Surname}
                            </td>
                            <td className='BulkTableData'>
                                {item.IDNumber}
                            </td>
                            <td className='BulkTableData'>
                                {item.AgreementNumber}
                            </td>
                            <td className='BulkTableData'>
                                {item.AssetType}
                            </td>
                            <td className='BulkTableData'>
                                {item.Make}
                            </td>
                            <td className='BulkTableData'>
                                {item.Model}
                            </td>
                            <td className='BulkTableData'>
                                {item.AssetReg_SerialNumber}
                            </td>
                            <td className='BulkTableData'>
                                {item.PurchaseAmount}
                            </td>
                            <td className='BulkTableData'>
                                {item.TotalPaidToDate}
                            </td>
                            <td className='BulkTableData'>
                                {item.BalanceOutstanding}
                            </td>
                        </tr>
                    )
                } else {
                    return (
                        <tr >
                            <td className='BulkTableData'>
                                {item.National}
                            </td>
                            <td className='BulkTableData'>
                                {item.Surname}
                            </td>
                            <td className='BulkTableData'>
                                {item.Financier}
                            </td>
                            <td className='BulkTableData'>
                                {item.Amount}
                            </td>
                            <td className='BulkTableData'>
                                {item.PaidToDate}
                            </td>
                            <td className='BulkTableData'>
                                {item.Make}
                            </td>
                            <td className='BulkTableData'>
                                {item.Model}
                            </td>
                            <td className='BulkTableData'>
                                {item.UniqueIdentifier}
                            </td>
                        </tr>
                    )
                }
            })}
        </tbody>
    )
}

export const TbodyCompanyUpload = ({ data }) => {

    return (
        <tbody className='prevTableBody'>
            {data.map((item) => {
                if (!item.registrationNumber || !item.source || !item.link ||
                    !item.caseNumber || !item.dateAdded) {
                    var index = data.indexOf(item)
                    delete data[index]
                    return (
                        <tr className='corrupt'>
                            <td className='BulkTableData'>
                                {item.caseNumber}
                            </td>
                            <td className='BulkTableData'>
                                {item.registrationNumber}
                            </td>
                            <td className='BulkTableData'>
                                {item.source}
                            </td>
                            <td className='BulkTableData'>
                                {item.dateAdded}
                            </td>
                            <td className='BulkTableData'>
                                {item.summary}
                            </td>
                        </tr>)
                } else {
                    return (
                        <tr >
                            <td className='BulkTableData'>
                                {item.caseNumber}
                            </td>
                            <td className='BulkTableData'>
                                {item.registrationNumber}
                            </td>
                            <td className='BulkTableData'>
                                {item.source}
                            </td>
                            <td className='BulkTableData'>
                                {item.dateAdded}
                            </td>
                            <td className='BulkTableData'>
                                {item.summary}
                            </td>
                        </tr>
                    )
                }
            })}
        </tbody>
    )
}

export const TbodyIndividualActiveAccounts = ({ data, setupdata }) => {
    var cleandata
    return (
        <tbody className='prevTableBody'>
            {data.map((item) => {
                if (!item.FirstName || !item.Surname || !item.IDNumber || !item.Address || !item.AccountID
                    || !item.CurrencyType || !item.LoanAmount || !item.Installment || !item.StartDate ||
                    !item.EndDate) {
                    var index = data.indexOf(item)
                    delete data[index]
                    return (
                        <tr className='corrupt'>
                            <td className='BulkTableData'>
                                {item.AccountID}
                            </td>

                            <td className='BulkTableData'>
                                {item.Surname}
                            </td>

                            <td className='BulkTableData'>
                                {item.IDNumber}
                            </td>

                            <td className='BulkTableData'>
                                {item.CurrencyType}
                            </td>

                            <td className='BulkTableData'>
                                {item.LoanAmount}
                            </td>

                            <td className='BulkTableData'>
                                {item.Installment}
                            </td>

                            <td className='BulkTableData'>
                                {item.OverdueBalance}
                            </td>
                        </tr>
                    )
                } else {
                    return (
                        <tr>
                            <td className='BulkTableData'>
                                {item.AccountID}
                            </td>

                            <td className='BulkTableData'>
                                {item.Surname}
                            </td>

                            <td className='BulkTableData'>
                                {item.IDNumber}
                            </td>

                            <td className='BulkTableData'>
                                {item.CurrencyType}
                            </td>

                            <td className='BulkTableData'>
                                {item.LoanAmount}
                            </td>

                            <td className='BulkTableData'>
                                {item.Installment}
                            </td>

                            <td className='BulkTableData'>
                                {item.OverdueBalance}
                            </td>
                        </tr>
                    )
                }
            })}
        </tbody>
    )
}

export const TbodyCompanyActiveAccounts = ({ data, setupdata }) => {
    var cleandata
    return (
        <tbody className='prevTableBody'>
            {data.map((item) => {
                if (!item.CompanyName || !item.RegistrationNumber || !item.ContactNumber || !item.AccountID
                    || !item.CurrencyType || !item.LoanAmount || !item.Installment || !item.StartDate ||
                    !item.EndDate) {
                    var index = data.indexOf(item)
                    delete data[index]
                    return (
                        <tr className='corrupt'>
                            <td className='BulkTableData'>
                                {item.AccountID}
                            </td>

                            <td className='BulkTableData'>
                                {item.CompanyName}
                            </td>

                            <td className='BulkTableData'>
                                {item.RegistrationNumber}
                            </td>

                            <td className='BulkTableData'>
                                {item.CurrencyType}
                            </td>

                            <td className='BulkTableData'>
                                {item.LoanAmount}
                            </td>

                            <td className='BulkTableData'>
                                {item.Installment}
                            </td>

                            <td className='BulkTableData'>
                                {item.OverdueBalance}
                            </td>
                        </tr>
                    )
                } else {
                    return (
                        <tr>
                            <td className='BulkTableData'>
                                {item.AccountID}
                            </td>

                            <td className='BulkTableData'>
                                {item.CompanyName}
                            </td>

                            <td className='BulkTableData'>
                                {item.RegistrationNumber}
                            </td>

                            <td className='BulkTableData'>
                                {item.CurrencyType}
                            </td>

                            <td className='BulkTableData'>
                                {item.LoanAmount}
                            </td>

                            <td className='BulkTableData'>
                                {item.Installment}
                            </td>

                            <td className='BulkTableData'>
                                {item.OverdueBalance}
                            </td>
                        </tr>
                    )
                }
            })}
        </tbody>
    )
}

export const TbodyCompanies = ({ data, setupdata }) => {
    var cleandata
    return (
        <tbody className='prevTableBody'>
            {data.map((item) => {
                if (!item.RegistrationNumber || !item.RegisteredName || !item.MobileNumber || !item.VatNumber
                    || !item.ContactPerson || !item.TradingStatus || !item.RegistrationDate) {
                    var index = data.indexOf(item)
                    delete data[index]
                    return (
                        <tr className='corrupt'>
                            <td className='BulkTableData'>
                                {item.RegistrationNumber}
                            </td>

                            <td className='BulkTableData'>
                                {item.RegisteredName}
                            </td>

                            <td className='BulkTableData'>
                                {item.ContactPerson}
                            </td>
                        </tr>
                    )
                } else {
                    return (
                        <tr>
                            <td className='BulkTableData'>
                                {item.RegistrationNumber}
                            </td>

                            <td className='BulkTableData'>
                                {item.RegisteredName}
                            </td>

                            <td className='BulkTableData'>
                                {item.ContactPerson}
                            </td>
                        </tr>
                    )
                }
            })}
        </tbody>
    )
}

export const TbodyIndividuals = ({ data, setupdata }) => {
    var cleandata
    return (
        <tbody className='prevTableBody'>
            {data.map((item) => {
                if (!item.NationalID || !item.FirstName || !item.Surname || !item.IdentificationType) {
                    var index = data.indexOf(item)
                    delete data[index]
                    return (
                        <tr className='corrupt'>
                            <td className='BulkTableData'>
                                {item.FirstName}
                            </td>

                            <td className='BulkTableData'>
                                {item.Surname}
                            </td>


                            <td className='BulkTableData'>
                                {item.IdentificationType}
                            </td>

                            <td className='BulkTableData'>
                                {item.NationalID}
                            </td>


                        </tr>
                    )
                } else {
                    return (
                        <tr>
                            <td className='BulkTableData'>
                                {item.FirstName}
                            </td>

                            <td className='BulkTableData'>
                                {item.Surname}
                            </td>


                            <td className='BulkTableData'>
                                {item.IdentificationType}
                            </td>

                            <td className='BulkTableData'>
                                {item.NationalID}
                            </td>
                        </tr>
                    )
                }
            })}
        </tbody>
    )
}

