import React from 'react'
import { getDate } from '../../../js/getDate'
import { TableData, TableDataHeaders, TableHeading } from '../IndividualReport/ReportTemplates'

export const DetailsOfNextOfKin = ({ nextOfKin }) => {


    if (nextOfKin.data.length === 0) {
        return (
            <table className="report_table">

                <TableHeading
                    heading={"DETAILS OF NEXT OF KIN"}
                    span={8}
                />

                <tr className="data_row">
                    <td colSpan={10}>DETAILS NOT Available</td>
                </tr>
            </table>
        )
    }

    return (
        <>


            {
                (nextOfKin.data !== null || nextOfKin.data.length > 0) &&

                <table className="report_table">

                    <TableHeading
                        heading={"DETAILS OF Next Of Kin"}
                        span={8}
                    />

                    <tr className="data_row">
                        <TableDataHeaders
                            data={"Name"}
                        />

                        <TableData
                            data={nextOfKin.data[0].related_to.firstname + ` ${nextOfKin.data[0].related_to.forenames} ${nextOfKin.data[0].related_to.surname}`}
                        />
                    </tr>


                    <tr className="data_row">
                        <TableDataHeaders
                            data={"ID Number"}
                        />

                        <TableData
                            data={nextOfKin.data[0].related_to.national_id}
                        />
                    </tr>

                    <tr className="data_row">
                        <TableDataHeaders
                            data={"Date Of Birth"}
                        />

                        <TableData
                            data={getDate(nextOfKin.data[0].related_to.dob)}
                        />
                    </tr>

                    <tr className="data_row">
                        <TableDataHeaders
                            data={"Mobile"}
                        />

                        <TableData
                            data={nextOfKin.data[0].related_to.mobile}
                        />
                    </tr>

                    <tr className="data_row">
                        <TableDataHeaders
                            data={"Relationship"}
                        />

                        <TableData
                            data={nextOfKin.data[0].related_to.relationship}
                        />
                    </tr>

                    <tr className="data_row">
                        <TableDataHeaders
                            data={"Residental Address"}
                        />

                        <TableData
                            data={nextOfKin.data[0].related_to.address}
                        />
                    </tr>

                    <tr className="data_row">
                        <TableDataHeaders
                            data={"Employer"}
                        />

                        <TableData
                            data={nextOfKin.data[0].related_to.employer_name}
                        />
                    </tr>

                    <tr className="data_row">
                        <TableDataHeaders
                            data={"Occupation"}
                        />

                        <TableData
                            data={nextOfKin.data[0].related_to.job_title}
                        />
                    </tr>






                </table>
            }

        </>
    )
}
