import axios from "axios";
import { toast } from "react-toastify";


export const uploadSingleClaim = (isVerified, dataSource, searchedEntity, searchedCreditor, claimAmount, comment, dataDate, CurrencyTypeState, token, debtorTypeState, creditorTypeState, dataTypeState, accountNumber, clearFields, setUploading) => {


    axios.post(`/claim/`, {

        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
        }
        ,
        is_varified: isVerified,
        data_souce: dataSource,
        company_debtor_fins: debtorTypeState === "Company" ? searchedEntity.data[0].fins_number : null,
        individual_debtor_fins: debtorTypeState === "Individual" ? searchedEntity.data[0].fins_number : null,
        company_creditor_fins: creditorTypeState === "Company" ? searchedCreditor.data[0].fins_number : null,
        individual_creditor_fins: creditorTypeState === "Individual" ? searchedCreditor.data[0].fins_number : null,
        account_number: accountNumber,
        amount: claimAmount,
        currency_type: CurrencyTypeState,
        comment,
        date_of_claim: dataDate,
        overdue_balance: 0,
        is_absconder: dataTypeState === "Absconder" ? true : false,

    })
        .then(res => {
            setUploading(false)
            if (res.status >= 200 && res.status <= 299) {
                toast.success("Claim added successfully")
                clearFields();

            } else {
                toast.error("Error occured while adding claim")
            }

        })
        .catch(err => {
            setUploading(false)
            toast.error("Couldn't add claim,please check your connection and try again")
        })
}

export const uploadSingleCourtRec = (isVerified, dataSource, caseNumber, plantifType, plantifName, defendentType, searchedDefendent, token, judgementDate, courtName, CurrencyTypeState, amount, clearFields, setUploading) => {


    //searched entity for debtor searched
    axios.post(`/court/`, {

        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
        }
        ,
        is_varified: isVerified,
        data_souce: dataSource,
        case_number: caseNumber,
        plaintiff_type: plantifType,
        plaintf_name: plantifName,
        defendent_type: defendentType,
        defendent_company: defendentType === "Company" ? searchedDefendent.data[0].fins_number : null,
        defendent_individual: defendentType === "Individual" ? searchedDefendent.data[0].fins_number : null,
        judgement_date: judgementDate,
        amount,
        currency_type: CurrencyTypeState,
        court_name: courtName,

    })
        .then(res => {
            setUploading(false);
            if (res.status >= 200 && res.status <= 299) {
                toast.success("Court record added successfully")
                clearFields();
            } else {
                toast.error("Error occured while adding court record")
            }

        })
        .catch(() => {
            setUploading(false);
            toast.error("Couldn't add court record,please check your connection and try again")
        })
}


export const uploadSinglePublicRec = (isVerified, dataSource, caseNumber, dataDate, link, pin, source, summary, searchedOwner, ownerType, token, clearFields, setUploading) => {


    //searched entity for debtor searched
    axios.post(`/public-record/`, {

        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
        }
        ,
        is_varified: isVerified,
        source: dataSource,
        pin,
        link,
        summary,
        case_number: caseNumber,
        date_added: dataDate,
        company: ownerType === "Company" ? searchedOwner.data[0].fins_number : null,
        individual: ownerType === "Individual" ? searchedOwner.data[0].fins_number : null,

    })
        .then(res => {
            setUploading(false)
            if (res.status >= 200 && res.status <= 299) {
                toast.success("Public Record record added successfully")
                clearFields();
            } else {
                toast.error("Error occured while adding public record record")
            }

        })
        .catch(() => {
            setUploading(false);
            toast.error("Couldn't add public record,please check your connection and try again")
        })
}
