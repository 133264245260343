import React from 'react'
import '../../../css/CompanyEdit.css'
import CloseIcon from '@mui/icons-material/Close'
import { AddEditAssociatedCompanies } from '../../Company/AddEditAssociatedCompanies'
import { AddEditCompany } from '../../Company/AddEditCompany'
import AddEditIndividual from '../../Individuals/AddEditIndividual'

const AddModal = ({ setrun, add, data, setDisplay, display }) => {
  return (
    <div className={display === true ?
      'EditModalcssbiu'
      :
      'EditModalcssbiuHidden'}>
      {add === 'Company' &&
        <AddEditCompany
          caller={'addBureau'}
          setDisplayedForm={setDisplay}
        />
      }

      {add === 'Individual' &&
        <AddEditIndividual
          caller='addbureau'
          setDisplayedForm={setDisplay}
        />
      }
      <div className='closeIconDivBIU'>
        <CloseIcon className='closeIconBiu' onClick={() => { setDisplay('close')}} />
      </div>
    </div>
  )
}

export default AddModal