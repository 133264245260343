import React, { useEffect, useContext, useState } from 'react';
import { fetchData, handleResponses } from '../js/fetchData';
import { fetchByFinsNumbers } from '../js/searchRecord';
import { filterAbsconderSlowPayerActive } from '../js/filterAbsconderSlowPayerActive';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import "./../css/Searchtemplate.css";
import { Loader } from './Loader';
import CloseIcon from '@mui/icons-material/Close';
import { LoadingModal, Modal } from './Modal';
import axios from 'axios';
import { InputWithDropdownContainer, SelectContainer } from './InputContainer';
import { debtorType } from './Company/selectOptionsjson';
import AuthContext from '../AuthContext/AuthContext';
import { useNavigate } from 'react-router-dom';
import { ValuesContext } from './Admin/HomeAdmin';
import { closeIndivdualRecord, deleteRecord } from '../js/closeIndividualClaim';
import { type } from '@testing-library/user-event/dist/type';
import { getDate } from '../js/getDate';
import { toast } from 'react-toastify';

export const ApproveAdverseData = () => {

    const { authTokens } = useContext(AuthContext);
    const [checked, setChecked] = useState(false)
    const navigate = useNavigate();
    const { userData } = useContext(ValuesContext);
    const [searchedEntity, setSearchedEntity] = useState("");
    const [showModal, setShowModal] = useState(false);
    const [closeStatus, setCloseStatus] = useState(false);
    const [debtorTypeState, setDebtorTypeState] = useState("Individual")
    const [activeAccs, setActiveCreditAccs] = useState("");
    const [failureFetch, setFailureFetch] = useState("");
    const [fetching, setFetching] = useState(false);
    const [claims, setClaims] = useState({ data: null });
    const [courts, setCourts] = useState({ data: null });
    const [absconders, setAbsconders] = useState({ data: null });
    const [slowPayers, setSlowPayers] = useState({ data: null });
    const [allRecords, setAllRecords] = useState({ data: null });
    const [publicRecords, setPublicRecords] = useState({ data: null });

    useEffect(() => {

        fetchData(
            String(authTokens.access),
            setAllRecords,
            'unapproved-records',
            null,
            null
        )
    }, []);

    useEffect(() => {

        if (allRecords.data === null) {
            return;
        }

        setClaims({ data: allRecords.data.claims.filter(claim => claim.is_varified === false && claim.is_absconder === false) });
        setAbsconders({ data: allRecords.data.claims.filter(claim => claim.is_varified === false && claim.is_absconder === true) });
        setPublicRecords({ data: allRecords.data.public_records.filter(publicRecord => publicRecord.is_varified === false) });
        setCourts({ data: allRecords.data.courts.filter(court => court.is_varified === false) });
        /*  setSlowPayers({ data: allRecords.data.slowpayers.filter(slowpayer => slowpayer.is_varified === false) }); */

    }, [allRecords])


    const approveAllRecords = () => {

        setChecked(true)
        axios.get(`/approve-records/`)
            .then(function (response) {
                setClaims({ data: [] });
                setCourts({ data: [] });
                setPublicRecords({ data: [] });
                setAbsconders({ data: [] });
                toast.success('Public record approved');
            })
            .catch(function (error) {

                setChecked(false);
                toast.error('Failed to approve,try again');

            });
    }



    return (

        <section className="search-claims">
            {showModal && <LoadingModal closeStatus={closeStatus} setShowModal={setShowModal} />}
            <div className="search-claims-header">
                <p>Approve Records</p>
            </div>

            <div className="claims-container" style={{ marginTop: 0, height: '100%' }}>

                <div className="claims-header" style={{ fontSize: '.7rem' }}>
                    <h4>Record Type</h4>
                    <h4>Creditor</h4>
                    <h4>Data Date</h4>
                    <h4>Name</h4>
                    <h4>National ID /Reg No.</h4>
                    <h4>Currency</h4>
                    <h4>Amount</h4>
                    <h4>
                        Approve
                        <p>
                            <RadioButtonUncheckedIcon
                                className={`checkbox ${checked && 'selected'}`}
                                onClick={approveAllRecords}
                            />
                            All
                        </p>
                    </h4>
                    <h4>Reject</h4>
                </div>

                {
                    claims.data !== null &&
                    claims.data.length > 0 &&
                    claims.data.map((claim, index) => {

                        if (claim.is_closed !== true) {
                            return (
                                <Claim
                                    claim={claim}
                                    index={index}
                                    typeOfRecord={"claim"}
                                    authTokens={authTokens}
                                    userData={userData}
                                    setShowModal={setShowModal}
                                    setCloseStatus={setCloseStatus}
                                    searchedEntity={searchedEntity}
                                    navigate={navigate}
                                    claims={claims}
                                    setClaims={setClaims}
                                />
                            )
                        }
                    })
                }

                {
                    absconders.data !== null &&
                    absconders.data.length > 0 &&
                    absconders.data.map((claim, index) => {
                        if (claim.is_closed !== true) {
                            return (
                                <Claim
                                    claim={claim}
                                    index={index}
                                    typeOfRecord={"absconder"}
                                    authTokens={authTokens}
                                    userData={userData}
                                    setShowModal={setShowModal}
                                    setCloseStatus={setCloseStatus}
                                    searchedEntity={searchedEntity}
                                    navigate={navigate}
                                    claims={claims}
                                    setClaims={setClaims}
                                />
                            )
                        }
                    })
                }



                {
                    courts.data !== null &&
                    courts.data.length > 0 &&
                    courts.data.map((court, index) => {
                        if (court.is_closed !== true) {
                            return (
                                <Court
                                    court={court}
                                    index={index}
                                    typeOfRecord={"court"}
                                    authTokens={authTokens}
                                    userData={userData}
                                    setShowModal={setShowModal}
                                    setCloseStatus={setCloseStatus}
                                    searchedEntity={searchedEntity}
                                    navigate={navigate}
                                    courts={courts}
                                    setCourts={setCourts}
                                />
                            )
                        }
                    })
                }

                {
                    publicRecords.data !== null &&
                    publicRecords.data.length > 0 &&
                    publicRecords.data.map((publicRecord, index) => {

                        return (
                            <PublicRecords
                                publicRecord={publicRecord}
                                index={index}
                                typeOfRecord={"Public Record"}
                                authTokens={authTokens}
                                userData={userData}
                                setShowModal={setShowModal}
                                setCloseStatus={setCloseStatus}
                                searchedEntity={searchedEntity}
                                navigate={navigate}
                                publicRecords={publicRecords}
                                setPublicRecords={setPublicRecords}
                            />
                        )

                    })
                }



            </div>



        </section>
    )
}


const SearchSection = ({ setSearchedEntity, debtorTypeState, setDebtorTypeState }) => {

    const [searchTerm, setSearchTerm] = useState("");

    const { authTokens } = useContext(AuthContext);


    return (
        <div className="search-container-section">

            <form className="search-container-form">


                <div className="search-parameter-input-container">
                    <SelectContainer
                        label={"Select Filter"}
                        currentValue={debtorTypeState}
                        setValue={setDebtorTypeState}
                        options={debtorType}
                        id={""}
                        width={"100%"}

                    />
                </div>

                <div className="search-term-input-container">
                    <InputWithDropdownContainer
                        label={"Enter Name / ID  / Reg Number"}
                        type={"text"}
                        currentValue={searchTerm}
                        setValue={setSearchTerm}
                        id={""}
                        typeOfEntity={debtorTypeState}
                        setSearchedEntity={setSearchedEntity}
                        authTokens={authTokens}
                        width={"100%"}
                    />
                </div>


                {/* 
                <div className="search-section-btn-container">
                    <button 
                        className="search-button" 
                    >
                        Search
                    </button>
                </div> */}
            </form>
        </div>
    )
}

const PublicRecords = ({ publicRecord, index, typeOfRecord, authTokens, userData, setShowModal, setCloseStatus, searchedEntity, navigate, publicRecords, setPublicRecords }) => {

    const [checked, setChecked] = useState(false);
    const [deleteChecked, setDeleteCheched] = useState(false)


    const approve = () => {

        setChecked(true)
        axios.post(`/approve/`, {
            data: {
                record_type: typeOfRecord,
                id: publicRecord.id
            }
        })
            .then(function (response) {
                const id = publicRecord.id;
                const newPublicRecords = publicRecords.data.filter(p => id !== p.id);
                setPublicRecords({ data: newPublicRecords });
                setChecked(false)
                toast.success('Public record approved');
            })
            .catch(function (error) {

                setChecked(false);
                toast.error('Failed to approve,try again');

            });
    }



    return (
        <article className="claim" style={{ backgroundColor: index % 2 === 0 && 'white', fontSize: '.7rem' }}>
            <p style={{ textTransform: 'capitalize' }}>{typeOfRecord}</p>
            <p>N/A</p>
            <p>{publicRecord.date_added ? getDate(publicRecord.date_added) : 'N/A'}</p>
            <p>{publicRecord.company !== null ? publicRecord.company.registration_name : (publicRecord.individual ? `${publicRecord.individual.firstname} ${publicRecord.individual.surname}` : 'N/A')}</p>
            <p>{publicRecord.company !== null ? publicRecord.company.registration_number : (publicRecord.individual ? `${publicRecord.individual.national_id}` : 'N/A')}</p>
            <p>{`N/A`}</p>
            <p>{'N/A'} </p>
            <p>
                <RadioButtonUncheckedIcon
                    className={`checkbox ${checked && 'selected'}`}
                    onClick={approve}
                />
            </p>
            <p>
                <RadioButtonUncheckedIcon
                    className={`checkbox ${deleteChecked && 'selected'}`}
                    onClick={() => deleteRecord(publicRecord.id, 'public-record', authTokens, publicRecords, setPublicRecords)}
                />
            </p>
        </article>
    )
}

const Claim = ({ claim, index, typeOfRecord, authTokens, userData, setShowModal, setCloseStatus, searchedEntity, navigate, claims, setClaims }) => {

    const [checked, setChecked] = useState(false);
    const [deleteChecked, setDeleteCheched] = useState(false)

    const approve = () => {

        setChecked(true)
        axios.post(`/approve/`, {
            data: {
                record_type: typeOfRecord,
                id: claim.claim_number
            }
        })
            .then(function (response) {
                const id = claim.claim_number;
                const newclaims = claims.data.filter(c => id !== c.claim_number);
                setClaims({ data: newclaims });
                setChecked(false)
                toast.success('Claim approved');
            })
            .catch(function (error) {
                toast.error('Failed to approve,try again');
                setChecked(false)
                toast.error('Failed to approve,try again');

            });
    }

    return (
        <article className="claim" style={{ backgroundColor: index % 2 === 0 && 'white', fontSize: '.7rem' }}>
            <p style={{ textTransform: 'capitalize' }}>{typeOfRecord}</p>
            <p>{claim.company_creditor_fins !== null ? claim.company_creditor_fins.registration_name : (claim.individual_creditor_fins ? `${claim.individual_creditor_fins.firstname} ${claim.individual_creditor_fins.surname}` : 'N/A')}</p>
            <p>{getDate(claim.date_of_claim)}</p>
            <p>{claim.company_debtor_fins !== null ? claim.company_debtor_fins.registration_name : (claim.individual_debtor_fins ? `${claim.individual_debtor_fins.firstname} ${claim.individual_debtor_fins.surname}` : 'N/A')}</p>
            <p>{claim.company_debtor_fins !== null ? claim.company_debtor_fins.registration_number : (claim.individual_debtor_fins ? `${claim.individual_debtor_fins.national_id}` : 'N/A')}</p>
            <p>{`${claim.currency_type}`}</p>
            <p>{claim.amount} </p>
            <p>
                <RadioButtonUncheckedIcon
                    className={`checkbox ${checked && 'selected'}`}
                    onClick={approve}
                />
            </p>
            <p>
                <RadioButtonUncheckedIcon
                    className={`checkbox ${deleteChecked && 'selected'}`}
                    onClick={() => deleteRecord(claim.claim_number, 'claim', authTokens, claims, setClaims)}
                />
            </p>
        </article>
    )

}

const SlowPayer = ({ slowPayer, index, authTokens, userData, setShowModal, setCloseStatus, searchedEntity, navigate, slowPayers, setSlowPayers }) => {


    return (
        <article className="claim" style={{ backgroundColor: index % 2 === 0 && 'white' }}>
            <p>Slowpayer</p>
            <p>{slowPayer.company_creditor !== null ? slowPayer.company_creditor.registration_name : `${slowPayer.individual_creditor.firstname} ${slowPayer.individual_creditor.surname}`}</p>
            <p>{slowPayer.acount_number}</p>
            <p>{slowPayer.data}</p>
            <p>{slowPayer.data_souce}</p>
            <p>{`${slowPayer.currency_type}`}</p>
            <p>{slowPayer.principal_amount} </p>
            <p><button onClick={() => deleteRecord(slowPayer.credit_detail, 'slowpayer', authTokens, slowPayers, setSlowPayers)} style={{ color: 'white', background: 'red', width: '100%', padding: '.3em 0', border: 'none', cursor: 'pointer' }}>Delete</button></p>
        </article>
    )

}

const Court = ({ court, index, typeOfRecord, authTokens, userData, setShowModal, setCloseStatus, searchedEntity, navigate, courts, setCourts }) => {

    const [checked, setChecked] = useState(false);
    const [deleteChecked, setDeleteCheched] = useState(false)



    const approve = () => {

        setChecked(!checked)
        axios.post(`/approve/`, {
            data: {
                record_type: typeOfRecord,
                id: court.case_number
            }
        })
            .then(function (response) {
                setCourts({ data: courts.data.filter(c => c.case_number !== court.case_number) });
                toast.success('Claim approved');
                setChecked(!checked)
            })
            .catch(function (error) {
                toast.error('Failed to approve,try again');
                setChecked(!checked)
            });
    }


    const deleteCourt = () => {

        axios.post('/delete-court/', {
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ${authTokens.access}`
            },
            data: {
                case_number: court.case_number
            }
        })
            .then(res => {
                setCourts({ data: courts.data.filter(c => c.case_number !== court.case_number) });
                setChecked(!checked)
                toast.success('Court deleted');

            })
            .catch(function (error) {
                setChecked(!checked)
                toast.error('Failed to approve,try again');
            });
    }

    return (
        <article className="claim" style={{ backgroundColor: index % 2 === 0 && 'white', fontSize: '.7rem' }}>
            <p>Court</p>
            <p>{court.plaintf_name}</p>
            <p>{getDate(court.date)}</p>
            <p>{court.defendent_company !== null ? court.defendent_company.registration_name : (court.defendent_individual ? `${court.defendent_individual.firstname} ${court.defendent_individual.surname}` : 'N/A')}</p>
            <p>{court.defendent_company !== null ? court.defendent_company.registration_number : (court.defendent_individual ? `${court.defendent_individual.national_id}` : 'N/A')}</p>
            <p>{`${court.currency_type}`}</p>
            <p>{court.amount} </p>
            <p>  <RadioButtonUncheckedIcon
                className={`checkbox ${checked && 'selected'}`}
                onClick={approve}
            /></p>
            <p>
                <RadioButtonUncheckedIcon
                    className={`checkbox ${deleteChecked && 'selected'}`}
                    onClick={deleteCourt}
                />
            </p>
        </article>
    )

}
