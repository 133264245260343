import React, { useState, useContext } from 'react'
import { InputAreaContainer, InputContainer } from '../InputContainer'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import '../../css/FormContainerStyle.css';
import '../../css/addcompany.css';
import SaveIcon from '@mui/icons-material/Save'
import AddIcon from '@mui/icons-material/Add'
import { DataSubmission } from '../Other/DataSubmission';
import { toggleForms } from '../../js/toggleForms';
import AuthContext from "../../AuthContext/AuthContext";
import { useNavigate } from 'react-router-dom'

const CommentRating = ({ companyFins, FormData, id, setDisplayedForm }) => {
    const [rating, setRating] = useState(() => {
        if (FormData) {
            return FormData.rating
        } else {
            return ''
        }
    })
    const [bussinessSummary, setBussinessSummary] = useState(() => {
        if (FormData) {
            return FormData.bussiness_summary
        } else {
            return ''
        }
    })
    const [bankCode, setBankCode] = useState(() => {
        if (FormData) {
            return FormData.bank_code
        } else {
            return ''
        }
    })
    const [comment, setComment] = useState(() => {
        if (FormData) {
            return FormData.comment
        } else {
            return ''
        }
    })
    const [paymentTrend, setPaymentTrend] = useState(() => {
        if (FormData) {
            return FormData.trend
        } else {
            return ''
        }
    })
    const [bankComment, setBankComment] = useState(() => {
        if (FormData) {
            return FormData.bank_comment
        } else {
            return ''
        }
    })
    const [condition, setCondition] = useState(() => {
        if (FormData) {
            return FormData.condition
        } else {
            return ''
        }
    })
    const { authTokens, logoutUser } = useContext(AuthContext);

    const Navigate = useNavigate()
    const SubmitForm = async (e) => {
        e.preventDefault()
        let responsed = ''

        responsed = await DataSubmission(
            String(authTokens.access),
            'post',
            '/save-report/',
            {
                biu_report_id: FormData.biu_report_id,
                company: companyFins,
                rating: rating,
                comment: comment,
                trend: paymentTrend,
                condition: condition,
                bank_code: bankCode,
                bank_comment: bankComment,
                bussiness_summary: bussinessSummary
            }
        )

        if (responsed[0].result === 'success') {
            toast.success('Company ' + companyFins + ' has been added succesfully!')
            Navigate('/admin/business-intelligence')
        }
    }

    return (
        <section className="form-container">
            <h3 className="form-header">Summary</h3>
            <form className="form">

                <section className='secinputcontainer'>

                    <section className=' secinputdeatails'>

                        <InputContainer
                            label={"Overall Risk Rating"}
                            type={"text"}
                            currentValue={rating}
                            setValue={setRating}
                            id={'rating'}
                        />

                        <InputContainer
                            label={"Payment Trend"}
                            type={"text"}
                            currentValue={paymentTrend}
                            setValue={setPaymentTrend}
                            id={'rating'}
                        />

                        <InputContainer
                            label={"Condition"}
                            type={"text"}
                            currentValue={condition}
                            setValue={setCondition}
                            id={'rating'}
                        />

                        <InputContainer
                            label={"Bank Code"}
                            type={"text"}
                            currentValue={bankCode}
                            setValue={setBankCode}
                            id={'rating'}
                        />

                        <InputAreaContainer
                            label={"Bank Comment"}
                            type={"text"}
                            currentValue={bankComment}
                            setValue={setBankComment}
                            id={'comment'}
                        />

                        <InputAreaContainer
                            label={"Summary Comment"}
                            type={"text"}
                            currentValue={comment}
                            setValue={setComment}
                            id={'comment'}
                        />

                        <InputAreaContainer
                            label={"Bussiness Summary"}
                            type={"text"}
                            currentValue={bussinessSummary}
                            setValue={setBussinessSummary}
                            id={'summary'}
                        />

                    </section>

                </section>

                <section className='secButtons'>

                    <button icon="close" className="formButtons" text="Close"
                        align="center" onClick={e => SubmitForm(e)} >
                        <SaveIcon className='icons' />
                        <blockquote>
                            Save
                        </blockquote>
                    </button>

                </section>

            </form>
        </section>
    )
}

export default CommentRating
