import axios from "axios";;

export const deleteInquiry = (report, setDeleting, biuReportReqs, setBIUReportReqs, requestsMade, setRequestsMade, req) => {

    setDeleting("deleting");
    axios.delete(`biu-request/${report.request_id}/`, {
        headers: {
            /* "Authorization": `Bearer ` + String(accessToken), */
            'Content-Type': "application/json"
        }
    })
        .then(res => {

            if (res.status >= 200 && res.status <= 299) {

                setDeleting(true);
                setTimeout(() => {

                    if (biuReportReqs !== null) {
                        const newInquiries = biuReportReqs.data.filter(rep => rep.request_id !== report.request_id);
                        setBIUReportReqs({ data: newInquiries, message: "fetched successfully" })
                        setDeleting(false)
                    } else {
                        const newReqs = requestsMade.filter(requ => requ.compIndName !== requ.compIndName);
                        setRequestsMade(newReqs);
                        setDeleting(false)
                    }

                }, 1500)

            } else {

                setDeleting("failed");
                setTimeout(() => {
                    setDeleting(false)
                }, 1000)

            }
        })
        .catch(() => {
            setDeleting("failed");
            setTimeout(() => {
                setDeleting(false)
            }, 1000)
        })
}