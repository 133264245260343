import React from "react";
import ModalLoadingCSS from "../css/ModalLoading.module.css";

export default function ModalLoading({isInvocing}) {
  return (
    <div style={{ display: isInvocing ? "flex" : "none" }} className={ModalLoadingCSS.TFmodal}>
      <div className={ModalLoadingCSS.TFmodalContent}>
        <div className={ModalLoadingCSS.TFloader}></div>
        <div className={ModalLoadingCSS.TFmodalText}>Loading...</div>
      </div>
    </div>
  );
}
