import React from 'react'
import { TableHeading } from '../../Individuals/IndividualReport/ReportTemplates'
import { BankingDetails } from './BankingDetails';
import { Auditors } from './Auditors';
import { TradeReferences } from './TradeReferences';

export const Operations = ({ companyCapital, report, company, divisions, associatedCompanies, subsidiaries, branch, auditors, bankDetails, tradeReferences }) => {

  const strippedDivisions = divisions.data !== null ? divisions.data.map(division => division.division) : [];
  const strippedAssociatedCompanies = associatedCompanies.data !== null ? associatedCompanies.data.map(associatedCompany => associatedCompany.company_name) : [];
  const strippedSubsidiaries = subsidiaries.data !== null ? subsidiaries.data.map(subsidiary => subsidiary.company_name) : [];
  const strippedBranches = branch.data !== null ? branch.data.map(b => b.branch_name) : [];

  return (

    <table className="report_table" border={1}>

      <tr className='row_heading' style={{ margin: '50px 0', backgroundColor: '#4472c4', color: 'white' }}>
        <td className='txt_rowheading' colSpan={4}>
          <h1 className='section_heading' style={{ fontSize: '1.2rem' }}>
            OPERATIONS
          </h1>
        </td>
      </tr>

      <tr className='row_heading' style={{ margin: '50px 0', backgroundColor: '#b4c6e7', color: 'black' }}>
        <td className='txt_rowheading' colSpan={4}>
          <h1 className='section_heading' style={{ fontSize: '1rem' }}>
            Operational Details
          </h1>
        </td>
      </tr>


      <tr>
        <th style={{ textAlign: 'left', fontWeight: '400' }}>Industry: </th>
        <td style={{ textAlign: 'left', fontWeight: '400' }}>{company.industry ? company.industry : 'N/A'}</td>
        <th style={{ textAlign: 'left', fontWeight: '400' }}>Number of Employees: </th>
        <td style={{ textAlign: 'left', fontWeight: '400' }}>{company.number_of_employees ? company.number_of_employees : 'N/A'}</td>
      </tr>

      <tr>
        <th style={{ textAlign: 'left', fontWeight: '400' }}>Net Worth: </th>
        <td style={{ textAlign: 'left', fontWeight: '400' }}>{(companyCapital.data === null || companyCapital.data.length === 0) ? 'N/A' : companyCapital.data[0].networth}</td>
      </tr>

      <br />
      <p>Business Summary: </p>
      <tr>
        <td style={{ textAlign: 'left', fontWeight: '400' }}>{report.data.bussiness_summary ? report.data.bussiness_summary : 'N/A'}</td>
      </tr>

      <br /><br />
      <tr className='row_heading' style={{ margin: '50px 0', backgroundColor: '#b4c6e7', color: 'black' }}>
        <td className='txt_rowheading' colSpan={4}>
          <h1 className='section_heading' style={{ fontSize: '1rem' }}>
            Structure
          </h1>
        </td>
      </tr>

      <tr>
        <th style={{ textAlign: 'left', fontWeight: '600' }}>Holding Company: </th>
        <td colSpan={3} style={{ textAlign: 'left', fontWeight: '400' }}>{company.parent_company ? company.parent_company : 'N/A'}</td>
      </tr>
      <br />

      <tr>
        <th style={{ textAlign: 'left', fontWeight: '600' }}>Subsidiaries : </th>
        <td colSpan={3} style={{ textAlign: 'left', fontWeight: '400' }}>{strippedSubsidiaries}</td>
      </tr>

      <br />

      <tr>
        <th style={{ textAlign: 'left', fontWeight: '600' }}>Associate Companies: </th>
        <td colSpan={3} style={{ textAlign: 'left', fontWeight: '400' }}>{strippedAssociatedCompanies}</td>
      </tr>

      <br />

      <tr>
        <th style={{ textAlign: 'left', fontWeight: '600' }}>Divisions: </th>
        <td colSpan={3} style={{ textAlign: 'left', fontWeight: '400' }}>{strippedDivisions}</td>
      </tr>

      <br />

      <tr>
        <th style={{ textAlign: 'left', fontWeight: '600' }}>Branches: </th>
        <td colSpan={3} style={{ textAlign: 'left', fontWeight: '400' }}>{strippedBranches}</td>
      </tr>

      <br /><br />


      <tr>
        <td colSpan={5}>
          <BankingDetails bankDetails={bankDetails} />
        </td>
      </tr>

      <br />
      <tr>
        <td colSpan={5}>
          <Auditors auditors={auditors} />
        </td>
      </tr>

      <tr className="report_section">
        <td colSpan={5}><TradeReferences tradeReferences={tradeReferences} /></td>
      </tr>





    </table>
  )
}
