import React from 'react'
import { TableHeading, TableDataHeaders, TableData } from '../../Individuals/IndividualReport/ReportTemplates'
import { getDate } from '../../../js/getDate'

export const CompanyIdentificationAndSummary = ({ company, BIU, color }) => {
    console.log(company)
    return (

        <table className='report_table'>

            {
                !BIU &&
                <TableHeading
                    span={8}
                    color={color}
                    heading={"Registration Details"}
                />
            }

            {
                BIU &&
                <tr className='row_heading' style={{ margin: '50px 0', backgroundColor: '#4472c4', color: 'white' }}>
                    <td className='txt_rowheading' colSpan={4}>
                        <h1 className='section_heading' style={{ fontSize: '1.2rem' }}>
                            REGISTRATION DETAILS
                        </h1>
                    </td>
                </tr>
            }
            {
                BIU &&
                <tr className='row_heading' style={{ margin: '50px 0', backgroundColor: '#b4c6e7', color: 'black' }}>
                    <td className='txt_rowheading' colSpan={4}>
                        <h1 className='section_heading' style={{ fontSize: '1rem' }}>
                            Current Identification
                        </h1>
                    </td>
                </tr>
            }

            {
                !BIU &&
                <>
                    <tr className="data_row">

                        <TableDataHeaders
                            id="registered_name_header"
                            data={"Registered Name:"}
                            textAlignClass={'text_section_left_align'}
                        />

                        <TableData
                            id={"registered_name_field"}
                            data={`${company.registration_name ? company.registration_name : "N/A"}`}
                            textAlignClass={'text_section_left_align'}
                        />

                        <TableDataHeaders
                            id="legal_status_header"
                            data={"Trading Name:"}
                            textAlignClass={'text_section_left_align'}
                        />

                        <TableData
                            id={"legal_status_field"}
                            data={`${company.trading_name ? company.trading_name : "N/A"}`}
                            textAlignClass={'text_section_left_align'}
                        />

                    </tr>

                    <tr className="data_row">

                        <TableDataHeaders
                            id="registration_number_header"
                            data={"Registration number:"}
                            textAlignClass={'text_section_left_align'}
                        />

                        <TableData
                            id={"registration_number_field"}
                            data={`${company.registration_number ? company.registration_number : "N/A"}`}
                            textAlignClass={'text_section_left_align'}
                        />

                        <TableDataHeaders
                            id="date_of_registration_header"
                            data={"Year of registration:"}
                            textAlignClass={'text_section_left_align'}
                        />

                        <TableData
                            id={"date_of_registration_field"}
                            data={`${company.registration_date ? getDate(company.registration_date) : "N/A"}`}
                            textAlignClass={'text_section_left_align'}
                        />

                    </tr>


                    <tr className="data_row">

                        <TableDataHeaders
                            id="vat_number_header"
                            data={"Trading Status:"}
                            textAlignClass={'text_section_left_align'}
                        />

                        <TableData
                            id={"vat_number_field"}
                            data={`${company.trading_status ? company.trading_status : "N/A"}`}
                            textAlignClass={'text_section_left_align'}
                        />

                        <TableDataHeaders
                            id="bp_number_header"
                            data={"Industry Sector:"}
                            textAlignClass={'text_section_left_align'}
                        />

                        <TableData
                            id={"bp_number_field"}
                            data={`${company.industry ? company.industry : "N/A"}`}
                            textAlignClass={'text_section_left_align'}
                        />

                    </tr>

                </>
            }





            {
                BIU &&
                <>
                    <tr className="data_row">

                        <TableDataHeaders
                            id="registered_name_header"
                            data={"Registered Number:"}
                            textAlignClass={'text_section_left_align'}
                        />

                        <TableData
                            id={"registered_name_field"}
                            data={`${company.registration_number ? company.registration_number : "N/A"}`}
                            textAlignClass={'text_section_left_align'}
                        />

                        <TableDataHeaders
                            id="legal_status_header"
                            data={"Date of Reg:"}
                            textAlignClass={'text_section_left_align'}
                        />

                        <TableData
                            id={"legal_status_field"}
                            data={`${company.registration_date ? getDate(company.registration_date) : "N/A"}`}
                            textAlignClass={'text_section_left_align'}
                        />

                    </tr>
                    <br />
                    <tr className="data_row">

                        <TableDataHeaders
                            id="registered_name_header"
                            data={"Legal Status:"}
                            textAlignClass={'text_section_left_align'}
                        />

                        <TableData
                            id={"registered_name_field"}
                            data={`${company.legal_status ? company.legal_status : "N/A"}`}
                            textAlignClass={'text_section_left_align'}
                        />

                        <TableDataHeaders
                            id="legal_status_header"
                            data={"Trading Status:"}
                            textAlignClass={'text_section_left_align'}
                        />

                        <TableData
                            id={"legal_status_field"}
                            data={`${company.trading_status ? company.trading_status : "N/A"}`}
                            textAlignClass={'text_section_left_align'}
                        />

                    </tr>
                    <br />
                    <tr className="data_row">

                        <TableDataHeaders
                            id="registered_name_header"
                            data={"BP Number:"}
                            textAlignClass={'text_section_left_align'}
                        />

                        <TableData
                            id={"registered_name_field"}
                            data={`${company.bp_number ? company.bp_number : "N/A"}`}
                            textAlignClass={'text_section_left_align'}
                        />

                        <TableDataHeaders
                            id="legal_status_header"
                            data={"VAT Number:"}
                            textAlignClass={'text_section_left_align'}
                        />

                        <TableData
                            id={"legal_status_field"}
                            data={`${company.vat_number ? company.vat_number : "N/A"}`}
                            textAlignClass={'text_section_left_align'}
                        />

                    </tr>
                </>
            }

        </table>
    )
}
