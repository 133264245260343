
export const filterAbsconderSlowPayerActive = (allClaims,setClaims,creditAccounts,setActiveCreditAccs,setAbsconders,setSlowPayer,setHistoricals) => {

    const absconders = allClaims.data.filter(claim => claim.is_absconder === true);
    const slowPayers = creditAccounts.data.filter(account => account.overdue_balance !== "0.00");
    const claims = allClaims.data.filter(claim => claim.is_absconder === false);
    const activeCreditAccs = creditAccounts.data.filter(account => account.overdue_balance === "0.00");
    const historicals = creditAccounts.data.filter(account => account.is_closed === true);

    setAbsconders({data: absconders})
    setSlowPayer({data: slowPayers});
    setActiveCreditAccs({data: activeCreditAccs});
    setClaims({data: claims})
    setHistoricals && setHistoricals({data: historicals});
    
}