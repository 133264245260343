import { Home } from './components/Home';
import React, { useState, createContext } from 'react';
import { LoginPage } from './components/LoginPage'
import './css/App.css';
import HomeAdmin from './components/Admin/HomeAdmin';
import axios from 'axios';
import { IndividualReport } from './components/Individuals/IndividualReport/IndividualReport';
import { CompanyReport2 } from './components/Company/CompanyReport/CompanyReport2';
import { AuthProvider } from './AuthContext/AuthContext';
import { CompanyBiuReport } from './components/Company/CompanyBIUReport/CompanyBiuReport';
import { IndividualBIUReport } from './components/Individuals/IndividualBIUReport/IndividualBIUReport';
import { CompanyBIUEdit } from './components/Company/CompanyBIUEdit';
import { IndividualBIUReportEditing } from './components/Individuals/IndividualBIUReportEditing';
import { ClearanceLetter } from './components/ClearanceLetter';
import BulkForm from './components/BulkUpload/BulkForm';
import Profile from './components/Other/Profile';
import { BureauDashboard } from './components/Admin/BureauDashboard';
import { Claims } from './components/Claims';
import { SearchIndividuals } from './components/Individuals/SearchIndividuals';
import { SearchCompanies } from './components/Company/SearchCompanies';
import { SearchHPI } from './components/HPI/SearchHPI';
import { ReportsBIU } from './components/ReportsBIU';
import { Pricing } from './components/Pricing';
import { BIUDashboard } from './components/BIUDashboard';
import { Targets } from './components/Targets';
import { InvoicingPeriods } from './components/InvoicingPeriods';
import { InternalUsers } from './components/InternalUsers';
import { ExternalUsers } from './components/ExternalUser';
import LandingClient from './components/Clients/LandingClient';
import { Settings } from './components/Settings';
import { ClientAminUsersAdd } from './components/ClientAminUsersAdd';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import { Error500 } from './components/Error500';
import HomeTemp from './components/Admin/Home';
import { ApproveAdverseData } from './components/ApproveAdverseData';
import { ChangePasswordForm } from './components/Other/ChangePassword';
import { ToastContainer } from 'react-toastify';
import { HPIReport } from './components/HPI/HPIReport';
import {HpiRegistration} from './components/HPI/HpiRegistration'

import InvoiceList from './components/InvoiceList';
import InvoiceReport from './components/InvoiceReport'
import RescissionReport from './components/RescissionReport'
import FiscalTaxInvoice from './components/FiscalTaxInvoice'
import ConfirmationLetter from './components/ConfirmationLetter'

axios.defaults.xsrfCookieName = 'csrftoken';
axios.defaults.xsrfHeaderName = 'X-CSRFToken';

export const EnquiriesContext = createContext();


const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
    errorElement: <Error500 />
  },
  {
    path: '/login',
    element: <LoginPage />,
    errorElement: <Error500 />
  },
  {
    path: '/admin',
    element: <HomeAdmin />,
    errorElement: <Error500 />,
    children: [
      {
        path: '',
        element: <HomeTemp />
      },
      {
        path: 'bureau',
        element: <BureauDashboard />
      },
      {
        path: 'close-records',
        element: <Claims admin />
      },
      {
        path: 'individuals',
        element: <SearchIndividuals edit />
      },
      {
        path: 'company',
        element: <SearchCompanies edit />
      },
      {
        path: 'hire-purchase',
        element: <SearchHPI admin />
      },
      {
        path: 'business-intelligence',
        element: <BIUDashboard />
      },
      {
        path: 'business-intelligence-report',
        element: <ReportsBIU />
      },
      {
        path: 'invoice-list',
        element: <InvoiceList />
      },

      {
        path: 'pricing',
        element: <Pricing />
      },
      {
        path: 'targets',
        element: <Targets />
      },
      {
        path: 'invoicing-periods',
        element: <InvoicingPeriods />
      },
      {
        path: 'internal-users',
        element: <InternalUsers />
      },
      {
        path: 'external-users',
        element: <ExternalUsers />
      },
      {
        path: 'clients',
        element: <LandingClient section={'table'} />
      },
      {
        path: 'settings',
        element: <Settings />
      },
      {
        path: 'bureau-hpi',
        element: <SearchHPI bureau />
      },
      {
        path: 'hire-purchase-registration',
        element: <HpiRegistration/>
      },
      {
        path: 'client-add-user',
        element: <ClientAminUsersAdd />
      },
      {
        path: 'search-individuals',
        element: <SearchIndividuals enquiries />
      },
      {
        path: 'search-companies',
        element: <SearchCompanies />
      },
      {
        path: 'approve-adverse-record',
        element: <ApproveAdverseData />
      },
      {
        path: 'profile',
        element: <ChangePasswordForm />
      },
    ]
  },
  {
    path: 'invoice-report',
    element: <InvoiceReport />
  },
  {
    path: 'rescission-report',
    element: <RescissionReport/>
  },

  {
    path: 'fiscal-tax-invoice/',
    element: <FiscalTaxInvoice />
  },
  
  {
    path: '/report/company-report',
    element: <CompanyReport2 />
  },
  {
    path: '/report/hpi-report',
    element: <HPIReport />
  },
  {
    path: '/report/company-edit-biu-report/:id',
    element: <CompanyBIUEdit />
  },
  {
    path: '/report/individual-edit-biu-report/:id',
    element: <IndividualBIUReportEditing />
  },
  {
    path: '/report/individual-report',
    element: <IndividualReport />
  },
  {
    path: '/report/company-biu-report',
    element: <CompanyBiuReport />
  },
  {
    path: '/reportindividual-biu-report',
    element: <IndividualBIUReport />
  },
  {
    path: '/letter/print-clearance-letter',
    element: <ClearanceLetter />
  },
  {
    path: '/hpi/confirmation-letter',
    element: <ConfirmationLetter />
  },
  
]);


function App() {

  const [reportData, setReportData] = useState({});

  return (

    <EnquiriesContext.Provider value={{ reportData, setReportData }}>
      <AuthProvider>
        <RouterProvider router={router} />
      </AuthProvider>
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        closeButton={true}
      />
    </EnquiriesContext.Provider>
  );
}

export default App;
