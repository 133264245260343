import React, { useContext, useRef, useState, useEffect } from "react";
import AuthContext from "../../AuthContext/AuthContext";
import InvoiceCSS from "../../css/Hpi.module.css";
import axios from "axios";

export const HpiRegistration = () => {
  const [loading, setLoading] = useState(true);
  const [selectedHPI, setSelectedHPI] = useState(false);
  const [hpi, setHpi] = useState(null);

  const [role, setRole] = useState(false);

  useEffect(() => {
    axios.get(`/read_hpi`).then((response) => {
      console.log(response.data);
      setHpi(response.data);
      setLoading(false);
    });
  }, []);

  const changeHandler = (e) => console.log([e.target.id], e.target.value);
  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(e)
  };
  if (loading) {
    return <div>Loading...</div>;
  }

  // const { authTokens } = useContext(AuthContext);
  // console.log(hp)
  return (
    <section className="search-hpi">
      <div className="search-hpi-header">
        <p>Hire Purchase Registration</p>
      </div>
      <div id="mainPage" className={InvoiceCSS.page}>
        {/* //invoice list section */}
        <div className={InvoiceCSS.contentDiv}>
          <div
            className={InvoiceCSS.sectionContainer}
            style={{ marginBottom: "30px" }}
          >
            <div>
              <h3 className={InvoiceCSS.cent}>HIRE PURCHASE REGISTRATION</h3>
            </div>
            <div
              className={InvoiceCSS.floatTheadWrapper}
              style={{ position: "relative", clear: "both", overflowX: "auto" }}
            >
              <table
                border="0"
                cellPadding="0"
                cellSpacing="0"
                style={{ width: "200px", width: "1410px" }}
              >
                <thead>
                  <tr className={InvoiceCSS.tftr}>
                    <th>Serial No.</th>
                    <th colSpan="2">Data Date</th>
                    <th>Financier</th>
                    <th colSpan="2">Asset Make</th>
                    <th>Reg/Serial Number</th>
                    <th>Currency</th>
                    <th>Purchase Amount</th>
                    <th>Start Date</th>
                    <th>End Date</th>
                    <th>
                      Select / All
                      <input
                        value={selectedHPI}
                        onChange={changeHandler}
                        type="radio"
                        id="all"
                        // checked={true}
                        name="hpi_to_register"
                      />
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {/* component one for Bureau ZWL */}

                  {hpi.map(
                    ({
                      id,
                      serial_number,
                      data_date,
                      financer,
                      asset_make,
                      reg_number,
                      currency,
                      purchace_amount,
                      start_date,
                      end_date,
                    }) => {
                      return (
                        <tr key={id}>
                          <th colSpan="2">{serial_number}</th>
                          <td>{data_date}</td>
                          <th colSpan="2">{financer}</th>
                          <td>{asset_make}</td>
                          <td>{reg_number}</td>
                          <td>{currency}</td>
                          <td>{purchace_amount}</td>
                          <td>{start_date}</td>
                          <td>{end_date}</td>
                          <td>
                            <input
                              value={selectedHPI}
                              onChange={changeHandler}
                              type="radio"
                              id={id}
                              name="hpi_to_register"
                              
                            />
                          </td>
                        </tr>
                      );
                    }
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
