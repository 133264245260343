import React, { useContext, useState } from 'react'
import { CurrencyType, Subscription } from '../Company/selectOptionsjson'
import { InputContainer, SelectContainer } from '../InputContainer'
import AddIcon from '@mui/icons-material/Add'
import '../../css/clientsarena.css'
import SaveIcon from '@mui/icons-material/Save'
import { DataSubmission } from '../Other/DataSubmission'
import AuthContext from '../../AuthContext/AuthContext'

const AddEditSubscription = ({
  setdisplay,
  caller,
  finsNumber,
  setvisible,
  subData }) => {
  const { authTokens, logoutUser } = useContext(AuthContext);
  const [subCategory, setSubCategory] = useState(() => {
    if (caller === 'editsubscription') {
      return subData[0].subscription_category
    } else {
      return ''
    }
  })

  const [companyCharge, setCompanyCharge] = useState(() => {
    if (caller === 'editsubscription') {
      return subData[0].company_charge
    } else {
      return ''
    }
  })
  const [individualCharge, setIndividualCharge] = useState(() => {
    if (caller === 'editsubscription') {
      return subData[0].individual_charge
    } else {
      return ''
    }
  })
  const [currencyType, setCurrencyType] = useState(() => {
    if (caller === 'editsubscription') {
      return subData[0].currency_type
    } else {
      return ''
    }
  })

  const AddSubscription = async (action) => {
    let responsed = ''
    if (caller === 'editsubscription') {
      responsed = await DataSubmission(
        String(authTokens.access),
        'put',
        '/subscription/' + subData[0].id + '/',
        {
          id: subData[0].id,
          company_client: finsNumber,
          subscription_category: subCategory,
          company_charge: companyCharge,
          individual_charge: individualCharge,
          currency_type: currencyType

        }
      )
    } else {
      if (caller === 'addsubscription') {
        setdisplay('loading')
      }
      if (caller === 'editsubscription') {
        setdisplay('red')
      }
      responsed = await DataSubmission(
        String(authTokens.access),
        'post',
        '/subscription/',
        {
          company_client: finsNumber,
          subscription_category: subCategory,
          company_charge: companyCharge,
          individual_charge: individualCharge,
          currency_type: currencyType

        }
      )
    }

    if (responsed[0].result === 'success' && caller === 'addsubscription') {
      setdisplay('table')
      setvisible('no')
    }
    if (responsed[0].result === 'success' &&
      (caller === 'addsubscriptionclientedit' || caller === 'addsubscription')
    ) {
      setdisplay('subscription')
    }
  }
  return (
    <div className='newuserclient'>

      <div className='newuserHeader'>
        <div className='adduserheadingtext'>
          <h2 className='adduserclientheader'>
            Subscription
          </h2>
        </div>
      </div>

      <div className='userDetails'>
        <div className='detailsuser'>
          <SelectContainer
            label={"Subscription Cartegory"}
            currentValue={subCategory}
            setValue={setSubCategory}
            options={Subscription}
          />

          <SelectContainer
            label={"Currency Type"}
            currentValue={currencyType}
            setValue={setCurrencyType}
            options={CurrencyType}
          />

          <InputContainer
            label={"Company Charge"}
            type={"number"}
            currentValue={companyCharge}
            setValue={setCompanyCharge}
            id={'companyCharge'}
          />
        </div>
        <div className='detailsuser'>
          <InputContainer
            label={"Individual Charge"}
            type={"number"}
            currentValue={individualCharge}
            setValue={setIndividualCharge}
            id={'individualCharge'}
          />

        </div>
        {
          caller === 'editauditors' ?
            <section className='secButtonsclients'>
              <button icon="close" onClick={() => AddSubscription('done')} className="formButtons" text="Close"
                align="center" >
                <SaveIcon className='icons' />
                <blockquote>
                  Update
                </blockquote>
              </button>
            </section>
            :
            <section className='secButtonsclients'>

              <button icon="close" onClick={() => AddSubscription('done')} className="formButtons" text="Close"
                align="center"  >
                <SaveIcon className='icons' />
                <blockquote>
                  Save
                </blockquote>
              </button>

            </section>
        }
      </div>

    </div>
  )
}

export default AddEditSubscription