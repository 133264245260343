import React from "react";

export default function RenderService({ services }) {
    let renderText = ''
  if (services === "BureauEnqZWL") {
    renderText = 'Bureau Enquiries - ZWL'
  } else if (services === "BureauEnqUSD") {
    renderText = 'Bureau Enquiries - USD'
  } else if (services === "HirePurZWL") {
    renderText = 'Hire Purchase - ZWL'
  } else if (services === "RescissionZWL") {
    renderText = 'Rescission - ZWL'
  } else if (services === "RescissionUSD") {
    renderText = 'Rescission - USD'
  } else if (services === "BusinessRepZWL") {
    renderText = 'Business Reports - ZWL'
  } else if (services === "all") {
    renderText = 'N/A'
    //to be implemented
  }

  return <h5>{renderText}</h5>;
}
