import React, { useState, useEffect, useContext } from "react"
import { debtorType, currentType } from "../Company/selectOptionsjson"
import { searchRecord } from "./../../js/searchRecord"
import { toast } from "react-toastify";
import { InputContainer, SelectContainer, InputAreaContainer, InputWithDropdownContainer } from "../InputContainer";
import CircularProgress from '@mui/material/CircularProgress';
import { checkIfSearchedEntityIsAvailable } from "../../js/checkIfSearchedEntityIsAvailable";
import { uploadSinglePublicRec } from "../../js/uploadSingleAdverseData";
import { Token } from "@mui/icons-material";
import { ValuesContext } from "../Admin/HomeAdmin";

//
export const AddSinglePublicRec = ({ authTokens }) => {


    const [ownerType, setOwnerType] = useState("Individual");
    const { userData } = useContext(ValuesContext)
    const [uploading, setUploading] = useState(false);
    const [userInputOwnerType, setUserInputOwnerType] = useState("");
    const [dataSource, setDataSource] = useState((userData.data.groups[0] === 4 || userData.data.groups[0] === 3) ? `${userData.data.firstname} ${userData.data.surname}` : '');
    const [searchedOwner, setSearchedOwner] = useState("");
    const [source, setSource] = useState("");
    const [summary, setSummary] = useState("");
    const [link, setLink] = useState("");
    const [caseNumber, setCaseNumber] = useState("");
    const [pin, setPin] = useState("");
    const [dataDate, setDataDate] = useState("");


    const clearFields = () => {
        setDataSource('');
        setOwnerType("Individual");
        setUserInputOwnerType("");
        setSearchedOwner("");
        setSource("");
        setSummary("");
        setLink("");
        setCaseNumber("");
        setPin("");
    }


    const sendAdverseData = (e) => {

        e.preventDefault();

        if (
            searchedOwner.data.length !== 1
        ) {
            toast.error(`Please provide the ${ownerType} for this data to proceed`)
            return;
        }

        setUploading(true)

        uploadSinglePublicRec(
            userData.data.groups[0] === 1 || userData.data.groups[0] === 2 ? true : false,
            dataSource,
            caseNumber,
            dataDate,
            link,
            pin,
            source,
            summary,
            searchedOwner,
            ownerType,
            String(authTokens.access),
            clearFields,
            setUploading
        )


    }


    return (
        <>
            <form className="adverse-single-form">

                <h3 style={{ marginBottom: '25px', backgroundColor: 'var(--main-color)', color: 'white', fontSize: '.9rem', textAlign: 'center', padding: '10px', width: '100%' }}>
                    Creditor
                </h3>

                {
                    (userData.data.groups[0] === 1 || userData.data.groups[0] === 2) &&
                    <InputContainer
                        label={"Data Source"}
                        type={"text"}
                        currentValue={dataSource}
                        setValue={setDataSource}
                        id={""}
                    />

                }


                <InputContainer
                    label={"Reference Number"}
                    type={"text"}
                    currentValue={caseNumber}
                    setValue={setCaseNumber}
                    id={""}
                />

                <InputContainer
                    label={"Data Date"}
                    type={"Date"}
                    id={"data date"}
                    currentValue={dataDate}
                    setValue={setDataDate}
                />

                <h3 style={{ marginBottom: '25px', backgroundColor: 'var(--main-color)', color: 'white', fontSize: '.9rem', textAlign: 'center', padding: '10px', width: '100%' }}>
                    Debtor
                </h3>

                <SelectContainer
                    label={"Subject Type"}
                    currentValue={ownerType}
                    setValue={setOwnerType}
                    options={debtorType}
                    id={""}
                />


                <InputWithDropdownContainer
                    label={"Subject Name / Reg # / ID"}
                    type={"text"}
                    currentValue={userInputOwnerType}
                    setValue={setUserInputOwnerType}
                    id={""}
                    typeOfEntity={ownerType}
                    authTokens={authTokens}
                    setSearchedEntity={setSearchedOwner}
                />


                <InputContainer
                    label={"Link"}
                    type={"text"}
                    currentValue={link}
                    setValue={setLink}
                    id={""}
                />



                <InputAreaContainer
                    label={"Comment"}
                    type={"text"}
                    id={"date closed"}
                    currentValue={summary}
                    setValue={setSummary}
                />
            </form>


            {
                uploading === true &&
                <CircularProgress />
            }

            {
                uploading === false &&
                <button className="btn-adverse-submit" onClick={e => sendAdverseData(e)}>
                    Submit
                </button>
            }
        </>

    )
}
