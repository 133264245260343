import React from 'react';
import { useState, useContext } from 'react';
import { FilterRecords } from '../FilterRecords';
import { ValuesContext } from '../Admin/HomeAdmin';
import CarRentalIcon from '@mui/icons-material/CarRental';
import { Modal } from '../Modal';
import { Loader } from '../Loader';
import './../../css/Searchtemplate.css';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import { getDate } from '../../js/getDate';
import { UploadHPI } from '../Other/UploadHPI';
import { useNavigate } from 'react-router-dom';
import { ReportChecking } from '../ReportChecking';
import { checkIfUserInternal } from '../../js/snippets';

export const SearchHPI = ({ admin, bureau }) => {
    // console.log('bureau----------')
    // console.log(bureau)

    const { fetchedHPI } = useContext(ValuesContext);
    const [activeSearch, setActiveSearch] = useState(false);
    const [activeDisplay, setActiveDisplay] = useState("fetched");
    const [searchTerm, setSearchTerm] = useState("");
    const [searchResult, setSearchResult] = useState({
        data: [],
        message: ""
    });
    let searchParameters = [
        { value: "financier", displayedName: "Financier" },
        { value: "agreement_number", displayedName: "Agreement Number" },
        { value: "registration_number", displayedName: "Registration Number" },
        { value: "engine_number", displayedName: "Engine Number" },
        { value: "chasis", displayedName: "Chassis" },
        { value: "serial", displayedName: "Serial" }
    ]

    if (bureau) {
        searchParameters = searchParameters.filter(parameter => parameter.value !== 'agreement_number' && parameter.value !== 'financier');
    }
    

    return (

        <section className="search-hpi">

            <div className="search-hpi-header">
                <p>search HPI</p>
            </div>

            <FilterRecords
                searchTermLabel={`search by ${bureau && 'agreement no./'}reg no./engine no/chassis/serial`}
                searchParameters={searchParameters}
                RecordIcon={CarRentalIcon}
                addRecordText={"add HPI"}
                // addRecordText={`${bureau ? 'Exclude' : 'add HPI'}`}
                setSearchResult={setSearchResult}
                setActiveSearch={setActiveSearch}
                setActiveDisplay={setActiveDisplay}
                endpoint={"hp-search"}
                AddRecordForm={UploadHPI}
                bulkType={"Hire Purchase"}
                bulkText={"Bulk HPI"}
                searchTerm={searchTerm}
                setSearchTerm={setSearchTerm}
            />



            {
                searchResult.data !== null &&
                <div className="financiar-div">
                    <p className="financier">{searchResult.data[0] && `Financier : ${searchResult.data[0].financier_company.registration_name}`}</p>
                    <p className='account-number'>{searchResult.data[0] && `Account Number : ${searchResult.data[0].financier_company.account_number}`}</p>
                </div>
            }

            <div className="search-hpi-container">

                <div className="hpi-header">
                    <h4 style={{ fontSize: '5rem' }}>agreement number</h4>
                    <h4>lessee</h4>
                    <h4>asset make</h4>
                    <h4>reg/serial number</h4>
                    <h4>currency</h4>
                    <h4>purchase amount</h4>
                    <h4>start date</h4>
                    <h4>end date</h4>
                    {!admin && <h4>View</h4>}
                    {
                        admin &&
                        <>
                            <h4>Edit</h4>
                        </>
                    }
                </div>

                {/* Rendering all companies */}


                {
                    activeDisplay === "fetched" ? <FetchedCompanies fetchedHPI={fetchedHPI} admin={admin} /> :
                        <SearchedCompanies
                            searchResult={searchResult}
                            activeSearch={activeSearch}
                            admin={admin}
                            bureau={bureau}
                        />
                }


            </div>



        </section>

    )
}

const FetchedCompanies = ({ fetchedHPI, admin }) => {


    return (

        <>
            {
                fetchedHPI.data === null ? <p className='message'>{fetchedHPI.message}</p> :

                    fetchedHPI.data.map((company, index) => {

                        return (
                            <Company index={index} key={company.national_id} company={company} admin={admin} />
                        )
                    })
            }
        </>
    )
}

// all fetched results of HPI search
const SearchedCompanies = ({ activeSearch, searchResult, admin, bureau }) => {
    // console.log("searchResult")
    // console.log(searchResult)
    
    return (

        <>
            {
                activeSearch === true ? <Loader /> :
                    searchResult.data === null ? <p className='message'>{searchResult.message}</p> :

                        // render individuals if available

                        searchResult.data.length === 0 ? <p className='message'>No record was found</p> :
                            searchResult.data.map((company, index) => {
                                return (
                                    <Company index={index} key={company.national_id} company={company} admin={admin} bureau={bureau} />
                                )
                            })

            }
        </>
    )
}


const Company = ({ company, index, admin, bureau }) => {

    const [checked, setChecked] = useState(false);
    const [showEditForm, setShowEditForm] = useState(false);
    const [reportChecking, setReportChecking] = useState(false)
    const navigate = useNavigate();
    const { userData } = useContext(ValuesContext);

    const showModal = () => {
        //setChecked(!checked);
        setShowEditForm(!showEditForm)
    }



    return (
        <article className="company" style={{ backgroundColor: index % 2 === 0 && 'white' }}>

            {/* Enabling the Modal to pop up if it meets the conditions */}
            {
                showEditForm &&
                <Modal
                    Component={UploadHPI}
                    setFormStatus={setChecked}
                    data={company}
                />

            }


            {
                /* Show report checking */
                reportChecking && (userData.data.groups[0] === 1 || userData.data.groups[0] === 2) &&
                <Modal
                    Component={ReportChecking}
                    setFormStatus={setReportChecking}
                    data={company}
                />
            }

            <p>{company.agreement_number}</p>
            <p>{company.lesse_name}</p>
            <p>{company.make}</p>
            <p>{company.serial ? company.serial : company.reg_number}</p>
            {/*    <p>{company.debtor_type}</p> */}
            <p>{company.currency}</p>
            <p>{company.purchase_amount}</p>
            <p>{getDate(company.agreement_start_date)}</p>
            <p>{getDate(company.agreement_due_date)}</p>

            {/* Rendering different UI components based on the priviledges */}

            {
                bureau ?
                    <p>
                        <RadioButtonUncheckedIcon
                            className={`checkbox ${checked && 'selected'}`}
                            onClick={() => checkIfUserInternal(userData, setReportChecking, company, navigate, setChecked, "hpi")}
                            // onClick={() => console.log('check box tfa===')}

                        />
                    </p> :
                    <p>
                        <RadioButtonUncheckedIcon
                            className={`checkbox ${checked && 'selected'}`}
                            // onClick={() => console.log('check box tfa===')}
                            onClick={() => showModal()}
//tfa editing here
                        />
                    </p>
            }


        </article>
    )
}
