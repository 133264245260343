import React, { useContext, useEffect, useState } from 'react';
import './../css/Pricing.css';
import RemoveIcon from '@mui/icons-material/Remove';
import AuthContext from '../AuthContext/AuthContext';
import { fetchData } from '../js/fetchData';
import { CurrencyType } from './Company/selectOptionsjson';
import { InputContainer, InputWithDropdownContainer, SelectContainer } from './InputContainer';
import { toast } from 'react-toastify';
import axios from 'axios';

export const Pricing = () => {

    const [currentPricing, setCurrentPricing] = useState("bureau");
    const [subscriptions, setSubscriptions] = useState({ data: null });
    const [charge, setCharge] = useState({ data: null })
    const { authTokens } = useContext(AuthContext);


    useEffect(() => {
        fetchData(
            String(authTokens.access),
            setSubscriptions,
            "subscription",
            null,
            null
        )

        fetchData(
            String(authTokens.access),
            setCharge,
            'charge',
            null,
            null
        )
    }, [])




    return (

        <div className="section-pricing">

            <div className="pricing-nav-container">
                <button className={`${currentPricing === 'bureau' && 'active-pricing'}`} onClick={() => setCurrentPricing('bureau')}>Bureau Pricing</button>
                <button className={`${currentPricing === 'biu' && 'active-pricing'}`} onClick={() => setCurrentPricing('biu')}>Business Reports Pricing</button>
            </div>

            {
                currentPricing === 'bureau' &&
                <EnquiriesPricing
                    pricing={charge}
                    subscriptions={subscriptions}
                    setSubscriptions={setSubscriptions}
                />
            }

            {
                currentPricing === 'biu' &&
                <BusinessPricing
                    pricing={charge}
                    subscriptions={subscriptions}
                    setSubscriptions={setSubscriptions}
                />
            }

        </div>
    )
}

const EnquiriesPricing = ({ subscriptions, setSubscriptions }) => {


    const { authTokens } = useContext(AuthContext);
    const [pricing, setPricing] = useState({ data: null })
    const [showUpdateForm, setShowUpdateForm] = useState(false)
    const [clientName, setClientName] = useState("");
    const [currency, setCurrency] = useState("");
    const [individualAmount, setIndividualAmount] = useState(0);
    const [standardIndividualAmount, setStandardIndividualAmount] = useState(0);
    const [standardCompanyAmount, setStandardCompanyAmount] = useState(0)
    const [searchedEntity, setSearchedEntity] = useState("")
    const [companyAmount, setCompanyAmount] = useState(0);
    const [showAddForm, setShowAddForm] = useState(false)
    const [pricingChanged, setPricingChanged] = useState([])

    useEffect(() => {

        if (pricing.data === null) {
            return;
        }

        setStandardCompanyAmount(pricing.data[0].bureau_company);
        setStandardIndividualAmount(pricing.data[0].bureau_individual);
    }, [pricing])

    useEffect(() => {
        fetchData(
            String(authTokens.access),
            setPricing,
            'charge',
            null,
            null
        )
    }, [])

    const clearField = () => {
        setClientName("")
        setCurrency("")
        setIndividualAmount(0)
        setCompanyAmount(0)
    }


    const cancelSubscription = (subscriptionId, subsList, setSubsList, typeOfList, clickedSub) => {

        axios.delete(`/subscription/${subscriptionId}/`, {
            headers: {
                Authorization: `Bearer ${authTokens.access}`,
                Accept: "application/json",
                "Content-Type": "application/json"
            }
        })
            .then(response => {

                if (response.status >= 200 && response.status < 300) {
                    toast.success('Subscription returned to standard')

                    if (typeOfList === 'fetched') {
                        const newSubs = subsList.data.filter(sub => {
                            if (sub.id !== subscriptionId) {
                                return sub
                            }
                        })

                        setSubsList({ data: newSubs });
                    } else {

                        const newSubs = subsList.filter(sub => {

                            if (sub.company.data[0].fins_number !== clickedSub.company_client) {
                                return sub
                            }
                        })

                        setSubsList(newSubs);

                    }


                } else {
                    toast.error('Subscription not returned to standard')
                }
            })
            .catch((err) => {
                toast.error('Error occured while processing subscription')
            })
    }

    const addPricingProp = (e) => {


        e.preventDefault();

        if (clientName === '' || currency === '' || individualAmount === 0 || companyAmount === 0) {
            toast.error('All fields are required')
            return;
        }

        setPricingChanged(
            [...pricingChanged, {
                company: searchedEntity,
                currencyType: currency,
                individualCharge: individualAmount,
                companyCharge: companyAmount,
                subCategory: 'Bureau',
            }]
        )

        clearField();


    }
    return (

        <section className="enquiries-pricing">

            <h3 className="enquiries-heading">
                Enquiries Pricing
            </h3>


            <h4 className="standard-pricing">
                standard pricing
            </h4>

            <table className="standard-pricing-table" border={1}>

                <tr>
                    <th>Customers</th>
                    <th>Currency</th>
                    <th>Individual Amount</th>
                    <th>Company Amount</th>
                </tr>

                <tr>
                    <td>All</td>
                    <td>ZWL</td>
                    <td>{standardIndividualAmount}</td>
                    <td>{standardCompanyAmount}</td>
                </tr>
            </table>

            <div className="add-special-btn-container">
                <button onClick={() => setShowUpdateForm(!showUpdateForm)}>Update</button>
            </div>



            {
                showUpdateForm &&
                <MyForm
                    setOldComp={setStandardCompanyAmount}
                    setOldInd={setStandardIndividualAmount}
                    type={'bureau'}
                    pricing={pricing}
                />
            }



            <h4 className="special-pricing">
                Special Pricing
            </h4>

            <table className="special-pricing-table" border={1}>

                <tr>
                    <th>Customer</th>
                    <th>Currency</th>
                    <th>Individual Amount</th>
                    <th>Company Amount</th>
                </tr>

                {
                    subscriptions.data !== null &&
                    subscriptions.data.map(sub => {

                        if (sub.subscription_category === 'Bureau') {

                            return (
                                <tr key={sub.id}>
                                    <td>{sub.company_client.registration_name}</td>
                                    <td>{sub.currency_type}</td>
                                    <td>{sub.individual_charge}</td>
                                    <td>{sub.company_charge}</td>
                                    <td style={{ backgroundColor: 'var(--main-color' }} onClick={() => cancelSubscription(sub.id, subscriptions, setSubscriptions, 'fetched', sub)}><RemoveIcon style={{ color: 'white' }} /></td>
                                </tr>
                            )
                        }
                    })
                }

                {
                    pricingChanged.length > 0 &&
                    pricingChanged.map((sub, index) => {

                        return (
                            <ProposedPriceChange
                                sub={sub}
                                index={index}
                                cancelSubscription={cancelSubscription}
                                setPricingChanged={setPricingChanged}
                                pricingChanged={pricingChanged}
                            />
                        )

                    })
                }


            </table>

            <div className="add-special-btn-container">
                <button onClick={() => setShowAddForm(!showAddForm)}>Add</button>
            </div>

            {
                showAddForm &&

                <form className="add-special-sub-form">

                    <div className="add-special-sub-form__container">
                        <InputWithDropdownContainer
                            label={"Enter Client Name"}
                            type={"text"}
                            currentValue={clientName}
                            setValue={setClientName}
                            id={"clientName"}
                            typeOfEntity={'company'}
                            authTokens={authTokens}
                            setSearchedEntity={setSearchedEntity}
                        />

                        <SelectContainer
                            label={'Currency'}
                            currentValue={currency}
                            setValue={setCurrency}
                            options={CurrencyType}
                            id={"currency"}
                        />

                        <InputContainer
                            label={"Individual Amount"}
                            type={"number"}
                            currentValue={individualAmount}
                            setValue={setIndividualAmount}
                            id={"individualAmount"}
                        />


                        <InputContainer
                            label={"Company Amount"}
                            type={"number"}
                            currentValue={companyAmount}
                            setValue={setCompanyAmount}
                            id={"CompanyAmount"}
                        />
                    </div>

                    <div style={{ display: 'flex', justifyContent: 'flex-end' }} className="special-pricing-submit-btn-container">

                        <button className="submit-special-pricing" onClick={e => addPricingProp(e)}>
                            Submit
                        </button>
                    </div>


                </form>
            }
        </section>
    )
}

const ProposedPriceChange = ({ sub, index, cancelSubscription, setPricingChanged, pricingChanged, }) => {

    const { authTokens } = useContext(AuthContext);
    const [changedSub, setChangedSub] = useState({});


    useEffect(() => {

        axios.post('/subscription/', {
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: "Bearer " + String(authTokens.access)
            },
            subscription_category: sub.subCategory,
            company_client: sub.company.data[0].fins_number,
            currency_type: sub.currencyType,
            individual_charge: sub.individualCharge,
            company_charge: sub.companyCharge,
        })
            .then(function (response) {

                if (response.status >= 200 && response.status < 300) {
                    setChangedSub(response.data);
                    toast.success('Pricing updated')
                } else {
                    toast.error('Error updating the pricing')
                    const newSubs = pricingChanged.filter(changedSub => {
                        if (changedSub.company.data[0].registration_name !== sub.company.data[0].registration_name) {
                            return changedSub;
                        }
                    })
                    setPricingChanged(newSubs);
                }
            })
            .catch(() => {
                toast.error('Any error occurred while processing')
            });

    }, [])

    return (
        <tr key={index}>
            <td>{sub.company.data[0].registration_name}</td>
            <td>{sub.currencyType}</td>
            <td>{sub.individualCharge}</td>
            <td>{sub.companyCharge}</td>
            <td style={{ backgroundColor: 'var(--main-color' }} onClick={() => cancelSubscription(changedSub.id, pricingChanged, setPricingChanged, 'changed', changedSub)}><RemoveIcon style={{ color: 'white' }} /></td>
        </tr>
    )
}


function MyForm({ setOldInd, setOldComp, type, pricing }) {
    const { authTokens } = useContext(AuthContext);
    const [currency, setCurrency] = useState('');
    const [individual, setIndividual] = useState('');
    const [company, setCompany] = useState('');

    const handleSubmit = (event) => {
        event.preventDefault();

        const headers = {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${authTokens.access}`
        }
            ;

        const data = {
            biu_individual: individual,
            biu_company: company,
            bureau_company: pricing.data[0].bureau_company,
            bureau_individual: pricing.data[0].bureau_individual,
            hpi: pricing.data[0].hpi,
            subscription: pricing.data[0].subscription
        };

        const crdata = {
            bureau_individual: individual,
            bureau_company: company,
            hpi: pricing.data[0].hpi,
            subscription: pricing.data[0].subscription,
            biu_individual: pricing.data[0].biu_individual,
            biu_company: pricing.data[0].biu_company
        };

        const getData = (type) => {

            if (type === 'biu') {
                return data
            } else {
                return crdata
            }
        }




        axios.put('/charge/1/', getData(type), { headers })
            .then(response => {
                setOldComp(company);
                setOldInd(individual);

                toast.success('Pricing updated');
            })
            .catch(error => {
                if (error.response) {
                    toast.error('Internal server error');
                } else if (error.request) {
                    toast.error('Something went wrong,try again')
                } else {
                    toast.error('An error occurred,try again')
                }
            });
    };


    return (
        <form onSubmit={handleSubmit}>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>


                <InputContainer
                    label={"Individual Amount"}
                    type={"number"}
                    currentValue={individual}
                    setValue={setIndividual}
                    id={"individualAmount"}
                    width={'45%'}
                />


                <InputContainer
                    label={"Company Amount"}
                    type={"number"}
                    currentValue={company}
                    setValue={setCompany}
                    id={"CompanyAmount"}
                    width={'45%'}
                />
            </div>
            <div className="add-special-btn-container">
                <button type="submit">Submit</button>
            </div>
        </form>
    );
}

const BusinessPricing = ({ subscriptions, setSubscriptions }) => {


    const { authTokens } = useContext(AuthContext);
    const [showUpdateForm, setShowUpdateForm] = useState(false);
    const [pricing, setPricing] = useState({ data: null })
    const [clientName, setClientName] = useState("");
    const [currency, setCurrency] = useState("");
    const [individualAmount, setIndividualAmount] = useState(0);
    const [standardIndividualAmount, setStandardIndividualAmount] = useState(0);
    const [standardCompanyAmount, setStandardCompanyAmount] = useState(0)
    const [searchedEntity, setSearchedEntity] = useState("")
    const [companyAmount, setCompanyAmount] = useState(0);
    const [showAddForm, setShowAddForm] = useState(false)
    const [pricingChanged, setPricingChanged] = useState([])


    useEffect(() => {

        if (pricing.data === null) {
            return;
        }

        setStandardCompanyAmount(pricing.data[0].biu_company);
        setStandardIndividualAmount(pricing.data[0].biu_individual);
    }, [pricing])

    useEffect(() => {
        fetchData(
            String(authTokens.access),
            setPricing,
            'charge',
            null,
            null
        )
    }, [])

    const clearField = () => {
        setClientName("")
        setCurrency("")
        setIndividualAmount(0)
        setCompanyAmount(0)
    }


    const cancelSubscription = (subscriptionId, subsList, setSubsList, typeOfList, clickedSub) => {

        axios.delete(`/subscription/${subscriptionId}/`, {
            headers: {
                Authorization: `Bearer ${authTokens.access}`,
                Accept: "application/json",
                "Content-Type": "application/json"
            }
        })
            .then(response => {

                if (response.status >= 200 && response.status < 300) {
                    toast.success('Subscription returned to standard')

                    if (typeOfList === 'fetched') {
                        const newSubs = subsList.data.filter(sub => {
                            if (sub.id !== subscriptionId) {
                                return sub
                            }
                        })

                        setSubsList({ data: newSubs });
                    } else {

                        const newSubs = subsList.filter(sub => {

                            if (sub.company.data[0].fins_number !== clickedSub.company_client) {
                                return sub
                            }
                        })

                        setSubsList(newSubs);

                    }

                } else {
                    toast.error('Subscription not returned to standard')
                }
            })
            .catch(() => toast.error('Error occurred while processing subscription'))
    }

    const addPricingProp = (e) => {


        e.preventDefault();

        if (clientName === '' || currency === '' || individualAmount === 0 || companyAmount === 0) {
            toast.error('All fields are required')
            return;
        }

        setPricingChanged(
            [...pricingChanged, {
                company: searchedEntity,
                currencyType: currency,
                individualCharge: individualAmount,
                companyCharge: companyAmount,
                subCategory: 'Biu',
            }]
        )

        clearField();


    }
    return (

        <section className="enquiries-pricing">

            <h3 className="enquiries-heading">
                Business Reports Pricing
            </h3>


            <h4 className="standard-pricing">
                standard pricing
            </h4>

            <table className="standard-pricing-table" border={1}>

                <tr>
                    <th>Customers</th>
                    <th>Currency</th>
                    <th>Individual Amount</th>
                    <th>Company Amount</th>
                </tr>

                <tr>
                    <td>All</td>
                    <td>ZWL</td>
                    <td>{standardIndividualAmount}</td>
                    <td>{standardCompanyAmount}</td>
                </tr>
            </table>

            <div className="add-special-btn-container">
                <button onClick={() => setShowUpdateForm(!showUpdateForm)}>Update</button>
            </div>

            {
                showUpdateForm &&
                <MyForm
                    setOldComp={setStandardCompanyAmount}
                    setOldInd={setStandardIndividualAmount}
                    type={'biu'}
                    pricing={pricing}
                />
            }



            <h4 className="special-pricing">
                Special Pricing
            </h4>

            <table className="special-pricing-table" border={1}>

                <tr>
                    <th>Customer</th>
                    <th>Currency</th>
                    <th>Individual Amount</th>
                    <th>Company Amount</th>
                </tr>

                {
                    subscriptions.data !== null &&
                    subscriptions.data.map(sub => {

                        if (sub.subscription_category === 'Biu') {

                            return (
                                <tr key={sub.id}>
                                    <td>{sub.company_client.registration_name}</td>
                                    <td>{sub.currency_type}</td>
                                    <td>{sub.individual_charge}</td>
                                    <td>{sub.company_charge}</td>
                                    <td style={{ backgroundColor: 'var(--main-color' }} onClick={() => cancelSubscription(sub.id, subscriptions, setSubscriptions, 'fetched', sub)}><RemoveIcon style={{ color: 'white' }} /></td>
                                </tr>
                            )
                        }
                    })
                }

                {
                    pricingChanged.length > 0 &&
                    pricingChanged.map((sub, index) => {

                        return (
                            <ProposedPriceChange
                                sub={sub}
                                index={index}
                                cancelSubscription={cancelSubscription}
                                setPricingChanged={setPricingChanged}
                                pricingChanged={pricingChanged}
                            />
                        )

                    })
                }


            </table>

            <div className="add-special-btn-container">
                <button onClick={() => setShowAddForm(!showAddForm)}>Add</button>
            </div>

            {
                showAddForm &&

                <form className="add-special-sub-form">

                    <div className="add-special-sub-form__container">
                        <InputWithDropdownContainer
                            label={"Enter Client Name"}
                            type={"text"}
                            currentValue={clientName}
                            setValue={setClientName}
                            id={"clientName"}
                            typeOfEntity={'company'}
                            authTokens={authTokens}
                            setSearchedEntity={setSearchedEntity}
                        />

                        <SelectContainer
                            label={'Currency'}
                            currentValue={currency}
                            setValue={setCurrency}
                            options={CurrencyType}
                            id={"currency"}
                        />

                        <InputContainer
                            label={"Individual Amount"}
                            type={"number"}
                            currentValue={individualAmount}
                            setValue={setIndividualAmount}
                            id={"individualAmount"}
                        />


                        <InputContainer
                            label={"Company Amount"}
                            type={"number"}
                            currentValue={companyAmount}
                            setValue={setCompanyAmount}
                            id={"CompanyAmount"}
                        />
                    </div>

                    <button className="submit-special-pricing" onClick={e => addPricingProp(e)}>
                        Submit
                    </button>

                </form>
            }
        </section>
    )
}


