import React, { useState, useEffect, useRef, useContext } from "react";
import axios from "axios";
import './../../../css/CompanyReport2.css'
import { handleResponses } from "../../../js/fetchData";
import { fetchByFinsNumbers } from "../../../js/searchRecord";
import { chargeCompanyCreditReport } from "../../../js/chargeClient";
import { CompanyReportHeader } from "./CompanyReportHeader";
import { RiskClassification } from "../../Individuals/IndividualReport/RiskClassification";
import { CompanyIdentificationAndSummary } from "./CompanyIdentificationAndSummary";
import { ScoreRange } from "../../Individuals/IndividualReport/ScoreRange";
import { CompanyContactDetails } from "./CompanyContactDetails";
import { ReportSummary } from "../../Individuals/IndividualReport/ReportSummary";
import { ActiveCreditAccs } from "./ActiveCreditAccs";
import Absconder from '../../Individuals/IndividualReport/Absconder';
import CourtRecords from "../../Individuals/IndividualReport/CourtRecords";
import Claims from "../../Individuals/IndividualReport/Claims";
import HPI from "../../Individuals/IndividualReport/HPI";
import Inquiries from "../../Individuals/IndividualReport/Inquiries";
import PublicInfo from "../../Individuals/IndividualReport/PublicInfo";
import { IndividualReportFooter } from "../../Individuals/IndividualReport/IndividualReportFooter";
import { Loader } from "../../Loader";
import { filterAbsconderSlowPayerActive } from "../../../js/filterAbsconderSlowPayerActive";
import { PrintBtn } from "../../Individuals/IndividualReport/IndividualReport";
import AuthContext from "../../../AuthContext/AuthContext";

export const CompanyReport2 = () => {
   /////
   const [company, setCompany] = useState(JSON.parse(localStorage.getItem("data")));
   const { authTokens } = useContext(AuthContext);
   const [enqDetails, setEnqDetails] = useState(JSON.parse(localStorage.getItem("enq-details")));
   const [score, setScore] = useState({ data: null })
   const [failureFetch, setFailureFetch] = useState(false);
   const [search, setSearchResult] = useState({ data: null });
   const [activeCreditAccs, setActiveCreditAccs] = useState({ data: null });
   const [creditAccounts, setCreditAccounts] = useState({ data: null });
   const [historicals, setHistoricals] = useState({ data: null });
   const [claims, setClaims] = useState({ data: null })
   const [absconders, setAbsconders] = useState({ data: null });
   const [slowPayer, setSlowPayer] = useState({ data: null });
   const [courts, setCourts] = useState({ data: null })
   const [hirePurchases, setHirePurchases] = useState({ data: null });
   const [inquiries, setInquries] = useState({ data: null });
   const [publicInfo, setPublicInfo] = useState({ data: null });
   const [directorships, setDirectorships] = useState({ data: null });


   useEffect(() => {
      if (inquiries.data == null) {
         return;
      } else if (inquiries.message === 'fetch was successful') {
         setFailureFetch("done")
      }

   }, [inquiries])

   useEffect(() => {
      if (score.data === null) {
         return
      }

      fetchByFinsNumbers(
         String(authTokens.access),
         company.fins_number,
         "company-enquiries/",
         setInquries,
         setFailureFetch,
         null
      );

   }, [score])

   useEffect(() => {

      if (
         claims.message === "fetch was successful" &&
         courts.message === "fetch was successful" &&
         publicInfo.message === "fetch was successful" &&
         hirePurchases.message === "fetch was successful" &&
         creditAccounts.message === "fetch was successful" &&
         directorships.message === "fetch was successful" &&
         enqDetails !== null) {


         // charging the client
         chargeCompanyCreditReport(company.fins_number, enqDetails.user, setSearchResult, setScore, String(authTokens.access));
         filterAbsconderSlowPayerActive(claims, setClaims, creditAccounts, setActiveCreditAccs, setAbsconders, setSlowPayer, setHistoricals);


      }

   }, [claims, courts, publicInfo, hirePurchases, creditAccounts, directorships])


   // retrieng data
   useEffect(() => {

      axios.post(`/claim-search/`, {

         headers: {
            Accept: "application/json",
            "Content-Type": "application/json"
         }
         ,
         data: {
            searchParameter: "company_debtor_fins",
            fins: company.fins_number
         }
      })
         .then(res => {

            if (res.status !== 200) {
               setFailureFetch(true)
            }
            handleResponses(res, setClaims);

         })
         .catch(err => {
            setFailureFetch(true)
            handleResponses(err, setClaims);
         })



      fetchByFinsNumbers(
         String(authTokens.access),
         company.fins_number,
         "company-credit-details/",
         setCreditAccounts,
         setFailureFetch,
         null
      )

      fetchByFinsNumbers(
         String(authTokens.access),
         company.fins_number,
         'company-court/',
         setCourts,
         setFailureFetch,
         null
      )

      fetchByFinsNumbers(
         String(authTokens.access),
         company.fins_number,
         "company-hire-purchase/",
         setHirePurchases,
         setFailureFetch,
         null
      )



      fetchByFinsNumbers(
         String(authTokens.access),
         company.fins_number,
         "company-public-info/",
         setPublicInfo,
         setFailureFetch,
         null,
      )

      fetchByFinsNumbers(
         String(authTokens.access),
         company.fins_number,
         "company-executive/",
         setDirectorships,
         setFailureFetch,
         null,
      )

   }, [])


   return (

      <>

         {
            failureFetch === false &&
            <Loader />
         }

         {
            failureFetch === true &&
            <p className='failed-to-fetch' style={{ color: "red", textAlign: "center", marginTop: "1em" }}>Something went wrong,try again</p>
         }

         {
            failureFetch === "done" &&
            <IndReports
               activeCreditAccs={activeCreditAccs}
               historicals={historicals}
               courts={courts}
               hirePurchases={hirePurchases}
               inquiries={inquiries}
               publicInfo={publicInfo}
               claims={claims}
               directorships={directorships}
               absconders={absconders}
               slowPayer={slowPayer}
               score={score}
               company={company}
               enqDetails={enqDetails}
            />
         }



      </>
   )
}

const IndReports = ({ enqDetails, slowPayer, company, activeCreditAccs, historicals, courts, hirePurchases, inquiries, publicInfo, claims, directorships, absconders, score
}) => {
   const type = useRef("");
   const [showBtn, setShowBtn] = useState(true)

   return (
      <>

         <table ref={type} className={"company-report"}>
            <tr className="report-section">
               <CompanyReportHeader name={company.registration_name} />
            </tr>

            <table border={2} style={{ width: '100%', padding: '20px' }}>

               <tr className='report_section'>
                  <RiskClassification score={score} color={'#305496'} />

               </tr>

               <tr className='report_section'>
                  <ScoreRange score={score} color={'#b4c6e7'} />
               </tr>

            </table>

            <table border={2} style={{ width: '100%', padding: '20px', marginTop: '20px' }}>

               <tr className='row_heading' style={{ margin: '20px 0', backgroundColor: '#305496', color: 'white' }}>
                  <td className='txt_rowheading'>
                     <h3 className='section_heading'>
                        COMPANY DETAIILS
                     </h3>
                  </td>
               </tr>

               <tr className='report_section'>
                  <CompanyIdentificationAndSummary company={company} color={'#b4c6e7'} />
               </tr>

               <tr className='report_section'>
                  <CompanyContactDetails company={company} color={'#b4c6e7'} />
               </tr>
            </table>
            <table border={2} style={{ width: '100%', padding: '10px', marginTop: '20px' }}>


               <tr className="report_section">
                  <ReportSummary
                     absconders={absconders}
                     activeCreditAccs={activeCreditAccs}
                     historicals={historicals}
                     claims={claims}
                     courts={courts}
                     inquiries={inquiries}
                     hirePurchases={hirePurchases}
                     slowPayer={slowPayer}
                     color={'#305496'} />

               </tr>

            </table>


            <table border={2} style={{ width: '100%', padding: '10px', marginTop: '20px' }}>

               <tr className='row_heading' style={{ margin: '20px 0', backgroundColor: '#b4c6e7' }}>
                  <td className='txt_rowheading'>
                     <h3 className='section_heading'>
                        CREDIT ACCOUNTS
                     </h3>
                  </td>
               </tr>


               <tr className="report_section">
                  <ActiveCreditAccs activeCreditAccs={activeCreditAccs} color={'#d6dce4'} />
               </tr>

               <                tr className='report_section'>
                  <HPI hirePurchases={hirePurchases} color={'#d6dce4'} />
               </tr>

               <tr className="report_section">
                  <ActiveCreditAccs activeCreditAccs={historicals} isHistorical color={'#d6dce4'} />
               </tr>

            </table>


            <table border={2} style={{ width: '100%', padding: '10px', marginTop: '20px' }}>

               <tr className='row_heading' style={{ margin: '20px 0', backgroundColor: '#b4c6e7' }}>
                  <td className='txt_rowheading'>
                     <h3 className='section_heading'>
                        ADVERSE RECORDS
                     </h3>
                  </td>
               </tr>


               <tr className='report_section'>
                  <Claims claims={claims} enqDetails={enqDetails} slowPayers={slowPayer} color={'#d6dce4'} />
               </tr>

               <tr className='report_section'>
                  <Absconder absconders={absconders} color={'#d6dce4'} enqDetails={enqDetails} />
               </tr>

               <tr className='report_section'>
                  <CourtRecords courts={courts} color={'#d6dce4'} enqDetails={enqDetails} />
               </tr>

            </table>

            <table border={2} style={{ width: '100%', padding: '10px', marginTop: '20px' }}>
               <tr className='report_section'>
                  <Inquiries inquiries={inquiries} enqDetails={enqDetails} color={'#b4c6e7'} />
               </tr>
            </table>

            <table border={2} style={{ width: '100%', padding: '10px', marginTop: '20px' }}>
               <tr className='report_section'>
                  <PublicInfo publicInfo={publicInfo} color={'#b4c6e7'} />
               </tr>
            </table>

            <tr className="report_section">
               <IndividualReportFooter />
            </tr>
         </table>

         {showBtn && <PrintBtn showBtn={showBtn} setShowBtn={setShowBtn} type={type} />}
      </>
   )
}