import axios from 'axios';
import { useContext } from 'react';
import { handleResponses } from "./fetchData";


export const searchRecord = (token, setActiveDisplay, setActiveSearch, searchParameter, searchTerm, setSearchResult, endpoint, setAllSearchResult) => {


    setActiveSearch !== null && setActiveSearch(true);
    setActiveDisplay !== null && setActiveDisplay("searched")

    console.log('searchParameter', searchParameter)
    // if (endpoint === 'registration_numbery' || searchParameter === 'registration_name') {
    //     searchTerm = searchTerm.toLowerCase();
    // }
    // if (endpoint === 'hp-search') {
    //     console.log('something here')
    //     searchParameter ='fffff'
    // }
    axios.post(`/${endpoint}/`, {
        headers: {
            Accept: "application/json",
            Authorization: `Bearer ${token}`
        }
        ,
        data: {
            searchParameter,
            search: searchTerm
        }
    })
        .then(res => {
            if (endpoint === 'hp-search') {
                setSearchResult({
                    data: res.data,
                    message: "fetch was successful"
                })
            }else{
                if (searchParameter === 'registration_number' || searchParameter === 'registration_name') {
                    // company
    
                    const fullmathCompany = res.data.filter(company => company[searchParameter].toLowerCase() === searchTerm.toLowerCase());
                    setSearchResult({
                        data: fullmathCompany.length === 0 ? res.data : fullmathCompany,
                        message: "fetch was successful"
                    })
    
                } else if (searchParameter === 'full_name' || searchParameter === 'national_id') {
                    // individual
    
                    const fullmatchIndividual = res.data.filter(individual => {
                        const fullname = `${individual.firstname} ${individual.surname}`.toLowerCase();
    
                        if (searchParameter === 'national_id') {
    
                            if (individual.national_id.toLowerCase() === searchTerm.toLowerCase()) {
                                return individual;
                            }
                        }
    
                        if (searchParameter === "full_name") {
    
                            if (fullname === searchTerm.toLowerCase()) {
                                return individual;
                            }
                        }
                    });
    
                    setSearchResult({
                        data: fullmatchIndividual.length === 0 ? res.data : fullmatchIndividual,
                        message: "fetch was successful"
                    })
                } else {
                    console.log("search result barwa", res.data)
                    setSearchResult({
                        data: res.data,
                        message: "fetch was successful"
                    })
                }
            }
           
            setActiveSearch !== null && setActiveSearch(false)

        })
        .catch(err => {
            handleResponses(err, setSearchResult);
            setActiveSearch !== null && setActiveSearch(false);
        })
}

export const fetchByFinsNumbers = (token, fins, endpoint, setSearchResult, setFailureFetch, searchParameter) => {

    axios.post(`/${endpoint}`, {

        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
        }
        ,
        data: {
            searchParameter,
            fins
        }
    })
        .then(res => {

            if (setFailureFetch !== null && res.status !== 200) {
                setFailureFetch(true)
            }

            handleResponses(res, setSearchResult);

        })
        .catch(err => {
            setFailureFetch !== null && setFailureFetch(true)
            handleResponses(err, setSearchResult);
        })

}
