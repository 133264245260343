import React from "react"
export const ReportSummary = ({ report }) => {

    return (
        <table border={1} style={{ marginTop: '1em', borderCollapse: 'collapse', width: '55%', padding: '.5em 0' }}>
            <tr className='row_heading' style={{ margin: '50px 0', backgroundColor: '#0069B3', color: 'white' }}>
                <td className='txt_rowheading' colSpan={2}>
                    <h1 className='section_heading' style={{ fontSize: '1.2rem' }}>
                        REPORT SUMMARY
                    </h1>
                </td>
            </tr>
            <br />
            <tr style={{ backgroundColor: 'yellow' }}>
                <td style={{ textAlign: 'center', fontWeight: '600' }}>OVERALL RISK RATING:</td>
                <td style={{ textAlign: 'center', fontWeight: '600' }}>{report.data.rating ? report.data.rating : 'N/A'}</td>
            </tr>
            <br />
            <tr>
                <td style={{ textAlign: 'center' }}>Payment Trend:</td>
                <td style={{ textAlign: 'center' }}>{report.data.trend ? report.data.trend : 'N/A'}</td>
            </tr>
            <br />
            <tr>
                <td style={{ textAlign: 'center' }}>Condition:</td>
                <td style={{ textAlign: 'center' }}>{report.data.condition ? report.data.condition : 'N/A'}</td>
            </tr>
            <br />
            <tr>
                <td style={{ textAlign: 'center' }}>Bank Code:</td>
                <td style={{ textAlign: 'center' }}>{report.data.bank_code ? report.data.bank_code : 'N/A'}</td>
            </tr>
            <br />
            <p style={{ fontSize: '.9rem' }}>Bank Comment:</p>
            <tr style={{ margin: '0 .5em' }}>
                <td style={{ fontSize: '.9rem' }} colSpan={2}>{report.data.bank_comment ? report.data.bank_comment : 'N/A'}</td>
            </tr>
            <br />
            <p style={{ fontSize: '.9rem' }}>Summary Comment:</p>
            <tr style={{ margin: '0 .5em' }}>
                <td style={{ fontSize: '.9rem' }} colSpan={2}>{report.data.comment ? report.data.comment : 'N/A'}</td>
            </tr>


        </table>
    )
}