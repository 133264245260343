
import React, { useContext, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import '../css/login.css'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
import AuthContext from '../AuthContext/AuthContext';
import logo from '../css/finlog.png'
import { DataSubmission } from './Other/DataSubmission';


export const LoginPage = () => {
    const { loginUser } = useContext(AuthContext);
    const [username, setUsername] = useState('')
    const [password, setPassword] = useState('')

    let Navigate = useNavigate()

    const home = (e) => {
        e.preventDefault()
        Navigate('/')
    }

    const resetPass = async (e) => {
        e.preventDefault()
        if (username === "") {
            toast.warning("Provide username to reset password !")
        } else {
            if (window.confirm('New password will be send to ' + username)) {
                const resetStats = await DataSubmission(
                    '',
                    'POST',
                    '/reset-password/',
                    {
                        data: {
                            email: username
                        }
                    },

                )
                if(resetStats[0].result === 'failure'){
                    toast.error('Password reset failed try again later !')
                }else{
                    toast.success('New password was send to ' + username)
                }
            }
        }
    }

    const Authenticate = (e) => {
        e.preventDefault()
        if (username === "" && password === "") {
            toast.error("Provide username and password to login!")
        }

        else if (password === "") {
            toast.error("Please provide the password to login! ")
        }
        else if (username !== "" && password !== "") {
            e.preventDefault();
            loginUser(username, password);
        }
        else if (username === "") {
            toast.error("Please provide the user name to login !")
        }

    }

    return (
        <div className='login'>
            <form className="LoginForm">
                <div className='login_details'>
                    <div className='img_logo'>
                        <img src={logo} alt="" className='img_logo' />
                    </div>
                    <div className='username_password_container'>
                        <div className='lbl_txt_container' >

                            <div className='div_label'>
                                <p>Username </p>
                            </div>

                            <div className='login_input_container'>
                                <input className='login_input' type='text' name='username' id='username'
                                    value={username} onChange={e => setUsername(e.target.value)} />
                            </div>
                        </div>

                        <div className='lbl_txt_container' >

                            <div className='div_label'>
                                <p>Password </p>
                            </div>

                            <div className='login_input_container'>
                                <input className='login_input' type='password' name='password' id='password'
                                    value={password} onChange={e => setPassword(e.target.value)} />
                            </div>
                        </div>
                    </div>

                    <button className='btn_login' onClick={e => Authenticate(e)}> Login </button>

                    <button className='btn_forgot-password' onClick={e => resetPass(e)}> Forgot password ? </button>
                    <br />

                    <button className='btn_forgot-password' onClick={e => home(e)}> Back </button>

                </div>
            </form>
        </div>
    )
}

export default LoginPage