import React from 'react';
import './../../css/EditReport.css'
import { useParams } from 'react-router-dom';
import CompanyEdit from '../Company/Edit/CompanyEdit';

export const IndividualBIUReportEditing = () => {

    const {fins} = useParams();
  return (
    
    <div className="edit-report-container">

        <CompanyEdit
            finsNumber={fins}
        />
    </div>
  )
}
