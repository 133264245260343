import React from 'react'
import { getDate } from '../../../js/getDate'
import { TableData, TableDataHeaders, TableHeading } from './ReportTemplates'

const Inquiries = ({ inquiries, enqDetails, color }) => {

    const internalInquires = inquiries.data !== null && inquiries.data.filter(inq => inq.charge === "0.00");
    const externalInquires = inquiries.data !== null && inquiries.data.filter(inq => inq.charge !== "0.00");

    return (
        <table className='report_table'>
            <TableHeading
                span={6}
                color={color}
                heading={"ENQUIRIES"}
            />

            <tr className='data_row' >
                <TableDataHeaders
                    id={"count_header"}
                    data={""}
                />

                <TableDataHeaders
                    id={"company_header"}
                    data={"Company"}
                />

                {
                    enqDetails.enqType === 'internal' &&
                    <TableDataHeaders
                        id={"enquirer_header"}
                        data={"Enquirer"}
                    />
                }

                <TableDataHeaders
                    id={"date_of_inquiry_header"}
                    data={"Date Of Enquiry"}
                />
            </tr>

            {//mappping function

                externalInquires !== false &&
                externalInquires.reverse().map((inquiry, index) => {

                    if (inquiry.charge !== "0.00") {
                        return <Inquiry inquiry={inquiry} index={index} enqType={enqDetails.enqType} />
                    }
                })

            }

            {
                enqDetails.enqType === "internal" &&

                <>
                    <br />
                    <br />
                    <TableHeading
                        span={6}
                        heading={"Internal"}
                        color={'#d6dce4'}
                    />

                    <tr className="data_row">
                        <TableDataHeaders
                            id={"company_header"}
                            data={"Enquirer"}
                            span={3}
                        />

                        <TableDataHeaders
                            id={"enquirer_header"}
                            data={"Enquiry Date"}
                            span={1}
                        />
                    </tr>



                    {
                        internalInquires !== false &&
                        internalInquires.reverse().map((inquiry, index) => {
                            if (inquiry.charge === "0.00") {
                                return (


                                    <tr style={{ textAlign: "center" }}>
                                        <td colSpan={3}>{inquiry.user ? `${inquiry.user.firstname} ${inquiry.user.surname}` : 'N/A'}</td>
                                        <td>{getDate(inquiry.created)}</td>
                                    </tr>


                                )
                            }
                        })
                    }
                </>

            }


        </table>
    )
}

const Inquiry = ({ inquiry, index, enqType }) => {

    return (

        <tr className='data_row' >
            <TableData
                id={"count_field"}
                data={index + 1}
            />

            <TableData
                id={"company_field"}
                data={inquiry.company_client ? inquiry.company_client.registration_name : "N/A"}
            />

            {
                enqType === 'internal' &&

                <TableData
                    id={"enquirer_field"}

                    data={inquiry.user ? `${inquiry.user.firstname} ${inquiry.user.surname}` : 'N/A'}
                />
            }


            <TableData
                id={"date_of_inquiry_field"}
                data={getDate(inquiry.created)}
            />
        </tr>
    )
}

export default Inquiries