import React, {useState,useContext} from 'react';
import './../css/AdverseUploads.css';
import {  activeAccountsForm } from './Company/selectOptionsjson';
import { SelectContainer } from './InputContainer';
import BulkForm from './BulkUpload/BulkForm';


export const ActiveAccsUploads = () => {


  return (
    
    <section className="adverse-uploads-container">

        <MultipleUploads/>
        
    </section>
  )
}


const MultipleUploads = () => {

  //const [dataTypeState,setDataTypeState] = useState("Individual Active Accounts")

  return (
    <section className="multiple-adverse-uploads-container">
{/* 
      <div className='multiple-data-type-container'>
        <SelectContainer
            label={"Data Type"}
            currentValue={dataTypeState}
            setValue={setDataTypeState}
            options={activeAccountsForm}
            id={""}
        />
      </div> */}


      <div className="bulk-forms-container">
        <BulkForm data={'Active Accounts'}/>
      </div>


    </section>
  )
}

