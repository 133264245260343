export const setRiskColor = (score,riskType) => {

    if(score === undefined){
        return {backgroundColor: '', color: 'black'};
    }

    if(score.data === null) {
        return {backgroundColor:'', color: 'black'};
    }


    switch(riskType) {
        
        case "low_risk":
                
            if(score.data >= 333 && score.data <= 500){
                return {
                    backgroundColor: "green",
                    color: 'white'
                }
            }
            return;
        case "medium_risk":
            
            if(score.data >= 166 && score.data <= 332){
                return {
                    backgroundColor: "orange",
                    color: 'white'
                }
            }
            return;

        case "high_risk":


            if(score.data > 0 && score.data <= 82){
                return {
                    backgroundColor: "red",
                    color: 'white'
                }
            }


            if(score.data > 82 && score.data <= 165){
                return {
                    backgroundColor: "red",
                    color: 'white'
                }
            }
            return;
            
           

        case "indexvalueLLR":

            if(score.data >= 417 && score.data <= 500){
                return {
                    backgroundColor: 'green',
                    color: 'white'
                }
            }
            return;
        
        case "indexvalueLMR":
            if(score.data >=  250 && score.data <= 322){
                return  {
                    backgroundColor: 'orange',
                    color: 'white'
                }
            }
            return;
        case "indexvalueHHR":
            if(score.data >= 0 && score.data <= 82){
                return  {
                    backgroundColor: 'red',
                    color: 'white'
                }
            }
            return;
        case "indexvalueLHR":
            if(score.data >= 83 && score.data <= 165){
                return  {
                    backgroundColor: 'red',
                    color: 'white'
                }
            }
            return
        
        case "indexvalueHMR":
            if(score.data >= 166 && score.data <= 249){
                return  {
                    backgroundColor: 'orange',
                    color: 'white'
                }
            }
            return;
        case "indexvalueHLR":
            if(score.data >= 333 && score.data <= 416){
                return  {
                    backgroundColor: 'green',
                    color: 'white'
                }
            }
            return
        default:
            if(riskType){
                return {
                    backgroundColor: '',
                    color: 'black'
                }
            }
            return

    }

   
}
