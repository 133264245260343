import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import finlogo from "../backgrounds/finlog.png";
import InvoiceReportCSS from "../css/InvoiceReport.module.css";
import html2pdf from "html2pdf.js";
// import html2canvas from "html2canvas";
import PDFTaxInvoice from "./PDFTaxInvoice";

export default function InvoiceReport() {
  const location = useLocation();
  console.log('location ', location.state.service)
  const [isClicked, setIsClicked] = useState(false);

  const [hideEL, setHideEL] = useState(false);
  const [hideInv, setHideInv] = useState(true);

  const chargeTotal = location.state.data.reduce(
    (accumulator, currentValue) => accumulator + currentValue.charge,
    0
  );
  const [isClassAdded, setClassAdded] = useState(false);

  // const generateFiscalInvoice = () => {

  //   const url = `/fiscal-tax-invoice/?client=${client}&service=${services}&from=${periodFrom}&to=${periodTo}`
  //   window.open(url, "_blank", "noopener,noreferrer");
  // };
  // console.log(location.state.client)
  const printReport = () => {
    setIsClicked(true);
    setTimeout(() => {
      window.print();
    }, 500);
  };
  const generateElPDF = () => {
    setHideInv(true);
    setHideEL(false);
    // setClassAdded(!isClassAdded);
    const element = document.getElementById("elReport"); // Replace with the actual ID of your HTML element
    html2pdf(element);
  };

  const generateInvoicePDF = () => {
    // setClassAdded(!isClassAdded);
    setHideEL(true);
    setHideInv(false)
    const element = document.getElementById('invPDFTax'); // Replace with the actual ID of your HTML element
    html2pdf(element);
  };

  const elReport = {
    // Define your styles here
    display: hideEL ? "none" : "",
  };

  const invPDFTax = {
    // Define your styles here
    display: hideInv ? "none" : "",
  };

  let rowID = 1;

  return (
    <>
      <div id="elReport" style={elReport}>
        <table className="Individual_report">
          <tr className="report_section">
            <th className="report-header">
              <tr className="company-report-header-row">
                <div className="company-report-logo-container">
                  <img src={finlogo} alt="fincheck logo" />
                  <p className="moto">
                    Enhancing Value Through Bussiness Information
                  </p>
                </div>

                <div className="header-address">
                  <p className="txtheader-address">
                    8th Floor Club Chambers
                    <br />
                    Cnr Nelson Mandela Avenue / 3rd Street <br />
                    Harare
                    <br />
                    Zimbabwe
                  </p>
                </div>

                <div className="header-contactinfo">
                  <p className="txtheader-contact">
                    +263 (0)242704891-5
                    <br />
                    +263 (0)242794488
                    <br />
                    +263 715 239 711
                    <br />
                    enquiries@fincheckzim.com
                    <br />
                    www.fincheckzim.com
                  </p>
                </div>
              </tr>

              <tr className="header-name">
                <h4 className="creditreporton">
                  <b>
                    {new Date().toLocaleDateString("en-US", {
                      month: "long",
                      day: "numeric",
                      year: "numeric",
                    })}
                  </b>
                </h4>
              </tr>
            </th>
          </tr>
          <div
            style={{
              width: "100%",
              padding: "5px",
              marginBottom: "10px",
              textAlign: "center",
              fontWeight: "bold",
              borderRadius: "5px",
              border: "1px solid grey",
            }}
          >
            ENQUIRY LIST:{" "}
            <span style={{ fontSize: "14px", textTransform: "uppercase" }}>
              {location.state.client}
            </span>
          </div>
          {/* border={2} style={{ width: "100%", padding: "20px" }} */}
          <div className={InvoiceReportCSS.floatTheadWrapper}>
            <table
              style={{
                width: "100%",
                border: "1px solid black",
                borderCollapse: "collapse",
              }}
            >
              <thead>
                <tr>
                  <th scope="col"></th>
                  <th scope="col">Date</th>
                  <th scope="col">User</th>
                  <th scope="col">ID / Reg Number</th>
                  <th scope="col">Subject Name</th>
                  <th scope="col">Charge</th>
                </tr>
              </thead>
              <tbody>
                {/* {JSON.stringify(location.state.data)} */}

                {location.state.data.map(
                  ({
                    ienquiry_id,
                    caller,
                    charge,
                    date_created,
                    id_or_reg_number,
                    subject_name,
                  }) => {
                    return (
                      <tr key={ienquiry_id}>
                        <th scope="row">{rowID++}</th>
                        <td>{date_created.split("T")[0]}</td>
                        <td>{caller}</td>
                        <td>{id_or_reg_number}</td>
                        <td>{subject_name}</td>
                        <td>{charge}</td>
                      </tr>
                    );
                  }
                )}
              </tbody>
              <tfoot>
                <tr>
                  <td colspan="5" style={{ textAlign: "right" }}>
                    Total
                  </td>
                  <td>${chargeTotal}</td>
                </tr>
              </tfoot>
            </table>
          </div>

          <tr className="report_section">
            <tfoot className="report_footer">
              <br />
              <hr className="footer_hr" />
              <tr>
                <div>
                  <p></p>
                </div>
              </tr>
              <tr>
                <p>
                  <b></b>
                </p>
              </tr>
              <tr>
                <b>
                  <p align="center"></p>
                </b>
              </tr>
            </tfoot>
          </tr>
        </table>
      </div>
      <div id="invPDFTax" style={invPDFTax}>
        <PDFTaxInvoice
          data={location.state.invoiceData}
          service={location.state.service}
        />
      </div>
      <div
        style={{ marginLeft: "90px", marginTop: "20px", marginBottom: "90px" }}
      >
        <button
          // className={InvoiceReportCSS.removePrintBtn}
          className={InvoiceReportCSS.submitBtn}
          onClick={generateElPDF}
        >
          Download Enquiry List
        </button>

        <button
          // className={InvoiceReportCSS.removePrintBtn}
          className={InvoiceReportCSS.InvoiceBtn}
          onClick={generateInvoicePDF}
        >
          Download Invoice
        </button>
      </div>
    </>
  );
}
