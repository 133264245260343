import React from 'react'
import { TableData, TableDataHeaders, TableHeading } from '../../Individuals/IndividualReport/ReportTemplates'

export const CompanyContactDetails = ({company,color}) => {
  return (
    
    <table className="report_table">

        <TableHeading
            span={6}
            color={color}
            heading={"Contact Details"}
        />

        <tr className="data_row">

            <TableDataHeaders
                id={"landline_header"}
                data={"Telephone No.:"}
                textAlignClass={'text_section_left_align'}
            />

            <TableData
                id={"landline_field"}
                data={`${company.telephone_no ? company.telephone_no : "N/A"}`}
                textAlignClass={'text_section_left_align'}
            />

            <TableDataHeaders
                id={"mobile_number_header"}
                data={"Mobile No.:"}
                textAlignClass={'text_section_left_align'}
            />

            <TableData
                id={"mobile_number_field"}
                data={`${company.mobile_phone ? company.mobile_phone : "N/A"}`}
                textAlignClass={'text_section_left_align'}
            />
        </tr>

        <tr className="data_row">

            <TableDataHeaders
                id={"physical_address_header"}
                data={"Physical Address:"}
                textAlignClass={'text_section_left_align'}
            />

            <TableData
                id={"physical_address_field"}
                data={`${company.current_address ? company.current_address : "N/A"}`}
                textAlignClass={'text_section_left_align'}
            />

        </tr>

        <tr className="data_row">


            <TableDataHeaders
                id={"website_header"}
                data={"Email:"}
                textAlignClass={'text_section_left_align'}
            />

            <TableData
                id={"website_field"}
                data={`${company.email? company.email : "N/A"}`}
                textAlignClass={'text_section_left_align'}
            />

            <TableDataHeaders
                id={"website_header"}
                data={"Website:"}
                textAlignClass={'text_section_left_align'}
            />

            <TableData
                id={"website_field"}
                data={`${company.website ? company.website : "N/A"}`}
                textAlignClass={'text_section_left_align'}
            />
        </tr>
    </table>
  )
}
