import React from 'react'
import { getDate } from '../../../js/getDate'
import { TableData, TableDataHeaders } from '../../Individuals/IndividualReport/ReportTemplates'

export const BIUContactDetails = ({ data, type, report }) => {
    return (

        <table className="report_table">

            <tr className='row_heading' style={{ margin: '50px 0', backgroundColor: '#4472c4', color: 'white' }}>
                <td className='txt_rowheading' colSpan={4}>
                    <h1 className='section_heading' style={{ fontSize: '1.2rem' }}>
                        Contact Details
                    </h1>
                </td>
            </tr>


            <tr className="data_row">

                <TableDataHeaders
                    id={"name_header"}
                    data={"Address:"}
                />

                <TableData
                    id={"name_field"}
                    data={`${data.current_address ? data.current_address : 'N/A'}`}
                />

                <TableDataHeaders
                    id={"name_header"}
                    data={"Telephone:"}
                />

                <TableData
                    id={"name_field"}
                    data={`${data.mobile_phone ? data.mobile_phone : 'N/A'}`}
                />

            </tr>

            <tr className="data_row">

                <TableDataHeaders
                    id={"name_header"}
                    data={"Email:"}
                />

                <TableData
                    id={"name_field"}
                    data={`${data.email ? data.email : 'N/A'}`}
                />

                <TableDataHeaders
                    id={"name_header"}
                    data={"Mobile:"}
                />

                <TableData
                    id={"name_field"}
                    data={`${data.mobile_phone ? data.mobile_phone : 'N/A'}`}
                />

            </tr>

            <tr className="data_row">

                <TableDataHeaders
                    id={"name_header"}
                    data={"Website:"}
                />

                <TableData
                    id={"name_field"}
                    data={`${data.website ? data.website : 'N/A'}`}
                />

                <TableDataHeaders
                    id={"name_header"}
                    data={"Whatsapp:"}
                />

                <TableData
                    id={"name_field"}
                    data={`${report.data.whatspapp_number ? report.data.whatspapp_number : 'N/A'}`}
                />

            </tr>



            {
                /* 
                                type === "individual" &&
                
                                <tr className="data_row">
                
                                    <TableDataHeaders
                                        id={"date_of_birth_header"}
                                        data={"Date Of Birth"}
                                    />
                
                                    <TableData
                                        id={"date_of_birth_field"}
                                        data={getDate(data.dob)}
                                    />
                
                                </tr> */
            }


            {
                /* type === "individual" &&
                <tr className="data_row">

                    <TableDataHeaders
                        id={"id_header"}
                        data={"National ID"}
                    />

                    <TableData
                        id={"id_field"}
                        data={data.national_id}
                    />

                </tr> */
            }

            {
                /* type === "individual" &&
                <tr className="data_row">

                    <TableDataHeaders
                        id={"gender_header"}
                        data={"Gender"}
                    />

                    <TableData
                        id={"gender_field"}
                        data={data.gender}
                    />

                </tr> */
            }

            {
                /* type === "individual" &&

                <tr className="data_row">

                    <TableDataHeaders
                        id={"marital_status_header"}
                        data={"Marital Status"}
                    />

                    <TableData
                        id={"marital_status_field"}
                        data={data.marital_status}
                    />

                </tr> */
            }

            {/* <tr className="data_row">

                <TableDataHeaders
                    id={"email_header"}
                    data={"Email"}
                />

                <TableData
                    id={"email_field"}
                    data={data.email}
                />

            </tr>
 */}
            {/* <tr className="data_row">

                <TableDataHeaders
                    id={"mobile_number_header"}
                    data={"Mobile Number"}
                />

                <TableData
                    id={"mobile_number_field"}
                    data={type === "individual" ? data.phone_number : data.mobile_phone}
                />

            </tr>
 */}
            {
                /*  type !== "individual" &&
                 <tr className="data_row">
 
                     <TableDataHeaders
                         id={"website_header"}
                         data={"Website"}
                     />
 
                     <TableData
                         id={"website_field"}
                         data={data.website}
                     />
 
                 </tr> */
            }

            {/*   <tr className="data_row">

                <TableDataHeaders
                    id={"landline_header"}
                    data={"Landline"}
                />

                <TableData
                    id={"landline_field"}
                    data={type === "individual" ? data.landline : data.land_line_phone}
                />

            </tr>

            <tr className="data_row">

                <TableDataHeaders
                    id={"physical_address_header"}
                    data={"Physical Address"}
                />

                <TableData
                    id={"physical_address_field"}
                    data={type === "individual" ? data.address : data.current_address}
                />

            </tr>

            <tr className="data_row">

                <TableDataHeaders
                    id={"former_address_header"}
                    data={"Former Address"}
                />

                <TableData
                    id={"former_address_field"}
                    data={"088838232"}
                />

            </tr>

 */}



        </table>
    )
}
