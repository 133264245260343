import React, { useState, useEffect, useRef, useContext } from 'react';
import { toast } from 'react-toastify';
import "./../css/AddBIURequest.css";
import { InputContainer } from './InputContainer';
import { getDate } from '../js/getDate';
import { deleteInquiry } from '../js/deleteInquiry';
import RemoveIcon from '@mui/icons-material/Remove';
import CircularProgress from '@mui/material/CircularProgress';
import ClearIcon from '@mui/icons-material/Clear';
import CheckIcon from '@mui/icons-material/Check';
import axios from 'axios';
import { searchRecord } from '../js/searchRecord';
import { UsersDropDown } from './ReportChecking';
import { searchUser } from '../js/snippets';
import { fetchData } from '../js/fetchData';
import { ValuesContext } from './Admin/HomeAdmin';
import AuthContext from '../AuthContext/AuthContext';
import { individual } from './BulkUpload/TableHeadings';

export const AddBIURequest = ({ data, setFormStatus }) => {


    //const [requestDate,setReqDate] = useState("");
    const { authTokens } = useContext(AuthContext);
    const { userData } = useContext(ValuesContext);
    const [selected, setSelected] = useState("company")
    const [users, setUsers] = useState({ data: [] });
    const [subscriptions, setSubscriptions] = useState({ data: [] })
    const [searchedUsers, setSearchedUsers] = useState([]);
    const [searchedSubs, setSearchedSubs] = useState([]);
    const requesterInput = useRef();
    const [compIndName, setCompIndName] = useState("");
    const [compIndReg, setCompIndReg] = useState("");
    const [requestsMade, setRequestsMade] = useState([]);
    const [userSelected, setUserSelected] = useState(null)


    useEffect(() => {

        fetchData(
            String(authTokens.access),
            setUsers,
            "user",
            null,
            null
        )

        fetchData(
            String(authTokens.access),
            setSubscriptions,
            "/client/",
            null,
            null
        )
    }, [])


    const clearField = () => {
        setCompIndName("");
        setCompIndReg('');

        if (userData.data.groups[0] === 1 || userData.data.groups[0] == 2) {
            requesterInput.current.value = '';
        }
    };

    const submitRequest = () => {

        if (
            compIndName === "" ||
            compIndReg === "") {

            toast("All fields are required");
        } else {
            var selectedUser = {};

            if ((userData.data.groups === 3 || userData.data.groups === 4) && requesterInput.current.value === '') {
                toast("All fields are required");
            }

            if (userData.data.groups[0] === 3 || userData.data.groups[0] === 4) {
                selectedUser = userData.data.username;
            } else {
                selectedUser = users.data.filter(user => {
                    const name = user.firstname + " " + user.surname;

                    if (name.toLowerCase() === requesterInput.current.value.toLowerCase()) {
                        return user;
                    }
                });
            }

            setRequestsMade([...requestsMade, {
                compIndName,
                name: (userData.data.groups[0] === 3 || userData.data.groups[0] === 4) ? selectedUser[0] : userSelected,
                compIndReg,
                username: (userData.data.groups[0] === 3 || userData.data.groups[0] === 4) ? selectedUser : selectedUser[0].username,
                requestingClient: (userData.data.groups[0] === 3 || userData.data.groups[0] === 4) ? userData.data.company.registration_name : userSelected.company.registration_name
            }])

            clearField();
        }
    }

    return (

        <section className="add-biu-request">

            <div className="enq-req-form-container">


                <div className="type-of-inq">

                    <div className="company-radio">
                        <p>company</p>
                        <input
                            type="radio"
                            name="company"
                            checked={selected === "company"}
                            onClick={() => setSelected("company")}
                        />
                    </div>

                    <div className="individual-radio">
                        <p>individual</p>
                        <input
                            type="radio"
                            name="individual"
                            checked={selected === "individual"}
                            onClick={() => setSelected("individual")}
                        />
                    </div>
                </div>

                <form className="enquiry-form">
                    {/*  <InputContainer
                    label={"Request Date"}
                    type={"date"}
                    currentValue={requestDate}
                    setValue={setReqDate}
                    id={""}
                />
 */}
                    <InputContainer
                        label={`${selected === "company" ? "Target Company" : "Individual"} Name`}
                        type={"text"}
                        currentValue={compIndName}
                        setValue={setCompIndName}
                        id={""}
                    />

                    <InputContainer
                        label={`${selected === "company" ? "Company Reg Number" : "Individual Id No"}`}
                        type={"text"}
                        currentValue={compIndReg}
                        setValue={setCompIndReg}
                        id={""}
                    />




                    {
                        (userData.data.groups[0] === 1 || userData.data.groups[0] === 2) &&
                        <div className="input-container">

                            <label>Requesting Username</label>

                            <div className="requester-input-container">
                                <input
                                    type="text"
                                    ref={requesterInput}
                                    onChange={() => searchUser(requesterInput, users, setSearchedUsers, "users", null)}
                                />
                            </div>
                            <UsersDropDown
                                requesterInput={requesterInput}
                                searchedUsers={searchedUsers}
                                setSelectedUser={setUserSelected}
                                typeOfDropdown={"users"}
                            />
                        </div>
                    }

                    {

                        (userData.data.groups[0] === 1 || userData.data.groups[0] === 2) &&
                        <div className="input-container">

                            <label>Requesting Client</label>
                            <div className="requester-input-container">
                                <input
                                    type="text"
                                    value={userSelected ? userSelected.company.registration_name : ''}
                                    readOnly
                                />
                            </div>
                        </div>

                    }




                </form>

                <button className="add" onClick={submitRequest}>Add Request</button>
            </div>

            <AddedTable requestsMade={requestsMade} selected={selected} setRequestsMade={setRequestsMade} />

            <button className="close-the-add-req" onClick={() => setFormStatus(false)}>
                Submit
            </button>
        </section>
    )
}

const AddedTable = ({ requestsMade, selected, setRequestsMade }) => {

    const { userData } = useContext(ValuesContext);
    const typeOfUser = userData.data.groups[0];

    return (
        <div>
            <table border={2} className="bui-internal-reports-reqs">

                <tr className='bui-internal-reports__heading'>
                    <td colSpan={8}>
                        <div className="report-heading-div">
                            <h4>Reports</h4>
                        </div>
                    </td>
                </tr>

                <tr className="bui-internal-reports__data-headings">
                    <td><b>Report No.</b></td>
                    {typeOfUser === 1 || typeOfUser === 2 && <td><b>Date of request</b></td>}
                    <td><b>Company/Individual Name</b></td>
                    <td><b>Company / Individual Reg No/ID</b></td>
                    {(typeOfUser === 1 || typeOfUser === 2) && <td><b>Requesting Client</b></td>}
                    {(typeOfUser === 1 || typeOfUser === 2) && <td><b>Requesting UserName</b></td>}
                </tr>

                {
                    requestsMade.lenght !== 0 &&
                    requestsMade.map((report, index) => {
                        return (
                            <ReportsReqDetails
                                report={report}
                                index={index}
                                selected={selected}
                                requestsMade={requestsMade}
                                setRequestsMade={setRequestsMade}
                            />
                        )
                    })
                }
            </table>

        </div>

    )
}

const ReportsReqDetails = ({ report, index, selected, requestsMade, setRequestsMade }) => {

    const [deleting, setDeleting] = useState(false);
    const { authTokens } = useContext(AuthContext)
    const [failed, setFailed] = useState("");
    const [searchResult, setSearchResult] = useState({
        data: null,
        message: ""
    });
    const [addedReport, setAddedReport] = useState({})
    const { userData } = useContext(ValuesContext);



    useEffect(() => {

        searchRecord(
            String(authTokens.access),
            null,
            null,
            selected === "company" ? "registration_name" : "national_id",
            selected === "company" ? report.compIndReg : report.compIndReg,
            setSearchResult,
            `search-${selected}`
        )

    }, [])

    useEffect(() => {


        if (searchResult.message === "") {
            return;
        }


        const reqData = {
            user: report.username
        };



        if (searchResult.message === "fetch was successful" && selected === "company") {
            const company = searchResult.data.filter(company => {
                if (company.registration_name.toLowerCase() === report.compIndName.toLowerCase() || company.registration_number.toLowerCase() === report.compIndReg.toLowerCase()) {
                    return company
                }
            })
            reqData["fins"] = company.length === 0 ? " " : company[0].fins_number;
            reqData["company_name"] = company[0] ? company[0].registration_name : report.compIndName;
            reqData["company_reg_num"] = company[0] ? company[0].registration_number : report.compIndReg;

        } else if (searchResult.message === "fetch was successful" && selected === "individual") {
            const individual = searchResult.data.filter(individual => {
                if (`${individual.firstname} ${individual.surname}`.toLowerCase() === report.compIndName.toLowerCase() || `${individual.firstname} ${individual.fornames} ${individual.surname}`.toLowerCase() === report.compIndName.toLowerCase() || individual.national_id.toLowerCase() === report.compIndReg.toLowerCase()) {
                    return individual
                }
            })
            reqData["fins"] = individual.length === 0 ? " " : individual[0].fins_number;
            reqData["individual_name"] = individual[0] ? `${individual[0].firstname} ${individual[0].surname}` : report.compIndName;
            reqData["individual_id_num"] = individual[0] ? individual[0].national_id : report.compIndReg;

        } else if (searchResult.message !== "fetch was successful") {
            setFailed(true)
            return;
        }



        axios.post(`/single-${selected}-log-biu/`, {
            headers: {
                "Content-Type": "application/json"
            },
            data: reqData
        })
            .then(res => {


                setAddedReport(res.data[0])
                if (res.status > 299) {
                    setFailed(true);
                } else {
                    setAddedReport(res.data[0])
                    setFailed(false)
                }
            })
            .catch(() => {
                setFailed(true)
            })
    }, [searchResult])



    return (
        <>



            <tr key={index} className="report-req-details" style={{ backgroundColor: failed === true ? "red" : failed === false ? "green" : "white" }}>
                <td>{index + 1}</td>
                {/* <td>{getDate(new Date())}</td> */}
                <td>{report.compIndName}</td>
                <td>{report.compIndReg}</td>
                {(userData.data.groups[0] == 1 || userData.data.groups[0] == 2) && <td>{report.requestingClient}</td>}
                {(userData.data.groups[0] == 1 || userData.data.groups[0] == 2) && <td>{`${report.name.firstname} ${report.name.surname}`}</td>}
                {/*  <td>{report.status}</td> */}
                <td className='remove-icon-container' onClick={() => deleteInquiry(addedReport, setDeleting, null, null, requestsMade, setRequestsMade, report)}>
                    {deleting === false && <RemoveIcon className='remove-icon' />}
                    {deleting === "deleting" && <CircularProgress size={"1.5rem"} />}
                    {deleting === "failed" && <ClearIcon style={{ color: "red" }} />}
                    {deleting === true && <CheckIcon style={{ color: "green" }} />}
                </td>

            </tr>
        </>
    )
}
