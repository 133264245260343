import React, { useContext, useEffect, useState } from 'react'
import { InputAreaContainer, InputContainer, MultiSelectContainerWithSearch, SelectContainer } from '../InputContainer'
import SaveIcon from '@mui/icons-material/Save'
import { DataSubmission } from '../Other/DataSubmission'
import AuthContext from '../../AuthContext/AuthContext'
import '../../css/clientsarena.css'
import { slt_AccesLevel, slt_IdentificationType } from '../Individuals/IndividualselectOptions'
import axios from 'axios'
import { CircularProgress } from '@mui/material'
import { toast } from 'react-toastify'
import { ValuesContext } from '../Admin/HomeAdmin'

const AddeditUser = ({ setdisplay, refresh, setvisible, caller, finsNumber, usergroup, setactive, userData, isAdmin }) => {
    let userDetails = useContext(ValuesContext).userData

    if (isAdmin === undefined) {
        isAdmin = false
    }

    if(userData){
        if (userData[0].length !== 0) {
            var object = userData[0]
            Object.keys(object).map(
              key => {
                if (object[key] === undefined || object[key] === null) {
                  object[key] = '--'
                }
              }
            )
          }
    }
    
    const [firstName, setFirstName] = useState(() => {
        if (caller === 'edituser') {
            return userData[0].firstname
        } else {
            return ''
        }
    })
    const [company, setCompany] = useState(() => {
        if (caller === 'edituser') {
            return { value: userData[0].company.fins_number, label: userData[0].company.registration_name }
        } else {
            return {}
        }
    })
    const [lastName, setLastName] = useState(() => {
        if (caller === 'edituser') {
            return userData[0].surname
        } else {
            return ''
        }
    })
    const [email, setEmail] = useState(() => {
        if (caller === 'edituser') {
            return userData[0].username
        } else {
            return ''
        }
    })

    const [username, setUsername] = useState(() => {
        if (caller === 'edituser') {
            return userData[0].username
        } else {
            return ''
        }
    })

    const [identificationType, setIdentificationType] = useState(() => {
        if (caller === 'edituser') {
            return userData[0].identification_type
        } else {
            return ''
        }
    })

    const [idNumber, setIdNumber] = useState(() => {
        if (caller === 'edituser') {
            return userData[0].national_id
        } else {
            return ''
        }
    })

    const [position, setPosition] = useState(() => {
        if (caller === 'edituser') {
            return userData[0].job_title
        } else {
            return ''
        }
    })

    const [mobileNumber, setMobileNumber] = useState(() => {
        if (caller === 'edituser') {
            return userData[0].mobile
        } else {
            return ''
        }
    })

    const [address, setAddress] = useState(() => {
        if (caller === 'edituser') {
            return userData[0].address
        } else {
            return ''
        }
    })

    const { authTokens, logoutUser } = useContext(AuthContext);
    const [accessLevel, setAccessLevel] = useState(() => {
        if (caller === 'edituser') {
            if (userData[0].groups[0] == 1 || userData[0].groups[0] == 3) {
                return 'Admin'
            } else if (userData[0].groups[0] == 2 || userData[0].groups[0] == 4) {
                return 'User'
            } else {
                return ''
            }
        } else {
            if (isAdmin === true) {
                return 'Admin'
            } else {
                return ''
            }

        }
    })
    const [reqstatus, setReqstatus] = useState('red')

    const AddUser = async (e) => {
        e.preventDefault()
        let responsed = ''
        let useraccess = ''
        let valueCompany = ''
        if (finsNumber === undefined || finsNumber === '' || finsNumber === null) {
            toast.warning('First add the client first before adding the user !')
        } else if (idNumber === '' || lastName === '' || accessLevel === '' || email === '' || identificationType === '') {
            if (idNumber === '') {
                toast.warning('Please provide user id number')
            }
            if (lastName === '') {
                toast.warning('Please provide user last name')
            }
            if (accessLevel === '') {
                toast.warning('Please select user access level')
            }
            if (email === '') {
                toast.warning('Please provide user email')
            }
            if (identificationType === '') {
                toast.warning('Please select the identification type')
            }
        }
        else {
            ((userDetails.data.groups[0] === 1 || userDetails.data.groups[0] === 2) && usergroup === 'external' && caller !== 'adduserclient') ?
                valueCompany = company.value : valueCompany = finsNumber
            setReqstatus('yellow')
            if (usergroup === 'internal') {
                if (accessLevel === 'Admin') {
                    useraccess = [1]
                } else {
                    useraccess = [2]
                }
            }
            else if (usergroup === 'external') {
                if (accessLevel === 'Admin') {
                    useraccess = [3]
                } else {
                    useraccess = [4]
                }

            }

            if (caller === 'edituser') {
                responsed = await DataSubmission(
                    String(authTokens.access),
                    'put',
                    '/user/' + userData[0].id + '/',
                    {
                        id: userData[0].id,
                        company: valueCompany,
                        surname: lastName,
                        firstname: firstName,
                        email: email,
                        username: email,
                        groups: useraccess,
                        identification_type: identificationType,
                        national_id: idNumber,
                        mobile: mobileNumber,
                        address: address,
                        job_title: position

                    }
                )
            } else {
                responsed = await DataSubmission(
                    String(authTokens.access),
                    'post',
                    '/user/',
                    {
                        company: valueCompany,
                        surname: lastName,
                        firstname: firstName,
                        email: email,
                        username: email,
                        groups: useraccess,
                        identification_type: identificationType,
                        national_id: idNumber + '_',
                        mobile: mobileNumber,
                        address: address,
                        job_title: position


                    },
                    'adduser'
                )
            }

            if (responsed[0].result !== 'success') {
                setReqstatus('red')
            }

            if (responsed[0].result === 'success' && caller === 'addadminuser') {
                setReqstatus('green')
                const companyFins = responsed[1].request_response.data.fins_number;

                setactive !== null && setactive('subscription')
            }
            if (responsed[0].result === 'success' && (caller === 'addadminuserclient' || caller === 'adduserclient')) {
                if (setvisible !== undefined) {
                    setvisible('no')
                }

                if (refresh) { refresh('table') }
                if (setdisplay) { setdisplay('adminUser') }

            }
            if (responsed[0].result === 'success' && (caller === 'editclientadduser' || caller === 'edituser')) {
                if (setactive !== null) {
                    setactive('adminUser')
                }
                setReqstatus('green')
            } else
                if (responsed[0].result === 'success') {
                    setReqstatus('green')

                }
        }
    }

    return (
        <div className='newuserclient'>
            <div className='newuserHeader'>
                <div className='adduserheadingtext'>
                    <h2 className='adduserclientheader'>
                        System User
                    </h2>
                </div>
            </div>

            <div className='userDetails'>
                {((userDetails.data.groups[0] === 1 || userDetails.data.groups[0] === 2) && usergroup === 'external' && caller !== 'adduserclient') &&
                    <div className='detailsuser'>
                        <MultiSelectContainerWithSearch
                            currentValue={company}
                            setValue={setCompany}
                            label={'Client'}
                        />
                    </div>
                }
                <div className='detailsuser'>
                    <InputContainer
                        label={"First Name"}
                        type={"text"}
                        currentValue={firstName}
                        setValue={setFirstName}
                        id={'firstName'}
                    />

                    <InputContainer
                        label={"Last Name"}
                        type={"text"}
                        currentValue={lastName}
                        setValue={setLastName}
                        id={'lastName'}
                    />

                    <InputContainer
                        label={"User Email"}
                        type={"text"}
                        currentValue={email}
                        setValue={setEmail}
                        id={'email'}
                    />
                </div>
                <div className='detailsuser'>

                    <SelectContainer
                        label={"Identification Type "}
                        currentValue={identificationType}
                        setValue={setIdentificationType}
                        options={slt_IdentificationType}
                    />

                    <InputContainer
                        label={"Identification Number"}
                        type={"text"}
                        currentValue={idNumber}
                        setValue={setIdNumber}
                        id={'idNumber'}
                    />

                    <InputContainer
                        label={"Position"}
                        type={"text"}
                        currentValue={position}
                        setValue={setPosition}
                        id={'position'}
                    />

                </div>

                <div className='detailsuser'>
                    <InputContainer
                        label={"Mobile Number"}
                        type={"text"}
                        currentValue={mobileNumber}
                        setValue={setMobileNumber}
                        id={'mobileNumber'}
                    />

                    <InputAreaContainer
                        label={"Address"}
                        type={"text"}
                        currentValue={address}
                        setValue={setAddress}
                        id={'address'}
                    />

                    {isAdmin === false &&
                        <>
                            {userDetails.data.groups[0] < 4 &&
                                <SelectContainer
                                    label={"Access Level  "}
                                    currentValue={accessLevel}
                                    setValue={setAccessLevel}
                                    options={slt_AccesLevel}
                                />
                            }
                        </>

                    }

                </div>
                {
                    caller === 'edituser' ?
                        <section className='secButtonsclients'>
                            <button icon="close" onClick={(e) => AddUser(e)} className="formButtons" text="Close"
                                align="center" >
                                <SaveIcon className='icons' />
                                <blockquote>
                                    Update
                                </blockquote>
                            </button>
                        </section>
                        :
                        <section className='secButtonsclients'>
                            {reqstatus === 'red' &&
                                <button icon="close" onClick={(e) => { AddUser(e); }} className="formButtons" text="Close"
                                    align="center"  >
                                    <SaveIcon className='icons' />
                                    <blockquote>
                                        Save
                                    </blockquote>
                                </button>
                            }
                            {reqstatus === 'yellow' &&
                                <div className='clientloadingcircle'>
                                    <CircularProgress />
                                </div>
                            }
                        </section>
                }
            </div>

        </div>
    )
}

export default AddeditUser
