import React, { useEffect, useState } from 'react'
import '../../../css/CompanyEdit.css'
import { useContext } from 'react';
import AuthContext from '../../../AuthContext/AuthContext'
import { toast, ToastContainer } from 'react-toastify'
import axios, { AxiosError } from 'axios'
import { CircularProgress } from '@mui/material'
import { EditCompany } from '../EditCompany'
import { AssocTables, AuditorsTable, BankingDetailsTable, CompanyBankReportsTable, CompanyBranchesTable, CompanyDeedsTable, CompanyDivisionTable, CompanyExecutivesTable, CompanyPublicRecordsTable, CompanyShareholdersTable, CompanySubsidiaryTable, CompanyTradeReferencesTable } from './Tables';
import { CapitalForm } from '../Capital';
import CommentRating from '../CommentRating';

const CompanyEdit = ({ id }) => {
  var response

  let { authTokens, logoutUser } = useContext(AuthContext);
  const [data, setData] = useState([])
  const [reportData, setReportData] = useState([])
  const [capital, setCapital] = useState([])
  const [run, setRun] = useState('loading')
  const [finsNumber, setFinsNumber] = useState('')
  const [logs, setLogs] = useState([])
  const [capitalDisplay, setCapitalDisplay] = useState(false)
  var Formdata = ''

  useEffect(() => {
    axios({
      method: 'get',
      url: '/biu-request/' + id + '/',
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + String(authTokens.access)
      },
      data: { data: { search: finsNumber } },
    })
      .then((res) => {
        setReportData(res.data)
        setFinsNumber(res.data.subject_company.fins_number)
      }).catch((AxiosError) => {
        if (AxiosError.response.data) {
          if (AxiosError.response.data.includes('Proxy error')) {
            toast.error('Network error. Check network connection!')
          }
        }
        setRun('error')
        if (AxiosError.code === "ERR_NETWORK") {
          toast.error("Network Error!")
        } else (
          toast.error('Internal system error contact system admin!')
        )
      }
      )
    axios({
      method: 'post',
      url: '/generate-biu-report/',
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + String(authTokens.access)
      },
      data: { data: { id: id } },
    })
      .then((res) => {
        setLogs(res.data)
        setRun('finished')
      }).catch((AxiosError) => {
        if (AxiosError.response.data) {
          if (AxiosError.response.data.includes('Proxy error')) {
            toast.error('Network error. Check network connection!')
          }
        }
        setRun('error')
        if (AxiosError.code === "ERR_NETWORK") {
          toast.error("Network Error!")
        } else (
          toast.error('Internal system error!. Please try again later ')
        )
      }
      )
  }, [])

  useEffect(() => {
    if (finsNumber === '') {
    } else {
      axios({
        method: 'post',
        url: '/search-company/',
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + String(authTokens.access)
        },
        data: { data: { search: finsNumber } },
      })
        .then((res) => {
          setData(res.data)
          setRun('finished')
        }).catch((AxiosError) => {
          if (AxiosError.response.data) {
            if (AxiosError.response.data.includes('Proxy error')) {
              toast.error('Network error. Check network connection!')
            }
          }
          setRun('error')
          if (AxiosError.code === "ERR_NETWORK") {
            toast.error("Network Error!")
          } else (
            toast.error('Internal system error contact system admin!')
          )
        }
        );
      axios({
        method: 'post',
        url: '/company-capitals/',
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + String(authTokens.access)
        },
        data: { data: { fins: finsNumber } },
      }).then((res) => {
        setCapital(res.data)
        setCapitalDisplay(true)
      }).catch(err => {
        setRun('error');
      })
    }
  }, [finsNumber])

  return (

    <div className={'mainEditDiv-Container'}>
      {run === 'loading' &&
        <div className='loadingdiv'>
          <CircularProgress className='loading' />
        </div>
      }
      {run === 'error' &&
        <div className='loadingdiv'>
          <h2 color='red'>Error !</h2>
        </div>
      }
      {
        (run === 'finished' && finsNumber !== '' && data.length !== 0) &&
        <EditCompany companyData={data} />
      }
      <br />
      <hr />
      <br />
      {(run === 'finished' && finsNumber !== '' && capitalDisplay) &&
        < CapitalForm
          finsNumber={finsNumber}
          capitalData={capital}
          caller='editCapital'
        />}

      <br />
      <hr />
      <br />
      {
        (run === 'finished' && finsNumber !== '' && logs.length !== 0) ?
          <CompanyBranchesTable
            setRun={setRun}
            finsNumber={finsNumber}
            log={logs.length !== 0 && logs.logs.branch}
            report_id={id}
          />
          :
          <div className='loadingdiv'>
            <h2 color='red'>Failed to load Table refresh Page !</h2>
          </div>
      }

      <br />
      <hr />
      <br />
      {
        (run === 'finished' && finsNumber !== '' && logs.length !== 0) ?
          <CompanyDivisionTable
            setRun={setRun}
            finsNumber={finsNumber}
            log={logs.length !== 0 && logs.logs.division}
            report_id={id}
          />
          :
          <div className='loadingdiv'>
            <h2 color='red'>Failed to load Table refresh Page !</h2>
          </div>
      }
      <br />
      <hr />
      <br />
      {
        (run === 'finished' && finsNumber !== '' && logs.length !== 0) ?
          <AssocTables
            setRun={setRun}
            finsNumber={finsNumber}
            log={logs.length !== 0 && logs.logs.associated_company}
            report_id={id}
          />
          :
          <div className='loadingdiv'>
            <h2 color='red'>Failed to load Table refresh Page !</h2>
          </div>
      }

      <br />
      <hr />
      <br />
      {
        (run === 'finished' && finsNumber !== '' && logs.length !== 0) ?
          <CompanySubsidiaryTable
            setRun={setRun}
            finsNumber={finsNumber}
            log={logs.length !== 0 && logs.logs.subsiduary_company}
            report_id={id}
          />
          :
          <div className='loadingdiv'>
            <h2 color='red'>Failed to load Table refresh Page !</h2>
          </div>
      }

      <br />
      <hr />
      <br />
      {
        (run === 'finished' && finsNumber !== '' && logs.length !== 0) ?
          <CompanyExecutivesTable
            setRun={setRun}
            finsNumber={finsNumber}
            log={logs.length !== 0 && logs.logs.company_executive}
            report_id={id}
          />
          :
          <div className='loadingdiv'>
            <h2 color='red'>Failed to load Table refresh Page !</h2>
          </div>
      }

      <br />
      <hr />
      <br />
      {
        (run === 'finished' && finsNumber !== '' && logs.length !== 0) ?
          <CompanyShareholdersTable
            setRun={setRun}
            finsNumber={finsNumber}
            log={logs.length !== 0 && logs.logs.shareholder}
            report_id={id}
          />
          :
          <div className='loadingdiv'>
            <h2 color='red'>Failed to load Table refresh Page !</h2>
          </div>
      }

      <br />
      <hr />
      <br />
      {
        (run === 'finished' && finsNumber !== '' && logs.length !== 0) ?
          <CompanyDeedsTable
            setRun={setRun}
            finsNumber={finsNumber}
            log={logs.length !== 0 && logs.logs.deed}
            report_id={id}
          />
          :
          <div className='loadingdiv'>
            <h2 color='red'>Failed to load Table refresh Page !</h2>
          </div>
      }

      <br />
      <hr />
      <br />
      {
        (run === 'finished' && finsNumber !== '' && logs.length !== 0) ?
          <AuditorsTable
            setRun={setRun}
            finsNumber={finsNumber}
            log={logs.length !== 0 && logs.logs.auditor}
            report_id={id}
          />
          :
          <div className='loadingdiv'>
            <h2 color='red'>Failed to load Table refresh Page !</h2>
          </div>
      }

      <br />
      <hr />
      <br />
      {
        (run === 'finished' && finsNumber !== '' && logs.length !== 0) ?
          <BankingDetailsTable
            setRun={setRun}
            finsNumber={finsNumber}
            log={logs.length !== 0 && logs.logs.bank_account}
            report_id={id}
          />
          :
          <div className='loadingdiv'>
            <h2 color='red'>Failed to load Table refresh Page !</h2>
          </div>
      }

      <br />
      <hr />
      <br />
      {
        (run === 'finished' && finsNumber !== '' && logs.length !== 0) ?
          <CompanyBankReportsTable
            setRun={setRun}
            finsNumber={finsNumber}
            log={logs.length !== 0 && logs.logs.bank_report}
            report_id={id}
          />
          :
          <div className='loadingdiv'>
            <h2 color='red'>Failed to load Table refresh Page !</h2>
          </div>
      }

      <br />
      <hr />
      <br />
      {
        (run === 'finished' && finsNumber !== '' && logs.length !== 0) ?
          <CompanyPublicRecordsTable
            setRun={setRun}
            finsNumber={finsNumber}
            log={logs.length !== 0 && logs.logs.public_record}
            report_id={id}
          />
          :
          <div className='loadingdiv'>
            <h2 color='red'>Failed to load Table refresh Page !</h2>
          </div>
      }

      <br />
      <hr />
      <br />
      {
        (run === 'finished' && finsNumber !== '' && logs.length !== 0) ?
          <CompanyTradeReferencesTable
            setRun={setRun}
            finsNumber={finsNumber}
            log={logs.length !== 0 && logs.logs.trade_reference}
            report_id={id}
          />
          :
          <div className='loadingdiv'>
            <h2 color='red'>Failed to load Table refresh Page !</h2>
          </div>
      }

      <br />
      <hr />
      <br />
      {
        (run === 'finished' && finsNumber !== '' && logs.length !== 0) ?
          <CommentRating
            FormData={logs}
            companyFins={finsNumber}
          />
          :
          <div className='loadingdiv'>
            <h2 color='red'>Failed to load Form refresh Page !</h2>
          </div>
      }
    </div>
  )
}
export default CompanyEdit