import React, { useEffect, useState, useContext, createContext } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import { Outlet, useNavigate } from 'react-router-dom'
import "./../../css/HomeAdmin.css"
import { LogoSection } from './LogoSection'
import Navbar from './Navbar'
import { TopNavbar } from './TopNavbar';
import { fetchUserData } from '../../js/fetchData';
import AuthContext from '../../AuthContext/AuthContext';
import { ToastContainer } from 'react-toastify';
// comment

export const ValuesContext = createContext();

const HomeAdmin = () => {

  const navigate = useNavigate();
  const permissions = useContext(AuthContext);
  let { authTokens } = useContext(AuthContext)
  const [activePage, setActivePage] = useState("bureau-dashboard");
  const [userData, setUserData] = useState({});
  const [userDetails, setUserDetails] = useState({})

  useEffect(() => { setUserDetails(userData) }, [userData])


  const [fetchedIndividuals, setFetchedIndividuals] = useState({
    data: [],
    message: ""
  })

  const [fetchedCompanies, setFetchedCompanies] = useState({
    data: [],
    message: ""
  });

  const [fetchedClaims, setFetchedClaims] = useState({
    data: [],
    message: ""
  })




  // fetching data
  useEffect(() => {

    if (permissions.user === null) {
      navigate("/login");
      return;
    }

    //fetchData(String(authTokens.access) , setFetchedClaims, "claim")

    /*     fetchData(String(authTokens.access) , setFetchedClaims, "vehicle-hire-purchase"); */


    fetchUserData(String(authTokens.access), "get-user", permissions.user.user_id, setUserData)


  }, [])


  const contextData = {
    userData,
    activePage,
    fetchedIndividuals,
    fetchedCompanies,
    userDetails,
    fetchedClaims,
    fetchedHPI: fetchedCompanies,
    setFetchedIndividuals,
    setFetchedCompanies
  }

  if (Object.keys(userData).length > 0) {

    if (userData.data !== null) {
      return (

        <ValuesContext.Provider value={contextData}>
          <section className="admin-view-container">

            <div className="logo-section">
              <LogoSection />
            </div>

            <div className="navbar-section">
              <Navbar setActivePage={setActivePage} />
            </div>

            <div className="top-navbar-section">
              <TopNavbar setActivePage={setActivePage} />
            </div>

            <div className="view-section">

              <Outlet />

            </div>
          </section>
        </ValuesContext.Provider>
      )
    } else {
      return <p style={{ color: 'red', marginTop: '2em', textAlign: 'center' }}>Could not get user details,please login again</p>
    }
  } else {

    return (
      <div className="loading-content-container" style={{ display: 'flex', justifyContent: 'center', marginTop: '2em' }}>
        <CircularProgress size='1rem' />
      </div>
    )
  }
}

export default HomeAdmin