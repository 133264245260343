import axios from 'axios';
import React, { useState } from 'react'
import { useContext } from 'react';
import AuthContext from '../AuthContext/AuthContext';
import './../css/Settings.css';
import { ValuesContext } from './Admin/HomeAdmin';
import { toast } from 'react-toastify'
import { InputContainer } from './InputContainer';

export const Settings = () => {
    const {userData} = useContext(ValuesContext);
    const company = userData.data.company;
    const {authTokens} = useContext(AuthContext) 

    const [vat,setVat] = useState(company.vat_number);
    const [areaCode,setAreaCode] = useState(company.area_code);
    const [town,setTown] = useState('');
    const [country,setCountry] = useState(company.country);
    const [telephone1,setTelephone1] = useState('');
    const [telephone2,setTelephone2] = useState('');
    const [mobileNumber,setMobileNumber] = useState(company.mobile_phone);
    const [adminFirstName,setAdminFirstName] = useState('');
    const [adminSurname,setAdminSurname] = useState('');
    const [companyEmail,setCompanyEmail] = useState(company.email);
    const [website,setWebsite] = useState(company.website);
    const [companyLogo,setCompanyLogo] = useState(company.logo);
    const [suburb,setSuburb] = useState('');
    const [companyName,setCompanyName] = useState(company.registration_name);
    const [street,setStreet] = useState('');
    const [companyRegNum,setCompanyRegNum] = useState(company.registration_number);

    const updateSettings = () => {

        axios.put(`/company/${company.fins_number}/`, {
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: "Bearer " + String(authTokens.access)
            },
            fins_number: company.fins_number,
            cb_number: company.cb_number,
            registration_number: companyRegNum,
            registration_name: companyName,
            legal_state: company.legal_state,
            trading_status: company.trading_status,
            parent_company: company.parent_company,
            former_address: company.former_address,
            current_address: company.current_address,
            postal_address: company.postal_address,
            mobile_phone: mobileNumber,
            logo: companyLogo,
            trading_status: company.trading_name,
            registration_date: company.registration_date,
            bp_number: company.bp_number,
            land_line_phone: company.land_line_phone,
            vat_number: vat,
            number_of_employees: company.number_of_employees,
            website: website,
            trend: company.trend,
            condition_co: company.condition_co,
            email: companyEmail,
            is_confirmed: company.is_confirmed,
            is_valid: company.is_valid,
            operations: company.operations,
            is_suspended: company.is_suspended,
            contact_person: company.contact_person,
            risk_class: company.risk_class,
            free_check: company.free_check,
            account_number: company.account_number,
            is_under_judicial: company.is_under_judicial,
            industry: company.industry,
            area_code: areaCode

        })
            .then(response => {

                if (response.status >= 200 && response.status < 300) {
                    toast.success('Settings updated successfully')
                } else {
                    toast.error('Settings update failed')
                }
            })
            .catch(() => toast.error('Could not send request,please check your settings'))
    }
    return (

        <section className="company-settings">

            <h3>Company Settings</h3>


            <form className="settings-form">

                <div className="settings-input-container">
                    <InputContainer
                        label={'Company Name'}
                        currentValue={companyName}
                        setValue={setCompanyName}
                        type={'text'}
                        width={'100%'}
                    />

                    <InputContainer
                        label={'Company Reg Number'}
                        currentValue={companyRegNum}
                        setValue={setCompanyRegNum}
                        type={'text'}
                    />

                    <InputContainer
                        label={'VAT Number'}
                        currentValue={vat}
                        setValue={setVat}
                        type={'text'}
                    />

                    <InputContainer
                        label={'Street No & Name'}
                        currentValue={street}
                        setValue={setStreet}
                        type={'text'}
                    />

                    <InputContainer
                        label={'Suburb'}
                        currentValue={suburb}
                        setValue={setSuburb}
                        type={'text'}
                    />

                    <           InputContainer
                        label={'Town/City'}
                        currentValue={town}
                        setValue={setTown}
                        type={'text'}
                    />

                    <InputContainer
                        label={'Area Code'}
                        currentValue={areaCode}
                        setValue={setAreaCode}
                        type={'text'}
                    />

                    <InputContainer
                        label={'Country'}
                        currentValue={country}
                        setValue={setCountry}
                        type={'text'}
                    />

                    <InputContainer
                        label={'Telephone 1'}
                        currentValue={telephone1}
                        setValue={setTelephone1}
                        type={'text'}
                    />

                    <InputContainer
                        label={'Telephone 2'}
                        currentValue={telephone2}
                        setValue={setTelephone2}
                        type={'text'}
                    />

                    <InputContainer
                        label={'Mobile Number'}
                        currentValue={mobileNumber}
                        setValue={setMobileNumber}
                        type={'text'}
                    />

                    <InputContainer
                        label={'Admin First Name'}
                        currentValue={adminFirstName}
                        setValue={setAdminFirstName}
                        type={'text'}
                    />

                    <InputContainer
                        label={'Admin Surname'}
                        currentValue={adminSurname}
                        setValue={setAdminSurname}
                        type={'text'}
                    />

                    <InputContainer
                        label={'Company Email'}
                        currentValue={companyEmail}
                        setValue={setCompanyEmail}
                        type={'text'}
                    />

                    <InputContainer
                        label={'Website'}
                        currentValue={website}
                        setValue={setWebsite}
                        type={'text'}
                    />

                    <InputContainer
                        label={'Company Logo'}
                        currentValue={companyLogo}
                        setValue={setCompanyLogo}
                        type={'text'}
                    />
                </div>

                <div className="submit-settings-btn" onClick={updateSettings}>
                    Save
                </div>

            </form>
        </section>
    )
}
