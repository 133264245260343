import React from "react";

export default function USDorZWL({ service }) {
  if (service === "BureauEnqZWL") {
    return (
      <>
        <span>ZWL</span>
      </>
    );
  } else if (service === "BureauEnqUSD") {
    return (
      <>
        <span>USD</span>
      </>
    );
  } else {
    return "";
  }
}
