import React, { useContext, useEffect, useState } from 'react'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'
import CheckIcon from '@mui/icons-material/Check'
import AuthContext from '../../../AuthContext/AuthContext';
import AddIcon from '@mui/icons-material/Add'
import { CircularProgress } from '@mui/material'
import SaveIcon from '@mui/icons-material/Save'
import axios from 'axios';
import { toast } from 'react-toastify';
import CloseIcon from '@mui/icons-material/Close'
import '../../../css/CompanyEdit.css'
import { DataSubmission } from '../../Other/DataSubmission';
import { InputContainer, InputContainerTable, InputContainerTableReadOnly, InputAreaContainer, SelectContainer } from '../../InputContainer';
import SearchModal from '../../Other/Search/SearchModal';
import { AccountType, CurrencyType } from '../selectOptionsjson';


const RemoveNull = (DataArray) => {
    if (DataArray) {
        if (DataArray.length !== 0) {
            DataArray.map(
                item => Object.keys(item).map(
                    key => {
                        if (item[key] === undefined || item[key] === null) {
                            item[key] = '--'
                        }
                    }
                )
            )
        }
    }
}

const Confirm = async (data, setStatus, status, authTokens) => {
    let responsed = await DataSubmission(
        String(authTokens.access),
        'post',
        '/complete-biu-report/',
        { data: data }
    )

    if (responsed[0].result === 'success') {
        setStatus(!status);
    } else {

    }
}
//*****************************************************Company Branches******************************************
export const CompanyBranchesTable = ({ setRun, finsNumber, report_id, log }) => {
    let { authTokens, logoutUser } = useContext(AuthContext);
    const [branchData, setBranchData] = useState([])
    const [status, setStatus] = useState('finished')
    const [branchName, setBranchName] = useState('')
    const [phoneNumber, setPhoneNumber] = useState('')
    const [address, setAddress] = useState('')
    const [buttonStatus, setButtonStatus] = useState(log)
    useEffect(() => {
        axios({
            method: 'post',
            url: '/company-branch/',
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: "Bearer " + String(authTokens.access)
            },
            data: { data: { fins: finsNumber } },
        })
            .then((res) => {
                setBranchData(res.data)
                setRun('finished')
            }).catch((AxiosError) => {
                if (AxiosError.response.data) {
                    if (AxiosError.response.data.includes('Proxy error')) {
                        toast.error('Network error. Check network connection!')
                    }
                } else (
                    toast.error('Internal system error contact system admin!')
                )
                setRun('error')

            }
            )
    }, [status])
    RemoveNull(branchData)

    const BranchDelete = async (id) => {
        if (window.confirm('Please confirm to delete !')) {
            setStatus('loading')
            await DataSubmission(
                String(authTokens.access),
                'DELETE',
                '/branch/' + id,
            )
            setStatus('finished')
        } else {
            setStatus('finished')
        }
    }

    const AddBranch = async (action) => {
        if (branchName === '') {
            toast.warning('Please provide the name of the branch.')
        } else {
            setStatus('loading')
            let responsed = await DataSubmission(
                String(authTokens.access),
                'post',
                '/branch/',
                {
                    address: address,
                    master_company: finsNumber,
                    branch_name: branchName,
                    phone_number: phoneNumber
                }
            )

            if (action === 'add' && responsed[0].result === 'success') {
                setStatus('adding');
                setBranchName('')
                setPhoneNumber('')
                setAddress('')
            } else {
                setStatus('finished')
            }

        }
    }

    return (
        <div className='tablediv'>
            <h2 className='tableDivHeaderBIU'>Branches</h2>
            <div className='Biutablecontainer'>
                {status === 'loading' ?
                    <div className='loadingdiv'>
                        <CircularProgress className='loadingBIUtables' />
                    </div>
                    :
                    <table className='tableBIUedit' >
                        <thead className='tableHead'>
                            <tr>
                                <th colSpan={3}> {branchData.length} Records Returned </th>
                                <th colSpan={2} onClick={() => {
                                    setPhoneNumber(''); setAddress(''); setBranchName(''); setStatus('adding');
                                }} className='thaddbuttonbiu'><AddIcon /></th>
                            </tr>
                            <tr>
                                <th>Branch Name</th>
                                <th>Phone Number</th>
                                <th>Address</th>
                                <th colSpan={2}></th>
                            </tr>
                        </thead>
                        <tbody className='tablebody'>
                            <tr className={(status === 'adding' || branchData.length === 0) ?
                                'tablerow'
                                :
                                'tablerowinvisible'}>
                                <td className='tabledata'>
                                    <InputContainerTable
                                        currentValue={branchName}
                                        setValue={setBranchName}
                                        id={'branchName'}
                                    />
                                </td>

                                <td className='tabledata'>
                                    <InputContainerTable
                                        currentValue={phoneNumber}
                                        setValue={setPhoneNumber}
                                        id={'phoneNumber'}
                                    />
                                </td>

                                <td className='tabledata'>
                                    <InputContainerTable
                                        currentValue={address}
                                        setValue={setAddress}
                                        id={'address'}
                                    />
                                </td>
                                <td className='tabledata'>
                                    <SaveIcon className='editicons' onClick={() => { AddBranch('done') }} />
                                </td>

                                <td className='tabledata'>
                                    <AddIcon className='editicons' onClick={() => { AddBranch('add') }} />
                                </td>
                            </tr>
                            {branchData.map((item) => {
                                return (
                                    <BranchRow
                                        item={item}
                                        BranchDelete={BranchDelete}
                                        setStatus={setStatus}
                                    />
                                )
                            })}
                        </tbody>
                    </table>
                }

            </div>
            {!buttonStatus ?
                <button className='BiuConfirmButton'
                    onClick={
                        () => Confirm(
                            { id: report_id, finished_item: 'branch' },
                            setButtonStatus,
                            buttonStatus,
                            authTokens
                        )}
                >
                    <SaveIcon className='ConfirmBiuicon' />
                    Confirm
                </button>
                :
                <button className='BiuConfirmedButton'
                    onClick={
                        () => Confirm(
                            { id: report_id, finished_item: 'branch' },
                            setButtonStatus,
                            buttonStatus,
                            authTokens
                        )}
                >
                    <CheckIcon className='ConfirmBiuicon' />
                    Confirmed
                </button>
            }xs

        </div>
    )
}

const BranchRow = ({ item, BranchDelete, setStatus }) => {
    const { authTokens, logoutUser } = useContext(AuthContext);
    const [edit, setEdit] = useState(false);
    const [branchName, setBranchName] = useState(item.branch_name)
    const [phoneNumber, setPhoneNumber] = useState(item.phone_number)
    const [address, setAddress] = useState(item.address)

    const EditBranch = async () => {
        setStatus('loading')
        let responsed = await DataSubmission(
            String(authTokens.access),
            'put',
            '/branch/' + item.branch_id + '/',
            {
                branch_id: item.branch_id,
                address: address,
                master_company: item.master_company,
                branch_name: branchName,
                phone_number: phoneNumber
            })

        if (responsed[0].result === "success") {
        }
        setStatus('finished')
    }
    return (
        <>
            {
                edit ?
                    <tr key={item.assoc_id} className='tablerow'>
                        <td className='tabledata'>
                            <InputContainerTable
                                currentValue={branchName}
                                setValue={setBranchName}
                                id={'branchName'}
                            />
                        </td>

                        <td className='tabledata'>
                            <InputContainerTable
                                currentValue={phoneNumber}
                                setValue={setPhoneNumber}
                                id={'phoneNumber'}
                            />
                        </td>

                        <td className='tabledata'>
                            <InputContainerTable
                                currentValue={address}
                                setValue={setAddress}
                                id={'address'}
                            />
                        </td>
                        <td className='tabledata'>
                            <SaveIcon className='editicons' onClick={() => EditBranch()} />
                        </td>
                        <td className='tabledata'>
                            <CloseIcon className='editicons' onClick={() => setEdit(!edit)} />
                        </td>
                    </tr>
                    :
                    <tr key={item.division_id} className='tablerow'>
                        <td className='tabledata'>{item.branch_name}</td>
                        <td className='tabledata'>{item.phone_number}</td>
                        <td className='tabledata'>{item.address}</td>
                        <td className='tabledata'>
                            <EditIcon className='editicons' onClick={() => setEdit(!edit)} />
                        </td>
                        <td className='tabledata'>
                            <DeleteIcon className='editicons' onClick={() => BranchDelete(item.branch_id)} />
                        </td>
                    </tr>
            }
        </>

    )
}

//*********************************************Asscociated Companies**********************************************
export const AssocTables = ({ setRun, finsNumber, report_id, log }) => {
    let { authTokens, logoutUser } = useContext(AuthContext);
    const [assocdata, setAssocdata] = useState([])
    const [companyName, setCompanyName] = useState([])
    const [status, setStatus] = useState('finished')
    const [buttonStatus, setButtonStatus] = useState(log)

    useEffect(() => {
        setAssocdata([]);

        axios({
            method: 'post',
            url: '/company-associated-company/',
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: "Bearer " + String(authTokens.access)
            },
            data: { data: { fins: finsNumber } },
        })
            .then((res) => {
                setAssocdata(res.data)
            }).catch((AxiosError) => {

                setRun('error')
                if (AxiosError.code === "ERR_NETWORK") {
                    toast.error("Network Error!")
                } else (
                    toast.error('Internal system error contact system admin!')
                )
            }
            )
    }, [status,])

    RemoveNull(assocdata)

    const AddAssoc = async (companyName, action) => {
        if (companyName === '') {
            toast.warning('Please provide the name of the company.')
        } else {
            setStatus('loading')
            let responsed = await DataSubmission(
                String(authTokens.access),
                "post",
                "/associated-company/",
                {
                    company_name: companyName,
                    assoc_with: finsNumber,
                }
            );

            if (action === "done" && responsed[0].result === "success") {

                setStatus('finished')
            }
            else if (action === "add" && responsed[0].result === "success") {
                setStatus('adding')
                setCompanyName('')

            }
        }
    }

    const AssocDelete = async (id) => {
        setStatus('loading')
        if (window.confirm('Please confirm to delete !')) {
            await DataSubmission(
                String(authTokens.access),
                'DELETE',
                '/associated-company/' + id,
            )
            setStatus('finished')
            setAssocdata([])
        } else {
            setStatus('finished')
        }
    }

    return (
        <div className='tablediv'>
            <h2 className='tableDivHeaderBIU'>Associated Companies</h2>
            <div className='Biutablecontainer'>
                {status === 'loading' ?
                    <div className='loadingdiv'>
                        <CircularProgress className='loadingBIUtables' />
                    </div>
                    :
                    <table className='tableBIUedit'>
                        <thead className='tableHead'>
                            <tr>
                                <th colSpan={2}>{assocdata.length} Records Returned</th>
                                <th onClick={() => setStatus('adding')} className='thaddbuttonbiu'><AddIcon /></th>
                            </tr>
                            <tr>
                                <th>Company Name</th>
                                <th colSpan={2}></th>
                            </tr>
                        </thead>
                        <tbody className='tablebody'>
                            <tr className={status === 'adding' ?
                                'tablerow'
                                :
                                'tablerowinvisible'}>
                                <td className='tabledata'>
                                    <InputContainerTable
                                        currentValue={companyName}
                                        setValue={setCompanyName}
                                        id={'companyName'}
                                    />
                                </td>
                                <td className='tabledata'>
                                    <SaveIcon className='editicons' onClick={() => { AddAssoc(companyName, 'done') }} />
                                </td>

                                <td className='tabledata'>
                                    <AddIcon className='editicons' onClick={() => { AddAssoc(companyName, 'add') }} />
                                </td>
                            </tr>
                            {assocdata.map((item) => {
                                return (
                                    <AssocRow
                                        setStatus={setStatus}
                                        item={item}
                                        AssocDelete={AssocDelete}
                                    />
                                )
                            })}
                        </tbody>
                    </table>
                }
            </div>
            {!buttonStatus ?
                <button className='BiuConfirmButton'
                    onClick={
                        () => Confirm(
                            { id: report_id, finished_item: 'associated_company' },
                            setButtonStatus,
                            buttonStatus,
                            authTokens
                        )}
                >
                    <SaveIcon className='ConfirmBiuicon' />
                    Confirm
                </button>
                :
                <button className='BiuConfirmedButton'
                    onClick={
                        () => Confirm(
                            { id: report_id, finished_item: 'associated_company' },
                            setButtonStatus,
                            buttonStatus,
                            authTokens
                        )}
                >
                    <CheckIcon className='ConfirmBiuicon' />
                    Confirmed
                </button>
            }
        </div>
    )
}

const AssocRow = ({ item, AssocDelete, setStatus }) => {
    const { authTokens, logoutUser } = useContext(AuthContext);
    const [edit, setEdit] = useState(false);
    const [assocName, setAssocName] = useState(item.company_name)

    const EditAssocCompany = async () => {
        setStatus('loading')
        let responsed = await DataSubmission(
            String(authTokens.access),
            "put",
            "/associated-company/" + item.assoc_id + '/',
            {
                assoc_id: item.assoc_id,
                company_name: assocName,
                assoc_with: item.assoc_with,
            }
        )

        if (responsed[0].result === "success") {
        }
        setStatus('finished')
    }
    return (
        <>
            {
                edit ?
                    <tr key={item.assoc_id} className='tablerow'>
                        <td className='tabledata'>
                            <InputContainerTable
                                currentValue={assocName}
                                setValue={setAssocName}
                                id={'companyName'}
                            />
                        </td>
                        <td className='tabledata'>
                            <SaveIcon className='editicons' onClick={() => EditAssocCompany()} />
                        </td>
                        <td className='tabledata'>
                            <CloseIcon className='editicons' onClick={() => setEdit(!edit)} />
                        </td>
                    </tr>
                    :
                    <tr key={item.assoc_id} className='tablerow'>
                        <td className='tabledata'>{item.company_name}</td>
                        <td className='tabledata'>
                            <EditIcon className='editicons' onClick={() => setEdit(!edit)} />
                        </td>
                        <td className='tabledata'>
                            <DeleteIcon className='editicons' onClick={() => AssocDelete(item.assoc_id)} />
                        </td>
                    </tr>
            }
        </>

    )
}

{/* ______________________________Company Company Executives_____________________________________________________________ */ }
export const CompanyExecutivesTable = ({ setRun, finsNumber, report_id, log }) => {
    let { authTokens, logoutUser } = useContext(AuthContext);
    const [recordData, setRecordData] = useState([])
    const [status, setStatus] = useState('finished')
    const [displaySearch, setDisplaySearch] = useState(false)
    const [name, setName] = useState('')
    const [id, setId] = useState('')
    const [executiveFins, setExecutiveFins] = useState('')
    const [post, setPost] = useState('')
    const [buttonStatus, setButtonStatus] = useState(log)


    useEffect(() => {
        axios({
            method: 'post',
            url: '/company-executive/',
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: "Bearer " + String(authTokens.access)
            },
            data: { data: { fins: finsNumber } },
        })
            .then((res) => {
                setRecordData(res.data)
            }).catch((AxiosError) => {
                setRun('error')
                if (AxiosError.code === "ERR_NETWORK") {
                    toast.error("Network Error!")
                } else (
                    toast.error('Internal system error contact system admin!')
                )
            }
            )
    }, [status,])

    RemoveNull(recordData)

    const AddExecutive = async (action) => {
        if (executiveFins === '') {
            toast.warning('Please select the individual before adding.')
        } else if (post === '') {
            toast.warning('Please fill in the post before adding.')
        } else {
            setStatus('loading')
            let responsed = await DataSubmission(
                String(authTokens.access),
                "post",
                "/company-executives/",
                {
                    individual: executiveFins,
                    post: post,
                    company: finsNumber,
                }
            );

            if (action === "done" && responsed[0].result === "success") {
                setStatus('finished')
                setExecutiveFins(''); setPost(''); setId(''); setName('')
            }
            else if (action === "add" && responsed[0].result === "success") {
                setStatus('adding')
                setExecutiveFins(''); setPost(''); setId(''); setName('')
            } else {
                setStatus('adding')
            }
        }
    }

    const RecordDelete = async (id) => {
        if (window.confirm('Please confirm to delete !')) {
            setStatus('loading')
            await DataSubmission(
                String(authTokens.access),
                'DELETE',
                '/company-executives/' + id,
            )
            setStatus('finished')
        } else {
            setStatus('finished')
        }
    }


    return (
        <div className='tablediv'>
            <SearchModal
                setname={setName}
                searching={'Individual'}
                setid={setId}
                setDisplay={setDisplaySearch}
                setFins={setExecutiveFins}
                display={displaySearch}
            />
            <h2 className='tableDivHeaderBIU'> Board of Directors </h2>
            <div className='Biutablecontainer'>
                {status === 'loading' ?
                    <div className='loadingdiv'>
                        <CircularProgress className='loadingBIUtables' />
                    </div>
                    :
                    <table className='tableBIUedit'>
                        <thead className='tableHead'>
                            <tr>
                                <th colSpan={3}>{recordData.length} Records Returned</th>
                                <th colSpan={2} onClick={() => { setStatus('adding'); setDisplaySearch(!displaySearch) }} className='thaddbuttonbiu'><AddIcon /></th>
                            </tr>
                            <tr>
                                <th>Id Number</th>
                                <th>Name</th>
                                <th>Post</th>
                                <th colSpan={2}></th>
                            </tr>
                        </thead>
                        <tbody className='tablebody'>
                            <tr className={status === 'adding' && executiveFins !== '' ?
                                'tablerow'
                                :
                                'tablerowinvisible'}>
                                <td className='tabledata'>
                                    <InputContainerTableReadOnly
                                        currentValue={id}
                                        setValue={setId}
                                        id={'id'}
                                    />
                                </td>
                                <td className='tabledata'>
                                    <InputContainerTableReadOnly
                                        currentValue={name}
                                        setValue={setName}
                                        id={'name'}
                                    />
                                </td>
                                <td className='tabledata'>
                                    <InputContainerTable
                                        currentValue={post}
                                        setValue={setPost}
                                        id={'post'}
                                    />
                                </td>
                                <td className='tabledata'>
                                    <SaveIcon className='editicons' onClick={() => { AddExecutive('done') }} />
                                </td>

                                <td className='tabledata'>
                                    <AddIcon className='editicons' onClick={() => { AddExecutive('add') }} />
                                </td>
                            </tr>
                            {recordData.map((item) => {
                                return (
                                    <ExecutiveRow
                                        item={item}
                                        RecordDelete={RecordDelete}
                                        setStatus={setStatus}
                                    />
                                )
                            })}
                        </tbody>
                    </table>
                }
            </div>

            {!buttonStatus ?
                <button className='BiuConfirmButton'
                    onClick={
                        () => Confirm(
                            { id: report_id, finished_item: 'company_executive' },
                            setButtonStatus,
                            buttonStatus,
                            authTokens
                        )}
                >
                    <SaveIcon className='ConfirmBiuicon' />
                    Confirm
                </button>
                :
                <button className='BiuConfirmedButton'
                    onClick={
                        () => Confirm(
                            { id: report_id, finished_item: 'company_executive' },
                            setButtonStatus,
                            buttonStatus,
                            authTokens
                        )}
                >
                    <CheckIcon className='ConfirmBiuicon' />
                    Confirmed
                </button>
            }
        </div >
    )
}

const ExecutiveRow = ({ item, RecordDelete, setStatus }) => {
    const { authTokens, logoutUser } = useContext(AuthContext);
    const [edit, setEdit] = useState(false);
    const [post, setPost] = useState(item.post)

    const EditExecutive = async () => {
        setStatus('loading')
        let responsed = await DataSubmission(
            String(authTokens.access),
            'put',
            '/company-executives/' + item.id + '/',
            {
                subsidiary_id: item.id,
                post: post,
                company: item.company.fins_number
            }
        )

        if (responsed[0].result === "success") {
        }
        setStatus('finished')
    }
    return (
        <>
            {
                edit ?
                    <tr key={item.assoc_id} className='tablerow'>
                        <td className='tabledata'>
                            <InputContainerTableReadOnly
                                currentValue={item.individual.national_id}
                                id={'id'}
                            />
                        </td>
                        <td className='tabledata'>
                            <InputContainerTableReadOnly
                                currentValue={item.individual.firstname + ' ' + item.individual.surname}
                                id={'name'}
                            />
                        </td>
                        <td className='tabledata'>
                            <InputContainerTable
                                currentValue={post}
                                setValue={setPost}
                                id={'post'}
                            />
                        </td>
                        <td className='tabledata'>
                            <SaveIcon className='editicons' onClick={() => EditExecutive()} />
                        </td>
                        <td className='tabledata'>
                            <CloseIcon className='editicons' onClick={() => setEdit(!edit)} />
                        </td>
                    </tr>
                    :
                    <tr key={item.id} className='tablerow'>
                        <td className='tabledata'> {item.individual.national_id}</td>
                        <td className='tabledata'> {item.individual.firstname} {item.individual.surname}</td>
                        <td className='tabledata'> {item.post}</td>
                        <td className='tabledata'>
                            <EditIcon className='editicons' onClick={() => setEdit(!edit)} />
                        </td>
                        <td className='tabledata'>
                            <DeleteIcon className='editicons' onClick={() => RecordDelete(item.id)} />
                        </td>
                    </tr>
            }
        </>

    )
}

{/* ______________________________Company CompanyDivisions_____________________________________________________________ */ }
export const CompanySubsidiaryTable = ({ setRun, finsNumber, report_id, log }) => {
    let { authTokens, logoutUser } = useContext(AuthContext);
    const [recordData, setRecordData] = useState([])
    const [status, setStatus] = useState('finished')
    const [subsidiaryName, setSubsidiaryName] = useState('')
    const [buttonStatus, setButtonStatus] = useState(log)


    useEffect(() => {
        axios({
            method: 'post',
            url: '/company-subsidiary-company/',
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: "Bearer " + String(authTokens.access)
            },
            data: { data: { fins: finsNumber } },
        })
            .then((res) => {
                setRecordData(res.data)
                setRun('finished')
            }).catch((AxiosError) => {
                setRun('error')
                if (AxiosError.code === "ERR_NETWORK") {
                    toast.error("Network Error!")
                } else (
                    toast.error('Internal system error contact system admin!')
                )
            }
            )
    }, [status,])

    RemoveNull(recordData)

    const AddSubsidiary = async (companyName, action) => {
        if (companyName === '') {
            toast.warning('Please provide the name of the subsidiary name.')
        } else {
            setStatus('loading')
            let responsed = await DataSubmission(
                String(authTokens.access),
                'post',
                '/subsidiary-company/',
                {
                    company_name: subsidiaryName,
                    parent_company: finsNumber
                }
            );

            if (action === "done" && responsed[0].result === "success") {
                setStatus('finished')
                setSubsidiaryName('')
            }
            else if (action === "add" && responsed[0].result === "success") {
                setStatus('adding')
                setSubsidiaryName('')
            } else {
                setStatus('adding')
            }
        }
    }

    const RecordDelete = async (id) => {
        if (window.confirm('Please confirm to delete !')) {
            setStatus('loading')
            await DataSubmission(
                String(authTokens.access),
                'DELETE',
                '/subsidiary-company/' + id,
            )
            setStatus('finished')
        } else {
            setStatus('finished')
        }
    }


    return (
        <div className='tablediv'>
            <h2 className='tableDivHeaderBIU'>Subsidiaries</h2>
            <div className='Biutablecontainer'>
                {status === 'loading' ?
                    <div className='loadingdiv'>
                        <CircularProgress className='loadingBIUtables' />
                    </div>
                    :
                    <table className='tableBIUedit'>
                        <thead className='tableHead'>
                            <tr>
                                <th colSpan={2}>{recordData.length} Records Returned</th>
                                <th onClick={() => setStatus('adding')} className='thaddbuttonbiu'><AddIcon /></th>
                            </tr>
                            <tr>
                                <th>Subsidiary</th>
                                <th colSpan={2}></th>
                            </tr>
                        </thead>
                        <tbody className='tablebody'>
                            <tr className={status === 'adding' ?
                                'tablerow'
                                :
                                'tablerowinvisible'}>
                                <td className='tabledata'>
                                    <InputContainerTable
                                        currentValue={subsidiaryName}
                                        setValue={setSubsidiaryName}
                                        id={'subsidiaryName'}
                                    />
                                </td>
                                <td className='tabledata'>
                                    <SaveIcon className='editicons' onClick={() => { AddSubsidiary(subsidiaryName, 'done') }} />
                                </td>

                                <td className='tabledata'>
                                    <AddIcon className='editicons' onClick={() => { AddSubsidiary(subsidiaryName, 'add') }} />
                                </td>
                            </tr>
                            {recordData.map((item) => {
                                return (
                                    <SubsidiaryRow
                                        item={item}
                                        RecordDelete={RecordDelete}
                                        setStatus={setStatus}
                                    />
                                )
                            })}
                        </tbody>
                    </table>
                }

            </div>

            {!buttonStatus ?
                <button className='BiuConfirmButton'
                    onClick={
                        () => Confirm(
                            { id: report_id, finished_item: 'subsiduary_company' },
                            setButtonStatus,
                            buttonStatus,
                            authTokens
                        )}
                >
                    <SaveIcon className='ConfirmBiuicon' />
                    Confirm
                </button>
                :
                <button className='BiuConfirmedButton'
                    onClick={
                        () => Confirm(
                            { id: report_id, finished_item: 'subsiduary_company' },
                            setButtonStatus,
                            buttonStatus,
                            authTokens
                        )}
                >
                    <CheckIcon className='ConfirmBiuicon' />
                    Confirmed
                </button>
            }
        </div>
    )
}

const SubsidiaryRow = ({ item, RecordDelete, setStatus }) => {
    const { authTokens, logoutUser } = useContext(AuthContext);
    const [edit, setEdit] = useState(false);
    const [subsidiaryName, setSubsidiaryName] = useState(item.company_name)

    const EditSubsidiary = async () => {
        setStatus('loading')
        let responsed = await DataSubmission(
            String(authTokens.access),
            'put',
            '/subsidiary-company/' + item.subsidiary_id + '/',
            {
                subsidiary_id: item.subsidiary_id,
                company_name: subsidiaryName,
                parent_company: item.parent_company
            }
        )

        if (responsed[0].result === "success") {
        }
        setStatus('finished')
    }
    return (
        <>
            {
                edit ?
                    <tr key={item.assoc_id} className='tablerow'>
                        <td className='tabledata'>
                            <InputContainerTable
                                currentValue={subsidiaryName}
                                setValue={setSubsidiaryName}
                                id={'subsidiaryName'}
                            />
                        </td>
                        <td className='tabledata'>
                            <SaveIcon className='editicons' onClick={() => EditSubsidiary()} />
                        </td>
                        <td className='tabledata'>
                            <CloseIcon className='editicons' onClick={() => setEdit(!edit)} />
                        </td>
                    </tr>
                    :
                    <tr key={item.subsidiary_id} className='tablerow'>
                        <td className='tabledata'>{item.company_name}</td>
                        <td className='tabledata'>
                            <EditIcon className='editicons' onClick={() => setEdit(!edit)} />
                        </td>
                        <td className='tabledata'>
                            <DeleteIcon className='editicons' onClick={() => RecordDelete(item.subsidiary_id)} />
                        </td>
                    </tr>
            }
        </>

    )
}

{/* ______________________________Company CompanyShareholders_____________________________________________________________ */ }
export const CompanyShareholdersTable = ({ setRun, finsNumber, report_id, log }) => {
    let { authTokens, logoutUser } = useContext(AuthContext);
    const [recordData, setRecordData] = useState([])
    const [status, setStatus] = useState('finished')
    const [name, setName] = useState('')
    const [percentage, setPercentage] = useState('')
    const [dateAcquired, setDateAcquired] = useState('')
    const [buttonStatus, setButtonStatus] = useState(log)


    useEffect(() => {
        axios({
            method: 'post',
            url: '/company-shareholders/',
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: "Bearer " + String(authTokens.access)
            },
            data: { data: { fins: finsNumber } },
        })
            .then((res) => {
                setRecordData(res.data)
                setRun('finished')
            }).catch((AxiosError) => {
                setRun('error')
                if (AxiosError.code === "ERR_NETWORK") {
                    toast.error("Network Error!")
                } else (
                    toast.error('Internal system error contact system admin!')
                )
            }
            )
    }, [status,])

    RemoveNull(recordData)

    const AddShareholder = async (action) => {
        if (name === '' || percentage === '') {
            toast.warning('Please provide the name of the shareholder and their share percentage.')
        } else {
            setStatus('loading')
            let responsed = await DataSubmission(
                String(authTokens.access),
                'post',
                '/shareholder/',
                {
                    name: name,
                    company: finsNumber,
                    percentage: percentage,
                    date_acquired: dateAcquired
                }
            );

            if (action === "done" && responsed[0].result === "success") {
                setStatus('finished')
                setDateAcquired(''); setName(''); setPercentage('')
            }
            else if (action === "add" && responsed[0].result === "success") {
                setStatus('adding')
                setDateAcquired(''); setName(''); setPercentage('')
            } else {
                setStatus('adding')
            }
        }
    }

    const RecordDelete = async (id) => {
        if (window.confirm('Please confirm to delete !')) {
            setStatus('loading')
            await DataSubmission(
                String(authTokens.access),
                'DELETE',
                '/shareholder/' + id + '/',
            )
            setStatus('finished')
        } else {
            setStatus('finished')
        }
    }

    return (
        <div className='tablediv'>
            <h2 className='tableDivHeaderBIU'>Shareholders</h2>
            <div className='Biutablecontainer'>
                {status === 'loading' ?
                    <div className='loadingdiv'>
                        <CircularProgress className='loadingBIUtables' />
                    </div>
                    :
                    <table className='tableBIUedit'>
                        <thead className='tableHead'>
                            <tr>
                                <th colSpan={3}>{recordData.length} Records Returned</th>
                                <th colSpan={2} onClick={() => setStatus('adding')} className='thaddbuttonbiu'><AddIcon /></th>
                            </tr>
                            <tr>
                                <th>Name</th>
                                <th>Percentage</th>
                                <th>Date Acquired</th>
                                <th colSpan={2}></th>
                            </tr>
                        </thead>
                        <tbody className='tablebody'>
                            <tr className={status === 'adding' ?
                                'tablerow'
                                :
                                'tablerowinvisible'}>
                                <td className='tabledata'>
                                    <InputContainerTable
                                        currentValue={name}
                                        setValue={setName}
                                        id={'name'}
                                    />
                                </td>
                                <td className='tabledata'>
                                    <InputContainerTable
                                        currentValue={percentage}
                                        setValue={setPercentage}
                                        type={'number'}
                                        id={'percentage'}
                                    />
                                </td>

                                <td className='tabledata'>
                                    <InputContainerTable
                                        currentValue={dateAcquired}
                                        setValue={setDateAcquired}
                                        type={'month'}
                                        id={'dateAcquired'}
                                    />
                                </td>
                                <td className='tabledata'>
                                    <SaveIcon className='editicons' onClick={() => { AddShareholder('done') }} />
                                </td>

                                <td className='tabledata'>
                                    <AddIcon className='editicons' onClick={() => { AddShareholder('add') }} />
                                </td>
                            </tr>
                            {recordData.map((item) => {
                                return (
                                    <ShareholderRow
                                        item={item}
                                        RecordDelete={RecordDelete}
                                        setStatus={setStatus}
                                    />
                                )
                            })}
                        </tbody>
                    </table>
                }
            </div>

            {!buttonStatus ?
                <button className='BiuConfirmButton'
                    onClick={
                        () => Confirm(
                            { id: report_id, finished_item: 'shareholder' },
                            setButtonStatus,
                            buttonStatus,
                            authTokens
                        )}
                >
                    <SaveIcon className='ConfirmBiuicon' />
                    Confirm
                </button>
                :
                <button className='BiuConfirmedButton'
                    onClick={
                        () => Confirm(
                            { id: report_id, finished_item: 'shareholder' },
                            setButtonStatus,
                            buttonStatus,
                            authTokens
                        )}
                >
                    <CheckIcon className='ConfirmBiuicon' />
                    Confirmed
                </button>
            }
        </div>
    )
}

const ShareholderRow = ({ item, RecordDelete, setStatus }) => {
    const { authTokens, logoutUser } = useContext(AuthContext);
    const [edit, setEdit] = useState(false);
    const [name, setName] = useState(item.name)
    const [percentage, setPercentage] = useState(item.percentage)
    const [dateAcquired, setDateAcquired] = useState(item.date_acquired)

    const EditShareholder = async () => {
        setStatus('loading')
        let responsed = await DataSubmission(
            String(authTokens.access),
            'put',
            '/shareholder/' + item.id + '/',
            {
                id: item.id,
                name: name,
                company: item.company,
                percentage: percentage,
                date_acquired: dateAcquired
            }
        )

        if (responsed[0].result === "success") {
        }
        setStatus('finished')
    }
    return (
        <>
            {
                edit ?
                    <tr key={item.assoc_id} className='tablerow'>
                        <td className='tabledata'>
                            <InputContainerTable
                                currentValue={name}
                                setValue={setName}
                                id={'name'}
                            />
                        </td>
                        <td className='tabledata'>
                            <InputContainerTable
                                currentValue={percentage}
                                setValue={setPercentage}
                                type={'number'}
                                id={'percentage'}
                            />
                        </td>

                        <td className='tabledata'>
                            <InputContainerTable
                                currentValue={dateAcquired}
                                setValue={setDateAcquired}
                                type={'month'}
                                id={'dateAcquired'}
                            />
                        </td>
                        <td className='tabledata'>
                            <SaveIcon className='editicons' onClick={() => EditShareholder()} />
                        </td>
                        <td className='tabledata'>
                            <CloseIcon className='editicons' onClick={() => setEdit(!edit)} />
                        </td>
                    </tr>
                    :
                    <tr key={item.id} className='tablerow'>
                        <td className='tabledata'>{item.name}</td>
                        <td className='tabledata'>{item.percentage}</td>
                        <td className='tabledata'>{item.date_acquired}</td>
                        <td className='tabledata'>
                            <EditIcon className='editicons' onClick={() => setEdit(!edit)} />
                        </td>
                        <td className='tabledata'>
                            <DeleteIcon className='editicons' onClick={() => RecordDelete(item.id)} />
                        </td>
                    </tr>
            }
        </>

    )
}

{/* ______________________________Company CompanyDivisions_____________________________________________________________ */ }
export const CompanyDivisionTable = ({ setRun, finsNumber, report_id, log }) => {
    let { authTokens, logoutUser } = useContext(AuthContext);
    const [recordData, setRecordData] = useState([])
    const [status, setStatus] = useState('finished')
    const [divisionName, setDivisionName] = useState('')
    const [buttonStatus, setButtonStatus] = useState(log)

    useEffect(() => {
        axios({
            method: 'post',
            url: '/company-division/',
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: "Bearer " + String(authTokens.access)
            },
            data: { data: { fins: finsNumber } },
        })
            .then((res) => {
                setRecordData(res.data)
            }).catch((AxiosError) => {
                setRun('error')
                if (AxiosError.code === "ERR_NETWORK") {
                    toast.error("Network Error!")
                } else (
                    toast.error('Internal system error contact system admin!')
                )
            }
            )
    }, [status,])

    RemoveNull(recordData)

    const AddDivision = async (companyName, action) => {
        if (companyName === '') {
            toast.warning('Please provide the name of the division.')
        } else {
            setStatus('loading')
            let responsed = await DataSubmission(
                String(authTokens.access),
                "post",
                "/division/",
                {
                    division: divisionName,
                    company: finsNumber,
                }
            );

            if (action === "done" && responsed[0].result === "success") {
                setStatus('finished')
                setDivisionName('')
            }
            else if (action === "add" && responsed[0].result === "success") {
                setStatus('adding')
                setDivisionName('')
            }
        }
    }

    const RecordDelete = async (id) => {
        if (window.confirm('Please confirm to delete !')) {
            setStatus('loading')
            await DataSubmission(
                String(authTokens.access),
                'DELETE',
                '/division/' + id,
            )
            setStatus('finished')
        } else {
            setStatus('finished')
        }
    }

    return (
        <div className='tablediv'>
            <h2 className='tableDivHeaderBIU'>Divisions</h2>
            <div className='Biutablecontainer'>
                {status === 'loading' ?
                    <div className='loadingdiv'>
                        <CircularProgress className='loadingBIUtables' />
                    </div>
                    :
                    <table className='tableBIUedit'>
                        <thead className='tableHead'>
                            <tr>
                                <th colSpan={2}> {recordData.length} Records Returned </th>
                                <th onClick={() => setStatus('adding')} className='thaddbuttonbiu'><AddIcon /></th>
                            </tr>
                            <tr>
                                <th>Division Name</th>
                                <th colSpan={2}></th>
                            </tr>
                        </thead>
                        <tbody className='tablebody'>
                            <tr className={status === 'adding' ?
                                'tablerow'
                                :
                                'tablerowinvisible'}>
                                <td className='tabledata'>
                                    <InputContainerTable
                                        currentValue={divisionName}
                                        setValue={setDivisionName}
                                        id={'companyName'}
                                    />
                                </td>
                                <td className='tabledata'>
                                    <SaveIcon className='editicons' onClick={() => { AddDivision(divisionName, 'done') }} />
                                </td>

                                <td className='tabledata'>
                                    <AddIcon className='editicons' onClick={() => { AddDivision(divisionName, 'add') }} />
                                </td>
                            </tr>
                            {recordData.map((item) => {
                                return (
                                    <DivisionRow
                                        item={item}
                                        RecordDelete={RecordDelete}
                                        setStatus={setStatus} />
                                )
                            })}
                        </tbody>
                    </table>
                }
            </div>
            {!buttonStatus ?
                <button className='BiuConfirmButton'
                    onClick={
                        () => Confirm(
                            { id: report_id, finished_item: 'division' },
                            setButtonStatus,
                            buttonStatus,
                            authTokens
                        )}
                >
                    <SaveIcon className='ConfirmBiuicon' />
                    Confirm
                </button>
                :
                <button className='BiuConfirmedButton'
                    onClick={
                        () => Confirm(
                            { id: report_id, finished_item: 'division' },
                            setButtonStatus,
                            buttonStatus,
                            authTokens
                        )}
                >
                    <CheckIcon className='ConfirmBiuicon' />
                    Confirmed
                </button>
            }
        </div>
    )
}

const DivisionRow = ({ item, RecordDelete, setStatus }) => {
    const { authTokens, logoutUser } = useContext(AuthContext);
    const [edit, setEdit] = useState(false);
    const [divisionName, setDivisionName] = useState(item.division)

    const EditDivision = async () => {
        setStatus('loading')
        let responsed = await DataSubmission(
            String(authTokens.access),
            "put",
            "/division/" + item.division_id + '/',
            {
                division_id: item.division_id,
                division: divisionName,
                company: item.company,
            }
        )

        if (responsed[0].result === "success") {
        }
        setStatus('finished')
    }
    return (
        <>
            {
                edit ?
                    <tr key={item.assoc_id} className='tablerow'>
                        <td className='tabledata'>
                            <InputContainerTable
                                currentValue={divisionName}
                                setValue={setDivisionName}
                                id={'divisionName'}
                            />
                        </td>
                        <td className='tabledata'>
                            <SaveIcon className='editicons' onClick={() => EditDivision()} />
                        </td>
                        <td className='tabledata'>
                            <CloseIcon className='editicons' onClick={() => setEdit(!edit)} />
                        </td>
                    </tr>
                    :
                    <tr key={item.division_id} className='tablerow'>
                        <td className='tabledata'>{item.division}</td>
                        <td className='tabledata'>
                            <EditIcon className='editicons' onClick={() => setEdit(!edit)} />
                        </td>
                        <td className='tabledata'>
                            <DeleteIcon className='editicons' onClick={() => RecordDelete(item.division_id)} />
                        </td>
                    </tr>
            }
        </>

    )
}
//*********************************************Auditors***************************************************************
export const AuditorsTable = ({ setRun, finsNumber, report_id, log }) => {
    let { authTokens, logoutUser } = useContext(AuthContext);
    const [auditorsData, setAuditorsData] = useState([])
    const [status, setStatus] = useState('finished')
    const [name, setName] = useState('')
    const [buttonStatus, setButtonStatus] = useState(log)


    useEffect(() => {
        axios({
            method: 'post',
            url: '/company-auditor/',
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: "Bearer " + String(authTokens.access)
            },
            data: { data: { fins: finsNumber } },
        })
            .then((res) => {
                setAuditorsData(res.data)
                setRun('finished')
            }).catch((AxiosError) => {
                setRun('error')
                if (AxiosError.code === "ERR_NETWORK") {
                    toast.error("Network Error!")
                } else (
                    toast.error('Internal system error contact system admin!')
                )
            }
            )
    }, [status,])

    RemoveNull(auditorsData)

    const AddAuditor = async (action) => {
        if (name === '') {
            toast.warning('Please provide the auditor name.')
        } else {
            setStatus('loading')
            let responsed = await DataSubmission(
                String(authTokens.access),
                'post',
                '/auditor/',
                {
                    auditors_name: name,
                    audited_company: finsNumber
                }
            );

            if (action === "done" && responsed[0].result === "success") {
                setStatus('finished')
                setName('')
            }
            else if (action === "add" && responsed[0].result === "success") {
                setStatus('adding')
                setName('')
            }
        }
    }

    const AuditorDelete = async (id) => {
        setStatus('loading')
        if (window.confirm('Please confirm to delete !')) {
            await DataSubmission(
                String(authTokens.access),
                'DELETE',
                '/auditor/' + id,
            )
            setStatus('finished')
        } else {
            setStatus('finished')
        }
    }

    return (
        <div className='tablediv'>
            <h2 className='tableDivHeaderBIU'>Auditors</h2>
            <div className='Biutablecontainer'>
                {status === 'loading' ?
                    <div className='loadingdiv'>
                        <CircularProgress className='loadingBIUtables' />
                    </div>
                    :
                    <table className='tableBIUedit'>
                        <thead className='tableHead'>
                            <tr>
                                <th colSpan={2}>{auditorsData.length} Records Returned</th>
                                <th onClick={() => setStatus('adding')} className='thaddbuttonbiu'><AddIcon /></th>
                            </tr>
                            <tr>
                                <th>Auditor Name</th>
                                <th colSpan={2}></th>
                            </tr>
                        </thead>
                        <tbody className='tablebody'>
                            <tr className={status === 'adding' ?
                                'tablerow'
                                :
                                'tablerowinvisible'}>
                                <td className='tabledata'>
                                    <InputContainerTable
                                        currentValue={name}
                                        setValue={setName}
                                        id={'name'}
                                    />
                                </td>
                                <td className='tabledata'>
                                    <SaveIcon className='editicons' onClick={() => { AddAuditor('done') }} />
                                </td>

                                <td className='tabledata'>
                                    <AddIcon className='editicons' onClick={() => { AddAuditor('add') }} />
                                </td>
                            </tr>
                            {auditorsData.map((item) => {
                                return (
                                    <AuditorRow
                                        item={item}
                                        RecordDelete={AuditorDelete}
                                        setStatus={setStatus}
                                    />
                                )
                            })}
                        </tbody>
                    </table>
                }
            </div>
            {!buttonStatus ?
                <button className='BiuConfirmButton'
                    onClick={
                        () => Confirm(
                            { id: report_id, finished_item: 'auditor' },
                            setButtonStatus,
                            buttonStatus,
                            authTokens
                        )}
                >
                    <SaveIcon className='ConfirmBiuicon' />
                    Confirm
                </button>
                :
                <button className='BiuConfirmedButton'
                    onClick={
                        () => Confirm(
                            { id: report_id, finished_item: 'auditor' },
                            setButtonStatus,
                            buttonStatus,
                            authTokens
                        )}
                >
                    <CheckIcon className='ConfirmBiuicon' />
                    Confirmed
                </button>
            }
        </div>
    )
}

const AuditorRow = ({ item, RecordDelete, setStatus }) => {
    const { authTokens, logoutUser } = useContext(AuthContext);
    const [edit, setEdit] = useState(false);
    const [name, setName] = useState(item.auditors_name)

    const EditAuditor = async () => {
        setStatus('loading')
        let responsed = await DataSubmission(
            String(authTokens.access),
            'put',
            '/auditor/' + item.auditor_id + '/',
            {
                auditor_id: item.auditor_id,
                auditors_name: name,
                audited_company: item.audited_company.fins_number
            }
        )

        if (responsed[0].result === "success") {
        }
        setStatus('finished')
    }
    return (
        <>
            {
                edit ?
                    <tr key={item.assoc_id} className='tablerow'>
                        <td className='tabledata'>
                            <InputContainerTable
                                currentValue={name}
                                setValue={setName}
                                id={'name'}
                            />
                        </td>
                        <td className='tabledata'>
                            <SaveIcon className='editicons' onClick={() => EditAuditor()} />
                        </td>
                        <td className='tabledata'>
                            <CloseIcon className='editicons' onClick={() => setEdit(!edit)} />
                        </td>
                    </tr>
                    :
                    <tr key={item.auditor_id} className='tablerow'>
                        <td className='tabledata'>{item.auditors_name}</td>
                        <td className='tabledata'>
                            <EditIcon className='editicons' onClick={() => setEdit(!edit)} />
                        </td>
                        <td className='tabledata'>
                            <DeleteIcon className='editicons' onClick={() => RecordDelete(item.auditor_id)} />
                        </td>
                    </tr>
            }
        </>

    )
}

{/* ______________________________Company Deeds+_____________________________________________________________ */ }
export const CompanyDeedsTable = ({ setRun, finsNumber, report_id, log }) => {
    let { authTokens, logoutUser } = useContext(AuthContext);
    const [deedsData, setDeedsData] = useState([])
    const [status, setStatus] = useState('finished')
    const [address, setAddress] = useState('')
    const [description, setDescription] = useState('')
    const [currency, setCurrency] = useState('')
    const [amount, setAmount] = useState('')
    const [buttonStatus, setButtonStatus] = useState(log)


    useEffect(() => {
        axios({
            method: 'post',
            url: '/deed-search/',
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: "Bearer " + String(authTokens.access)
            },
            data: { data: { fins: finsNumber, searchParameter: 'owner_company' } },
        })
            .then((res) => {
                setDeedsData(res.data)
            }).catch((AxiosError) => {
                setRun('error')
                if (AxiosError.code === "ERR_NETWORK") {
                    toast.error("Network Error!")
                } else (
                    toast.error('Internal system error contact system admin!')
                )
            }
            )
    }, [status,])

    RemoveNull(deedsData)

    const AddDeed = async (action) => {
        if (address === '' || amount === '') {
            toast.warning('Please provide the address and value of the property.')
        } else {
            setStatus('loading')
            let responsed = await DataSubmission(
                String(authTokens.access),
                'post',
                '/deed/',
                {
                    description: description,
                    address: address,
                    currency_type: currency,
                    amount: amount,
                    owner_company: finsNumber
                }
            );

            if (action === "done" && responsed[0].result === "success") {
                setStatus('finished')
                setDescription(''); setAddress(''); setCurrency(''); setAmount('')
            }
            else if (action === "add" && responsed[0].result === "success") {
                setStatus('adding')
                setDescription(''); setAddress(''); setCurrency(''); setAmount('')
            } else {
                setStatus('adding')
            }
        }
    }

    const DeedDelete = async (id) => {
        if (window.confirm('Please confirm to delete !')) {
            setStatus('loading')
            await DataSubmission(
                String(authTokens.access),
                'DELETE',
                '/deed/' + id,
            )
            setStatus('finished')
        } else {
            setStatus('finished')
        }
    }

    return (
        <div className='tablediv'>
            <h2 className='tableDivHeaderBIU'>Title Deeds</h2>
            <div className='Biutablecontainer'>
                {status === 'loading' ?
                    <div className='loadingdiv'>
                        <CircularProgress className='loadingBIUtables' />
                    </div>
                    :
                    <table className='tableBIUedit'>
                        <thead className='tableHead'>
                            <tr>
                                <th colSpan={4}> {deedsData.length} Records Returned </th>
                                <th colSpan={2} onClick={() => setStatus('adding')} className='thaddbuttonbiu'><AddIcon /></th>
                            </tr>
                            <tr>
                                <th>Address</th>
                                <th>Description</th>
                                <th>Currency</th>
                                <th>Value</th>
                                <th colSpan={2}></th>
                            </tr>
                        </thead>
                        <tbody className='tablebody'>
                            <tr className={status === 'adding' ?
                                'tablerow'
                                :
                                'tablerowinvisible'}>
                                <td colSpan={4} className='tabledata'>
                                    <section className=' secinputdeatails'>

                                        <InputAreaContainer
                                            label={"Address"}
                                            type={"text"}
                                            currentValue={address}
                                            setValue={setAddress}
                                            id={'address'} />

                                        <InputAreaContainer
                                            label={"Description"}
                                            type={"text"}
                                            currentValue={description}
                                            setValue={setDescription}
                                            id={'description'} />

                                        <SelectContainer
                                            label={"Currency"}
                                            currentValue={currency}
                                            setValue={setCurrency}
                                            options={CurrencyType}
                                        />

                                        <InputContainer
                                            label={"Value"}
                                            type={"number"}
                                            currentValue={amount}
                                            setValue={setAmount}
                                            id={'txt_amount'}
                                        />
                                    </section>
                                </td>
                                <td className='tabledata'>
                                    <SaveIcon className='editicons' onClick={() => { AddDeed('done') }} />
                                </td>

                                <td className='tabledata'>
                                    <AddIcon className='editicons' onClick={() => { AddDeed('add') }} />
                                </td>
                            </tr>
                            {deedsData.map((item) => {
                                return (
                                    <DeedRow
                                        item={item}
                                        RecordDelete={DeedDelete}
                                        setStatus={setStatus}
                                    />
                                )
                            })}
                        </tbody>
                    </table>
                }
            </div>
            {!buttonStatus ?
                <button className='BiuConfirmButton'
                    onClick={
                        () => Confirm(
                            { id: report_id, finished_item: 'deed' },
                            setButtonStatus,
                            buttonStatus,
                            authTokens
                        )}
                >
                    <SaveIcon className='ConfirmBiuicon' />
                    Confirm
                </button>
                :
                <button className='BiuConfirmedButton'
                    onClick={
                        () => Confirm(
                            { id: report_id, finished_item: 'deed' },
                            setButtonStatus,
                            buttonStatus,
                            authTokens
                        )}
                >
                    <CheckIcon className='ConfirmBiuicon' />
                    Confirmed
                </button>
            }
        </div>
    )
}

const DeedRow = ({ item, RecordDelete, setStatus }) => {
    const { authTokens, logoutUser } = useContext(AuthContext);
    const [edit, setEdit] = useState(false);
    const [address, setAddress] = useState(item.address)
    const [description, setDescription] = useState(item.description)
    const [currency, setCurrency] = useState(item.currency_type)
    const [amount, setAmount] = useState(item.amount)

    const EditDeed = async () => {
        setStatus('loading')
        let responsed = await DataSubmission(
            String(authTokens.access),
            'put',
            '/deed/' + item.deed_id + '/',
            {
                deed_id: item.deed_id,
                description: description,
                address: address,
                currency_type: currency,
                amount: amount,
                owner_company: item.owner_company.fins_number
            }
        )

        if (responsed[0].result === "success") {
        }
        setStatus('finished')
    }
    return (
        <>
            {
                edit ?
                    <tr key={item.assoc_id} className='tablerow'>
                        <td colSpan={4} className='tabledata'>
                            <section className=' secinputdeatails'>

                                <InputAreaContainer
                                    label={"Address"}
                                    type={"text"}
                                    currentValue={address}
                                    setValue={setAddress}
                                    id={'address'} />

                                <InputAreaContainer
                                    label={"Description"}
                                    type={"text"}
                                    currentValue={description}
                                    setValue={setDescription}
                                    id={'description'} />

                                <SelectContainer
                                    label={"Currency"}
                                    currentValue={currency}
                                    setValue={setCurrency}
                                    options={CurrencyType}
                                />

                                <InputContainer
                                    label={"Value"}
                                    type={"number"}
                                    currentValue={amount}
                                    setValue={setAmount}
                                    id={'txt_amount'}
                                />
                            </section>
                        </td>
                        <td className='tabledata'>
                            <SaveIcon className='editicons' onClick={() => EditDeed()} />
                        </td>
                        <td className='tabledata'>
                            <CloseIcon className='editicons' onClick={() => setEdit(!edit)} />
                        </td>
                    </tr>
                    :
                    <tr key={item.deed_id} className='tablerow'>
                        <td className='tabledata'>{item.address}</td>
                        <td className='tabledata'>{item.description}</td>
                        <td className='tabledata'>{item.currency_type}</td>
                        <td className='tabledata'>{item.amount}</td>
                        <td className='tabledata'>
                            <EditIcon className='editicons' onClick={() => setEdit(!edit)} />
                        </td>
                        <td className='tabledata'>
                            <DeleteIcon className='editicons' onClick={() => RecordDelete(item.deed_id)} />
                        </td>
                    </tr>
            }
        </>

    )
}
//********************************************Banking****************************************************************** */
export const BankingDetailsTable = ({ setRun, finsNumber, report_id, log }) => {
    let { authTokens, logoutUser } = useContext(AuthContext);
    const [banksData, setBanksData] = useState([])
    const [status, setStatus] = useState('finished')
    const [accountNumber, setAccoutNumber] = useState('')
    const [typeofAccount, setTypeofAccount] = useState('')
    const [bankName, setBankName] = useState('')
    const [bankBranch, setBankBranch] = useState('')
    const [branchCode, setBranchCode] = useState('')
    const [accountName, setAccountName] = useState('')
    const [buttonStatus, setButtonStatus] = useState(log)

    useEffect(() => {
        axios({
            method: 'post',
            url: '/company-bank-account/',
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: "Bearer " + String(authTokens.access)
            },
            data: { data: { fins: finsNumber } },
        })
            .then((res) => {
                setBanksData(res.data)
                setRun('finished')
            }).catch((AxiosError) => {
                setRun('error')
                if (AxiosError.code === "ERR_NETWORK") {
                    toast.error("Network Error!")
                } else (
                    toast.error('Internal system error contact system admin!')
                )
            }
            )
    }, [status,])

    RemoveNull(banksData)

    const AddBank = async (action) => {
        if (bankName === '' || accountNumber === '') {
            toast.warning('Please provide the bank name, account number and account name.')
        } else {
            setStatus('loading')
            let responsed = await DataSubmission(
                String(authTokens.access),
                'post',
                '/bank-account/',
                {
                    account_number: accountNumber,
                    account_name: accountName,
                    bankbranch: bankBranch,
                    branch_code: branchCode,
                    bank_name: bankName,
                    account_type: typeofAccount,
                    company: finsNumber
                }
            );

            if (action === "done" && responsed[0].result === "success") {
                setStatus('finished')
                setBankName(''); setBranchCode(''); setBankBranch(''); setAccoutNumber(''); setTypeofAccount(''); setAccountName('')
            }
            else if (action === "add" && responsed[0].result === "success") {
                setStatus('adding')
                setBankName(''); setBranchCode(''); setBankBranch(''); setAccoutNumber(''); setTypeofAccount(''); setAccountName('')
            } else {
                setStatus('adding')
            }
        }
    }

    const BankDelete = async (id) => {
        if (window.confirm('Please confirm to delete !')) {
            setStatus('loading')
            await DataSubmission(
                String(authTokens.access),
                'DELETE',
                '/bank-account/' + id,
            )
            setStatus('finished')
        } else {
            setStatus('finished')
        }
    }


    return (
        <div className='tablediv'>
            <h2 className='tableDivHeaderBIU'>Banking Details</h2>
            <div className='Biutablecontainer'>
                {status === 'loading' ?
                    <div className='loadingdiv'>
                        <CircularProgress className='loadingBIUtables' />
                    </div>
                    :
                    <table className='tableBIUedit'>
                        <thead className='tableHead'>
                            <tr>
                                <th colSpan={5}>{banksData.length} Records Returned</th>
                                <th colSpan={2} onClick={() => setStatus('adding')} className='thaddbuttonbiu'><AddIcon /></th>
                            </tr>
                            <tr>
                                <th>Bank</th>
                                <th>Branch</th>
                                <th>Account Type</th>
                                <th>Account Name</th>
                                <th>Account Number</th>
                                <th colSpan={2}></th>
                            </tr>
                        </thead>
                        <tbody className='tablebody'>
                            <tr className={status === 'adding' ?
                                'tablerow'
                                :
                                'tablerowinvisible'}>
                                <td colSpan={5} className='tabledata'>
                                    <section className=' secinputdeatails'>

                                        <InputContainer
                                            label={"Bank Name"}
                                            type={"text"}
                                            currentValue={bankName}
                                            setValue={setBankName}
                                            id={'bank_name'}
                                        />

                                        <InputContainer
                                            label={"Bank Branch"}
                                            type={"text"}
                                            currentValue={bankBranch}
                                            setValue={setBankBranch}
                                            id={'bank_branch'}
                                        />

                                        <InputContainer
                                            label={"Branch Code"}
                                            type={"text"}
                                            currentValue={branchCode}
                                            setValue={setBranchCode}
                                            id={'branch_code'}
                                        />

                                        <InputContainer
                                            label={"Account Name"}
                                            type={"text"}
                                            currentValue={accountName}
                                            setValue={setAccountName}
                                            id={'account_name'}
                                        />

                                        <InputContainer
                                            label={"Account Number"}
                                            type={"text"}
                                            currentValue={accountNumber}
                                            setValue={setAccoutNumber}
                                            id={'account_number'}
                                        />

                                        <SelectContainer
                                            label={"Account Type "}
                                            currentValue={typeofAccount}
                                            setValue={setTypeofAccount}
                                            options={AccountType}
                                        />
                                    </section>
                                </td>
                                <td className='tabledata'>
                                    <SaveIcon className='editicons' onClick={() => { AddBank('done') }} />
                                </td>

                                <td className='tabledata'>
                                    <AddIcon className='editicons' onClick={() => { AddBank('add') }} />
                                </td>
                            </tr>
                            {banksData.map((item) => {
                                return (
                                    <BankingDetailsRow
                                        item={item}
                                        RecordDelete={BankDelete}
                                        setStatus={setStatus}
                                    />
                                )
                            })}
                        </tbody>
                    </table>
                }
            </div>
            {!buttonStatus ?
                <button className='BiuConfirmButton'
                    onClick={
                        () => Confirm(
                            { id: report_id, finished_item: 'bank_account' },
                            setButtonStatus,
                            buttonStatus,
                            authTokens
                        )}
                >
                    <SaveIcon className='ConfirmBiuicon' />
                    Confirm
                </button>
                :
                <button className='BiuConfirmedButton'
                    onClick={
                        () => Confirm(
                            { id: report_id, finished_item: 'bank_account' },
                            setButtonStatus,
                            buttonStatus,
                            authTokens
                        )}
                >
                    <CheckIcon className='ConfirmBiuicon' />
                    Confirmed
                </button>
            }
        </div>
    )
}

const BankingDetailsRow = ({ item, RecordDelete, setStatus }) => {
    const { authTokens, logoutUser } = useContext(AuthContext);
    const [edit, setEdit] = useState(false);
    const [accountNumber, setAccoutNumber] = useState(item.account_number)
    const [typeofAccount, setTypeofAccount] = useState(item.account_type)
    const [bankName, setBankName] = useState(item.bank_name)
    const [bankBranch, setBankBranch] = useState(item.bankbranch)
    const [branchCode, setBranchCode] = useState(item.branch_code)
    const [accountName, setAccountName] = useState(item.account_name)

    const EditBank = async () => {
        setStatus('loading')
        let responsed = await DataSubmission(
            String(authTokens.access),
            'put',
            '/bank-account/' + item.account_id + '/',
            {
                account_id: item.account_id,
                account_number: accountNumber,
                account_name: accountName,
                bankbranch: bankBranch,
                branch_code: branchCode,
                bank_name: bankName,
                account_type: typeofAccount,
                company: item.company.fins_number
            }
        )

        if (responsed[0].result === "success") {
        }
        setStatus('finished')
    }
    return (
        <>
            {
                edit ?
                    <tr key={item.assoc_id} className='tablerow'>
                        <td colSpan={5} className='tabledata'>
                            <section className=' secinputdeatails'>

                                <InputContainer
                                    label={"Bank Name"}
                                    type={"text"}
                                    currentValue={bankName}
                                    setValue={setBankName}
                                    id={'bank_name'}
                                />

                                <InputContainer
                                    label={"Bank Branch"}
                                    type={"text"}
                                    currentValue={bankBranch}
                                    setValue={setBankBranch}
                                    id={'bank_branch'}
                                />

                                <InputContainer
                                    label={"Branch Code"}
                                    type={"text"}
                                    currentValue={branchCode}
                                    setValue={setBranchCode}
                                    id={'branch_code'}
                                />

                                <InputContainer
                                    label={"Account Name"}
                                    type={"text"}
                                    currentValue={accountName}
                                    setValue={setAccountName}
                                    id={'account_name'}
                                />

                                <InputContainer
                                    label={"Account Number"}
                                    type={"text"}
                                    currentValue={accountNumber}
                                    setValue={setAccoutNumber}
                                    id={'account_number'}
                                />

                                <SelectContainer
                                    label={"Account Type "}
                                    currentValue={typeofAccount}
                                    setValue={setTypeofAccount}
                                    options={AccountType}
                                    id={'account_type'}
                                />
                            </section>
                        </td>
                        <td className='tabledata'>
                            <SaveIcon className='editicons' onClick={() => EditBank()} />
                        </td>
                        <td className='tabledata'>
                            <CloseIcon className='editicons' onClick={() => setEdit(!edit)} />
                        </td>
                    </tr>
                    :
                    <tr key={item.account_id} className='tablerow'>
                        <td className='tabledata'>{item.bank_name}</td>
                        <td className='tabledata'>{item.bankbranch}</td>
                        <td className='tabledata'>{item.account_type}</td>
                        <td className='tabledata'>{item.account_name}</td>
                        <td className='tabledata'>{item.account_number}</td>
                        <td className='tabledata'>
                            <EditIcon className='editicons' onClick={() => setEdit(!edit)} />
                        </td>
                        <td className='tabledata'>
                            <DeleteIcon className='editicons' onClick={() => RecordDelete(item.account_id)} />
                        </td>
                    </tr>
            }
        </>

    )
}

{/* ______________________________Company Company Bank reports_____________________________________________________________ */ }
export const CompanyBankReportsTable = ({ setRun, finsNumber, report_id, log }) => {
    let { authTokens, logoutUser } = useContext(AuthContext);
    const [recordData, setRecordData] = useState([])
    const [status, setStatus] = useState('finished')
    const [code, setCode] = useState('')
    const [date, setDate] = useState('')
    const [amount, setAmount] = useState('')
    const [bank, setBank] = useState('')
    const [currencyType, setCurrencyType] = useState('')
    const [comment, setComment] = useState('')
    const [buttonStatus, setButtonStatus] = useState(log)

    useEffect(() => {
        axios({
            method: 'post',
            url: '/company-bank-report/',
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: "Bearer " + String(authTokens.access)
            },
            data: { data: { fins: finsNumber } },
        })
            .then((res) => {
                setRecordData(res.data)
                setRun('finished')
            }).catch((AxiosError) => {

                setRun('error')
                if (AxiosError.code === "ERR_NETWORK") {
                    toast.error("Network Error!")
                } else (
                    toast.error('Internal system error contact system admin!')
                )
            }
            )
    }, [status,])

    RemoveNull(recordData)

    const AddBankReport = async (action) => {
        if (bank === '' || code === '') {
            toast.warning('Please provide the bank name and the report code.')
        } else {
            setStatus('loading')
            let responsed = await DataSubmission(
                String(authTokens.access),
                'post',
                '/bank-report/',
                {
                    code: code,
                    bank: bank,
                    naration: comment,
                    company: finsNumber,
                    currency_type: currencyType,
                    amount: amount,
                    date_of_report: date
                }
            );

            if (action === "done" && responsed[0].result === "success") {
                setStatus('finished')
                setAmount(''); setBank(''); setCode(''); setComment(''); setCurrencyType('');
                setDate(null);
            }
            else if (action === "add" && responsed[0].result === "success") {
                setStatus('adding')
                setAmount(''); setBank(''); setCode(''); setComment(''); setCurrencyType('');
                setDate(null);
            } else {
                setStatus('adding')
            }
        }
    }

    const RecordDelete = async (id) => {
        if (window.confirm('Please confirm to delete !')) {
            setStatus('loading')
            await DataSubmission(
                String(authTokens.access),
                'DELETE',
                '/bank-report/' + id + '/',
            )
            setStatus('finished')
        } else {
            setStatus('finished')
        }
    }


    return (
        <div className='tablediv'>
            <h2 className='tableDivHeaderBIU'>Bank Reports</h2>
            <div className='Biutablecontainer'>
                {status === 'loading' ?
                    <div className='loadingdiv'>
                        <CircularProgress className='loadingBIUtables' />
                    </div>
                    :
                    <table className='tableBIUedit'>
                        <thead className='tableHead'>
                            <tr>
                                <th colSpan={5}>{recordData.length} Records Returned</th>
                                <th colSpan={2} onClick={() => setStatus('adding')} className='thaddbuttonbiu'><AddIcon /></th>
                            </tr>
                            <tr>
                                <th>Code</th>
                                <th>Bank</th>
                                <th>Currency</th>
                                <th>Balance</th>
                                <th>Date of Report</th>
                                <th colSpan={2}></th>
                            </tr>
                        </thead>
                        <tbody className='tablebody'>
                            <tr className={status === 'adding' ?
                                'tablerow'
                                :
                                'tablerowinvisible'}>
                                <td colSpan={5} className='tabledata'>
                                    <section className=' secinputdeatails'>

                                        <InputContainer
                                            label={"Date of Report"}
                                            type={"date"}
                                            currentValue={date}
                                            setValue={setDate}
                                            id={'txt_date'}
                                        />

                                        <InputContainer
                                            label={"Code"}
                                            type={"text"}
                                            currentValue={code}
                                            setValue={setCode}
                                            id={'txt_code'}
                                        />

                                        <InputContainer
                                            label={"Bank"}
                                            type={"text"}
                                            currentValue={bank}
                                            setValue={setBank}
                                            id={'txt_bank'}
                                        />

                                        <SelectContainer
                                            label={"Currency"}
                                            currentValue={currencyType}
                                            setValue={setCurrencyType}
                                            options={CurrencyType}
                                        />

                                        <InputContainer
                                            label={"Amount"}
                                            type={"number"}
                                            currentValue={amount}
                                            setValue={setAmount}
                                            id={'txt_amount'}
                                        />

                                        <InputAreaContainer
                                            label={"Comment : "}
                                            type={"text"}
                                            currentValue={comment}
                                            setValue={setComment}
                                            id={'txt_comment'}
                                        />
                                    </section>
                                </td>
                                <td className='tabledata'>
                                    <SaveIcon className='editicons' onClick={() => { AddBankReport('done') }} />
                                </td>

                                <td className='tabledata'>
                                    <AddIcon className='editicons' onClick={() => { AddBankReport('add') }} />
                                </td>
                            </tr>
                            {recordData.map((item) => {
                                return (
                                    <BankReportRow
                                        item={item}
                                        RecordDelete={RecordDelete}
                                        setStatus={setStatus}
                                    />
                                )
                            })}
                        </tbody>
                    </table>
                }
            </div>
            {!buttonStatus ?
                <button className='BiuConfirmButton'
                    onClick={
                        () => Confirm(
                            { id: report_id, finished_item: 'bank_report' },
                            setButtonStatus,
                            buttonStatus,
                            authTokens
                        )}
                >
                    <SaveIcon className='ConfirmBiuicon' />
                    Confirm
                </button>
                :
                <button className='BiuConfirmedButton'
                    onClick={
                        () => Confirm(
                            { id: report_id, finished_item: 'bank_report' },
                            setButtonStatus,
                            buttonStatus,
                            authTokens
                        )}
                >
                    <CheckIcon className='ConfirmBiuicon' />
                    Confirmed
                </button>
            }
        </div>
    )
}

const BankReportRow = ({ item, RecordDelete, setStatus }) => {
    const { authTokens, logoutUser } = useContext(AuthContext);
    const [edit, setEdit] = useState(false);
    const [code, setCode] = useState(item.code)
    const [date, setDate] = useState(item.date_of_report)
    const [amount, setAmount] = useState(item.amount)
    const [bank, setBank] = useState(item.bank)
    const [currencyType, setCurrencyType] = useState(item.currency_type)
    const [comment, setComment] = useState(item.naration)

    const EditBankReport = async () => {
        if (bank === '' || code === '') {
            toast.warning('Please provide the bank name and the report code.')
        } else {
            setStatus('loading')
            let responsed = await DataSubmission(
                String(authTokens.access),
                'put',
                '/bank-report/' + item.report_id + '/',
                {
                    report_id: item.report_id,
                    code: code,
                    bank: bank,
                    naration: comment,
                    company: item.company,
                    currency_type: currencyType,
                    amount: amount,
                    date_of_report: date
                }

            )

            if (responsed[0].result === "success") {
            }
            setStatus('finished')
        }
    }

    return (
        <>
            {
                edit ?
                    <tr key={item.assoc_id} className='tablerow'>
                        <td colSpan={5} className='tabledata'>
                            <section className=' secinputdeatails'>

                                <InputContainer
                                    label={"Date of Report"}
                                    type={"date"}
                                    currentValue={date}
                                    setValue={setDate}
                                    id={'txt_date'}
                                />

                                <InputContainer
                                    label={"Code"}
                                    type={"text"}
                                    currentValue={code}
                                    setValue={setCode}
                                    id={'txt_code'}
                                />

                                <InputContainer
                                    label={"Bank"}
                                    type={"text"}
                                    currentValue={bank}
                                    setValue={setBank}
                                    id={'txt_bank'}
                                />

                                <SelectContainer
                                    label={"Currency"}
                                    currentValue={currencyType}
                                    setValue={setCurrencyType}
                                    options={CurrencyType}
                                />

                                <InputContainer
                                    label={"Amount"}
                                    type={"number"}
                                    currentValue={amount}
                                    setValue={setAmount}
                                    id={'txt_amount'}
                                />

                                <InputAreaContainer
                                    label={"Comment : "}
                                    type={"text"}
                                    currentValue={comment}
                                    setValue={setComment}
                                    id={'txt_comment'}
                                />
                            </section>
                        </td>
                        <td className='tabledata'>
                            <SaveIcon className='editicons' onClick={() => EditBankReport()} />
                        </td>
                        <td className='tabledata'>
                            <CloseIcon className='editicons' onClick={() => setEdit(!edit)} />
                        </td>
                    </tr>
                    :
                    <tr key={item.report_id} className='tablerow'>
                        <td className='tabledata'>{item.code}</td>
                        <td className='tabledata'>{item.bank}</td>
                        <td className='tabledata'>{item.currency_type}</td>
                        <td className='tabledata'>{item.amount}</td>
                        <td className='tabledata'>{item.date_of_report}</td>
                        <td className='tabledata'>
                            <EditIcon className='editicons' onClick={() => setEdit(!edit)} />
                        </td>
                        <td className='tabledata'>
                            <DeleteIcon className='editicons' onClick={() => RecordDelete(item.report_id)} />
                        </td>
                    </tr>
            }
        </>

    )
}
{/* ______________________________Company Public Records_____________________________________________________________ */ }
export const CompanyPublicRecordsTable = ({ setRun, finsNumber, report_id, log }) => {
    let { authTokens, logoutUser } = useContext(AuthContext);
    const [recordData, setRecordData] = useState([])
    const [status, setStatus] = useState('finished')
    const [date, setDate] = useState('')
    const [source, setSource] = useState('')
    const [link, setLink] = useState('')
    const [caseNumber, setCaseNumber] = useState('')
    const [summary, setSummary] = useState('')
    const [buttonStatus, setButtonStatus] = useState(log)

    useEffect(() => {
        axios({
            method: 'post',
            url: '/company-public-info/',
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: "Bearer " + String(authTokens.access)
            },
            data: { data: { fins: finsNumber } },
        })
            .then((res) => {
                setRecordData(res.data)
                setRun('finished')
            }).catch((AxiosError) => {

                setRun('error')
                if (AxiosError.code === "ERR_NETWORK") {
                    toast.error("Network Error!")
                } else (
                    toast.error('Internal system error contact system admin!')
                )
            }
            )
    }, [status,])

    RemoveNull(recordData)

    const AddPublicRecord = async (action) => {
        if (source === '' || caseNumber === '') {
            toast.warning('Please provide the source and case Number.')
        } else {
            setStatus('loading')
            let responsed = await DataSubmission(
                String(authTokens.access),
                'post',
                '/public-record/',
                {
                    date: date,
                    company: finsNumber,
                    source: source,
                    link: link,
                    case_number: caseNumber,
                    summary: summary
                }
            );

            if (action === "done" && responsed[0].result === "success") {
                setStatus('finished')
                setCaseNumber(''); setDate(null); setLink(''); setSource(''); setSummary('')
            }
            else if (action === "add" && responsed[0].result === "success") {
                setStatus('adding')
                setCaseNumber(''); setDate(null); setLink(''); setSource(''); setSummary('')
            } else {
                setStatus('adding')
            }
        }
    }

    const RecordDelete = async (id) => {
        if (window.confirm('Please confirm to delete !')) {
            setStatus('loading')
            await DataSubmission(
                String(authTokens.access),
                'DELETE',
                '/public-record/' + id,
            )
            setStatus('finished')
        } else {
            setStatus('finished')
        }
    }


    return (
        <div className='tablediv'>
            <h2 className='tableDivHeaderBIU'>Public Records</h2>
            <div className='Biutablecontainer'>
                {status === 'loading' ?
                    <div className='loadingdiv'>
                        <CircularProgress className='loadingBIUtables' />
                    </div>
                    :
                    <table className='tableBIUedit'>
                        <thead className='tableHead'>
                            <tr>
                                <th colSpan={3}>{recordData.length} Records Returned</th>
                                <th colSpan={2} onClick={() => setStatus('adding')} className='thaddbuttonbiu'><AddIcon /></th>
                            </tr>
                            <tr>
                                <th>Summary</th>
                                <th>Source</th>
                                <th>Date</th>
                                <th colSpan={2}></th>
                            </tr>
                        </thead>
                        <tbody className='tablebody'>
                            <tr className={status === 'adding' ?
                                'tablerow'
                                :
                                'tablerowinvisible'}>
                                <td colSpan={3} className='tabledata'>
                                    <section className=' secinputdeatails'>

                                        <InputContainer
                                            label={"Source"}
                                            type={"text"}
                                            currentValue={source}
                                            setValue={setSource}
                                            id={'txt_source'}
                                        />


                                        <InputContainer
                                            label={"Date Publishd"}
                                            type={"date"}
                                            currentValue={date}
                                            setValue={setDate}
                                            id={'txt_date'}
                                        />

                                        <InputContainer
                                            label={"Case Number"}
                                            type={"text"}
                                            currentValue={caseNumber}
                                            setValue={setCaseNumber}
                                            id={'txt_caseNumber'}
                                        />

                                        <InputContainer
                                            label={"Link"}
                                            type={"url"}
                                            currentValue={link}
                                            setValue={setLink}
                                            id={'txt_link'}
                                        />

                                        <InputAreaContainer
                                            label={"Summary"}
                                            type={"text"}
                                            currentValue={summary}
                                            setValue={setSummary}
                                            id={'txt_summary'}
                                        />

                                    </section>
                                </td>
                                <td className='tabledata'>
                                    <SaveIcon className='editicons' onClick={() => { AddPublicRecord('done') }} />
                                </td>

                                <td className='tabledata'>
                                    <AddIcon className='editicons' onClick={() => { AddPublicRecord('add') }} />
                                </td>
                            </tr>
                            {recordData.map((item) => {
                                return (
                                    <PublicRecordRow
                                        item={item}
                                        RecordDelete={RecordDelete}
                                        setStatus={setStatus}
                                    />
                                )
                            })}
                        </tbody>
                    </table>
                }
            </div>
            {!buttonStatus ?
                <button className='BiuConfirmButton'
                    onClick={
                        () => Confirm(
                            { id: report_id, finished_item: 'public_record' },
                            setButtonStatus,
                            buttonStatus,
                            authTokens
                        )}
                >
                    <SaveIcon className='ConfirmBiuicon' />
                    Confirm
                </button>
                :
                <button className='BiuConfirmedButton'
                    onClick={
                        () => Confirm(
                            { id: report_id, finished_item: 'public_record' },
                            setButtonStatus,
                            buttonStatus,
                            authTokens
                        )}
                >
                    <CheckIcon className='ConfirmBiuicon' />
                    Confirmed
                </button>
            }
        </div>
    )
}

const PublicRecordRow = ({ item, RecordDelete, setStatus }) => {
    const { authTokens, logoutUser } = useContext(AuthContext);
    const [edit, setEdit] = useState(false);
    const [date, setDate] = useState(item.date)
    const [source, setSource] = useState(item.source)
    const [link, setLink] = useState(item.link)
    const [caseNumber, setCaseNumber] = useState(item.case_number)
    const [summary, setSummary] = useState(item.summary)

    const EditPublicRecord = async () => {
        if (source === '' || caseNumber === '') {
            toast.warning('Please provide the source and case Number.')
        } else {
            setStatus('loading')
            let responsed = await DataSubmission(
                String(authTokens.access),
                'put',
                '/public-record/' + item.id + '/',
                {
                    id: item.id,
                    date: date,
                    company: item.company,
                    source: source,
                    link: link,
                    case_number: caseNumber,
                    summary: summary
                }
            )

            if (responsed[0].result === "success") {
            }
            setStatus('finished')
        }
    }

    return (
        <>
            {
                edit ?
                    <tr key={item.id} className='tablerow'>
                        <td colSpan={3} className='tabledata'>
                            <section className=' secinputdeatails'>

                                <InputContainer
                                    label={"Source"}
                                    type={"text"}
                                    currentValue={source}
                                    setValue={setSource}
                                    id={'txt_source'}
                                />


                                <InputContainer
                                    label={"Date Publishd"}
                                    type={"date"}
                                    currentValue={date}
                                    setValue={setDate}
                                    id={'txt_date'}
                                />

                                <InputContainer
                                    label={"Case Number"}
                                    type={"text"}
                                    currentValue={caseNumber}
                                    setValue={setCaseNumber}
                                    id={'txt_caseNumber'}
                                />

                                <InputContainer
                                    label={"Link"}
                                    type={"url"}
                                    currentValue={link}
                                    setValue={setLink}
                                    id={'txt_link'}
                                />

                                <InputAreaContainer
                                    label={"Summary"}
                                    type={"text"}
                                    currentValue={summary}
                                    setValue={setSummary}
                                    id={'txt_summary'}
                                />

                            </section>
                        </td>
                        <td className='tabledata'>
                            <SaveIcon className='editicons' onClick={() => EditPublicRecord()} />
                        </td>
                        <td className='tabledata'>
                            <CloseIcon className='editicons' onClick={() => setEdit(!edit)} />
                        </td>
                    </tr>
                    :
                    <tr key={item.id} className='tablerow'>
                        <td className='tabledata'>{item.summary}</td>
                        <td className='tabledata'>{item.source}</td>
                        <td className='tabledata'>{item.date}</td>
                        <td className='tabledata'>
                            <EditIcon className='editicons' onClick={() => setEdit(!edit)} />
                        </td>
                        <td className='tabledata'>
                            <DeleteIcon className='editicons' onClick={() => RecordDelete(item.id)} />
                        </td>
                    </tr>
            }
        </>

    )
}

{/* ______________________________Company Trade References_____________________________________________________________ */ }
export const CompanyTradeReferencesTable = ({ setRun, finsNumber, report_id, log }) => {
    let { authTokens, logoutUser } = useContext(AuthContext);
    const [recordData, setRecordData] = useState([])
    const [status, setStatus] = useState('finished')
    const [tradeReference, setTradeReference] = useState('')
    const [payingRecord, setPayingRecord] = useState('')
    const [highCredit, setHighCredit] = useState('')
    const [amountOwed, setAmountOwed] = useState('')
    const [currencyType, setCurrencyType] = useState('')
    const [amountOverdue, setAmountOverdue] = useState('')
    const [sellingTerms, setSellingTerms] = useState('')
    const [dateofReference, setDateofReference] = useState('')
    const [buttonStatus, setButtonStatus] = useState(log)

    useEffect(() => {
        axios({
            method: 'post',
            url: '/company-trade-reference/',
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: "Bearer " + String(authTokens.access)
            },
            data: { data: { fins: finsNumber } },
        })
            .then((res) => {
                setRecordData(res.data)
                setRun('finished')
            }).catch((AxiosError) => {

                setRun('error')
                if (AxiosError.code === "ERR_NETWORK") {
                    toast.error("Network Error!")
                } else (
                    toast.error('Internal system error contact system admin!')
                )
            }
            )
    }, [status,])

    RemoveNull(recordData)

    const AddTradeReference = async (action) => {
        if (tradeReference === '' || sellingTerms === '') {
            toast.warning('Please provide the trade reference and the selling terms.')
        } else {
            setStatus('loading')
            let responsed = await DataSubmission(
                String(authTokens.access),
                'post',
                '/trade-reference/',
                {
                    company: finsNumber,
                    trade_reference: tradeReference,
                    paying_records: payingRecord,
                    highcredit: highCredit,
                    amount_owed: amountOwed,
                    amount_overdue: amountOverdue,
                    selling_terms: sellingTerms,
                    currency_type: currencyType,
                    date_of_reference: dateofReference
                }
            );

            if (action === "done" && responsed[0].result === "success") {
                setStatus('finished')
                setAmountOverdue(''); setAmountOwed(''); setCurrencyType(''); setDateofReference(null);
                setHighCredit(''); setPayingRecord(''); setSellingTerms(''); setTradeReference('');
            }
            else if (action === "add" && responsed[0].result === "success") {
                setStatus('adding')
                setAmountOverdue(''); setAmountOwed(''); setCurrencyType(''); setDateofReference(null);
                setHighCredit(''); setPayingRecord(''); setSellingTerms(''); setTradeReference('');
            } else {
                setStatus('adding')
            }
        }
    }

    const RecordDelete = async (id) => {
        if (window.confirm('Please confirm to delete !')) {
            setStatus('loading')
            await DataSubmission(
                String(authTokens.access),
                'DELETE',
                '/trade-reference/' + id + '/',
            )
            setStatus('finished')
        } else {
            setStatus('finished')
        }
    }


    return (
        <div className='tablediv'>
            <h2 className='tableDivHeaderBIU'>Trade Reference</h2>
            <div className='Biutablecontainer'>
                {status === 'loading' ?
                    <div className='loadingdiv'>
                        <CircularProgress className='loadingBIUtables' />
                    </div>
                    :
                    <table className='tableBIUedit'>
                        <thead className='tableHead'>
                            <tr>
                                <th colSpan={5}>{recordData.length} Records Returned </th>
                                <th colSpan={2} onClick={() => setStatus('adding')} className='thaddbuttonbiu'><AddIcon /></th>
                            </tr>
                            <tr>
                                <th>Name</th>
                                <th>Paying Record</th>
                                <th>Currency</th>
                                <th>Amount Owed</th>
                                <th>Amount Overdue</th>
                                <th colSpan={2}></th>
                            </tr>
                        </thead>
                        <tbody className='tablebody'>
                            <tr className={status === 'adding' ?
                                'tablerow'
                                :
                                'tablerowinvisible'}>
                                <td colSpan={5} className='tabledata'>
                                    <section className=' secinputdeatails'>

                                        <InputContainer
                                            label={"Trade Reference"}
                                            type={"text"}
                                            currentValue={tradeReference}
                                            setValue={setTradeReference}
                                            id={'trade_reference'}
                                        />


                                        <InputContainer
                                            label={"Date of Reference"}
                                            type={"date"}
                                            currentValue={dateofReference}
                                            setValue={setDateofReference}
                                            id={'date_of_reference'}
                                        />

                                        <InputContainer
                                            label={"Paying Record"}
                                            type={"text"}
                                            currentValue={payingRecord}
                                            setValue={setPayingRecord}
                                            id={'paying_record'}
                                        />

                                        <InputContainer
                                            label={"High Credit"}
                                            type={"text"}
                                            currentValue={highCredit}
                                            setValue={setHighCredit}
                                            id={'high_credit'}
                                        />

                                        <SelectContainer
                                            label={"Currency Type"}
                                            currentValue={currencyType}
                                            setValue={setCurrencyType}
                                            options={CurrencyType}
                                        />

                                        <InputContainer
                                            label={"Amount Owed"}
                                            type={"number"}
                                            currentValue={amountOwed}
                                            setValue={setAmountOwed}
                                            id={'amount_owed'}
                                        />

                                        <InputContainer
                                            label={"Amount Overdue"}
                                            type={"number"}
                                            currentValue={amountOverdue}
                                            setValue={setAmountOverdue}
                                            id={'amount_overdue'}
                                        />

                                        <InputAreaContainer
                                            label={"Selling Terms"}
                                            type={"text"}
                                            currentValue={sellingTerms}
                                            setValue={setSellingTerms}
                                            id={'selling_terms'}
                                        />

                                    </section>

                                </td>
                                <td className='tabledata'>
                                    <SaveIcon className='editicons' onClick={() => { AddTradeReference('done') }} />
                                </td>

                                <td className='tabledata'>
                                    <AddIcon className='editicons' onClick={() => { AddTradeReference('add') }} />
                                </td>
                            </tr>

                            {recordData.map((item) => {
                                return (
                                    <TradeReferenceRow
                                        item={item}
                                        RecordDelete={RecordDelete}
                                        setStatus={setStatus}
                                    />
                                )
                            })}
                        </tbody>
                    </table>
                }
            </div>
            {!buttonStatus ?
                <button className='BiuConfirmButton'
                    onClick={
                        () => Confirm(
                            { id: report_id, finished_item: 'trade_reference' },
                            setButtonStatus,
                            buttonStatus,
                            authTokens
                        )}
                >
                    <SaveIcon className='ConfirmBiuicon' />
                    Confirm
                </button>
                :
                <button className='BiuConfirmedButton'
                    onClick={
                        () => Confirm(
                            { id: report_id, finished_item: 'trade_reference' },
                            setButtonStatus,
                            buttonStatus,
                            authTokens
                        )}
                >
                    <CheckIcon className='ConfirmBiuicon' />
                    Confirmed
                </button>
            }
        </div>
    )
}

const TradeReferenceRow = ({ item, RecordDelete, setStatus }) => {
    const { authTokens, logoutUser } = useContext(AuthContext);
    const [edit, setEdit] = useState(false);
    const [tradeReference, setTradeReference] = useState(item.trade_reference)
    const [payingRecord, setPayingRecord] = useState(item.paying_records)
    const [highCredit, setHighCredit] = useState(item.highcredit)
    const [amountOwed, setAmountOwed] = useState(item.amount_owed)
    const [currencyType, setCurrencyType] = useState(item.currency_type.code)
    const [amountOverdue, setAmountOverdue] = useState(item.amount_overdue)
    const [sellingTerms, setSellingTerms] = useState(item.selling_terms)
    const [dateofReference, setDateofReference] = useState(item.date_of_reference)

    const RecordEdit = async () => {
        if (tradeReference === '' || sellingTerms === '') {
            toast.warning('Please provide the trade reference and the selling terms.')
        } else {
            setStatus('loading')
            let responsed = await DataSubmission(
                String(authTokens.access),
                'put',
                '/trade-reference/' + item.trade_id + '/',
                {
                    trade_id: item.trade_id,
                    company: item.company.fins_number,
                    trade_reference: tradeReference,
                    paying_records: payingRecord,
                    highcredit: highCredit,
                    amount_owed: amountOwed,
                    amount_overdue: amountOverdue,
                    selling_terms: sellingTerms,
                    currency_type: currencyType,
                    date_of_reference: dateofReference
                }
            )

            if (responsed[0].result === "success") {
            }
            setStatus('finished')
        }
    }

    return (
        <>
            {
                edit ?
                    <tr key={item.id} className='tablerow'>
                        <td colSpan={5} className='tabledata'>
                            <section className=' secinputdeatails'>

                                <InputContainer
                                    label={"Trade Reference"}
                                    type={"text"}
                                    currentValue={tradeReference}
                                    setValue={setTradeReference}
                                    id={'trade_reference'}
                                />


                                <InputContainer
                                    label={"Date of Reference"}
                                    type={"date"}
                                    currentValue={dateofReference}
                                    setValue={setDateofReference}
                                    id={'date_of_reference'}
                                />

                                <InputContainer
                                    label={"Paying Record"}
                                    type={"text"}
                                    currentValue={payingRecord}
                                    setValue={setPayingRecord}
                                    id={'paying_record'}
                                />

                                <InputContainer
                                    label={"High Credit"}
                                    type={"text"}
                                    currentValue={highCredit}
                                    setValue={setHighCredit}
                                    id={'high_credit'}
                                />

                                <SelectContainer
                                    label={"Currency Type"}
                                    currentValue={currencyType}
                                    setValue={setCurrencyType}
                                    options={CurrencyType}
                                />

                                <InputContainer
                                    label={"Amount Owed"}
                                    type={"number"}
                                    currentValue={amountOwed}
                                    setValue={setAmountOwed}
                                    id={'amount_owed'}
                                />

                                <InputContainer
                                    label={"Amount Overdue"}
                                    type={"number"}
                                    currentValue={amountOverdue}
                                    setValue={setAmountOverdue}
                                    id={'amount_overdue'}
                                />

                                <InputAreaContainer
                                    label={"Selling Terms"}
                                    type={"text"}
                                    currentValue={sellingTerms}
                                    setValue={setSellingTerms}
                                    id={'selling_terms'}
                                />

                            </section>
                        </td>
                        <td className='tabledata'>
                            <SaveIcon className='editicons' onClick={() => RecordEdit()} />
                        </td>
                        <td className='tabledata'>
                            <CloseIcon className='editicons' onClick={() => setEdit(!edit)} />
                        </td>
                    </tr>
                    :
                    <tr key={item.trade_id} className='tablerow'>
                        <td className='tabledata'>{item.trade_reference}</td>
                        <td className='tabledata'>{item.paying_records}</td>
                        <td className='tabledata'>{item.currency_type.code}</td>
                        <td className='tabledata'>{item.amount_owed}</td>
                        <td className='tabledata'>{item.amount_overdue}</td>
                        <td className='tabledata'>
                            <EditIcon className='editicons' onClick={() => setEdit(!edit)} />
                        </td>
                        <td className='tabledata'>
                            <DeleteIcon className='editicons' onClick={() => RecordDelete(item.trade_id)} />
                        </td>
                    </tr>
            }
        </>

    )
}






