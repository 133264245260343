import React from 'react'
import { getDate } from '../../../js/getDate'
import { TableData, TableDataHeaders, TableHeading } from './ReportTemplates'

const Claims = ({ claims, enqDetails, slowPayers, color }) => {


    return (
        <table className='report_table'>
            <TableHeading
                span={5}
                color={color}
                heading={"Claims"}
            />

            <tr className='data_row' >
                <TableDataHeaders
                    id={"count_header"}
                    data={""}
                />

                <TableDataHeaders
                    id={"claimant_header"}
                    data={"Claimant"}
                />

                <TableDataHeaders
                    id={"currency_header"}
                    data={"Currency"}
                />

                <TableDataHeaders
                    id={"amount_header"}
                    data={"Amount"}
                />


                <TableDataHeaders
                    id={"claim_date_header"}
                    data={"Date of Claim "}
                />
            </tr>

            {
                (claims.data !== null && claims.data.filter(claim => claim.is_closed === false).length === 0 && (enqDetails && enqDetails.type !== 'internal')) &&
                <tr className='data_row'>
                    <td colSpan={10} style={{ color: 'green' }}> CLEAR</td>
                </tr>
            }


            {//mappping function

                claims.data !== null &&
                claims.data.sort((a, b) => a.is_closed - b.is_closed).map((claim, index) => {

                    if (claim.is_closed === false) {
                        return <Claim claim={claim} index={index} />
                    }

                    if (claim.is_closed === true && enqDetails && enqDetails.enqType === "internal") {

                        return (
                            <>
                                <Claim claim={claim} index={index} />
                                <tr className="closed-claim-details" style={{ background: "rgba(7, 193, 48, 0.26)", textAlign: "center" }}>
                                    <td colSpan={4} style={{ color: 'green' }}>{`Cleared - ${claim.comment}`}</td>
                                    <td>{getDate(claim.date_closed)}</td>
                                </tr>
                            </>
                        )
                    }
                })
            }

            {
                slowPayers.data !== null &&
                slowPayers.data.map((slowPayer, index) => {

                    return <SlowPayerClaim claim={slowPayer} index={index} />
                })
            }


        </table>
    )
}

const Claim = ({ claim, index }) => {
    const color = claim.is_closed ? 'rgba(7, 193, 48, 0.26)' : '';
    return (

        <tr className='data_row' style={{ background: color }}>
            <TableData
                id={"count_field"}
                data={index + 1}
            />

            <TableData
                id={"claimant_field"}
                data={claim.company_creditor_fins !== null ? claim.company_creditor_fins.registration_name : 'N/A'}
            />

            <TableData
                id={"currency_field"}
                data={claim.currency_type}
            />

            <TableData
                id={"amount_field"}
                data={claim.amount}
            />


            <TableData
                id={"claim_date_field"}
                data={getDate(claim.date_of_claim)}
            />
        </tr>
    )
}


const SlowPayerClaim = ({ claim, index }) => {


    return (

        <tr className='data_row' style={{ backgroundColor: `${claim.is_closed === true && "green"}` }}>
            <TableData
                id={"count_field"}
                data={index + 1}
            />

            <TableData
                id={"claimant_field"}
                data={claim.company_creditor.registration_name}
            />

            <TableData
                id={"currency_field"}
                data={claim.currency_type ? claim.currency_type : 'N/A'}
            />

            <TableData
                id={"amount_field"}
                data={claim.opening_amount ? claim.opening_amount : 'N/A'}
            />


            <TableData
                id={"claim_date_field"}
                data={getDate(claim.date ? claim.date : 'N/A')}
            />
        </tr>
    )
}

export default Claims