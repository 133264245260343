import React, { useContext, useState } from 'react'
import '../../css/bulkupload.css'
import { InputContainer, InputContainerReadOnly, MultiSelectContainerWithSearch, SelectContainer } from '../InputContainer'
import { BulkCreditType, BulkDataType, BulkDebtorType } from '../Company/selectOptionsjson'
import { ValuesContext } from '../Admin/HomeAdmin'

const NegativeDataUploadPanel = ({
    creditorName, setCreditorName,
    dataType, setDataType,
    dataSource, setDataSource,
    debtorType, setDebtorType,
    caller, creditType,
    setCreditType
}) => {
    const { userData } = useContext(ValuesContext)
    if (userData.data.groups[0] === 3 || userData.data.groups[0] === 4) {
        setDataSource(userData.data.username)
        setCreditorName(userData.data.company.fins_number)
    }
    return (
        <div className='dataentrypanel'>
            <div className='inputdivisionbulknegetive'>
                {(userData.data.groups[0] === 3 || userData.data.groups[0] === 4) ?
                    <InputContainerReadOnly
                        label={caller === 'Hire Purchase' ?
                            'Financier Name'
                            :
                            'Creditor Name'}
                        currentValue={userData.data.company.registration_name}
                        id={'creditorName'}
                    />
                    :
                    <MultiSelectContainerWithSearch
                        label={caller === 'Hire Purchase' ?
                            'Financier Name'
                            :
                            'Creditor Name'}
                        currentValue={creditorName}
                        width={'30%'}
                        setValue={setCreditorName}
                        id={'creditorName'}
                    />
                }

                {(userData.data.groups[0] === 3 || userData.data.groups[0] === 4) ?
                    <InputContainerReadOnly
                        label={'Data Source'}
                        currentValue={userData.data.username}
                        id={'dataSource'}
                    />

                    : <InputContainer
                        label={'Data Source'}
                        currentValue={dataSource}
                        setValue={setDataSource}
                        id={'dataSource'}
                    />
                }

                <SelectContainer
                    label={caller === 'Hire Purchase' ?
                        'Data Type'
                        :
                        'Debtor Type'}
                    currentValue={debtorType}
                    setValue={setDebtorType}
                    options={BulkDebtorType}
                />



            </div>

            <div className='inputdivisionbulknegetive'>
                {caller === 'ActiveAccounts' ?
                    <SelectContainer
                        label={'Credit Type'}
                        currentValue={creditType}
                        setValue={setCreditType}
                        options={BulkCreditType}
                    />
                    :
                    caller === 'Hire Purchase' ?
                        <InputContainer
                            label={'Data Date'}
                            type={'date'}
                            currentValue={creditType}
                            setValue={setCreditType}
                            id={'dataSource'}
                        />
                        :
                        <SelectContainer
                            label={'Data Type'}
                            currentValue={dataType}
                            setValue={setDataType}
                            options={BulkDataType}
                        />

                }

            </div>

        </div>
    )
}

export default NegativeDataUploadPanel