import React from 'react'
import { TableData, TableDataHeaders, TableHeading } from './ReportTemplates'

const IdentifictaionContactDetails = ({individual,color}) => {

    
    return (
        <table className='report_table'>
            <TableHeading
                span={6}
                color={color}
                heading={"Identification Details"}
            />

            <tr className='data_row' >
                <TableDataHeaders
                    id={"surname_header"}
                    data={"Surname  :"}
                    textAlignClass={'text_section_left_align'}
                />

                <TableData
                    id={"Surname_field"}
                    data={individual.surname}
                    textAlignClass={'text_section_left_align'}
                />

                <TableDataHeaders
                    id={"Forename_header"}
                    data={"First Name : "}
                    textAlignClass={'text_section_left_align'}
                />

                <TableData
                    id={"forename_field"}
                    data={individual.firstname}
                    textAlignClass={'text_section_left_align'}
                />
            </tr>

            <tr className='data_row' >
                <TableDataHeaders
                    id={"Nationaid_header"}
                    data={"National ID No.:"}
                    textAlignClass={'text_section_left_align'}
                />

                <TableData
                    id={"nationalid_field"}
                    data={individual.national_id  }
                    textAlignClass={'text_section_left_align'}
                />

                <TableDataHeaders
                    id={"dob_header"}
                    data={"Date Of Birth : "}
                    textAlignClass={'text_section_left_align'}
                />

                <TableData
                    id={"dob_field"}
                    data={individual.dob}
                    textAlignClass={'text_section_left_align'}
                />
            </tr>

            <tr className='data_row' >
                <TableDataHeaders
                    id={"MAritalStatus_header"}
                    data={"Marital Status  :"}
                    textAlignClass={'text_section_left_align'}
                />

                <TableData
                    id={"marital_status_field"}
                    data={individual.marital_status}
                    textAlignClass={'text_section_left_align'}
                />

                <TableDataHeaders
                    id={"gender_header"}
                    data={"Gender : "}
                    textAlignClass={'text_section_left_align'}
                />

                <TableData
                    id={"Gender_field"}
                    data={individual.gender}
                    textAlignClass={'text_section_left_align'}
                />
            </tr>

            
        </table>
    )
}


export const ContactDetails = ({individual,color}) => {

    return (

        <table className="report_table">

            <TableHeading
                span={6}
                color={color}
                heading={"Contact Details"}
            />


             <tr className='data_row' >
                <TableDataHeaders
                    id={"mobile_number_header"}
                    data={"Mobile Number  :"}
                    textAlignClass={'text_section_left_align'}
                />

                <TableData
                    id={"mobile_number_field"}
                    data={individual.mobile}
                    textAlignClass={'text_section_left_align'}
                />

                <TableDataHeaders
                    id={"Landline_header"}
                    data={"Telephone No.: "}
                    textAlignClass={'text_section_left_align'}
                />

                <TableData
                    id={"Landline_field"}
                    data={individual.telephone_no}
                    textAlignClass={'text_section_left_align'}
                />
            </tr>


            <tr className="data_row">
                <TableDataHeaders
                    id={"address_header"}
                    data={"Address  :"}
                    textAlignClass={'text_section_left_align'}
                />

                <TableData
                    id={"address_field"}
                    data={individual.address}
                    span={2}
                    textAlignClass={'text_section_left_align'}
                />
            </tr>

            <tr className="data_row">
                <TableDataHeaders
                    id={"town_header"}
                    data={"Email  :"}
                    textAlignClass={'text_section_left_align'}
                />

                <TableData
                    id={"town_field"}
                    data={individual.email}
                    span={3}
                    textAlignClass={'text_section_left_align'}
                />
            </tr>

        </table>
    )
}

export default IdentifictaionContactDetails