import React, { useState, useEffect, useRef, useContext } from 'react';
import { chargeCompanyCreditReport } from '../../../js/chargeClient';
import { fetchByFinsNumbers } from '../../../js/searchRecord';
import { handleResponses } from '../../../js/fetchData';
import "../../../css/IndividualReport.css"
import Absconder from './Absconder'
import Claims from './Claims'
import CourtRecords from './CourtRecords'
import Directorships from './Directorships'
import Employement from './Employement';
import axios from 'axios';
import HPI from './HPI'
import IdentifictaionContactDetails, { ContactDetails } from './IdentifictaionContactDetails'
import { filterAbsconderSlowPayerActive } from "../../../js/filterAbsconderSlowPayerActive";
import { ReportSummary } from './ReportSummary';
import { chargeIndividualCreditReport } from '../../../js/chargeClient';
import { IndividualReportFooter } from './IndividualReportFooter'
import { ActiveCreditAccs } from '../../Company/CompanyReport/ActiveCreditAccs'
import { IndividualReportheader } from './IndividualReportheader'
import Inquiries from './Inquiries'
import PublicInfo from './PublicInfo'
import { RiskClassification } from './RiskClassification'
import { Loader } from '../../Loader';
import { ScoreRange } from './ScoreRange'
import SlowPayer from './SlowPayer';
import { CompanyReportHeader } from '../../Company/CompanyReport/CompanyReportHeader';
import AuthContext from '../../../AuthContext/AuthContext';
import { TableHeading } from './ReportTemplates';

export const IndividualReport = () => {

    const [individual, setIndividual] = useState(JSON.parse(localStorage.getItem("data")));
    const { authTokens } = useContext(AuthContext);
    const [enqDetails, setEnqDetails] = useState(JSON.parse(localStorage.getItem("enq-details")));
    const [failureFetch, setFailureFetch] = useState(false);
    const [score, setScore] = useState({ data: null })
    const [search, setSearchResult] = useState({ data: null });
    const [activeCreditAccs, setActiveCreditAccs] = useState({ data: null });
    const [creditAccounts, setCreditAccounts] = useState({ data: null });
    const [historicals, setHistoricals] = useState({ data: null });
    const [claims, setClaims] = useState({ data: null })
    const [absconders, setAbsconders] = useState({ data: null });
    const [slowPayer, setSlowPayer] = useState({ data: null });
    const [courts, setCourts] = useState({ data: null })
    const [hirePurchases, setHirePurchases] = useState({ data: null });
    const [inquiries, setInquries] = useState({ data: null });
    const [publicInfo, setPublicInfo] = useState({ data: null });
    const [directorships, setDirectorships] = useState({ data: null });


    useEffect(() => {
        if (inquiries.data == null) {
            return;
        } else if (inquiries.message === 'fetch was successful') {
            setFailureFetch("done")
        }

    }, [inquiries])

    useEffect(() => {
        if (score.data === null) {
            return
        }

        fetchByFinsNumbers(
            String(authTokens.access),
            individual.fins_number,
            "individual-enquiry/",
            setInquries,
            setFailureFetch,
            null
        );

    }, [score])

    useEffect(() => {

        if (
            claims.message === "fetch was successful" &&
            courts.message === "fetch was successful" &&
            publicInfo.message === "fetch was successful" &&
            hirePurchases.message === "fetch was successful" &&
            creditAccounts.message === "fetch was successful" &&
            directorships.message === "fetch was successful" &&
            enqDetails !== null) {

            setFailureFetch("done")
            // charging the client
            chargeIndividualCreditReport(individual.fins_number, enqDetails.user, setSearchResult, setScore);
            filterAbsconderSlowPayerActive(claims, setClaims, creditAccounts, setActiveCreditAccs, setAbsconders, setSlowPayer, setHistoricals);

        }

    }, [claims, courts, publicInfo, hirePurchases, creditAccounts, directorships])


    useEffect(() => {

        axios.post(`/claim-search/`, {

            headers: {
                Accept: "application/json",
                "Content-Type": "application/json"
            }
            ,
            data: {
                searchParameter: "individual_debtor_fins",
                fins: individual.fins_number
            }
        })
            .then(res => {

                if (res.status !== 200) {
                    setFailureFetch(true)
                }
                handleResponses(res, setClaims);

            })
            .catch(err => {
                setFailureFetch(true)
                handleResponses(err, setClaims);
            })


        fetchByFinsNumbers(
            String(authTokens.access),
            individual.fins_number,
            'individual-court/',
            setCourts,
            setFailureFetch,
            null
        );

        fetchByFinsNumbers(
            String(authTokens.access),
            individual.fins_number,
            "individual-hire-purchase/",
            setHirePurchases,
            setFailureFetch,
            null
        );


        fetchByFinsNumbers(
            String(authTokens.access),
            individual.fins_number,
            "individual-public-info/",
            setPublicInfo,
            setFailureFetch,
            null,
        );

        /*   fetchByFinsNumbers(
              String(authTokens.access),
              individual.fins_number,
              "individual-enquiry/",
              setInquries,
              setFailureFetch,
              null,
           );
   */

        fetchByFinsNumbers(
            String(authTokens.access),
            individual.fins_number,
            "individual-executive/",
            setDirectorships,
            setFailureFetch,
            null,
        );


        fetchByFinsNumbers(
            String(authTokens.access),
            individual.fins_number,
            "individual-credit-details/",
            setCreditAccounts,
            setFailureFetch,
            null,
        );



    }, [])

    return (
        <>
            {
                failureFetch === false &&
                <Loader />
            }

            {
                failureFetch === true &&
                <p className='failed-to-fetch' style={{ color: "red", textAlign: "center", marginTop: "1em" }}>Something went wrong,try again</p>
            }

            {
                failureFetch === "done" &&
                <Reports
                    activeCreditAccs={activeCreditAccs}
                    historicals={historicals}
                    courts={courts}
                    hirePurchases={hirePurchases}
                    inquiries={inquiries}
                    publicInfo={publicInfo}
                    claims={claims}
                    directorships={directorships}
                    absconders={absconders}
                    slowPayer={slowPayer}
                    individual={individual}
                    score={score}
                    enqDetails={enqDetails}
                />
            }
        </>
    )
}

const Reports = ({ individual, enqDetails, courts, claims, hirePurchases, inquiries, publicInfo, directorships, absconders, score, activeCreditAccs, slowPayer, historicals }) => {

    const [showBtn, setShowBtn] = useState(true)
    const type = useRef("")
    return (
        <>

            <table ref={type} className='Individual_report'>

                <tr className='report_section'>
                    <CompanyReportHeader name={`${individual.firstname} ${individual.surname}`} />
                </tr>

                <table border={2} style={{ width: '100%', padding: '20px' }}>

                    <tr className='report_section'>
                        <RiskClassification score={score} color={'#305496'} />

                    </tr>

                    <tr className='report_section'>
                        <ScoreRange score={score} color={'#b4c6e7'} />
                    </tr>

                </table>


                <table border={2} style={{ width: '100%', padding: '20px', marginTop: '20px' }}>

                    <tr className='row_heading' style={{ margin: '20px 0', backgroundColor: '#305496', color: 'white' }}>
                        <td className='txt_rowheading'>
                            <h3 className='section_heading'>
                                PERSONAL DETAILS
                            </h3>
                        </td>
                    </tr>

                    <tr className='report_section'>
                        <IdentifictaionContactDetails individual={individual} color={'#b4c6e7'} />
                    </tr>

                    <tr className='report_section'>
                        <ContactDetails individual={individual} color={'#b4c6e7'} />
                    </tr>

                    <tr className='report_section'>
                        <Employement individual={individual} color={'#b4c6e7'} />
                    </tr>
                </table>



                <table border={2} style={{ width: '100%', padding: '10px', marginTop: '20px' }}>


                    <tr className="report_section">
                        <ReportSummary
                            absconders={absconders}
                            historicals={historicals}
                            activeCreditAccs={activeCreditAccs}
                            claims={claims}
                            courts={courts}
                            inquiries={inquiries}
                            hirePurchases={hirePurchases}
                            slowPayer={slowPayer}
                            enqDetails={enqDetails}
                            color={'#305496'} />

                    </tr>

                </table>


                <table border={2} style={{ width: '100%', padding: '10px', marginTop: '20px' }}>

                    <tr className='row_heading' style={{ margin: '20px 0', backgroundColor: '#b4c6e7' }}>
                        <td className='txt_rowheading'>
                            <h3 className='section_heading'>
                                CREDIT ACCOUNTS
                            </h3>
                        </td>
                    </tr>


                    <tr className="report_section">
                        <ActiveCreditAccs activeCreditAccs={activeCreditAccs} color={'#d6dce4'} />
                    </tr>

                    <                tr className='report_section'>
                        <HPI hirePurchases={hirePurchases} color={'#d6dce4'} />
                    </tr>

                    <tr className="report_section">
                        <ActiveCreditAccs activeCreditAccs={historicals} isHistorical color={'#d6dce4'} />
                    </tr>

                </table>


                <table border={2} style={{ width: '100%', padding: '10px', marginTop: '20px' }}>

                    <tr className='row_heading' style={{ margin: '20px 0', backgroundColor: '#b4c6e7' }}>
                        <td className='txt_rowheading'>
                            <h3 className='section_heading'>
                                ADVERSE RECORDS
                            </h3>
                        </td>
                    </tr>


                    <tr className='report_section'>
                        <Claims claims={claims} enqDetails={enqDetails} slowPayers={slowPayer} color={'#d6dce4'} />
                    </tr>

                    <tr className='report_section'>
                        <Absconder absconders={absconders} color={'#d6dce4'} enqDetails={enqDetails} />
                    </tr>

                    <tr className='report_section'>
                        <CourtRecords courts={courts} color={'#d6dce4'} enqDetails={enqDetails} />
                    </tr>

                </table>

                <table border={2} style={{ width: '100%', padding: '10px', marginTop: '20px' }}>
                    <tr className='report_section'>
                        <Inquiries inquiries={inquiries} enqDetails={enqDetails} color={'#b4c6e7'} />
                    </tr>
                </table>

                <table border={2} style={{ width: '100%', padding: '10px', marginTop: '20px' }}>
                    <tr className='report_section'>
                        <PublicInfo publicInfo={publicInfo} color={'#b4c6e7'} />
                    </tr>
                </table>



                <table border={2} style={{ width: '100%', padding: '10px', marginTop: '20px' }}>
                    <tr className='row_heading' style={{ margin: '20px 0', backgroundColor: '#305496', color: 'white' }}>
                        <td className='txt_rowheading'>
                            <h3 className='section_heading'>
                                AS KEY PERSON IN COMPANIES
                            </h3>
                        </td>
                    </tr>

                    <tr className='report_section'>
                        <Directorships directorships={directorships} color={'#b4c6e7'} isExec={false} />
                        <Directorships directorships={directorships} color={'#b4c6e7'} isExec />
                    </tr>
                </table>



                <tr className='report_section'>
                    <IndividualReportFooter />
                </tr>


            </table>
            {showBtn && <PrintBtn showBtn={showBtn} setShowBtn={setShowBtn} type={type} />}
        </>
    )
}

export const PrintBtn = ({ showBtn, setShowBtn, type }) => {

    const printReport = () => {
        type.current.classList.add("full-width");
        setShowBtn(false);


        setTimeout(() => {
            window.print();
        }, 500)

        setTimeout(() => {
            type.current.classList.remove("full-width");
            setShowBtn(true)
        }, 1000)

    }

    return (

        <div className="report" onClick={() => printReport()}>
            <button className="print-btn">
                Print
            </button>
        </div>
    )
}

