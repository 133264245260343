import axios from "axios";
import { toast } from "react-toastify";

export const closeIndivdualRecord = (id, recordType, userData, authTokens, setShowModal, setCloseStatus, searchedEntity, navigate) => {
    setShowModal(true)
    axios.post('/close-record/', {
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${authTokens.access}`
        },
        data: {
            id: id,
            closed_by: `${userData.data.firstname[0]} ${userData.data.surname}`,
            record_type: recordType
        }
    })
        .then((res) => {
            setCloseStatus({ statusCode: res.status, message: 'All records were closed successfully' });
            localStorage.setItem('clearanceDetails', JSON.stringify({
                userData: { firstname: userData.data.firstname, lastname: userData.data.surname, position: userData.data.position },
                cleared: { ...searchedEntity.data[0] }
            }))
            setTimeout(() => {
                navigate("/admin/print-clearance-letter");
            }, 2000)
        })
        .catch(err => {
            setCloseStatus({ statusCode: err.status, message: 'Record not closed,try again' })
        })

}

export const deleteRecord = (id, recordType, authTokens, data, setData) => {

    axios.delete(`/${recordType.toLowerCase()}/${id}/`, {
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${authTokens.access}`
        }
    })
        .then(response => {
            toast.success('Record deleted successfully');

            if (recordType === 'claim' || recordType === 'absconder') {
                setData({
                    data: data.data.filter(d => id !== d.claim_number)
                })
            } else if (recordType === 'court') {
                setData({
                    data: data.data.filter(d => id !== d.case_number)
                })
            } else if (recordType === 'slowpayer') {
                setData({
                    data: data.data.filter(d => id !== d.credit_detail)
                })
            }

        })
        .catch(err => {
            if (err.response) {
                if (err.response.status === 401) {
                    toast.error('Not authorized,try logging in again')
                } else if (err.response.status === 500) {
                    toast.error('An internal server error occurred,try again')
                } else if (err.response.status === 404) {
                    toast.error('Resource not found,try again')
                } else {
                    toast.error('An internal server error occurred,try again')
                }
            } else if (err.request) {
                toast.error('Request timed out,try again')
            } else {
                toast.error('An error occured in setting up your request,try again.')
            }
        })
}