import React from 'react'
import './../css/OurProducts.css';
import { productsDescs } from '../js/data';
import creditPNG from './../backgrounds/credit.png'
import BIUIntelligencePNG from './../backgrounds/business-intelligence.png';
import borrowPNG from './../backgrounds/borrow.png';
import analysisPNG from './../backgrounds/borrow.png';
import { useState } from 'react';
export const OurProducts = () => {

    return (

        <section id="our-products" className="our-products-section">

            <h3 className="our-products-section__header">
                our products
            </h3>

            <div className="products-container">

                <Product
                    img={creditPNG}
                    alt={"credit"}
                    productDesc={productsDescs.creditBureau}
                    productName={"credit bureau"}
                />

                <Product
                    img={BIUIntelligencePNG}
                    alt={"business intelligence"}
                    productDesc={productsDescs.businessIntelligenceReports}
                    productName="business intelligence reports"
                />

                <Product
                    img={borrowPNG}
                    alt={"debt recovery"}
                    productDesc={productsDescs.debtRecovery}
                    productName="debt recovery and control outsource"
                />

                <Product
                    img={analysisPNG}
                    alt={"training"}
                    productDesc={productsDescs.training}
                    productName="training"
                />
            </div>
        </section>
    )
}

const Product = ({ img, productDesc, alt, productName }) => {

    const [readMore, setReadMore] = useState(false);
    const [btnState, setBtnState] = useState("read more")
    const splittedDesc = productDesc.slice(0, 131);
    const remainderDesc = productDesc.slice(131);

    const toggleReadMore = () => {

        setReadMore(!readMore);

        if (btnState === "read more") {
            setBtnState("read less")
        } else {
            setBtnState("read more")
        }
    }

    return (
        <div className="product">

            <div className="product-header">
                <div className="img-container">
                    <img src={img} alt={alt} />
                </div>
                <p className="product-name">{productName}</p>
            </div>

            <p className="product-desc">
                {splittedDesc}{readMore && <RemainderDesc remainderDesc={remainderDesc} />}
            </p>

            <button className="read-more-btn" onClick={toggleReadMore}>
                {btnState}
            </button>
        </div>
    )

}

const RemainderDesc = ({ remainderDesc }) => {

    return (
        <span>
            {remainderDesc}
        </span>
    )
}