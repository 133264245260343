import React from 'react'
import { TableData, TableDataHeaders, TableHeading } from '../../Individuals/IndividualReport/ReportTemplates'

const HPI = ({ hirePurchases, color }) => {
    return (
        <table className='report_table'>


            <tr className='row_heading' style={{ margin: '50px 0', backgroundColor: '#d6dce4', color: 'black' }}>
                <td className='txt_rowheading' colSpan={12}>
                    <h1 className='section_heading' style={{ fontSize: '1rem' }}>
                        Hire Purchase Information
                    </h1>
                </td>
            </tr>

            <tr className='data_row' >
                <TableDataHeaders
                    id={"count_header"}
                    data={""}
                />

                <TableDataHeaders
                    id={"financier_header"}
                    data={"Financier"}
                />

                <TableDataHeaders
                    id={"credit_type_header"}
                    data={"Credit Type"}
                />

                <TableDataHeaders
                    id={"make_header"}
                    data={"Make"}
                />

                <TableDataHeaders
                    id={"model_header"}
                    data={"Model"}
                />

                <TableDataHeaders
                    id={"registration_number_header"}
                    data={"Reg No / Serial No"}
                />

                <TableDataHeaders
                    id={"chasis_number_header"}
                    data={"Chassis Number"}
                />


                <TableDataHeaders
                    id={"engine_number_header"}
                    data={"Engine Number"}
                />

                <TableDataHeaders
                    id={"year_header"}
                    data={"Start Date"}
                />

                <TableDataHeaders
                    id={"year_header"}
                    data={"End Date"}
                />

                <TableDataHeaders
                    id={"year_header"}
                    data={"Balance Owing"}
                />

                <TableDataHeaders
                    id={"year_header"}
                    data={"Overdue Amount"}
                />
            </tr>
            {/* 
            {
            (hirePurchases.data !== null && hirePurchases.data.length === 0) &&
            <tr className='data_row'>
                <td colSpan={10}></td>
            </tr>
            } */}

            {//mappping function

                hirePurchases.data !== null &&
                hirePurchases.data.map((hirePurchase, index) => {

                    return <HirePurchase hirePurchase={hirePurchase} index={index} />
                })
            }


        </table>
    )
}

const HirePurchase = ({ hirePurchase, index }) => {

    return (

        <tr className='data_row' >
            <TableData
                id={"count_field"}
                data={index + 1}
            />

            <TableData
                id={"financier"}
                data={hirePurchase.financier_company ? hirePurchase.financier_company.registration_name : 'N/A'}
            />

            <TableData
                id={"financier"}
                data={hirePurchase.asset_type ? hirePurchase.asset_type : 'N/A'}
            />

            <TableData
                id={"make_field"}
                data={hirePurchase.make ? hirePurchase.make : 'N/A'}
            />

            <TableData
                id={"model_field"}
                data={hirePurchase.model ? hirePurchase.model : 'N/A'}
            />

            <TableData
                id={"registration_number_field"}
                data={hirePurchase.reg_number ? hirePurchase.reg_number : hirePurchase.serial}
            />


            <TableData
                id={"chasis_number_field"}
                data={hirePurchase.chasis ? hirePurchase.chasis : 'N/A'}
            />

            <TableData
                id={"engine_number_field"}
                data={hirePurchase.engine_number ? hirePurchase.engine_number : 'N/A'}
            />

            <TableData
                id={"year_field"}
                data={hirePurchase.agreement_start_date ? hirePurchase.agreement_start_date : 'N/A'}
            />

            <TableData
                id={"year_field"}
                data={hirePurchase.agreement_due_date ? hirePurchase.agreement_due_date : 'N/A'}
            />


            <TableData
                id={"chasis_number_field"}
                data={hirePurchase.balance_owing ? hirePurchase.balance_owing : 'N/A'}
            />

            <TableData
                id={"chasis_number_field"}
                data={hirePurchase.overdue_amount ? hirePurchase.overdue_amount : 'N/A'}
            />
        </tr>
    )
}

export default HPI