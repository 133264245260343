import React from 'react'
import './../css/AboutFincheck.css';
import aboutFincheckJPG from './../backgrounds/about-fincheck.jpg'
export const AboutFincheck = () => {

    return (

        <section id="about-us" className="about-fincheck-section">

            <h3 className="about-fincheck-section__header">
                about fincheck
            </h3>

            <div className="about-fincheck-info-container">

                <article className="about-fincheck-info">

                    <p className="about-fincheck-info__para">
                        Fincheck (Pvt) Ltd, formerly Dun & Bradstreet and TransUnion is a company which provides insightful business information for Zimbabwean organisations for credit risk management, analysis and strategic planning purposes.
                    </p>
                </article>
            </div>

            <div className="about-fincheck-illustration">

                <div className="about-fincheck-illustration-container">
                    <img src={aboutFincheckJPG} alt="About fincheck illustration" className="about-fincheck-illustration" />
                </div>
            </div>

        </section>
    )
}