import React from 'react'
import { TableData, TableDataHeaders, TableHeading } from '../IndividualReport/ReportTemplates'

export const BIUContactInfo = ({ individual }) => {
    return (

        <table className="report_table">

            <TableHeading
                heading={"Contact Information"}
                span={4}
            />

            <tr className="data_row">
                <TableDataHeaders
                    data={"Name"}
                />

                <TableData
                    data={individual.firstname + ` ${individual.forenames} ` + individual.surname}
                />
            </tr>

            <tr className="data_row">
                <TableDataHeaders
                    data={"Gender"}
                />

                <TableData
                    data={individual.gender}
                />
            </tr>

            <tr className="data_row">
                <TableDataHeaders
                    data={"Mobile"}
                />

                <TableData
                    data={individual.mobile}
                />
            </tr>

            <tr className="data_row">
                <TableDataHeaders
                    data={"Date Of Birth"}
                />

                <TableData
                    data={individual.dob}
                />
            </tr>

            <tr className="data_row">
                <TableDataHeaders
                    data={"National ID"}
                />

                <TableData
                    data={individual.national_id}
                />
            </tr>

            <tr className="data_row">
                <TableDataHeaders
                    data={"Marital Status"}
                />

                <TableData
                    data={individual.marital_status}
                />
            </tr>

            <tr className="data_row">
                <TableDataHeaders
                    data={"Current Address"}
                />

                <TableData
                    data={individual.address}
                />
            </tr>

            <tr className="data_row">
                <TableDataHeaders
                    data={"Former Address"}
                />

                <TableData
                    data={individual.address}
                />
            </tr>
        </table>
    )
}
