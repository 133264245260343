import './../../css/Searchtemplate.css';
import React, { useEffect, useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import AddBusinessIcon from '@mui/icons-material/AddBusiness';
import EditIcon from '@mui/icons-material/Edit';
import { Modal } from '../Modal';
import { ValuesContext } from '../Admin/HomeAdmin';
import { Loader } from '../Loader';
import { FilterRecords } from '../FilterRecords';
import { EditCompanyFormsContainer } from './EditCompanyFormsContainer';
import { ReportChecking } from '../ReportChecking';
import { checkIfUserInternal } from '../../js/snippets';
import CompanyAddBIU from './Add/CompanyAddBIU';
import { CompanyUploads } from './CompanyUploads';
import { AddEditCompany } from './AddEditCompany';
import axios from 'axios';
import DeleteIcon from '@mui/icons-material/Delete';
import { toast } from 'react-toastify';
import AuthContext from '../../AuthContext/AuthContext';

export const SearchCompanies = ({ edit }) => {

    const { fetchedCompanies, setFetchedCompanies } = useContext(ValuesContext);
    const [searchTerm, setSearchTerm] = useState("");
    const [activeSearch, setActiveSearch] = useState(false);
    const [activeDisplay, setActiveDisplay] = useState("fetched")
    const [allSearchResult, setAllSearchResult] = useState({
        data: [],
        message: ""
    })
    const [searchResult, setSearchResult] = useState({
        data: [],
        message: ""
    });
    const searchParameters = [
        { value: "registration_name", displayedName: "Registration Name" },
        { value: "registration_number", displayedName: "Registration Number" }
    ]



    return (

        <section className="search-companies">

            <div className="search-companies-header">
                <p>Company Enquiries</p>
            </div>

            {/* <FilterCompany setAllCompanies={setAllCompanies}/> */}

            <FilterRecords
                searchTermLabel={"filter by registration name / number"}
                searchParameters={searchParameters}
                RecordIcon={AddBusinessIcon}
                addRecordText={"add company"}
                setSearchResult={setSearchResult}
                setActiveSearch={setActiveSearch}
                setActiveDisplay={setActiveDisplay}
                endpoint={"search-company"}
                AddRecordForm={CompanyUploads}
                bulkType={"Company Credit Reports"}
                edit={edit}
                searchTerm={searchTerm}
                setSearchTerm={setSearchTerm}
                allSearchResult={allSearchResult}
                setAllSearchResult={setAllSearchResult}
            />

            <div className="companies-container">

                <div className="companies-header">
                    <h4>registration name</h4>
                    <h4>registration number</h4>
                    {!edit && <h4>select</h4>}
                    {
                        edit &&
                        <>
                            <h4>Edit</h4>
                        </>
                    }
                    {
                        edit &&
                        <h4>Delete</h4>
                    }
                </div>

                {/* Rendering all companies */}


                {
                    activeDisplay === "fetched" ? <FetchedCompanies fetchedCompanies={fetchedCompanies} edit={edit} setFetchedCompanies={setFetchedCompanies} /> :
                        <SearchedCompanies
                            searchResult={searchResult}
                            setSearchResult={setSearchResult}
                            activeSearch={activeSearch}
                            edit={edit}
                            searchTerm={searchTerm}
                            setSearchTerm={setSearchTerm}
                            setActiveDisplay={setActiveDisplay}
                        />
                }


            </div>
        </section>
    )
}

const FetchedCompanies = ({ fetchedCompanies, edit, setFetchedCompanies }) => {



    return (

        <>
            {
                fetchedCompanies.data.length === 0 ? <p className='message'>Search for company</p> :

                    fetchedCompanies.data.map((company, index) => {

                        return (
                            <Company index={index} key={company.fins_number} company={company} edit={edit} companies={fetchedCompanies} setCompanies={setFetchedCompanies} />
                        )
                    })
            }
        </>
    )
}


const SearchedCompanies = ({ activeSearch, searchResult, edit, setSearchResult, searchTerm, setSearchTerm, setActiveDisplay }) => {

    const [showAddRecordForm, setShowAddRecordForm] = useState(false);
    const [displayMessage, setDisplayMessage] = useState(searchResult.data.length === 0 ? true : false)

    useEffect(() => {

        if (showAddRecordForm === true) {
            setSearchTerm('')
        }
    }, [showAddRecordForm])

    return (

        <>
            {showAddRecordForm && <Modal Component={CompanyUploads} setFormStatus={setShowAddRecordForm} />}
            {

                activeSearch === true ? <Loader /> :
                    searchResult.data === null ? <p className='message'>{`${searchResult.message} `}</p> :

                        // render individuals if available

                        (searchResult.data.length === 0 && searchTerm.length > 0) ?
                            <div className="not-found-container">
                                <p>No search results found for {searchTerm}. Please add company details by pressing OK</p>
                                <div className="not-found-buttons-container">
                                    <button className="ok-btn" onClick={() => setShowAddRecordForm(true)}>ok</button>
                                </div>
                            </div> :
                            searchResult.data.map((company, index) => {
                                return (
                                    <Company index={index} key={company.national_id} company={company} edit={edit} companies={searchResult} setCompanies={setSearchResult} />
                                )
                            })

            }
        </>
    )
}


const Company = ({ company, index, edit, companies, setCompanies }) => {

    const [checked, setChecked] = useState(false);
    const navigate = useNavigate();
    const [showEditForm, setShowEditForm] = useState(false);
    const [showReportChecking, setReportChecking] = useState(false);
    const { userData } = useContext(ValuesContext);
    const { authTokens } = useContext(AuthContext);



    const deleteCompany = () => {
        axios.delete(`/company/${company.fins_number}`, {
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + String(authTokens.access)
            }
        }).
            then(() => {
                toast.success('Company was deleted')

                const newComp = companies.data.filter(comp => comp.fins_number !== company.fins_number);
                setCompanies({
                    data: newComp
                })
            })
            .catch(err => {

                if (err.response) {
                    toast.error('Internal Server Error,try again')
                } else if (err.request) {
                    toast.error('Error occured while processing request,try again')
                } else {
                    toast.error('An error occured,try again');
                }
            })
    }



    return (
        <article key={company.fins_number} className="company" style={{ backgroundColor: index % 2 === 0 && 'white' }}>

            {/* Enabling the Modal to pop up if it meets the conditions */}
            {
                showEditForm &&
                <Modal
                    Component={EditCompany}
                    setFormStatus={setShowEditForm}
                    data={company}
                />
            }

            {
                /* Show report checking */
                showReportChecking && (userData.data.groups[0] === 1 || userData.data.groups[0] === 2) &&
                <Modal
                    Component={ReportChecking}
                    setFormStatus={setReportChecking}
                    data={company}
                />
            }

            <p>{company.registration_name}</p>
            <p>{company.registration_number}</p>

            {/* Rendering different UI components based on the priviledges */}
            {
                !edit ?

                    <p>
                        <RadioButtonUncheckedIcon
                            className={`checkbox ${checked && 'selected'}`}

                            onClick={() => checkIfUserInternal(userData, setReportChecking, company, navigate, setChecked, "company")}
                        />
                    </p> :

                    <>
                        <div className="edit-btn-container" onClick={() => setShowEditForm(true)}>
                            <button className="edit-btn">
                                <EditIcon className="edit-icon" />
                            </button>
                        </div>

                    </>

            }

            {
                edit &&
                <p>
                    <button onClick={deleteCompany} style={{ backgroundColor: 'var(--main-color)', border: 'none', borderRadius: '5px', cursor: 'pointer' }}>
                        <DeleteIcon style={{ color: 'white' }} />
                    </button>
                </p>
            }

        </article>
    )
}

const EditCompany = ({ data }) => {

    return (

        <>
            <AddEditCompany
                companyData={[data]}
                caller={'editBureau'}
            />

        </>
    )
}